import { Box, Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import {
  getTradingEvent,
  getTradingPrice,
} from "../../../common/utils/getTradingValues";

import useGetUser from "../../../common/hooks/useGetUser";
import useResponsive from "../../../common/utils/useResponsive";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import { numberWithCommas } from "../../../common/utils/numberWithCommas";
import { ENV, MINTING_ADDRESS, NETWORKS } from "../../../../constants";
import { useTranslation } from "react-i18next";
import { OWNER_CUT } from "../../../../constants";
import { useParams } from "react-router-dom";

const IMAGE_SIZE = 60;
const IMAGE_SIZE_MOBILE = 75;

const NATIVE_TRANSACTION_CLASSES = {
  "0x1": "EthTransactions",
  "0x3": "EthTransactions",
  "0x13881": "PolygonTransactions",
  "0x89": "PolygonTransactions",
};

const TOKEN_TRANSFER_CLASSES = {
  "0x1": "EthTokenTransfers",
  "0x3": "EthTokenTransfers",
  "0x13881": "PolygonTokenTransfers",
  "0x89": "PolygonTokenTransfers",
};

function ProfileTrading({ transfer: _transfer }) {
  const history = useHistory();

  const { t } = useTranslation();

  const { Moralis, user, web3 } = useMoralis();
  const { native } = useMoralisWeb3Api();

  const [price, setPrice] = useState(null);

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { transfer, item: _item } = _transfer;

  const item = JSON.parse(JSON.stringify(_item));

  const [deletedState, setDeletedState] = useState();

  const { user: fromUser, loading: fromUserLoading } = useGetUser(
    transfer?.from_address || transfer?.get("from_address")
  );

  const { user: toUser, loading: toUserLoading } = useGetUser(
    transfer?.to_address || transfer?.get("to_address")
  );

  const event = getTradingEvent(
    transfer?.from_address || transfer?.get("from_address"),
    transfer?.to_address || transfer?.get("to_address"),
    user?.get("accounts")[0]
  );

  const initTransfer = async () => {
    const transactionHash =
      transfer?.transaction_hash || transfer?.get("transaction_hash");
    const chainId = item?.chainId || item?.get("chainId");
    let price;
    if (transactionHash) {
      const nativeTransactionQuery = new Moralis.Query(
        NATIVE_TRANSACTION_CLASSES[chainId]
      );
      nativeTransactionQuery.equalTo("hash", transactionHash);
      const nativeResult = await nativeTransactionQuery.first();
      let value = nativeResult?.get("value");
      if (value > 0) {
        price = Number(value) / (1 + OWNER_CUT / 10000);
        if (!isNaN(price)) {
          setPrice(Moralis.Units.FromWei(price?.toString()));
        }
      } else {
        const tokenTransferQuery = new Moralis.Query(
          TOKEN_TRANSFER_CLASSES[chainId]
        );
        tokenTransferQuery.equalTo("transaction_hash", transactionHash);
        tokenTransferQuery.equalTo("confirmed", true);
        const tokenTransferResult = await tokenTransferQuery.first();
        const tokenValue = tokenTransferResult?.get("value");
        price = Number(tokenValue) / (1 + OWNER_CUT / 10000);
        if (!isNaN(price)) {
          setPrice(Moralis.Units.FromWei(price?.toString()));
        }
      }
    }

    try {
      const isDeleted = item?.isDeleted || item?.get("isDeleted");
      if (isDeleted) {
        setDeletedState(isDeleted);
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => await initTransfer())();
  }, [_transfer]);

  const onImageClick = () => {
    history.push(
      ROUTE_NAMES.market + `/${item?.tokenId || item?.get("tokenId")}`
    );
  };

  const onUsernameClick = (ethAddress) => {
    if (!ethAddress) return;
    const userAddress = user?.ethAddress || user?.get("ethAddress");
    if (ethAddress === userAddress) {
      history.push(ROUTE_NAMES.profile);
    } else {
      history.push(ROUTE_NAMES.profile + `/${ethAddress}`);
    }
  };

  const loading = toUserLoading || fromUserLoading;

  return !isMobile ? (
    <Flex
      alignItems="center"
      padding="20px 25px"
      bg="#F6F5F7"
      borderRadius="19px"
      margin="12px 0px"
      width="100%"
    >
      {!loading && (
        <>
          <Box
            cursor="pointer"
            onClick={onImageClick}
            pointerEvents={deletedState ? "none" : "auto"}
          >
            {!item?.isVideo ? (
              <img
                className="lazyload"
                src={item?.nftFilePath || item?.get("nftFilePath")}
                style={{
                  width: IMAGE_SIZE,
                  height: IMAGE_SIZE,
                  borderRadius: 6,
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                className="lazyload"
                src={item?.nftFilePath || item?.get("nftFilePath")}
                style={{
                  width: IMAGE_SIZE,
                  height: IMAGE_SIZE,
                  objectFit: "cover",
                  borderRadius: 6,
                }}
                playsInline
                autoPlay
                loop
                muted
              />
            )}
          </Box>
          <Box
            flex={2}
            borderRight="1px solid #C6C6C6"
            fontSize="13px"
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("profile.price")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {price
                ? numberWithCommas(price) +
                  `[${
                    NETWORKS[ENV]?.[item?.chainId || item?.get("chainId")]?.unit
                  }]`
                : "-"}{" "}
            </Box>
          </Box>
          <Box
            flex={1}
            borderRight="1px solid #C6C6C6"
            fontSize="13px"
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("profile.event")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {event}
            </Box>
          </Box>

          <Box
            flex={1}
            borderRight="1px solid #C6C6C6"
            fontSize="13px"
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              From
            </Box>
            <Box
              onClick={() =>
                onUsernameClick(
                  fromUser?.ethAddress || fromUser?.get("ethAddress")
                )
              }
              cursor="pointer"
              w="100%"
              noOfLines={1}
            >
              {transfer?.from_address === MINTING_ADDRESS
                ? "-"
                : fromUser?.username ||
                  fromUser?.get("username") ||
                  transfer?.from_address ||
                  "-"}
            </Box>
          </Box>
          <Box
            flex={1}
            borderRight="1px solid #C6C6C6"
            fontSize="13px"
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              To
            </Box>
            <Box
              onClick={() =>
                onUsernameClick(toUser?.ethAddress || toUser?.get("ethAddress"))
              }
              cursor="pointer"
              w="100%"
              noOfLines={1}
            >
              {transfer?.to_address === MINTING_ADDRESS
                ? "-"
                : toUser?.username ||
                  toUser?.get("username") ||
                  transfer?.to_address}
            </Box>
          </Box>
          <Box flex={1} fontSize="13px" padding="0px 17px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("profile.date")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(
                transfer?.block_timestamp || transfer?.get("block_timestamp")
              ).format("YYYY.MM.DD")}
            </Box>
          </Box>
        </>
      )}
    </Flex>
  ) : (
    <Box padding="22px 20px" bg="#F6F5F7" margin="10px 0px" borderRadius="19px">
      {!loading && (
        <>
          <Flex alignItems="center" mb="27px">
            <Box cursor="pointer" onClick={onImageClick}>
              {!item?.isvide ? (
                <img
                  className="lazyload"
                  src={item?.nftFilePath || item?.get("nftFilePath")}
                  style={{
                    width: IMAGE_SIZE_MOBILE,
                    height: IMAGE_SIZE_MOBILE,
                    borderRadius: 6,
                    objectFit: "cover",
                  }}
                />
              ) : (
                <video
                  className="lazyload"
                  src={item?.nftFilePath || item?.get("nftFilePath")}
                  style={{
                    width: IMAGE_SIZE_MOBILE,
                    height: IMAGE_SIZE_MOBILE,
                    objectFit: "cover",
                    borderRadius: 6,
                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                />
              )}
            </Box>
            <Flex
              flex={1}
              alignItems="center"
              bg="white"
              borderTopRightRadius="5px"
              borderBottomRightRadius="5px"
              paddingTop="11px"
              paddingBottom="11px"
              paddingLeft="51px"
              overflow="hidden"
            >
              <Box flex={1} fontSize="13px">
                <Box
                  color="#000000"
                  fontWeight="bold"
                  mb="8px"
                  w="100%"
                  noOfLines={1}
                >
                  {t("profile.price")}
                </Box>
                <Box w="100%" noOfLines={1}>
                  {price
                    ? numberWithCommas(price) +
                      `[${
                        NETWORKS[ENV]?.[item?.chainId || item?.get("chainId")]
                          ?.unit
                      }]`
                    : "-"}{" "}
                </Box>
              </Box>
            </Flex>
          </Flex>

          <Flex
            flex={1}
            alignItems="center"
            bg="white"
            borderRadius="5px"
            padding="11px 24px"
            mb="27px"
          >
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                {t("profile.event")}
              </Box>
              <Box w="100%" noOfLines={1}>
                {event}
              </Box>
            </Box>
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                From
              </Box>
              <Box
                onClick={() => onUsernameClick(fromUser?.get("ethAddress"))}
                w="100%"
                noOfLines={1}
              >
                {transfer?.from_address === MINTING_ADDRESS
                  ? "-"
                  : fromUser?.username ||
                    fromUser?.get("username") ||
                    transfer?.from_address ||
                    "-"}
              </Box>
            </Box>
          </Flex>
          <Flex
            flex={1}
            alignItems="center"
            bg="white"
            borderRadius="5px"
            padding="11px 24px"
          >
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                To
              </Box>
              <Box
                onClick={() => onUsernameClick(toUser?.get("ethAddress"))}
                w="100%"
                noOfLines={1}
              >
                {transfer?.to_address === MINTING_ADDRESS
                  ? "-"
                  : toUser?.username ||
                    toUser?.get("username") ||
                    transfer?.to_address}
              </Box>
            </Box>
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                {t("profile.date")}
              </Box>
              <Box w="100%" noOfLines={1}>
                {moment(
                  transfer?.block_timestamp || transfer?.get("block_timestamp")
                ).format("YYYY.MM.DD")}
              </Box>
            </Box>
          </Flex>
        </>
      )}
    </Box>
  );
}

export default ProfileTrading;
