import { ENV } from "../../../constants";
import { useCoinPriceContext } from "../providers/CoinPriceProvider";
import { numberWithCommas } from "../utils/numberWithCommas";
import useLanguageDetect from "./useLanguageDetect";

const CHAIN_IDS_MAP = {
  "0x3": "0x3",
  "0x1": "0x1",
  "0x13881": "0x13881",
  "0x89": "0x89",
};

function useCoinPrice(price, chainId) {
  const { isKorean } = useLanguageDetect();

  const { usd, krw, usdMatic, krwMatic } = useCoinPriceContext();

  const chainIds = CHAIN_IDS_MAP;

  switch (chainId) {
    case chainIds?.["0x3"]:
    case chainIds?.["0x1"]: {
      let convertedPrice = (isKorean ? krw : usd) * price;
      if (convertedPrice > 0) {
        convertedPrice = Number(convertedPrice.toFixed(2));

        return (isKorean ? "￦" : "$") + numberWithCommas(convertedPrice);
      } else {
        return (isKorean ? "￦" : "$") + convertedPrice;
      }
    }

    case chainIds?.["0x13881"]:
    case chainIds?.["0x89"]: {
      let convertedPrice = (isKorean ? krwMatic : usdMatic) * price;
      if (convertedPrice > 0) {
        convertedPrice = Number(convertedPrice.toFixed(2));

        return (isKorean ? "￦" : "$") + numberWithCommas(convertedPrice);
      } else {
        return (isKorean ? "￦" : "$") + convertedPrice;
      }
    }
  }
}

export default useCoinPrice;
