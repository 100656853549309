import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useMoralis,
  useMoralisWeb3Api,
  useWeb3ExecuteFunction,
} from "react-moralis";
import {
  CONTRACT_ADDRESS_MAP,
  ENV,
  NETWORKS,
  WETH_ADDRESS_MAP,
  WRAPPED_ETH_NAMES,
  WRAPPED_ETH_QUERY_MAP,
} from "../../../constants";
import { numberWithCommas } from "../utils/numberWithCommas";
import { WETH_CONTRACT_ABI } from "../../../abis/wethAbi";
import { ERC721_CONTRACT_ABI } from "../../../abis/tokenAbi";
import { isFirefox } from "react-device-detect";
import useCoinPrice from "../hooks/useCoinPrice";
import { preventArrowKeyFromInput } from "../utils/preventArrowKeyFromInput";
import { removeLeadingZero } from "../utils/removeLeadingZero";
import useResponsive from "../utils/useResponsive";
import loadingSvg from "../../../assets/icons/loading.svg";
import loadingGif from "../../../assets/icons/loading.gif";
import { useTranslation } from "react-i18next";

function WrapEthModal({ isOpen, onClose }) {
  const { Moralis, user, chainId } = useMoralis();
  const { isMobile } = useResponsive();
  const { native, token, account } = useMoralisWeb3Api();
  const tokenProcess = useWeb3ExecuteFunction();

  const [nativeBalance, setNativeBalance] = useState(0);

  const [ethAmount, setEthAmount] = useState(0);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const fetchBalances = async () => {
    const ethResult = await account.getNativeBalance({
      chain: chainId,
      address: user?.get("ethAddress"),
    });

    setNativeBalance(ethResult?.balance || 0);
  };

  useEffect(() => {
    if (user) {
      fetchBalances();
    }
  }, [user, chainId]);

  const onEthChange = (e) => {
    const { value } = e.target;

    if (isNaN(value) || value < 0) return;

    setEthAmount(removeLeadingZero(value));
  };

  const checkApproval = async () => {
    const approvalQuery = new Moralis.Query("WethApprove");
    approvalQuery.equalTo("address", user?.get("ethAddress"));
    approvalQuery.equalTo("contractAddress", CONTRACT_ADDRESS_MAP[chainId]);
    const queryResult = await approvalQuery.first();

    return queryResult;
  };

  const approve = async () => {
    const approvalAmount = Moralis.Units.ETH(1000000000000);

    const ops = {
      contractAddress: WETH_ADDRESS_MAP[chainId],
      abi: WETH_CONTRACT_ABI,
      functionName: "approve",
      params: {
        guy: CONTRACT_ADDRESS_MAP[chainId],
        wad: approvalAmount,
      },
    };
    try {
      const transaction = await Moralis.executeFunction(ops);

      let result;
      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      const approvalClass = Moralis.Object.extend("WethApprove");
      const approval = new approvalClass();
      approval.set("address", user?.get("ethAddress"));
      approval.set("contractAddress", CONTRACT_ADDRESS_MAP[chainId]);
      approval.set("amount", approvalAmount);
      await approval.save();
    } catch (error) {}
  };

  const convertEthToErc20 = async () => {
    setLoading(true);
    const approval = await checkApproval();

    if (!approval) {
      await approve();
    }

    const toWei = Moralis.Units.ETH(ethAmount);

    const ops = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "deposit",
      msgValue: toWei,
    };

    console.log({ ops });
    let result;
    try {
      const transaction = await Moralis.executeFunction(ops);

      result = await transaction.wait();
    } catch (error) {
      if (error.code === "TRANSACTION_REPLACED") {
        if (!error.cancelled) {
          result = error.replacement;
        }
      }
    }

    setLoading(false);
    onClose();
  };

  const ethBal = Moralis.Units.FromWei(nativeBalance);
  const coinPrice = useCoinPrice(ethAmount, chainId);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={!isMobile}
      size={isMobile ? "full" : "xl"}
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
    >
      <ModalContent
        padding="0px"
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
      >
        {!loading && (
          <ModalCloseButton
            top="20px"
            right="20px"
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ outline: "none" }}
          />
        )}
        <ModalBody
          padding="60px"
          paddingLeft={isMobile ? "20px" : "60px"}
          paddingRight={isMobile ? "20px" : "60px"}
        >
          <Box
            w="100%"
            textAlign="center"
            fontSize="25px"
            fontWeight="bold"
            mb={isMobile ? "0px" : "15px"}
          >
            {t("swap.swap")}
          </Box>
          <Flex
            position="relative"
            mb="32px"
            alignItems="center"
            justifyContent="center"
          >
            <img src={loadingSvg} />
            {loading && (
              <img
                src={loadingGif}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -80%)",
                }}
              />
            )}
          </Flex>
          <Box w="100%" h="3px" bg="black" mb="26px" />
          <Box mb="35px">
            <Box fontSize="15px" fontWeight="bold" mb="9px">
              {t("swap.swapFrom")}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="8px"
              bg="white"
              borderRadius="12px"
              padding={isMobile ? "17px 20px" : "8px 20px"}
              boxShadow="0px 20px 20px #00000029"
              fontSize="17px"
              fontWeight="extrabold"
            >
              <Flex alignItems="center">
                <Image src={NETWORKS[ENV]?.[chainId]?.swapIcon} mr="16px" />
                <Box>{NETWORKS[ENV]?.[chainId]?.shortName}</Box>
              </Flex>
              {!isMobile && (
                <Flex alignItems="center">
                  <Input
                    type="number"
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    fontSize="inherit"
                    fontWeight="inherit"
                    textAlign="right"
                    padding="0px"
                    marginRight="4px"
                    value={ethAmount}
                    onKeyDown={preventArrowKeyFromInput}
                    onChange={onEthChange}
                    outline="none"
                    border="none"
                    _focus={{ border: "none" }}
                  />
                  <Box mr="5px">{NETWORKS[ENV]?.[chainId]?.unit}</Box>
                  <Box fontSize="13px" color="#949AB2">
                    {coinPrice}
                  </Box>
                </Flex>
              )}
            </Flex>
            {isMobile && (
              <Flex
                alignItems="center"
                bg="white"
                borderRadius="12px"
                height="61px"
                padding="0px 20px"
                mb="10px"
                fontSize="15px"
                fontWeight="bold"
              >
                <Input
                  type="number"
                  placeholder="0"
                  onWheel={(e) => e.target.blur()}
                  textAlign="right"
                  padding="0px"
                  marginRight="4px"
                  fontSize="inherit"
                  fontWeight="inherit"
                  value={ethAmount}
                  onKeyDown={preventArrowKeyFromInput}
                  onChange={onEthChange}
                  outline="none"
                  border="none"
                  _focus={{ border: "none" }}
                />
                <Box mr="5px">{NETWORKS[ENV]?.[chainId]?.unit}</Box>
                <Box fontSize="13px" color="#949AB2">
                  {coinPrice}
                </Box>
              </Flex>
            )}
            <Box w="100%" textAlign="right" fontSize="11px" color="#949AB2">
              {numberWithCommas(Number(ethBal).toFixed(6))}{" "}
              {NETWORKS[ENV]?.[chainId]?.unit} available to swap
            </Box>
          </Box>
          <Box mb="80px">
            <Box fontSize="15px" fontWeight="bold" mb="9px">
              {t("swap.swapTo")}
            </Box>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              mb="8px"
              bg="white"
              borderRadius="12px"
              padding={isMobile ? "17px 20px" : "8px 20px"}
              boxShadow="0px 20px 20px #00000029"
              fontSize="17px"
              fontWeight="extrabold"
            >
              <Flex alignItems="center">
                <Image src={NETWORKS[ENV]?.[chainId]?.swapIcon} mr="16px" />
                <Box>{WRAPPED_ETH_NAMES[chainId]}</Box>
              </Flex>
              {!isMobile && (
                <Flex alignItems="center">
                  <Input
                    type="number"
                    isDisabled={true}
                    placeholder="0"
                    onWheel={(e) => e.target.blur()}
                    fontSize="inherit"
                    fontWeight="inherit"
                    textAlign="right"
                    padding="0px"
                    marginRight="4px"
                    value={ethAmount}
                    onKeyDown={preventArrowKeyFromInput}
                    onChange={onEthChange}
                    outline="none"
                    border="none"
                    _focus={{ border: "none" }}
                    _disabled={{ color: "black" }}
                  />
                  <Box mr="5px">{WRAPPED_ETH_NAMES[chainId]}</Box>
                  <Box fontSize="13px" color="#949AB2">
                    {coinPrice}
                  </Box>
                </Flex>
              )}
            </Flex>
            {isMobile && (
              <Flex
                alignItems="center"
                bg="white"
                borderRadius="12px"
                height="61px"
                padding="0px 20px"
                mb="10px"
                fontSize="15px"
                fontWeight="bold"
              >
                <Input
                  isDisabled={true}
                  type="number"
                  placeholder="0"
                  onWheel={(e) => e.target.blur()}
                  textAlign="right"
                  padding="0px"
                  marginRight="4px"
                  fontSize="inherit"
                  fontWeight="inherit"
                  value={ethAmount}
                  onKeyDown={preventArrowKeyFromInput}
                  onChange={onEthChange}
                  outline="none"
                  border="none"
                  _disabled={{ color: "black" }}
                  _focus={{ border: "none" }}
                />
                <Box mr="5px">{NETWORKS[ENV]?.[chainId]?.unit}</Box>
                <Box fontSize="13px" color="#949AB2">
                  {coinPrice}
                </Box>
              </Flex>
            )}
          </Box>
          <Button
            w="100%"
            h="56px"
            textAlign="center"
            bg="#1C20FE"
            borderRadius="12px"
            boxShadow="0px 20px 20px #00000029"
            color="white"
            fontSize="15px"
            fontWeight="bold"
            _hover={{ bg: "#1C20FE" }}
            _focus={{ bg: "#1C20FE", outline: "none" }}
            _active={{ bg: "#1C20FE", outline: "none" }}
            onClick={convertEthToErc20}
            disabled={loading}
          >
            {t("swap.doSwap")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default WrapEthModal;
