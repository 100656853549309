import { Box, Button, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import { ITEM_STATUS } from "../../common/utils/itemStatus";
import useResponsive from "../../common/utils/useResponsive";

function ArtDetailButtons({ item, openBuyNowModal, openOfferModal }) {
  const { user } = useMoralis();
  const { contractAddress } = useParams();

  const { isDesktop, isMobile, isTablet } = useResponsive();

  const buyDisabled =
    contractAddress ||
    item?.status !== ITEM_STATUS.SELL.value ||
    user?.id === item?.owner?.id;

  const offerDisabled = contractAddress || user?.id === item?.owner?.id;

  const { t } = useTranslation();
  return (
    <Flex>
      <Box
        position="relative"
        flex={1}
        margin={isDesktop ? "0px 0px" : isTablet ? "0px 13.5px" : "0px 7.5px"}
      >
        <Button
          w="100%"
          height={isDesktop ? "83px" : isTablet ? "63px" : "40px"}
          bg="#1D20FF 0% 0% no-repeat padding-box"
          color="white"
          borderRadius={!isMobile ? "19px" : "10px"}
          padding={isDesktop ? "27px 0px" : isTablet ? "23px 0px" : "11px 0px"}
          fontSize={isDesktop ? "25px" : isTablet ? "17px" : "15px"}
          fontWeight="bold"
          _hover={{
            backgroundColor: "#1d20ff",
          }}
          _focus={{
            outline: "none",
            backgroundColor: "#1d20ff",
          }}
          _active={{
            outline: "none",
            backgroundColor: "#1d20ff",
          }}
          onClick={openBuyNowModal}
          disabled={buyDisabled}
        >
          {t("item.buyNow")}
        </Button>
        <Box
          position="absolute"
          left="5%"
          right="5%"
          top="30%"
          height="100%"
          bg="rgba(29, 32, 255, 0.4) 0% 0% no-repeat padding-box"
          filter="blur(30px)"
          zIndex={-1}
          opacity={buyDisabled ? 0.5 : 1}
        />
      </Box>
      <Box
        position="relative"
        flex={1}
        margin={isDesktop ? "0px 0px" : isTablet ? "0px 13.5px" : "0px 7.5px"}
        marginLeft={isDesktop ? "15px" : isTablet ? "13.5px" : "7.5px"}
      >
        <Button
          w="100%"
          height={isDesktop ? "83px" : isTablet ? "63px" : "40px"}
          bg="white 0% 0% no-repeat padding-box"
          color="#1D20FF"
          borderRadius={!isMobile ? "19px" : "10px"}
          padding={isDesktop ? "27px 0px" : isTablet ? "23px 0px" : "11px 0px"}
          fontSize={isDesktop ? "25px" : isTablet ? "17px" : "15px"}
          fontWeight="bold"
          _hover={{
            backgroundColor: "white",
          }}
          _focus={{
            outline: "none",
          }}
          disabled={offerDisabled}
          onClick={openOfferModal}
        >
          {t("item.offer")}
        </Button>
        <Box
          position="absolute"
          left="5%"
          right="5%"
          top="30%"
          height="100%"
          bg="rgba(0,0,0,0.3) 0% 0% no-repeat padding-box"
          filter="blur(30px)"
          zIndex={-1}
          opacity={offerDisabled ? 0.5 : 1}
        />
      </Box>
    </Flex>
  );
}

export default ArtDetailButtons;
