import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { isFirefox } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import ProfileDropdownMenu from "../../../navigation/components/ProfileDropdownMenu";

function ProfileMenuModal({ isOpen, onClose }) {
  const { logout } = useMoralis();

  const { t } = useTranslation();

  const history = useHistory();

  const onEditProfileClick = () => {
    history.push(ROUTE_NAMES.updateProfile);
    onClose();
  };

  const onLogoutClick = async () => {
    onClose();
    await logout();
    history.push(ROUTE_NAMES.home);
    document.location.reload();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick isCentered>
      <ModalContent marginLeft="20px" marginRight="20px" bg="#F6F5F7">
        <ModalBody padding="22px" fontSize="15px">
          <Button
            w="100%"
            bg="white"
            mb="11px"
            borderRadius="12px"
            _hover={{ bg: "white", outline: "none" }}
            _active={{ bg: "white", outline: "none" }}
            _focus={{ bg: "white", outline: "none" }}
            onClick={onEditProfileClick}
          >
            {t("profileMenu.editProfile")}
          </Button>
          <Button
            w="100%"
            bg="white"
            borderRadius="12px"
            _hover={{ bg: "white", outline: "none" }}
            _active={{ bg: "white", outline: "none" }}
            _focus={{ bg: "white", outline: "none" }}
            onClick={onLogoutClick}
          >
            {t("profileMenu.logout")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ProfileMenuModal;
