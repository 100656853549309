import { Box, Container, Flex, HStack, Image } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import thankyou1 from "../../../assets/icons/thankyou1.png";
import thankyou2 from "../../../assets/icons/thankyou2.png";
import thankyou3 from "../../../assets/icons/thankyou3.png";
import thankyouMobile from "../../../assets/icons/thankyou_mobile.png";
import thankyou1Mobile from "../../../assets/icons/thankyou1_mobile.png";
import thankyou2Mobile from "../../../assets/icons/thankyou2_mobile.png";
import thankyou3Mobile from "../../../assets/icons/thankyou3_mobile.png";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";

let gobackTimeout;

function ThankyouContainer({
  route = ROUTE_NAMES.profile,
  isGoingBack = false,
}) {
  const history = useHistory();
  const { isMobile, isDesktop } = useResponsive();

  useEffect(() => {
    gobackTimeout = setTimeout(() => {
      if (!isGoingBack) {
        if (route) {
          history.push(route);
        }
      } else {
        history.goBack();
      }
    }, 2500);

    return () => {
      clearTimeout(gobackTimeout);
    };
  }, []);

  console.log("here");

  return isDesktop ? (
    <Flex>
      <Box flex={1}>
        <Image src={thankyou1} />
      </Box>
      <Box flex={1}>
        <Image src={thankyou2} />
      </Box>
      <Box flex={1}>
        <Image src={thankyou3} />
      </Box>
    </Flex>
  ) : (
    <Flex
      w="100vw"
      h="100vh"
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={thankyouMobile} h="100%" objectFit="contain" />
    </Flex>
  );
}

export default ThankyouContainer;
