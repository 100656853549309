import { Box, Flex, Input } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { preventArrowKeyFromInput } from "../../common/utils/preventArrowKeyFromInput";
import useResponsive from "../../common/utils/useResponsive";

function CreateRoyalties({ royalty, onRoyaltyChange, editDisabled }) {
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { t } = useTranslation();

  return (
    <Box mt={isDesktop ? "0px" : "30px"}>
      <Flex alignItems="center" paddingLeft="20px" mb="11px">
        <Box
          fontSize={!isTablet ? "20px" : "17px"}
          fontWeight="bold"
          marginRight="10px"
        >
          {t("create.royalties")}
        </Box>
        {!isMobile && <Box fontSize="15px">{t("create.royalty")}</Box>}
      </Flex>
      <Box
        w="100%"
        bg="#F6F5F7"
        borderRadius="19px"
        padding={isDesktop ? "25px 45px" : "21px 42px"}
      >
        {isMobile && (
          <Box fontSize="15px" mb="22px">
            {t("create.royalty")}
          </Box>
        )}
        <Input
          fontSize={!isTablet ? "15px" : "11px"}
          placeholder={t("create.royaltyEg")}
          bg="white"
          borderRadius="9px"
          border="none"
          type="number"
          outline="none"
          onWheel={(e) => e.target.blur()}
          _focus={{ border: "none" }}
          value={royalty}
          onKeyDown={preventArrowKeyFromInput}
          onChange={onRoyaltyChange}
          disabled={editDisabled}
          _disabled={{
            color: "black",
          }}
        />
      </Box>
    </Box>
  );
}

export default CreateRoyalties;
