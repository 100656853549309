import graphic from "../../../assets/icons/graphic.svg";
import painting from "../../../assets/icons/painting.svg";
import threed from "../../../assets/icons/3d.svg";
import photography from "../../../assets/icons/photography.svg";
import video from "../../../assets/icons/video.svg";
import collectibles from "../../../assets/icons/collectibles.svg";
import memes from "../../../assets/icons/memes.svg";

export const CATEGORIES = {
  GRAPHIC: {
    value: "GRAPHIC",
    name: "Graphic",
    icon: graphic,
  },
  PAINTING: {
    value: "PAINTING",
    name: "Painting",
    icon: painting,
  },
  "3D": {
    value: "3D",
    name: "3D",
    icon: threed,
  },
  PHOTOGRAPHY: {
    value: "PHOTOGRAPHY",
    name: "Photography",
    icon: photography,
  },
  VIDEO: {
    value: "VIDEO",
    name: "Video",
    icon: video,
  },
  COLLECTIBLES: {
    value: "COLLECTIBLES",
    name: "Collectibles",
    icon: collectibles,
  },
  MEMES: {
    value: "MEMES",
    name: "Memes",
    icon: memes,
  },
};
