import { Box, Image } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import useResponsive from "../../../common/utils/useResponsive";

const THUMBNAIL_WIDTH = 134;
const THUMBNAIL_HEIGHT = 173;

const THUMBNAIL_WIDTH_MOBILE = 98;
const THUMBNAIL_HEIGHT_MOBILE = 127;

function ProfileThumbnail({ stack }) {
  const history = useHistory();
  const { isMobile } = useResponsive();

  return (
    <Box
      width={isMobile ? THUMBNAIL_WIDTH_MOBILE : THUMBNAIL_WIDTH}
      height={isMobile ? THUMBNAIL_HEIGHT_MOBILE : THUMBNAIL_HEIGHT}
      margin="0px 3%"
      position="relative"
      zIndex={2}
      mb="86px"
    >
      {stack &&
        stack?.map((item, index) => {
          const top = -9 * index;
          const left = 10 * index;
          const imageStyle =
            index !== 0
              ? {
                  position: "absolute",
                  top: `${top}px`,
                  left: `${left}px`,
                  zIndex: -index,
                }
              : {};

          return (
            item && (
              <Box
                key={item?.id}
                cursor={index === 0 ? "pointer" : "default"}
                onClick={() =>
                  history.push(ROUTE_NAMES.market + `/${item?.tokenId}`)
                }
                pointerEvents={item?.isDeleted ? "none" : "auto"}
              >
                {!item?.isVideo ? (
                  <img
                    className="lazyload"
                    src={item?.imageSrc}
                    style={{
                      width: isMobile
                        ? THUMBNAIL_WIDTH_MOBILE
                        : THUMBNAIL_WIDTH,
                      height: isMobile
                        ? THUMBNAIL_HEIGHT_MOBILE
                        : THUMBNAIL_HEIGHT,
                      borderRadius: 23,
                      objectFit: "cover",
                      ...imageStyle,
                    }}
                  />
                ) : (
                  <video
                    className="lazyload"
                    src={item?.imageSrc}
                    style={{
                      width: isMobile
                        ? THUMBNAIL_WIDTH_MOBILE
                        : THUMBNAIL_WIDTH,
                      height: isMobile
                        ? THUMBNAIL_HEIGHT_MOBILE
                        : THUMBNAIL_HEIGHT,
                      objectFit: "cover",
                      borderRadius: "23px",
                      ...imageStyle,
                    }}
                    playsInline
                    autoPlay
                    loop
                    muted
                  />
                )}
                {index === 0 && (
                  <Box
                    textAlign="center"
                    mt={isMobile ? "21px" : "26px"}
                    fontSize="12px"
                    fontWeight="bold"
                    noOfLines={1}
                    overflow="hidden"
                  >
                    {item?.title}
                  </Box>
                )}
              </Box>
            )
          );
        })}
    </Box>
  );
}

export default ProfileThumbnail;
