import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";
import profileMenuIcon from "../../../assets/icons/profile_menu.svg";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function ArtDetailTitle({ item }) {
  const { isTablet, isDesktop, isMobile } = useResponsive();
  const { user, Moralis } = useMoralis();
  const { contractAddress } = useParams();

  const { t } = useTranslation();
  const history = useHistory();

  const { title, creator, owner, creatorAccount, ownerAccount } = item;

  const onUserClick = (userType) => {
    if (contractAddress) return;
    let address;
    if (userType === "creator") {
      address = creatorAccount;
    } else {
      address = ownerAccount;
    }

    if (address) {
      history.push(
        ROUTE_NAMES.profile +
          (address === user?.get("ethAddress") ? "" : `/${address}`)
      );
    }
  };

  return (
    <Flex
      flex={1}
      width="100%"
      bg="#F6F5F7 0% 0% no-repeat padding-box"
      borderRadius="19px"
      padding={isDesktop ? "27px 45px" : "25px 30px"}
      h={isDesktop ? "220px" : isTablet ? "162px" : "180px"}
      flexDirection="column"
      mb={isDesktop ? "30px" : "24px"}
    >
      <Box
        outline="none"
        border="none"
        padding="0px"
        fontSize={isDesktop ? "42px" : isTablet ? "32px" : "27px"}
        fontWeight="bold"
        marginBottom={isDesktop ? "15px" : "10px"}
      >
        {title}
      </Box>

      <Flex
        bg="white"
        padding={isDesktop ? "10px 42px" : "9px 30px"}
        fontSize={isDesktop ? "15px" : isTablet ? "10px" : "13px"}
        fontWeight="bold"
        marginBottom="8px"
        borderRadius="12px"
        boxSizing="border-box"
        alignItems="center"
      >
        <Box
          w={isMobile ? "60px" : "70px"}
          borderRight="1px solid black"
          mr={isDesktop ? "25px" : "28px"}
        >
          {t("item.artist")}
        </Box>
        {!contractAddress && (
          <Image
            src={creator?.get("avatar")?._url || profileMenuIcon}
            borderRadius="50%"
            boxSize={isDesktop ? "22px" : "20px"}
            mr={isDesktop ? "15px" : "10px"}
          />
        )}
        <Box
          flex={2}
          cursor="pointer"
          onClick={() => onUserClick("creator")}
          noOfLines={1}
          overflow="hidden"
          pointerEvents={contractAddress ? "none" : "auto"}
        >
          {!contractAddress ? creator?.get("username") : creator}
        </Box>
      </Flex>

      <Flex
        bg="white"
        padding={isDesktop ? "10px 42px" : "9px 30px"}
        fontSize={isDesktop ? "15px" : isTablet ? "10px" : "13px"}
        marginBottom="8px"
        borderRadius="12px"
        boxSizing="border-box"
        alignItems="center"
      >
        <Box
          w={isMobile ? "60px" : "70px"}
          borderRight="1px solid black"
          mr={isDesktop ? "25px" : "28px"}
        >
          {t("item.owner")}
        </Box>
        {!contractAddress && (
          <Image
            src={owner?.get("avatar")?._url || profileMenuIcon}
            borderRadius="50%"
            boxSize={isDesktop ? "22px" : "20px"}
            mr={isDesktop ? "15px" : "10px"}
          />
        )}
        <Box
          flex={2}
          cursor="pointer"
          onClick={() => onUserClick("owner")}
          noOfLines={1}
          overflow="hidden"
          pointerEvents={contractAddress ? "none" : "auto"}
        >
          {!contractAddress ? owner?.get("username") : owner}
        </Box>
      </Flex>
    </Flex>
  );
}

export default ArtDetailTitle;
