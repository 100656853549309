import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import logo from "../../../assets/icons/logo_white.svg";
import instagramIcon from "../../../assets/icons/instagram.svg";
import discordIcon from "../../../assets/icons/discord.svg";
import useResponsive from "../../common/utils/useResponsive";
import { Link } from "react-router-dom";
import ROUTE_NAMES from "../../../routes/utils/routeNames";

import mainScroll from "../../../assets/icons/main_scroll.svg";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";

function HomeFooter({}) {
  const { isTablet, isMobile, isDesktop } = useResponsive();

  const { Moralis } = useMoralis();

  const [footer, setFooter] = useState(null);

  const { i18n } = useTranslation();

  const onLangChange = (lang) => {
    i18n.changeLanguage(lang);

    document.location.reload();
  };

  const fetchFooter = async () => {
    const footerQuery = new Moralis.Query("Footer");
    const result = await footerQuery.first();

    if (result) {
      setFooter(JSON.parse(JSON.stringify(result)));
    }
  };

  useEffect(() => {
    if (!footer) {
      fetchFooter();
    }
  }, [footer]);

  if (!footer) return <div />;

  const {
    address,
    businessRegistration,
    contactUs,
    houseOfMennin,
    discord,
    instagram,
    ceo,
    chiefOfPrivacy,
  } = footer;

  return (
    <Box mb={!isMobile ? "154px" : "70px"} mt={isDesktop ? "110px" : "66px"}>
      <Box
        bg="#949AB2"
        borderRadius="26px"
        padding={
          isDesktop ? "50px 4%" : isTablet ? "35px 60px" : "50.32px 30px"
        }
        position="relative"
      >
        <Flex
          direction={isMobile ? "column" : "row"}
          alignItems="flex-start"
          justifyContent={!isMobile ? "space-around" : "space-between"}
          fontSize={!isTablet ? "17px" : "8px"}
          fontWeight="medium"
          color="#F6F5F7"
        >
          <Box mb={isMobile ? "50.68px" : "0px"}>
            <Image
              src={logo}
              w={!isTablet ? "127px" : "56.2px"}
              objectFit="contain"
            />
          </Box>
          <Box mb={isMobile ? "40px" : "0px"}>
            <Box mb={!isDesktop ? "17px" : isTablet ? "10px" : "20px"}>
              <Link to={ROUTE_NAMES.spirit}>THE MENNIN SPIRIT</Link>
            </Box>
            <Box mb={!isDesktop ? "17px" : isTablet ? "10px" : "20px"}>
              <a href={houseOfMennin} target="_blank">
                HOUSE OF MENNIN
              </a>
            </Box>
            <Flex
              fontSize={!isTablet ? "15px" : "9px"}
              fontWeight="thin"
              alignItems={isTablet ? "flex-start" : "center"}
              direction={isTablet ? "column" : "row"}
            >
              <Box>
                <Link to={ROUTE_NAMES.privacy}>PRIVACY</Link>
              </Box>
              {!isTablet && <Box margin="0px 12px"> | </Box>}
              <Box>
                <Link to={ROUTE_NAMES.terms}>TERMS</Link>
              </Box>
            </Flex>
          </Box>
          <Box mb={isMobile ? "50px" : "0px"}>
            <Box mb={!isDesktop ? "17px" : isTablet ? "10px" : "20px"}>
              ADDRESS
            </Box>
            <Box fontSize={!isTablet ? "15px" : "9px"} fontWeight="thin">
              <div dangerouslySetInnerHTML={{ __html: address }} />
            </Box>
          </Box>
          <Box>
            <Box mb={!isDesktop ? "17px" : isTablet ? "10px" : "20px"}>
              CONTACT US
            </Box>
            <Box fontSize={!isTablet ? "15px" : "9px"} fontWeight="thin">
              <a href={`mailto:${contactUs}`}>director@mennin.net</a>
            </Box>
            <Box
              fontSize={!isTablet ? "14px" : "9px"}
              fontWeight="thin"
              mb={!isMobile ? "0px" : "50px"}
            >
              {businessRegistration}
              <br />
              CEO : {ceo}
            </Box>
          </Box>
          <Box>
            <Box mb={!isDesktop ? "17px" : isTablet ? "10px" : "20px"}>
              FOLLOW US
            </Box>
            <Flex alignItems="center">
              <Box
                cursor="pointer"
                onClick={() => window.open(instagram)}
                mr="8px"
              >
                <Image
                  src={instagramIcon}
                  w={!isTablet ? "30px" : "14px"}
                  h={!isTablet ? "30px" : "14px"}
                />
              </Box>
              <Box cursor="pointer" onClick={() => window.open(discord)}>
                <Image
                  src={discordIcon}
                  w={!isTablet ? "30px" : "14px"}
                  h={!isTablet ? "30px" : "14px"}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        {!isMobile && (
          <Box
            position="absolute"
            right="7%"
            top="0px"
            transform="translateY(-50%)"
            cursor="pointer"
            onClick={() => {
              const root = document.getElementById("routes_wrapper");

              root?.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <Image src={mainScroll} width={isDesktop ? "50px" : "22px"} />
          </Box>
        )}
      </Box>
      {isMobile && (
        <Flex
          alignItems="center"
          paddingLeft={19}
          mt="16px"
          color="#949AB2"
          w="100%"
          justifyContent="space-between"
        >
          <Flex flex={1} alignItems="center">
            <Box onClick={() => onLangChange("en")} mr="8px">
              EN
            </Box>
            <Box mr="8px">|</Box>
            <Box onClick={() => onLangChange("ko")}>한국어</Box>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="flex-end"
            marginRight="20px"
            mt="12px"
            fontSize="12px"
            color="#949AB2"
          >
            ©MENNIN
          </Flex>
        </Flex>
      )}
      {!isMobile && (
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          marginRight="20px"
          mt="12px"
          fontSize="12px"
          color="#949AB2"
        >
          ©MENNIN
        </Flex>
      )}
    </Box>
  );
}

export default HomeFooter;
