import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "lazysizes";
// import a plugin
import "lazysizes/plugins/parent-fit/ls.parent-fit";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MoralisProvider } from "react-moralis";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { config } from "./utilities/config";
import theme from "./theme/theme";
import "./lib/i18n/i18n";
import "./lib/firebase";
import Loading from "./modules/common/components/Loading";
import redux from "./lib/redux";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
  console.info = () => {};
}

ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </div>
      }
    >
      <Provider store={redux}>
        <Router>
          <ChakraProvider theme={theme}>
            <MoralisProvider
              appId={config.MORALIS_APP_ID}
              serverUrl={config.MORALIS_SERVER_URL}
              initializeOnMount={true}
            >
              <App />
            </MoralisProvider>
          </ChakraProvider>
        </Router>
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
