import axios from "axios";

export async function getCoinPrice() {
  const usd = (await getUsdCoinPrice())?.ethereum?.usd;
  const krw = (await getKrwCoinPrice())?.ethereum?.krw;
  const usdMatic = (await getUsdMaticCoinPrice())?.["matic-network"]?.usd;
  const krwMatic = (await getKrwMaticCoinPrice())?.["matic-network"]?.krw;

  return {
    usd,
    krw,
    usdMatic,
    krwMatic,
  };
}

export async function getUsdCoinPrice() {
  const result = await axios({
    method: "GET",
    url: "https://api.coingecko.com/api/v3/simple/price",
    params: {
      ids: "ethereum",
      vs_currencies: "usd",
    },
  });

  return result.data;
}

export async function getKrwCoinPrice() {
  const result = await axios({
    method: "GET",
    url: "https://api.coingecko.com/api/v3/simple/price",
    params: {
      ids: "ethereum",
      vs_currencies: "krw",
    },
  });

  return result.data;
}

export async function getUsdMaticCoinPrice() {
  const result = await axios({
    method: "GET",
    url: "https://api.coingecko.com/api/v3/simple/price",
    params: {
      ids: "matic-network",
      vs_currencies: "usd",
    },
  });

  return result.data;
}

export async function getKrwMaticCoinPrice() {
  const result = await axios({
    method: "GET",
    url: "https://api.coingecko.com/api/v3/simple/price",
    params: {
      ids: "matic-network",
      vs_currencies: "krw",
    },
  });

  return result.data;
}
