import { Box, Button, Flex, Image, useToast } from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useChain, useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useHistory, useParams } from "react-router-dom";
import { ERC721_CONTRACT_ABI } from "../../../../abis/tokenAbi";
import { WETH_CONTRACT_ABI } from "../../../../abis/wethAbi";
import {
  APPROVAL_KEYS,
  CONTRACT_ADDRESS_MAP,
  ENV,
  NETWORKS,
  OWNER_CUT,
  WETH_ADDRESS_MAP,
  WRAPPED_ETH_QUERY_MAP,
} from "../../../../constants";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import { fromHexToDecimal } from "../../../auth/utils/convert";
import Loading from "../../../common/components/Loading";
import { getEmailBody } from "../../../common/utils/getEmailBody";
import { getMoralisRpcs } from "../../../common/utils/getMoralisRpcs";
import { ITEM_STATUS } from "../../../common/utils/itemStatus";
import { numberWithCommas } from "../../../common/utils/numberWithCommas";
import { sendEmail } from "../../../common/utils/sendEmail";
import useResponsive from "../../../common/utils/useResponsive";
import ThankyouContainer from "../../../create/components/ThankyouContainer";

const IMAGE_SIZE = 60;
const IMAGE_SIZE_TABLET = 28;
const IMAGE_SIZE_MOBILE = 75;

let interval;

function ViewAllOffer({ offer }) {
  const { isMobile, isDesktop, isTablet } = useResponsive();
  const { Moralis, user, enableWeb3, chainId, web3 } = useMoralis();
  const { switchNetwork } = useChain();
  const { userAccount } = useParams();

  const { t, i18n } = useTranslation();

  const toast = useToast();

  const history = useHistory();

  const rowRef = useRef();

  const {
    objectId,
    tokenId,
    offerPrice,
    item,
    buyerAccount,
    sellerAccount,
    contractAddress,
    createdAt,
    expiration,
    totalAmount,
    isCanceled,
    isRejected,
    isAccepted,
    isExpired,
  } = offer;

  const price = Moralis.Units.FromWei(offerPrice);

  const isMade = userAccount
    ? buyerAccount === userAccount
    : buyerAccount === user?.get("ethAddress");

  const [buyer, setBuyer] = useState(null);
  const [seller, setSeller] = useState(null);

  const [rowHeight, setRowHeight] = useState(0);

  const [expireState, setExpireState] = useState(isExpired);

  const [cancelState, setCancelState] = useState(isCanceled);
  const [rejectState, setRejectState] = useState(isRejected);
  const [acceptState, setAcceptState] = useState(isAccepted);

  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const fetchUser = async () => {
    const userQuery = await Moralis.Cloud.run("getUser", {
      account: isMade ? sellerAccount : buyerAccount,
    });

    if (isMade) {
      setSeller(userQuery);
    } else {
      setBuyer(userQuery);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (rowRef?.current) {
      const height = rowRef?.current?.offsetHeight;

      setRowHeight(height);
    }
  }, [rowRef?.current]);

  useEffect(() => {
    if (!expireState) {
      interval = setInterval(() => {
        const expired = moment().isSameOrAfter(
          moment(expiration?.iso),
          "seconds"
        );
        setExpireState(expired);
      }, 1000);
    }
    if (expireState) {
      if (interval) {
        clearInterval(interval);
        (async () => {
          const currentOffer = await fetchCurrentOffer();
          currentOffer.set("isExpired", true);
          await currentOffer.save();
        })();
        setExpireState(true);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [expireState]);

  useEffect(() => {
    let subscription;

    (async () => {
      const subscriptionQuery = new Moralis.Query("Offers");
      subscription = await subscriptionQuery.subscribe();
      subscription.on("update", (object) => {
        if (object?.id === objectId) {
          const updatedCancelState = object?.get("isCanceled");
          if (updatedCancelState) {
            setCancelState(updatedCancelState);
          }
          const updatedAcceptState = object?.get("isAccepted");
          if (updatedAcceptState) {
            setAcceptState(updatedAcceptState);
          }
          const updatedRejectState = object?.get("isRejected");
          if (updatedRejectState) {
            setRejectState(updatedRejectState);
          }
        }
      });
    })();

    return () => subscription?.unsubscribe();
  }, []);

  const onImageClick = () => {
    history.push(ROUTE_NAMES.market + `/${item?.tokenId}`);
  };

  const onUsernameClick = (ethAddress) => {
    if (!ethAddress) return;
    const userAddress = user?.ethAddress || user?.get("ethAddress");
    if (ethAddress === userAddress) {
      history.push(ROUTE_NAMES.profile);
    } else {
      history.push(ROUTE_NAMES.profile + `/${ethAddress}`);
    }
  };

  const checkIsApprovedForAll = async () => {
    const isApprovedForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "isApprovedForAll",
      params: {
        owner: user?.get("ethAddress"),
        operator: CONTRACT_ADDRESS_MAP[chainId],
      },
    };

    const transaction = await Moralis.executeFunction(isApprovedForAllOps);

    user?.set(APPROVAL_KEYS[chainId], CONTRACT_ADDRESS_MAP[chainId]);
    await user.save();

    return transaction;
  };

  const setApprovalForAll = async () => {
    const approvalForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "setApprovalForAll",
      params: {
        operator: CONTRACT_ADDRESS_MAP[chainId],
        approved: true,
      },
    };

    const transaction = await Moralis.executeFunction(approvalForAllOps);

    let result;
    try {
      result = await transaction.wait();
    } catch (error) {
      if (error.code === "TRANSACTION_REPLACED") {
        if (!error.cancelled) {
          result = error.replacement;
        }
      }
    }

    console.log({ result });

    user?.set(APPROVAL_KEYS[chainId], CONTRACT_ADDRESS_MAP[chainId]);
    await user.save();

    return result;
  };

  const ensureMarketIsApproved = async () => {
    let isApproved = await checkIsApprovedForAll();

    if (!isApproved) {
      try {
        const approvalResult = await setApprovalForAll();
        return true;
      } catch (error) {
        return false;
      }
    }

    return isApproved;
  };

  const fetchCurrentOffer = async () => {
    const currentOffer = await Moralis.Cloud.run("getOffer", {
      objectId,
    });

    return currentOffer;
  };

  const fetchOtherOffersForThisItem = async () => {
    const otherOffersForThisItem = await Moralis.Cloud.run(
      "getOtherOffersForThisItem",
      {
        tokenId,
        contractAddress,
        currentOfferId: objectId,
      }
    );

    return otherOffersForThisItem;
  };

  const checkBuyerTokenBalance = async () => {
    const query = new Moralis.Query(WRAPPED_ETH_QUERY_MAP[item?.chainId]);

    query.equalTo("address", user?.get("ethAddress"));
    query.equalTo(
      "token_address",
      WETH_ADDRESS_MAP[item?.chainId]?.toLowerCase()
    );

    const result = await query.first();

    return result?.get("balance");
  };

  const onCancel = async () => {
    const currentOffer = await fetchCurrentOffer();
    if (currentOffer) {
      currentOffer.save("isCanceled", true);
      await currentOffer.save();
      setCancelState(true);
    }
  };

  const onReject = async () => {
    const currentOffer = await fetchCurrentOffer();
    if (currentOffer) {
      currentOffer.save("isRejected", true);
      await currentOffer.save();
      setRejectState(true);

      const getBuyer = await Moralis.Cloud.run("getUser", {
        account: buyerAccount,
      });

      if (getBuyer?.get("email") && getBuyer?.get("checkNotification")) {
        sendEmail({
          email_subject: t("email.offerDeclined"),
          receiver: getBuyer?.get("email"),
          emailBody: getEmailBody(
            t("email.offerDeclined"),
            item?.isVideo,
            item?.imageSrc,
            "#9b9b9b",
            t("email.offerDeclinedBody1") +
              ` <strong>${price} ${
                NETWORKS[ENV]?.[item?.chainId]?.unit
              }</strong> ` +
              t("email.offerDeclinedBody2"),
            "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
            t("email.viewItem")
          ),
        });
      }
    }
  };

  const onAcceptClick = async () => {
    if (expireState) {
    } else {
      try {
        setLoading(true);
        if (chainId !== item?.chainId) {
          const newChain = web3.provider.isPortis
            ? {
                chainId: fromHexToDecimal(item?.chainId),
                nodeUrl: getMoralisRpcs()[fromHexToDecimal(item?.chainId)],
              }
            : item?.chainId;

          await switchNetwork(newChain);
        }

        const isApproved = await ensureMarketIsApproved();

        if (!isApproved) {
          toast({
            title: "Must approve first to use MENNIN",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          return;
        }

        const currentBuyerWethBalance = await checkBuyerTokenBalance();

        if (Number(currentBuyerWethBalance) < Number(totalAmount)) {
          toast({
            title: "Buyer doesn't have enough balance to proceed this offer",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          return;
        }

        const currentOffer = await fetchCurrentOffer();

        let ops;

        const { chainType } = item;

        const parsedRoyalty = JSON.parse(item?.royalty);

        switch (chainType) {
          case "off-chain": {
            ops = {
              contractAddress: CONTRACT_ADDRESS_MAP[item?.chainId],
              functionName: "transferItemByAcceptingOfferOffchain",
              abi: ERC721_CONTRACT_ABI,
              params: {
                buyer: buyerAccount,
                data: {
                  tokenId: item?.tokenId,
                  tokenURI: item?.tokenURI,
                  creatorAddress: sellerAccount,
                  royalty: parsedRoyalty?.amount?.toString(),
                },
                ownerCut: (Number(totalAmount) - Number(offerPrice)).toString(),
                price: totalAmount?.toString(),
              },
            };
            break;
          }

          default: {
            ops = {
              contractAddress: CONTRACT_ADDRESS_MAP[item?.chainId],
              functionName: "transferItemByAcceptingOffer",
              abi: ERC721_CONTRACT_ABI,
              params: {
                tokenId: tokenId,
                buyer: buyerAccount,
                seller: sellerAccount,
                artist: item?.creatorAccount,
                royalty: parsedRoyalty?.amount?.toString(),
                price: totalAmount?.toString(),
                ownerCut: (Number(totalAmount) - Number(offerPrice)).toString(),
              },
            };

            break;
          }
        }

        const otherOffersForThisItem = await fetchOtherOffersForThisItem();

        if (otherOffersForThisItem?.length > 0) {
          for await (let otherOffer of otherOffersForThisItem) {
            otherOffer.set("isRejected", true);
            await otherOffer.save();
          }
        }

        const transaction = await Moralis.executeFunction(ops);
        let result;
        try {
          result = await transaction.wait();
        } catch (error) {
          if (error.code === "TRANSACTION_REPLACED") {
            if (!error.cancelled) {
              result = error.replacement;
            }
          }
        }

        currentOffer.set("isAccepted", true);
        currentOffer.set("hash", result?.hash || result?.transactionHash);

        await currentOffer.save();

        const confirmedItem = await Moralis.Cloud.run("getItem", {
          tokenId: Number(item?.tokenId),
        });

        const currentLowestPrice = confirmedItem?.get("lowestPrice");

        if (chainType !== "on-chain") {
          confirmedItem.set("chainType", "on-chain");
        }
        confirmedItem.set("price", Number(offerPrice));
        if (currentLowestPrice > Number(offerPrice)) {
          confirmedItem.set("lowestPrice", Number(offerPrice));
        }
        confirmedItem.set("owner", buyer);
        confirmedItem.set("ownerAccount", buyer?.get("ethAddress"));
        confirmedItem.set("status", ITEM_STATUS.EXHIBIT_ONLY.value);

        const savedItem = await confirmedItem.save();

        const nftTransfers = Moralis.Object.extend("NFTTransfers");
        const transfer = new nftTransfers();
        transfer.set("tokenId", item?.tokenId);
        transfer.set("value", Number(price));
        transfer.set("from_address", sellerAccount);
        transfer.set("from", seller);
        transfer.set("to_address", buyerAccount);
        transfer.set("to", buyer);
        transfer.set("token_address", CONTRACT_ADDRESS_MAP[item?.chainId]);
        transfer.set("chainId", item?.chainId);
        transfer.set("item", savedItem);
        transfer.set("transaction_hash", result?.transactionHash);

        await transfer.save();

        const getBuyer = await Moralis.Cloud.run("getUser", {
          account: buyerAccount,
        });

        if (getBuyer?.get("email") && getBuyer?.get("checkNotification")) {
          sendEmail({
            email_subject: t("email.offerAccepted"),
            receiver: getBuyer?.get("email"),
            emailBody: getEmailBody(
              t("email.offerAccepted"),
              item?.isVideo,
              item?.imageSrc,
              "rgb(2, 78, 173)",
              t("email.offerAcceptedBody1") +
                ` <strong>${price} ${
                  NETWORKS[ENV]?.[item?.chainId]?.unit
                }</strong> ` +
                t("email.offerAcceptedBody2"),
              "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
              t("email.viewItem")
            ),
          });
        }

        setTimeout(() => {
          sendEmail({
            email_subject: "Offer Accepted",
            receiver: "mennintrade@gmail.com",
            emailBody: getEmailBody(
              t("email.offerAccepted"),
              item?.isVideo,
              item?.imageSrc,
              "rgb(2, 78, 173)",
              t("email.offerAcceptedBody1") +
                ` <strong>${price} ${
                  NETWORKS[ENV]?.[item?.chainId]?.unit
                }</strong> ` +
                t("email.offerAcceptedBody2"),
              "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
              t("email.viewItem")
            ),
          });
        }, 1000);

        setAcceptState(true);

        toast({
          title: "Successfully transferred",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
        setIsDone(true);
        setTimeout(() => {
          setIsDone(false);
        }, 2000);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }

      return;
    }
  };

  const lowestPrice = item?.lowestPrice;

  const floorDifference =
    lowestPrice > Number(offerPrice)
      ? Math.round((Number(offerPrice) / lowestPrice) * 100 * 100) / 100 +
        "% above"
      : Math.round((Number(offerPrice) / lowestPrice) * 100 * 100) / 100 +
        "% below";

  return !isMobile ? (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex
        ref={rowRef}
        alignItems="center"
        padding={isDesktop ? "20px 25px" : "12px 18px"}
        bg="#F6F5F7"
        borderRadius="19px"
        margin={isDesktop ? "12px 0px" : "10px 0px"}
        flex={1}
        mr="15px"
        overflow="hidden"
      >
        <>
          <Box cursor="pointer" onClick={onImageClick}>
            {!item?.isVideo ? (
              <Image
                width={`${isDesktop ? IMAGE_SIZE : IMAGE_SIZE_TABLET}px`}
                height={`${isDesktop ? IMAGE_SIZE : IMAGE_SIZE_TABLET}px`}
                objectFit="cover"
                borderRadius="6px"
                src={item?.imageSrc}
              />
            ) : (
              <video
                src={item?.imageSrc}
                style={{
                  width: isDesktop ? IMAGE_SIZE : IMAGE_SIZE_TABLET,
                  height: isDesktop ? IMAGE_SIZE : IMAGE_SIZE_TABLET,
                  objectFit: "cover",
                  borderRadius: 6,
                }}
                playsInline
                muted
                autoPlay
                loop
              />
            )}
          </Box>
          <Box
            flex={2}
            borderRight="1px solid #C6C6C6"
            fontSize={isDesktop ? "13px" : "8px"}
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("offered.price")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {price
                ? numberWithCommas(price) +
                  `[${NETWORKS[ENV]?.[item?.chainId]?.unit}]`
                : "-"}
            </Box>
          </Box>
          <Box
            flex={2}
            borderRight="1px solid #C6C6C6"
            fontSize={isDesktop ? "13px" : "8px"}
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("offered.floorDifference")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {floorDifference}
            </Box>
          </Box>

          {!isMade ? (
            <Box
              flex={1}
              borderRight="1px solid #C6C6C6"
              fontSize={isDesktop ? "13px" : "8px"}
              padding="0px 17px"
              overflow="hidden"
            >
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                From
              </Box>
              <Box
                onClick={() =>
                  onUsernameClick(buyer?.ethAddress || buyer?.get("ethAddress"))
                }
                cursor="pointer"
                w="100%"
                noOfLines={1}
              >
                {buyer?.get("username")}
              </Box>
            </Box>
          ) : (
            <Box
              flex={1}
              borderRight="1px solid #C6C6C6"
              fontSize={isDesktop ? "13px" : "8px"}
              padding="0px 17px"
              overflow="hidden"
            >
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                To
              </Box>
              <Box
                onClick={() =>
                  onUsernameClick(
                    seller?.ethAddress || seller?.get("ethAddress")
                  )
                }
                cursor="pointer"
                w="100%"
                noOfLines={1}
              >
                {seller?.get("username")}
              </Box>
            </Box>
          )}

          <Box
            flex={1}
            fontSize={isDesktop ? "13px" : "8px"}
            padding="0px 17px"
            overflow="hidden"
            borderRight="1px solid #C6C6C6"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("offered.expiration")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(expiration).format("YYYY. MM. DD")}
            </Box>
          </Box>
          <Box
            flex={1}
            fontSize={isDesktop ? "13px" : "8px"}
            padding="0px 17px"
            overflow="hidden"
          >
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {isMade ? t("offered.made") : t("offered.received")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(createdAt).format("YYYY. MM. DD")}
            </Box>
          </Box>
        </>
      </Flex>
      {userAccount ||
      expireState ||
      acceptState ||
      rejectState ||
      cancelState ? (
        <Box
          height={`${rowHeight}px`}
          w={isDesktop ? "157px" : "65px"}
          position="relative"
        >
          <Button
            w="100%"
            height="100%"
            color="black"
            borderRadius={isDesktop ? "15px" : "5px"}
            fontSize={isDesktop ? "17px" : "8px"}
            fontWeight="extrabold"
            disabled
          >
            {acceptState
              ? t("offered.accepted")
              : rejectState
              ? t("offered.declined")
              : cancelState
              ? t("offered.canceled")
              : expireState
              ? t("offered.expired")
              : t("offered.processing")}
          </Button>
        </Box>
      ) : isMade ? (
        <Box height={`${rowHeight}px`} w={isDesktop ? "157px" : "65px"}>
          <Button
            w="100%"
            height="100%"
            color="white"
            borderRadius={isDesktop ? "15px" : "5px"}
            fontSize={isDesktop ? "17px" : "8px"}
            fontWeight="extrabold"
            bg="#1D20FF"
            _hover={{
              backgroundColor: "#1D20FF",
            }}
            _active={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            onClick={onCancel}
            disabled={acceptState || cancelState || rejectState}
          >
            {t("offered.cancel")}
          </Button>
        </Box>
      ) : (
        <Flex
          flexDirection="column"
          alignItems="space-between"
          height={`${rowHeight}px`}
          w={isDesktop ? "157px" : "65px"}
        >
          <Button
            mb={isDesktop ? "15px" : "5px"}
            w="100%"
            color="white"
            bg="#1D20FF"
            borderRadius={isDesktop ? "15px" : "5px"}
            fontSize={isDesktop ? "17px" : "8px"}
            _hover={{
              backgroundColor: "#1D20FF",
            }}
            _active={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            onClick={onAcceptClick}
          >
            {t("offered.accept")}
          </Button>
          <Button
            w="100%"
            color="#949AB2"
            bg="#F6F5F7"
            borderRadius={isDesktop ? "15px" : "5px"}
            fontSize={isDesktop ? "17px" : "8px"}
            _hover={{
              backgroundColor: "#F6F5F7",
            }}
            _active={{
              backgroundColor: "#F6F5F7",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#F6F5F7",
              outline: "none",
            }}
            onClick={onReject}
          >
            {t("offered.decline")}
          </Button>
        </Flex>
      )}
      {(loading || isDone) && (
        <Flex
          position="fixed"
          direction="column"
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          zIndex={1500}
          bg="white"
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <Box w="100%" textAlign="center">
              <Box
                fontSize={isDesktop ? "25px" : isTablet ? "16px" : "20px"}
                fontWeight="extrabold"
                mb={isDesktop ? "10px" : isTablet ? "8px" : "3px"}
              >
                {t("global.acceptingOfferLoading")}
              </Box>
              <Loading width={isDesktop ? "60%" : isTablet ? "80%" : "90%"} />
            </Box>
          )}
          {isDone && (
            <Box w="90%">
              <ThankyouContainer route={null} />
            </Box>
          )}
        </Flex>
      )}
    </Flex>
  ) : (
    <Box margin="20px 0px">
      <Box padding="22px 20px" bg="#F6F5F7" borderRadius="19px" mb="15px">
        <Flex alignItems="center" mb="27px">
          <Box onClick={onImageClick}>
            {!item?.isVideo ? (
              <Image
                width={`${IMAGE_SIZE_MOBILE}px`}
                height={`${IMAGE_SIZE_MOBILE}px`}
                objectFit="cover"
                borderRadius="6px"
                src={item?.imageSrc}
              />
            ) : (
              <video
                src={item?.imageSrc}
                style={{
                  width: IMAGE_SIZE_MOBILE,
                  height: IMAGE_SIZE_MOBILE,
                  objectFit: "cover",
                  borderRadius: 6,
                }}
                playsInline
                muted
                autoPlay
                loop
              />
            )}
          </Box>
          <Flex
            flex={1}
            alignItems="center"
            bg="white"
            borderTopRightRadius="5px"
            borderBottomRightRadius="5px"
            paddingTop="11px"
            paddingBottom="11px"
            paddingLeft="51px"
            overflow="hidden"
          >
            <Box flex={1} fontSize="13px">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                {t("offered.price")}
              </Box>
              <Box w="100%" noOfLines={1}>
                {price
                  ? numberWithCommas(price) +
                    `[${NETWORKS[ENV]?.[item?.chainId]?.unit}]`
                  : "-"}
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          bg="white"
          borderRadius="5px"
          padding="11px 24px"
          mb="27px"
        >
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("offered.floorDifference")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {floorDifference}
            </Box>
          </Box>
          {!isMade ? (
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                From
              </Box>
              <Box
                onClick={() =>
                  onUsernameClick(buyer?.ethAddress || buyer?.get("ethAddress"))
                }
                w="100%"
                noOfLines={1}
              >
                {buyer?.get("username")}
              </Box>
            </Box>
          ) : (
            <Box flex={1} fontSize="13px" overflow="hidden">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                To
              </Box>
              <Box
                onClick={() =>
                  onUsernameClick(
                    seller?.ethAddress || seller?.get("ethAddress")
                  )
                }
                w="100%"
                noOfLines={1}
              >
                {seller?.get("username")}
              </Box>
            </Box>
          )}
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          bg="white"
          borderRadius="5px"
          padding="11px 24px"
        >
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("offered.expiration")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(expiration).format("YYYY. MM. DD")}
            </Box>
          </Box>
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {isMade ? t("offered.made") : t("offered.received")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(createdAt).format("YYYY. MM. DD")}
            </Box>
          </Box>
        </Flex>
      </Box>
      {userAccount ||
      expireState ||
      acceptState ||
      rejectState ||
      cancelState ? (
        <Box height="47px" w="100%" position="relative">
          <Button
            w="100%"
            height="100%"
            color="black"
            borderRadius="15px"
            fontSize="17px"
            fontWeight="extrabold"
            disabled
          >
            {acceptState
              ? t("offered.accepted")
              : rejectState
              ? t("offered.declined")
              : cancelState
              ? t("offered.canceled")
              : expireState
              ? t("offered.expired")
              : t("offered.processing")}
          </Button>
        </Box>
      ) : isMade ? (
        <Box height="47px" w="100%">
          <Button
            w="100%"
            height="100%"
            color="white"
            borderRadius="15px"
            fontSize="17px"
            fontWeight="extrabold"
            bg="#1D20FF"
            _hover={{
              backgroundColor: "#1D20FF",
            }}
            _active={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            onClick={onCancel}
            disabled={acceptState || cancelState || rejectState}
          >
            {t("offered.cancel")}
          </Button>
        </Box>
      ) : (
        <Flex
          flexDirection="row"
          alignItems="space-between"
          height="47px"
          w="100%"
        >
          <Button
            flex={1}
            mr="21px"
            color="white"
            bg="#1D20FF"
            borderRadius="15px"
            fontSize="17px"
            _hover={{
              backgroundColor: "#1D20FF",
            }}
            _active={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#1D20FF",
              outline: "none",
            }}
            onClick={onAcceptClick}
          >
            {t("offered.accept")}
          </Button>
          <Button
            flex={1}
            color="#949AB2"
            bg="#F6F5F7"
            borderRadius="15px"
            fontSize="17px"
            _hover={{
              backgroundColor: "#F6F5F7",
            }}
            _active={{
              backgroundColor: "#F6F5F7",
              outline: "none",
            }}
            _focus={{
              backgroundColor: "#F6F5F7",
              outline: "none",
            }}
            onClick={onReject}
          >
            {t("offered.decline")}
          </Button>
        </Flex>
      )}
      {(loading || isDone) && (
        <Flex
          position="fixed"
          direction="column"
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          zIndex={1500}
          bg="white"
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <Box w="100%" textAlign="center">
              <Box
                fontSize={isDesktop ? "25px" : isTablet ? "16px" : "20px"}
                fontWeight="extrabold"
                mb={isDesktop ? "10px" : isTablet ? "8px" : "3px"}
              >
                {t("global.acceptingOfferLoading")}
              </Box>
              <Loading width={isDesktop ? "60%" : isTablet ? "80%" : "90%"} />
            </Box>
          )}
          {isDone && <ThankyouContainer route={null} />}
        </Flex>
      )}
    </Box>
  );
}

export default ViewAllOffer;
