import { Button } from "@chakra-ui/button";
import { Flex, VStack } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../../common/utils/useResponsive";

const BUTTON_WIDTH = "100%";
const BUTTON_HEIGHT = "70px";

const BUTTON_WIDTH_TABLET = "234px";
const BUTTON_HEIGHT_TABLET = "60px";

const BUTTON_WIDTH_MOBILE = "50%";
const BUTTON_HEIGHT_MOBILE = "54px";

function UpdateProfileButtons({ loading, confirm, cancel, profile }) {
  const { t } = useTranslation();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { snsErrors, emailError, usernameError } = profile;

  const isConfirmDisabled = snsErrors || emailError || usernameError;

  return (
    <Flex
      flexDir={!isDesktop ? "row" : "column"}
      marginLeft={!isDesktop ? "0px" : "30px"}
      paddingLeft="0px"
      paddingRight="0px"
      flex={1}
      alignItems="center"
      width="100%"
      marginTop={!isDesktop ? "35px" : "0px"}
      justifyContent={isDesktop ? "space-between" : "center"}
    >
      <Button
        isLoading={loading}
        w={
          isDesktop
            ? BUTTON_WIDTH
            : isTablet
            ? BUTTON_WIDTH_TABLET
            : BUTTON_WIDTH_MOBILE
        }
        h={
          isDesktop
            ? BUTTON_HEIGHT
            : isTablet
            ? BUTTON_HEIGHT_TABLET
            : BUTTON_HEIGHT_MOBILE
        }
        color="white"
        background="#1D20FF 0% 0% no-repeat padding-box"
        borderRadius={isMobile ? "11px" : "19px"}
        fontSize={!isMobile ? "20px" : "16px"}
        fontWeight="bold"
        boxShadow={
          isMobile
            ? "0px 20px 20px -15px rgba(29, 32, 255, 0.4)"
            : "0px 35px 40px -20px rgba(29, 32, 255, 0.4)"
        }
        marginRight={!isDesktop ? "25px" : "0px"}
        marginBottom={!isDesktop ? "0px" : "40px"}
        _hover={{
          backgroundColor: "#1d20ff",
        }}
        _focus={{ outline: "none", backgroundColor: "#1d20ff" }}
        _active={{ outline: "none", backgroundColor: "#1d20ff" }}
        onClick={confirm}
        disabled={isConfirmDisabled}
      >
        {t("updateProfile.confirm")}
      </Button>
      <Button
        isLoading={loading}
        w={
          isDesktop
            ? BUTTON_WIDTH
            : isTablet
            ? BUTTON_WIDTH_TABLET
            : BUTTON_WIDTH_MOBILE
        }
        h={
          isDesktop
            ? BUTTON_HEIGHT
            : isTablet
            ? BUTTON_HEIGHT_TABLET
            : BUTTON_HEIGHT_MOBILE
        }
        color="#1d20ff"
        background="#F6F5F7 0% 0% no-repeat padding-box"
        borderRadius={isMobile ? "11px" : "19px"}
        fontSize={!isMobile ? "20px" : "16px"}
        fontWeight="bold"
        boxShadow={
          isMobile
            ? "0px 20px 20px -15px rgba(0,0,0,0.3)"
            : "0px 35px 40px -20px rgba(0,0,0,0.3)"
        }
        _hover={{
          backgroundColor: "#F6F5F7",
        }}
        _focus={{
          outline: "none",
          backgroundColor: "#F6F5F7",
        }}
        _active={{
          outline: "none",
          backgroundColor: "#F6F5F7",
        }}
        onClick={cancel}
      >
        {t("updateProfile.cancel")}
      </Button>
    </Flex>
  );
}

export default UpdateProfileButtons;
