import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, useToast } from "@chakra-ui/react";
import { Container, Flex, Spacer } from "@chakra-ui/layout";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";

import AuthModal from "../../auth/components/AuthModal";

import logo from "../../../assets/icons/logo.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import useResponsive from "../../common/utils/useResponsive";

import homeIcon from "../../../assets/icons/home.svg";
import categoryIcon from "../../../assets/icons/category.svg";
import artlistIcon from "../../../assets/icons/artlist.svg";
import tutorialIcon from "../../../assets/icons/tutorial.svg";
import createIcon from "../../../assets/icons/create.svg";
import profileMenuIcon from "../../../assets/icons/profile_menu.svg";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import {
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
} from "../../../utilities/constants";
import { useLocation } from "react-router-dom";
import { Avatar } from "@chakra-ui/react";
import ProfileDropdownMenu from "./ProfileDropdownMenu";
import { useTranslation } from "react-i18next";

const ICON_SIZE = 25;
const CREATE_ICON_SIZE = 35;

const LOGO_WIDTH = "148px";
const LOGO_WIDTH_TABLET = "90px";

function NavigationBar() {
  const { isTablet, isMobile, isDesktop } = useResponsive();

  const { isAuthenticated, user, isWeb3Enabled, isWeb3EnableLoading } =
    useMoralis();

  const { t } = useTranslation();

  const history = useHistory();

  const location = useLocation();
  const { pathname } = location;

  const toast = useToast();

  const [showAuthModal, setShowAuthModal] = useState(false);

  const [showProfileDropdownMenu, setShowProfileDropdownMenu] = useState(false);

  const isTransparentNavbar =
    pathname === ROUTE_NAMES.arts ||
    pathname.includes(ROUTE_NAMES.viewAll) ||
    pathname === ROUTE_NAMES.tutorial;

  useEffect(() => {
    if (isAuthenticated) {
      setShowAuthModal(false);
    }
  }, [isAuthenticated]);

  const toggleAuthModal = (value) => {
    setShowAuthModal(value);
  };

  const onProfileClick = () => {
    if (isMobile) {
      if (isAuthenticated) {
        history.push(ROUTE_NAMES.profile);
      } else {
        toggleAuthModal(true);
      }
    } else {
      if (isAuthenticated) {
        if (!showProfileDropdownMenu) {
          setShowProfileDropdownMenu(true);
        }
      } else {
        toggleAuthModal(true);
      }
    }
  };

  const onCreateClick = async () => {
    if (!isAuthenticated) {
      toggleAuthModal(true);
    } else if (isWeb3EnableLoading || !isWeb3Enabled) {
      toast({
        title: "Please check your wallet to connect with MENNIN",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      return;
    } else {
      history.push(ROUTE_NAMES.create);
    }
  };

  const onNormalRouteClick = (routeName) => {
    if (routeName === pathname) {
      document.location.reload();
    } else {
      history.push(routeName);
    }
  };

  return isMobile ? (
    <>
      <Container
        centerContent={true}
        position="fixed"
        bottom="14px"
        w="90%"
        bg="#F6F5F7"
        left="50%"
        transform="translateX(-50%)"
        padding="18px 40px"
        boxShadow="0px 3px 30px 0px rgba(0,0,0,0.3)"
        border="1px solid white"
        borderRadius="15px"
        zIndex={100}
        height={`${MOBILE_NAVIGATION_HEIGHT}px`}
      >
        <Flex alignItems="center" justifyContent="space-between" w="100%">
          {/* TODO: change it to opening category modal button */}
          <Link
            style={styles.link}
            to={ROUTE_NAMES.home}
            onClick={() => {
              if (pathname === ROUTE_NAMES.home) {
                document.location.reload();
              }
            }}
          >
            <img
              src={homeIcon}
              style={{ width: ICON_SIZE, height: ICON_SIZE }}
            />
          </Link>
          <Link
            style={styles.link}
            to={ROUTE_NAMES.arts}
            onClick={() => {
              if (pathname === ROUTE_NAMES.arts) {
                document.location.reload();
              }
            }}
          >
            <img
              src={artlistIcon}
              style={{ width: ICON_SIZE, height: ICON_SIZE }}
            />
          </Link>
          <Button
            style={styles.link}
            variant="ghost"
            backgroundColor="transparent"
            _hover={{ backgroundColor: "transparent" }}
            _focus={{
              outline: "none",
            }}
            _active={{
              outline: "none",
              backgroundColor: "white",
              boxShadow: "none",
            }}
            onClick={onCreateClick}
          >
            <img
              src={createIcon}
              style={{ width: CREATE_ICON_SIZE, height: CREATE_ICON_SIZE }}
            />
          </Button>
          <Link
            style={styles.link}
            to={ROUTE_NAMES.tutorial}
            onClick={() => {
              if (pathname === ROUTE_NAMES.tutorial) {
                document.location.reload();
              }
            }}
          >
            <img
              src={tutorialIcon}
              style={{ width: ICON_SIZE, height: ICON_SIZE }}
            />
          </Link>
          <Button
            style={styles.link}
            variant="ghost"
            backgroundColor="transparent"
            outline="none"
            _hover={{ backgroundColor: "transparent" }}
            _focus={{
              outline: "none",
            }}
            _active={{
              outline: "none",
              backgroundColor: "white",
              boxShadow: "none",
            }}
            onClick={onProfileClick}
          >
            <Image
              src={user?.get("avatar")?._url || profileMenuIcon}
              style={{ width: ICON_SIZE, height: ICON_SIZE }}
              borderRadius="50%"
            />
          </Button>
        </Flex>
      </Container>
      {showAuthModal && (
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => toggleAuthModal(false)}
        />
      )}
    </>
  ) : (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: DESKTOP_NAVIGATION_HEIGHT,
        zIndex: 200,
        backgroundColor: isTransparentNavbar ? "transparent" : "white",
      }}
    >
      <Flex px="12" py="8" alignItems="center">
        <Button
          variant="ghost"
          backgroundColor="transparent"
          _hover={{ backgroundColor: "transparent" }}
          _focus={{
            outline: "none",
            bg: "transparent",
          }}
          _active={{
            outline: "none",
            backgroundColor: "transparent",
            boxShadow: "none",
          }}
        >
          <Link
            to="/"
            onClick={() => {
              if (pathname === ROUTE_NAMES.home) {
                document.location.reload();
              }
            }}
          >
            <Image
              src={logo}
              width={isTablet ? LOGO_WIDTH_TABLET : LOGO_WIDTH}
            />
          </Link>
        </Button>
        <Spacer />
        <Flex alignItems="center">
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            variant="ghost"
            mr={isDesktop ? "35px" : "30px"}
            fontSize={isTablet ? "14px" : "16px"}
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ outlineWidth: 0, backgroundColor: "transparent" }}
            _active={{ backgroundColor: "transparent" }}
            onClick={() => onNormalRouteClick(ROUTE_NAMES.arts)}
            padding="0px"
          >
            {t("global.arts")}
          </Button>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            variant="ghost"
            mr={isDesktop ? "48px" : "30px"}
            fontSize={isTablet ? "14px" : "16px"}
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ outlineWidth: 0, backgroundColor: "transparent" }}
            _active={{ backgroundColor: "transparent" }}
            padding="0px"
            onClick={() => onNormalRouteClick(ROUTE_NAMES.tutorial)}
          >
            {t("global.tutorial")}
          </Button>
          <Button
            display="flex"
            alignItems="center"
            justifyContent="center"
            backgroundColor="#1d20ff"
            color="white"
            borderRadius="15px"
            height={isDesktop ? "40px" : "30px"}
            width={isDesktop ? "160px" : "120px"}
            fontSize={isDesktop ? "20px" : "14px"}
            mr={isDesktop ? "48px" : "30px"}
            _hover={{
              backgroundColor: "#1d20ff",
            }}
            _active={{
              outline: "none",
              backgroundColor: "#1d20ff",
            }}
            _focus={{
              outline: "none",
              backgroundColor: "#1d20ff",
            }}
            onClick={onCreateClick}
          >
            {t("global.create")}
          </Button>
          <Box position="relative">
            <Button
              variant="ghost"
              backgroundColor="transparent"
              _hover={{ backgroundColor: "transparent" }}
              _focus={{
                outline: "none",
              }}
              _active={{
                outline: "none",
                backgroundColor: "white",
                boxShadow: "none",
              }}
              cursor="pointer"
              pointerEvents={showProfileDropdownMenu ? "none" : "auto"}
              onClick={onProfileClick}
              padding="0px"
            >
              <Image
                src={user?.get("avatar")?._url || profileIcon}
                boxSize={isDesktop ? "52px" : "30px"}
                borderRadius="full"
              />
            </Button>
            {showProfileDropdownMenu && (
              <ProfileDropdownMenu
                closeMenu={() => setShowProfileDropdownMenu(false)}
              />
            )}
          </Box>
        </Flex>
      </Flex>
      {showAuthModal && (
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => toggleAuthModal(false)}
        />
      )}
    </div>
  );
}

const styles = {
  link: {
    padding: 5,
  },
};

export default NavigationBar;
