import { Box, Container, Flex, Image } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory, useParams } from "react-router-dom";

import { useSelector, useDispatch, useStore } from "react-redux";

import InfiniteScroll from "react-infinite-scroller";

import ArtsListThumbnail from "../../arts/components/ArtsListThumbnail";
import ProfileViewAllContainer from "../common/components/ProfileViewAllContainer";
import {
  PROFILE_TAB_ROUTE_NAMES,
  VIEW_ALL_ROUTE_NAMES,
} from "../profile/utils/profileTabOptions";

import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";
import TopLogo from "../../auth/components/TopLogo";
import Loading from "../../common/components/Loading";
import {
  setProfileCollectedItems,
  setProfileCreatedItems,
  setProfileFavoritesItems,
  setProfileOfferMadeItems,
  setProfileOfferReceivedItems,
} from "../../../lib/redux/reducers/profile/viewAll/profileViewAllReducer";

import arrowDownIcon from "../../../assets/icons/arrow_down_gray.svg";
import arrowUpIcon from "../../../assets/icons/arrow_up_gray.svg";
import clockIcon from "../../../assets/icons/clock.svg";
import ViewAllOffer from "./components/ViewAllOffer";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 14;

const OFFER_STATE = {
  RECEIVED: {
    option: "RECEIVED",
    name: "offerReceived",
  },
  MADE: {
    option: "MADE",
    name: "offerMade",
  },
};

function ViewAllContainer() {
  const { option, userAccount } = useParams();
  const history = useHistory();

  const { t } = useTranslation();

  const scrollWrapperRef = useRef();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { Moralis, user, chainId, web3 } = useMoralis();

  const created = useSelector((state) => state.profileViewAll.created);
  const createdCount = useSelector(
    (state) => state.profileViewAll.createdCount
  );
  const createdAccount = useSelector(
    (state) => state.profileViewAll.createdAccount
  );

  const collected = useSelector((state) => state.profileViewAll.collected);
  const collectedCount = useSelector(
    (state) => state.profileViewAll.collectedCount
  );
  const collectedAccount = useSelector(
    (state) => state.profileViewAll.collectedAccount
  );

  const offerReceived = JSON.parse(
    useSelector((state) => state.profileViewAll.offerReceived)
  );
  const offerReceivedCount = useSelector(
    (state) => state.profileViewAll.offerReceivedCount
  );
  const offerMade = JSON.parse(
    useSelector((state) => state.profileViewAll.offerMade)
  );
  const offerMadeCount = useSelector(
    (state) => state.profileViewAll.offerMadeCount
  );
  const offerAccount = useSelector(
    (state) => state.profileViewAll.offerAccount
  );

  const favorites = useSelector((state) => state.profileViewAll.favorites);
  const favoritesCount = useSelector(
    (state) => state.profileViewAll.favoritesCount
  );
  const favoritesAccount = useSelector(
    (state) => state.profileViewAll.favoritesAccount
  );

  const currentAccount = useSelector(
    (state) => state.profileViewAll.currentAccount
  );

  const dispatch = useDispatch();

  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);
  const [showOfferOption, setShowOfferOption] = useState(false);
  const [currentOfferOption, setCurrentOfferOption] = useState(
    OFFER_STATE.RECEIVED.option
  );

  const getViewAllResults = async (account) => {
    if (loading) return;

    if (!account) return;

    setLoading(true);

    switch (option) {
      case PROFILE_TAB_ROUTE_NAMES.CREATED: {
        const fetchResults = [];

        const query = await Moralis.Cloud.run("getUserCreatedThumbnails", {
          account: account,
          limit: PAGE_SIZE,
        });

        const { results, count } = query;

        for (let item of results) {
          const obj = {
            id: item?.id,
            tokenId: item?.get("tokenId"),
            imageSrc: item?.get("nftFilePath"),
            title: item?.get("title"),

            username: item?.get("owner")?.get("username"),
            price: item?.get("price"),
            isVideo: item?.get("isVideo"),
          };

          fetchResults.push(obj);
        }

        dispatch(
          setProfileCreatedItems({ items: fetchResults, count, account })
        );

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.COLLECTED: {
        const fetchResults = [];

        const query = await Moralis.Cloud.run("getUserCollectedThumbnails", {
          account: account,
          limit: PAGE_SIZE,
        });
        const { results, count } = query;

        for await (let item of results) {
          const obj = {
            id: item?.id,
            tokenId: item?.get("tokenId"),
            imageSrc: item?.get("nftFilePath"),
            title: item?.get("title"),
            username: item?.get("owner")?.get("username"),
            price: item?.get("price"),
            isVideo: item?.get("isVideo"),
          };

          fetchResults.push(obj);
        }

        dispatch(
          setProfileCollectedItems({ items: fetchResults, count, account })
        );

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.OFFERED: {
        const offerMadeQuery = await Moralis.Cloud.run("getOfferMade", {
          account: account,
          limit: PAGE_SIZE,
        });

        const { results: offerMadeResult, count: offerMadeCounts } =
          offerMadeQuery;

        dispatch(
          setProfileOfferMadeItems({
            items: JSON.stringify(offerMadeResult),
            count: offerMadeCounts,
            account,
          })
        );

        const offerReceivedQuery = await Moralis.Cloud.run("getOfferReceived", {
          account: account,
          limit: PAGE_SIZE,
        });

        const { results: offerReceiveResult, count: offerReceiveCount } =
          offerReceivedQuery;

        dispatch(
          setProfileOfferReceivedItems({
            items: JSON.stringify(offerReceiveResult),
            count: offerReceiveCount,
            account,
          })
        );

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.FAVORITE: {
        const fetchResults = [];

        const currentUserQuery = await Moralis.Cloud.run("getUser", {
          account: account,
        });

        const userItemRelation = currentUserQuery.relation("favorites");
        const userItemRelationQuery = userItemRelation.query();

        userItemRelationQuery.notEqualTo("isDeleted", true);
        userItemRelationQuery.limit(PAGE_SIZE);
        userItemRelationQuery.withCount();

        const query = await userItemRelationQuery.find();

        const { results, count } = query;

        for await (let item of results) {
          const userQuery = await Moralis.Cloud.run("getUser", {
            account: item?.get("ownerAccount"),
          });

          const username = userQuery?.get("username");

          const obj = {
            id: item?.id,
            tokenId: item?.get("tokenId"),
            imageSrc: item?.get("nftFilePath"),
            title: item?.get("title"),
            username,
            price: item?.get("price"),
            isVideo: item?.get("isVideo"),
          };

          fetchResults.push(obj);
        }

        dispatch(
          setProfileFavoritesItems({ items: fetchResults, count, account })
        );
      }
    }
    setLoading(false);
  };

  const offerCreateSubscriptionHandler = useCallback(async (object) => {
    const sellerAccount = object?.get("sellerAccount");
    const buyerAccount = object?.get("buyerAccount");

    if (sellerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferReceived = JSON.parse(
        currentState.profileViewAll.offerReceived
      );
      const previousOfferReceivedCount =
        currentState.profileViewAll.offerReceivedCount;

      const newState =
        previousOfferReceived?.length > 0
          ? [object, ...previousOfferReceived]
          : [object];
      const newCount =
        previousOfferReceivedCount > 0 ? previousOfferReceivedCount + 1 : 1;
      store.dispatch(
        setProfileOfferReceivedItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    } else if (buyerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferMade = JSON.parse(
        currentState.profileViewAll.offerMade
      );
      const previousOfferMadeCount = currentState.profileViewAll.offerMadeCount;

      const newState =
        previousOfferMade?.length > 0
          ? [object, ...previousOfferMade]
          : [object];
      const newCount =
        previousOfferMadeCount > 0 ? previousOfferMadeCount + 1 : 1;

      store.dispatch(
        setProfileOfferMadeItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    }
  }, []);

  const offerUpdateSubscriptionHandler = useCallback(async (object) => {
    const sellerAccount = object?.get("sellerAccount");
    const buyerAccount = object?.get("buyerAccount");

    if (sellerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferReceived = JSON.parse(
        currentState.profileViewAll.offerReceived
      );
      const previousOfferReceivedCount =
        currentState.profileViewAll.offerReceivedCount;

      const updatedIndex = [...previousOfferReceived]?.findIndex(
        (item) => item?.objectId === object?.id
      );
      const newState = [...previousOfferReceived];
      newState[updatedIndex] = object;

      const newCount = previousOfferReceivedCount;
      store.dispatch(
        setProfileOfferReceivedItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    } else if (buyerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferMade = JSON.parse(
        currentState.profileViewAll.offerMade
      );
      const previousOfferMadeCount = currentState.profileViewAll.offerMadeCount;

      const updatedIndex = [...previousOfferMade]?.findIndex(
        (item) => item?.objectId === object?.id
      );
      const newState = [...previousOfferMade];
      newState[updatedIndex] = object;

      const newCount = previousOfferMadeCount;

      store.dispatch(
        setProfileOfferMadeItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (option === PROFILE_TAB_ROUTE_NAMES.CREATED) {
      if (!createdAccount && userAccount) {
        getViewAllResults(userAccount);
      }

      if (!createdAccount && !userAccount) {
        getViewAllResults(user?.get("ethAddress"));
      }

      if (createdAccount && userAccount && createdAccount !== userAccount) {
        getViewAllResults(userAccount);
      }

      if (
        createdAccount &&
        !userAccount &&
        createdAccount !== user?.get("ethAddress")
      ) {
        getViewAllResults(user?.get("ethAddress"));
      }
    }
  }, [user, userAccount, createdAccount, created, option]);

  useEffect(() => {
    if (option === PROFILE_TAB_ROUTE_NAMES.COLLECTED) {
      if (!collectedAccount && userAccount) {
        getViewAllResults(userAccount);
      }

      if (!collectedAccount && !userAccount) {
        getViewAllResults(user?.get("ethAddress"));
      }

      if (collectedAccount && userAccount && collectedAccount !== userAccount) {
        getViewAllResults(userAccount);
      }

      if (
        collectedAccount &&
        !userAccount &&
        collectedAccount !== user?.get("ethAddress")
      ) {
        getViewAllResults(user?.get("ethAddress"));
      }
    }
  }, [user, userAccount, collectedAccount, collected, option]);

  useEffect(() => {
    if (option === PROFILE_TAB_ROUTE_NAMES.OFFERED) {
      if (!offerAccount && userAccount) {
        getViewAllResults(userAccount);
      }

      if (!offerAccount && !userAccount) {
        getViewAllResults(user?.get("ethAddress"));
      }

      if (offerAccount && userAccount && offerAccount !== userAccount) {
        getViewAllResults(userAccount);
      }

      if (
        offerAccount &&
        !userAccount &&
        offerAccount !== user?.get("ethAddress")
      ) {
        getViewAllResults(user?.get("ethAddress"));
      }
    }
  }, [user, userAccount, offerAccount, offerMade, offerReceived, option]);

  useEffect(() => {
    if (option === PROFILE_TAB_ROUTE_NAMES.FAVORITE) {
      if (!favoritesAccount && userAccount) {
        getViewAllResults(userAccount);
      }

      if (!favoritesAccount && !userAccount) {
        getViewAllResults(user?.get("ethAddress"));
      }

      if (favoritesAccount && userAccount && favoritesAccount !== userAccount) {
        getViewAllResults(userAccount);
      }

      if (
        favoritesAccount &&
        !userAccount &&
        favoritesAccount !== user?.get("ethAddress")
      ) {
        getViewAllResults(user?.get("ethAddress"));
      }
    }
  }, [user, userAccount, favoritesAccount, favorites, option]);

  const fetchMore = async () => {
    if (fetchingMore) return;

    switch (option) {
      case PROFILE_TAB_ROUTE_NAMES.CREATED: {
        if (created?.length < createdCount) {
          setFetchingMore(true);

          const fetchResults = [];

          const query = await Moralis.Cloud.run("getUserCreatedThumbnails", {
            account: createdAccount,
            limit: PAGE_SIZE,
            skip: created?.length,
          });

          const { results, count } = query;

          for (let item of results) {
            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),

              username: item?.get("owner")?.get("username"),
              price: item?.get("price"),
              isVideo: item?.get("isVideo"),
            };

            fetchResults.push(obj);
          }

          dispatch(
            setProfileCreatedItems({
              items: [...created, ...fetchResults],
              count,
              account: createdAccount,
            })
          );

          setFetchingMore(false);
        }

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.COLLECTED: {
        if (collected?.length < collectedCount) {
          const fetchResults = [];

          const query = await Moralis.Cloud.run("getUserCollectedThumbnails", {
            account: collectedAccount,
            limit: PAGE_SIZE,
            skip: collected?.length,
          });
          const { results, count } = query;

          for await (let item of results) {
            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),

              username: item?.get("owner")?.get("username"),
              isVideo: item?.get("isVideo"),

              price: item?.get("price"),
            };

            fetchResults.push(obj);
          }

          dispatch(
            setProfileCollectedItems({
              items: [...collected, ...fetchResults],
              count,
              account: collectedAccount,
            })
          );

          setFetchingMore(false);
        }

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.OFFERED: {
        if (
          offerMade?.length < offerMadeCount ||
          offerReceived?.length < offerReceivedCount
        ) {
          setFetchingMore(true);

          const offerMadeQuery = await Moralis.Cloud.run("getOfferMade", {
            account: offerAccount,
            limit: PAGE_SIZE,
            skip: offerMade?.length,
          });

          const { results: offerMadeResult, count: offerMadeCounts } =
            offerMadeQuery;

          dispatch(
            setProfileOfferMadeItems({
              items: JSON.stringify([...offerMade, ...offerMadeResult]),
              count: offerMadeCounts,
              account: offerAccount,
            })
          );

          const offerReceivedQuery = await Moralis.Cloud.run(
            "getOfferReceived",
            {
              account: offerAccount,
              limit: PAGE_SIZE,
              skip: offerReceived?.length,
            }
          );

          const { results: offerReceivedResult, count: offerReceivedCount } =
            offerReceivedQuery;

          dispatch(
            setProfileOfferReceivedItems({
              items: JSON.stringify([...offerReceived, ...offerReceivedResult]),
              count: offerReceivedCount,
              account: offerAccount,
            })
          );

          setFetchingMore(false);
        }

        break;
      }

      case PROFILE_TAB_ROUTE_NAMES.FAVORITE: {
        if (favorites?.length < favoritesCount) {
          setFetchingMore(true);
          const fetchResults = [];

          const currentUserQuery = await Moralis.Cloud.run("getUser", {
            account: favoritesAccount,
          });

          const userItemRelation = currentUserQuery.relation("favorites");
          const userItemRelationQuery = userItemRelation.query();

          userItemRelationQuery.skip(favorites?.length);
          userItemRelationQuery.limit(PAGE_SIZE);
          userItemRelationQuery.withCount();

          const query = await userItemRelationQuery.find();

          const { results, count } = query;

          for await (let item of results) {
            const userQuery = await Moralis.Cloud.run("getUser", {
              account: item?.get("ownerAccount"),
            });

            const username = userQuery?.get("username");

            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),
              username,
              isVideo: item?.get("isVideo"),
              price: item?.get("price"),
            };

            fetchResults.push(obj);
          }

          dispatch(
            setProfileFavoritesItems({
              items: [...favorites, ...fetchResults],
              count,
              account: favoritesAccount,
            })
          );

          setFetchingMore(false);
        }
        break;
      }
    }
  };

  const items = {
    [PROFILE_TAB_ROUTE_NAMES.CREATED]: created,
    [PROFILE_TAB_ROUTE_NAMES.COLLECTED]: collected,
    [PROFILE_TAB_ROUTE_NAMES.FAVORITE]: favorites,
  };

  const itemsCount = {
    [PROFILE_TAB_ROUTE_NAMES.CREATED]: createdCount,
    [PROFILE_TAB_ROUTE_NAMES.COLLECTED]: collectedCount,
    [PROFILE_TAB_ROUTE_NAMES.FAVORITE]: favoritesCount,
  };

  const offers = {
    RECEIVED: offerReceived,
    MADE: offerMade,
  };

  const renderPage = () => {
    switch (option) {
      case PROFILE_TAB_ROUTE_NAMES.OFFERED: {
        return (
          <Box ref={scrollWrapperRef}>
            <Flex
              ml={isDesktop ? "30px" : "15px"}
              fontSize={isDesktop ? "50px" : "23px"}
              fontWeight="extrabold"
              color="#3E3E3E"
              mb={isDesktop ? "80px" : "40px"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{t(`profile.${VIEW_ALL_ROUTE_NAMES[option]}`)}</Box>
              <Box
                position="relative"
                fontSize="15px"
                fontWeight="extrabold"
                w="300px"
                color="#949AB2"
                cursor="pointer"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  padding="12px 15px"
                  borderRadius="9px"
                  borderBottomRightRadius={showOfferOption ? "0px" : "9px"}
                  borderBottomLeftRadius={showOfferOption ? "0px" : "9px"}
                  bg="#F6F5F7"
                  onClick={() => setShowOfferOption(!showOfferOption)}
                >
                  <Flex alignItems="center">
                    <Box mr="20px">
                      <Image src={clockIcon} w="26px" h="26px" />
                    </Box>
                    <Box>
                      {t(`offered.${OFFER_STATE[currentOfferOption].name}`)}
                    </Box>
                  </Flex>
                  <Box>
                    <Image
                      src={showOfferOption ? arrowUpIcon : arrowDownIcon}
                    />
                  </Box>
                </Flex>
                {showOfferOption && (
                  <Box
                    position="absolute"
                    left="0px"
                    right="0px"
                    top="100%"
                    borderRadius="9px"
                    borderTopRightRadius="0px"
                    borderTopLeftRadius="0px"
                    bg="#F6F5F7"
                  >
                    {Object.keys(OFFER_STATE)
                      ?.filter((key) => key !== currentOfferOption)
                      ?.map((state) => (
                        <Flex
                          key={state}
                          alignItems="center"
                          justifyContent="space-between"
                          padding="12px 15px"
                          paddingLeft={12 + 26 + 20 + 3 + "px"}
                          onClick={() => {
                            setCurrentOfferOption(OFFER_STATE[state].option);
                            setShowOfferOption(false);
                          }}
                        >
                          <Box>{t(`offered.${OFFER_STATE[state].name}`)}</Box>
                        </Flex>
                      ))}
                  </Box>
                )}
              </Box>
            </Flex>
            {loading && <Loading />}
            {!loading && (
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMore}
                useWindow={false}
                getScrollParent={() => scrollWrapperRef?.current}
                hasMore={
                  offerMade?.length < offerMadeCount ||
                  offerReceived?.length < offerReceivedCount
                }
              >
                <Box>
                  {offers?.[currentOfferOption]?.map((item) => (
                    <ViewAllOffer key={item?.objectId} offer={item} />
                  ))}
                </Box>
              </InfiniteScroll>
            )}
          </Box>
        );
      }

      default: {
        return (
          <Box ref={scrollWrapperRef}>
            <Flex
              ml={isDesktop ? "30px" : "15px"}
              fontSize={isDesktop ? "50px" : "23px"}
              fontWeight="extrabold"
              color="#3E3E3E"
              mb={isDesktop ? "80px" : "40px"}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{t(`profile.${VIEW_ALL_ROUTE_NAMES[option]}`)}</Box>
            </Flex>
            {loading ? (
              <Loading />
            ) : (
              <InfiniteScroll
                pageStart={0}
                loadMore={fetchMore}
                useWindow={false}
                getScrollParent={() => scrollWrapperRef?.current}
                hasMore={items[option]?.length < itemsCount[option]}
              >
                <Flex
                  alignItems="center"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                >
                  {items[option]?.map((item) => {
                    return (
                      <ArtsListThumbnail
                        key={item.id}
                        tokenId={item?.tokenId}
                        imageSrc={item?.imageSrc}
                        title={item?.title}
                        price={item?.price}
                        username={item?.username}
                        routes={ROUTE_NAMES.market}
                        isVideo={item?.isVideo}
                      />
                    );
                  })}
                </Flex>
              </InfiniteScroll>
            )}
          </Box>
        );
      }
    }
  };

  return !isMobile ? (
    <ProfileViewAllContainer
      options={VIEW_ALL_ROUTE_NAMES}
      currentOption={option}
      page={renderPage()}
    />
  ) : (
    <Container ref={scrollWrapperRef}>
      <TopLogo />
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        marginLeft="0px"
        marginRight="0px"
        mt="49.93px"
        mb="32.5px"
      >
        {Object.keys(VIEW_ALL_ROUTE_NAMES).map((routeOption) => {
          const isCurrent = routeOption === option;

          return (
            <Flex
              key={routeOption}
              height="60px"
              borderRadius="12px"
              flexBasis="calc(50% - 12px)"
              alignItems="center"
              justifyContent="center"
              bg={isCurrent ? "#1D20FF" : "#F6F5F7"}
              marginTop="12px"
              marginBottom="12px"
              color={!isCurrent ? "#000000" : "#FFFFFF"}
              fontSize="15px"
              fontWeight="bold"
              onClick={() =>
                history.push(
                  ROUTE_NAMES.viewAll +
                    `/${routeOption}` +
                    (userAccount ? `/${userAccount}` : "")
                )
              }
            >
              {t(`profile.${VIEW_ALL_ROUTE_NAMES[routeOption]}`)}
            </Flex>
          );
        })}
      </Flex>
      {option === PROFILE_TAB_ROUTE_NAMES.OFFERED && (
        <Box
          position="relative"
          fontSize="15px"
          fontWeight="extrabold"
          w="100%"
          color="#949AB2"
          cursor="pointer"
        >
          <Flex
            alignItems="center"
            justifyContent="space-between"
            padding="12px 15px"
            borderRadius="9px"
            borderBottomRightRadius={showOfferOption ? "0px" : "9px"}
            borderBottomLeftRadius={showOfferOption ? "0px" : "9px"}
            bg="#F6F5F7"
            onClick={() => setShowOfferOption(!showOfferOption)}
          >
            <Flex alignItems="center">
              <Box mr="30px">
                <Image src={clockIcon} w="26px" h="26px" />
              </Box>
              <Box>{OFFER_STATE[currentOfferOption].name}</Box>
            </Flex>
            <Box>
              <Image src={showOfferOption ? arrowUpIcon : arrowDownIcon} />
            </Box>
          </Flex>
          {showOfferOption && (
            <Box
              position="absolute"
              left="0px"
              right="0px"
              top="100%"
              borderRadius="9px"
              borderTopRightRadius="0px"
              borderTopLeftRadius="0px"
              bg="#F6F5F7"
            >
              {Object.keys(OFFER_STATE)
                ?.filter((key) => key !== currentOfferOption)
                ?.map((state) => (
                  <Flex
                    key={state}
                    alignItems="center"
                    justifyContent="space-between"
                    padding="12px 15px"
                    paddingLeft={12 + 26 + 20 + "px"}
                    onClick={() => {
                      setCurrentOfferOption(OFFER_STATE[state].option);
                      setShowOfferOption(false);
                    }}
                  >
                    <Box>{OFFER_STATE[state].name}</Box>
                  </Flex>
                ))}
            </Box>
          )}
        </Box>
      )}
      {loading ? (
        <Loading />
      ) : option !== PROFILE_TAB_ROUTE_NAMES.OFFERED ? (
        <div>
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchMore}
            useWindow={false}
            getScrollParent={() => scrollWrapperRef?.current}
            hasMore={items[option]?.length < itemsCount[option]}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              {items[option]?.map((item) => {
                return (
                  <ArtsListThumbnail
                    key={item.id}
                    tokenId={item?.tokenId}
                    imageSrc={item?.imageSrc}
                    title={item?.title}
                    price={item?.price}
                    username={item?.username}
                    routes={ROUTE_NAMES.market}
                    viewCountDisabled={true}
                    isVideo={item?.isVideo}
                  />
                );
              })}
            </Flex>
          </InfiniteScroll>
        </div>
      ) : (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          useWindow={false}
          getScrollParent={() => scrollWrapperRef?.current}
          hasMore={
            offerMade?.length < offerMadeCount ||
            offerReceived?.length < offerReceivedCount
          }
        >
          <Box>
            {offers?.[currentOfferOption]?.map((item) => (
              <ViewAllOffer key={item?.objectId} offer={item} />
            ))}
          </Box>
        </InfiniteScroll>
      )}
    </Container>
  );
}

export default ViewAllContainer;
