import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { isFirefox } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useDispatch, useSelector } from "react-redux";

import arrow from "../../../assets/icons/price_arrow.svg";
import arrowUp from "../../../assets/icons/price_arrow_up.svg";
import { PRICE_UNIT } from "../../../constants";
import {
  setArtsPriceError,
  setArtsPriceFrom,
  setArtsPriceSelectedUnit,
  setArtsPriceTo,
  setArtsShowPriceSearch,
} from "../../../lib/redux/reducers/arts/artsReducer";
import useLanguageDetect from "../../common/hooks/useLanguageDetect";
import { useCoinPriceContext } from "../../common/providers/CoinPriceProvider";
import { preventArrowKeyFromInput } from "../../common/utils/preventArrowKeyFromInput";
import { removeLeadingZero } from "../../common/utils/removeLeadingZero";
import useResponsive from "../../common/utils/useResponsive";

const PRICE_UNIT_WIDTH = "232px";
const PRICE_UNIT_WIDTH_TABLET = "111px";
const PRICE_UNIT_HEIGHT = "45px";
const PRICE_UNIT_HEIGHT_TABLET = "40px";

const APPLY_BUTTON_WIDTH = "170px";
const APPLY_BUTTON_WIDTH_TABLET = "70px";

const ARROW_WIDTH = "20px";
const ARROW_WIDTH_TABLET = "14px";

function ArtListPriceRange({ itemChain, searchPriceRange }) {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const { t } = useTranslation();

  const PRICE_UNIT_TEXT = {
    ETH:
      itemChain === "0x3" || itemChain === "0x1"
        ? t("arts.Ethereum")
        : t("arts.Polygon"),
    USD: "Dollar (USD)",
    KRW: "원 (KRW)",
  };

  const optionRef = useRef();

  const { isKorean } = useLanguageDetect();

  const { Moralis } = useMoralis();

  const price = useCoinPriceContext();

  const units = [PRICE_UNIT.ETH, isKorean ? PRICE_UNIT.KRW : PRICE_UNIT.USD];
  const [showOptions, setShowOptions] = useState(false);
  const [optionsTop, setOptionsTop] = useState(0);

  const showPriceSearch = useSelector((state) => state.arts.showPriceSearch);
  const priceFrom = useSelector((state) => state.arts.priceFrom);
  const priceTo = useSelector((state) => state.arts.priceTo);
  const selectedUnit = useSelector((state) => state.arts.selectedUnit);
  const priceError = useSelector((state) => state.arts.priceError);

  const dispatch = useDispatch();

  const optionSelectorRef = useRef();

  useOutsideClick({
    ref: optionRef,
    handler: () => setShowOptions(false),
  });

  useEffect(() => {
    if (optionSelectorRef?.current) {
      const top = optionSelectorRef?.current?.offsetHeight;
      setOptionsTop(top);
    }
  }, [optionSelectorRef?.current]);

  const onUnitClick = (unit) => {
    dispatch(setArtsPriceSelectedUnit(unit));
    setShowOptions(false);
  };

  const onFromChange = (e) => {
    const { value } = e.target;

    if (isNaN(value) || value < 0) return;

    dispatch(setArtsPriceFrom(removeLeadingZero(value)));
  };

  const onToChange = (e) => {
    const { value } = e.target;

    if (isNaN(value) || value < 0) return;

    dispatch(setArtsPriceTo(removeLeadingZero(value)));
  };

  const onPriceModalClose = () => {
    dispatch(setArtsShowPriceSearch(false));
  };

  const onInputFocus = () => {
    if (priceError) {
      dispatch(setArtsPriceError(false));
    }
  };

  return !isMobile ? (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      mt={isDesktop ? "30px" : "20px"}
    >
      <Box
        ref={optionRef}
        position="relative"
        mr={isDesktop ? "50px" : "12px"}
        flex={isDesktop ? 0.5 : 0.45}
      >
        <Flex
          ref={optionSelectorRef}
          alignItems="center"
          justifyContent="space-between"
          bg="#F6F5F7"
          padding={isDesktop ? "10px 24px" : "10px 10px"}
          borderRadius={isDesktop ? "13px" : "8px"}
          borderBottomRightRadius={
            showOptions ? "0px" : isDesktop ? "13px" : "8px"
          }
          borderBottomLeftRadius={
            showOptions ? "0px" : isDesktop ? "13px" : "8px"
          }
          cursor="pointer"
          onClick={() => setShowOptions(!showOptions)}
        >
          <Box
            fontSize={isDesktop ? "18px" : "14px"}
            fontWeight="extrabold"
            color="#949AB2"
          >
            {PRICE_UNIT_TEXT[selectedUnit]}
          </Box>
          <Box>
            <Image
              src={showOptions ? arrowUp : arrow}
              w={isDesktop ? ARROW_WIDTH : ARROW_WIDTH_TABLET}
            />
          </Box>
        </Flex>
        {showOptions && (
          <Box
            position="absolute"
            top="100%"
            left="0px"
            right="0px"
            bg="#F6F5F7"
            padding={isDesktop ? "10px 24px" : "10px 10px"}
            borderBottomRightRadius={isDesktop ? "13px" : "8px"}
            borderBottomLeftRadius={isDesktop ? "13px" : "8px"}
            zIndex={3}
          >
            {[...units]
              .filter((item) => item !== selectedUnit)
              ?.map((item) => (
                <Box
                  key={item}
                  fontSize={isDesktop ? "18px" : "14px"}
                  fontWeight="extrabold"
                  color="#949AB2"
                  onClick={() => onUnitClick(item)}
                >
                  {PRICE_UNIT_TEXT[item]}
                </Box>
              ))}
          </Box>
        )}
      </Box>
      <Flex alignItems="center" justifyContent="space-around" flex={1}>
        <Input
          border={priceError ? "1px solid" : "none"}
          borderColor="red"
          borderRadius={isDesktop ? "13px" : "8px"}
          flex={1}
          _hover={{ outline: "none" }}
          height={isDesktop ? PRICE_UNIT_HEIGHT : PRICE_UNIT_HEIGHT_TABLET}
          bg="#F6F5F7"
          placeholder="0"
          type="number"
          fontSize={isDesktop ? "18px" : "14px"}
          fontWeight="extrabold"
          _focus={{
            outline: "none",
          }}
          _placeholder={{
            color: "#949AB2",
          }}
          mr={isDesktop ? "20px" : "8px"}
          value={priceFrom}
          onKeyDown={preventArrowKeyFromInput}
          onChange={onFromChange}
          onFocus={onInputFocus}
        />
        <Box
          fontSize={isDesktop ? "18px" : "14px"}
          fontWeight="extrabold"
          color="#949AB2"
          mr={isDesktop ? "20px" : "8px"}
        >
          {t("arts.to")}
        </Box>
        <Input
          border={priceError ? "1px solid" : "none"}
          borderColor="red"
          borderRadius={isDesktop ? "13px" : "8px"}
          flex={1}
          height={isDesktop ? PRICE_UNIT_HEIGHT : PRICE_UNIT_HEIGHT_TABLET}
          bg="#F6F5F7"
          placeholder="0"
          type="number"
          fontSize={isDesktop ? "18px" : "14px"}
          fontWeight="extrabold"
          _hover={{ outline: "none" }}
          _focus={{
            outline: "none",
          }}
          _placeholder={{
            color: "#949AB2",
          }}
          mr={isDesktop ? "20px" : "8px"}
          value={priceTo}
          onKeyDown={preventArrowKeyFromInput}
          onChange={onToChange}
          onFocus={onInputFocus}
        />
      </Flex>
      <Button
        width={isDesktop ? APPLY_BUTTON_WIDTH : APPLY_BUTTON_WIDTH_TABLET}
        height={isDesktop ? PRICE_UNIT_HEIGHT : PRICE_UNIT_HEIGHT_TABLET}
        padding="0px"
        fontSize={isDesktop ? "18px" : "14px"}
        fontWeight="extrabold"
        color="white"
        bg="#1D20FF"
        borderRadius={isDesktop ? "13px" : "8px"}
        textAlign="center"
        _hover={{ outline: "none" }}
        _active={{ outline: "none", border: "none" }}
        _focus={{ outline: "none", border: "none" }}
        onClick={searchPriceRange}
      >
        {t("arts.apply")}
      </Button>
    </Flex>
  ) : (
    <Modal
      isOpen={showPriceSearch}
      onClose={onPriceModalClose}
      size="full"
      blockScrollOnMount={true}
    >
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
        maxH="100vh"
      >
        <ModalHeader>
          <ModalCloseButton
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ outline: "none" }}
            top="50px"
            right="30px"
            size="22px"
          />
        </ModalHeader>
        <ModalBody paddingTop="100px">
          <Box
            ref={optionRef}
            position="relative"
            boxShadow="0px 10px 20px #00000029"
            borderRadius="13px"
            mb="35px"
          >
            <Flex
              ref={optionSelectorRef}
              alignItems="center"
              justifyContent="space-between"
              bg="white"
              padding="14px 24px"
              borderRadius="13px"
              borderBottomRightRadius={showOptions ? "0px" : "13px"}
              borderBottomLeftRadius={showOptions ? "0px" : "13px"}
              cursor="pointer"
              onClick={() => setShowOptions(!showOptions)}
            >
              <Box fontSize="18px" fontWeight="extrabold" color="#949AB2">
                {PRICE_UNIT_TEXT[selectedUnit]}
              </Box>
              <Box>
                <Image src={showOptions ? arrowUp : arrow} w="20px" />
              </Box>
            </Flex>
            {showOptions && (
              <Box
                position="absolute"
                top="99%"
                left="0px"
                right="0px"
                bg="white"
                padding="14px 24px"
                borderRadius="13px"
                borderTopRightRadius="0px"
                borderTopLeftRadius="0px"
                boxShadow="0px 10px 20px #00000029"
                zIndex="100"
              >
                {[...units]
                  .filter((item) => item !== selectedUnit)
                  ?.map((item) => (
                    <Box
                      key={item}
                      fontSize="18px"
                      fontWeight="extrabold"
                      color="#949AB2"
                      onClick={() => onUnitClick(item)}
                    >
                      {PRICE_UNIT_TEXT[item]}
                    </Box>
                  ))}
              </Box>
            )}
          </Box>
          <Box
            padding="8px 21px"
            height="50px"
            bg="white"
            boxShadow="0px 10px 20px #00000029"
            borderRadius="13px"
            mb="15px"
            border={priceError ? "1px solid" : "none"}
            borderColor="red"
          >
            <Input
              border="none"
              placeholder="0"
              padding="0px"
              type="number"
              fontSize="18px"
              fontWeight="extrabold"
              _focus={{
                border: "none",
              }}
              _placeholder={{
                color: "#949AB2",
              }}
              value={priceFrom}
              onKeyDown={preventArrowKeyFromInput}
              onChange={onFromChange}
              onFocus={onInputFocus}
            />
          </Box>
          <Box
            fontSize="18px"
            fontWeight="extrabold"
            color="#949AB2"
            textAlign="center"
            mb="15px"
          >
            {t("arts.to")}
          </Box>
          <Box
            padding="8px 21px"
            height="50px"
            bg="white"
            boxShadow="0px 10px 20px #00000029"
            borderRadius="13px"
            mb="60px"
            border={priceError ? "1px solid" : "none"}
            borderColor="red"
          >
            <Input
              border="none"
              placeholder="0"
              padding="0px"
              type="number"
              fontSize="18px"
              fontWeight="extrabold"
              _focus={{
                border: "none",
              }}
              _placeholder={{
                color: "#949AB2",
              }}
              value={priceTo}
              onKeyDown={preventArrowKeyFromInput}
              onChange={onToChange}
              onFocus={onInputFocus}
            />
          </Box>
          <Button
            width="100%"
            height="45px"
            padding="0px"
            fontSize="15px"
            fontWeight="extrabold"
            color="white"
            bg="#1D20FF"
            borderRadius="13px"
            textAlign="center"
            _hover={{ outline: "none" }}
            _active={{ outline: "none", border: "none" }}
            _focus={{ outline: "none", border: "none" }}
            onClick={searchPriceRange}
            boxShadow="0px 10px 20px #1D20FF29"
          >
            {t("arts.apply")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ArtListPriceRange;
