import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Flex } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../../common/utils/useResponsive";

function UpdateProfileBasicInfo({ profile, onValueChange, setProfile }) {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { username, description, usernameError } = profile;

  const onFocusUsername = () => {
    setProfile((prev) => ({
      ...prev,
      usernameError: false,
    }));
  };

  return (
    <Flex
      flex={isDesktop ? 3.5 : 3}
      flexDir="column"
      bg="#f6f5f7"
      borderRadius="19px"
      paddingTop={isMobile ? "25px" : "50px"}
      paddingBottom={isMobile ? "25px" : "50px"}
      paddingLeft={isMobile ? "20px" : "0px"}
      paddingRight={isMobile ? "20px" : "0px"}
      marginLeft={isDesktop ? "50px" : isTablet ? "25px" : "0px"}
      alignItems="center"
      width="100%"
    >
      <FormControl w={isMobile ? "90%" : "80%"} marginBottom="10">
        <FormLabel fontSize={isMobile ? "17px" : "20px"} fontWeight="bold">
          {t("updateProfile.username")}
        </FormLabel>
        <Input
          backgroundColor="white"
          borderRadius="8px"
          borderWidth="0px"
          padding="16px 25px"
          fontSize={isMobile ? "15px" : "17px"}
          fontWeight="bold"
          _focus={{
            outline: "none",
          }}
          value={username}
          isInvalid={usernameError}
          errorBorderColor="Red"
          placeholder={t("updateProfile.username")}
          onChange={(e) => onValueChange(e, "username")}
          onFocus={onFocusUsername}
        />
      </FormControl>
      <FormControl w={isMobile ? "90%" : "80%"}>
        <FormLabel fontSize={isMobile ? "17px" : "20px"} fontWeight="bold">
          {t("updateProfile.description")}
        </FormLabel>
        <Textarea
          backgroundColor="white"
          borderRadius="8px"
          borderWidth="0px"
          padding="16px 25px"
          fontSize={isMobile ? "14px" : "15px"}
          resize="none"
          height={isMobile ? "212px" : "105px"}
          overflowY="auto"
          _focus={{
            outline: "none",
          }}
          value={description}
          maxLength={210}
          placeholder={t("updateProfile.description")}
          onChange={(e) => onValueChange(e, "description")}
        />
      </FormControl>
    </Flex>
  );
}

export default UpdateProfileBasicInfo;
