import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react";
import React from "react";
import { isFirefox } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setArtsIsSearching,
  setArtsItems,
  setArtsSearchText,
} from "../../../lib/redux/reducers/arts/artsReducer";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import { CATEGORIES } from "../../common/utils/categories";

function ArtsMobileCategory({ isOpen, onClose }) {
  const history = useHistory();
  const { t } = useTranslation();

  const isSearching = useSelector((state) => state.arts.isSearching);
  const dispatch = useDispatch();

  const onCategoryClick = (category) => {
    history.push(ROUTE_NAMES.arts, {
      category,
    });
    if (isSearching) {
      dispatch(setArtsItems(null));
      dispatch(setArtsIsSearching(false));
      dispatch(setArtsSearchText(""));
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
      >
        <ModalHeader>
          <ModalCloseButton
            top="40px"
            right="40px"
            _hover={{ backgroundColor: "transparent" }}
            _focus={{ outline: "none" }}
          />
        </ModalHeader>
        <ModalBody marginTop="20px">
          <Flex direction="column" width="100%">
            <Box
              fontSize="20px"
              fontWeight="extrabold"
              color="#949AB2"
              mb="40px"
              onClick={() => onCategoryClick("ALL")}
            >
              {t("arts.arts")}
            </Box>
          </Flex>
          {Object.keys(CATEGORIES).map((key) => (
            <Flex
              alignItems="center"
              key={CATEGORIES[key].name}
              bg="white"
              borderRadius="7px"
              padding="9px 33px"
              mb="20px"
              onClick={() => onCategoryClick(key)}
            >
              <Box mr="23px">
                <Image src={CATEGORIES[key].icon} />
              </Box>
              <Box fontSize="15px" fontWeight="extrabold" color="#949AB2">
                {t(`arts.${CATEGORIES[key].name}`)}
              </Box>
            </Flex>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ArtsMobileCategory;
