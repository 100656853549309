import { Box, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import ProfileTrading from "../../profile/profile/components/ProfileTrading";
import { CONTRACT_ADDRESS_MAP } from "../../../constants";
import Loading from "../../common/components/Loading";
import { useParams } from "react-router-dom";
import ArtDetailTradingHistoryOtherMarket from "./ArtDetailTradingHistoryOtherMarket";

function ArtDetailTradingHistory({ item, _tradingHistory }) {
  const { Moralis } = useMoralis();
  const { contractAddress } = useParams();

  const [tradingHistory, setTradingHistory] = useState(_tradingHistory);

  return (
    <Box overflow="hidden" width="100%">
      <Box paddingLeft="20px" fontSize="20px" fontWeight="bold" mb="11px">
        Trading History
      </Box>
      {!contractAddress
        ? tradingHistory?.map((transfer, index) => (
            <ProfileTrading key={index} transfer={transfer} />
          ))
        : tradingHistory?.map((transfer, index) => (
            <ArtDetailTradingHistoryOtherMarket
              key={index}
              transfer={transfer}
              item={item}
            />
          ))}
    </Box>
  );
}

export default ArtDetailTradingHistory;
