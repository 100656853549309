import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import loadingSvg from "../../../assets/icons/loading.svg";
import loadingGif from "../../../assets/icons/loading.gif";
import useCoinPrice from "../../common/hooks/useCoinPrice";
import { useMoralis } from "react-moralis";
import { isFirefox } from "react-device-detect";
import { ENV, NETWORKS } from "../../../constants";
import useResponsive from "../../common/utils/useResponsive";
import { useTranslation } from "react-i18next";

function CreateConfirmModal({
  isOpen,
  onClose,
  price,
  loading,
  onConfirm,
  editDisabled,
}) {
  const { isMobile } = useResponsive();
  const { chainId } = useMoralis();
  const { t } = useTranslation();

  const coinPrice = useCoinPrice(price, chainId);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      isCentered={!isMobile}
      size={isMobile ? "full" : "xl"}
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      scrollBehavior="inside"
    >
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
      >
        <ModalHeader />
        {!loading && (
          <ModalCloseButton
            _focus={{ outline: "none" }}
            _hover={{ backgroundColor: "transparent" }}
          />
        )}
        <ModalBody
          paddingLeft={!isMobile ? "90px" : "30px"}
          paddingRight={!isMobile ? "90px" : "30px"}
          paddingTop={isMobile ? "90px" : "0px"}
          paddingBottom="95px"
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="0px"
          >
            <Box
              fontSize="24px"
              fontWeight="bold"
              color="#3E3E3E"
              mb={isMobile ? "0px" : "15px"}
            >
              {editDisabled ? t("createModal.update") : t("createModal.create")}
            </Box>
            <Box position="relative" mb="32px">
              <img src={loadingSvg} />
              {loading && (
                <img
                  src={loadingGif}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -80%)",
                  }}
                />
              )}
            </Box>
            <Box textAlign="left" w="100%">
              <Box fontSize="13px" fontWeight="bold" mb="13px">
                {t("createModal.price")}
              </Box>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                padding={!isMobile ? "17px 25px" : "10px 25px"}
                bg="white"
                borderRadius="12px"
                width="100%"
                boxShadow="0px 20px 20px #00000029"
                boxSizing="border-box"
                marginBottom="40px"
              >
                <Flex alignItems="center">
                  <Box
                    fontSize="20px"
                    fontWeight="bold"
                    color="#3E3E3E"
                    mr="10px"
                  >
                    {price}
                  </Box>
                  <Box fontSize="20px" color="#E8E8E8" fontWeight="bold">
                    {NETWORKS[ENV]?.[chainId]?.unit}
                  </Box>
                </Flex>
                <Box fontSize="15px" color="#3E3E3E" fontWeight="bold">
                  {coinPrice}
                </Box>
              </Flex>
            </Box>
            <Box textAlign="left" w="100%">
              <Box fontSize="13px" fontWeight="bold" mb="13px">
                {t("createModal.confirmMessage")}
              </Box>
              <Button
                width="100%"
                bg="#1D20FF 0% 0% no-repeat padding-box"
                color="white"
                borderRadius="12px"
                padding="22px 0px"
                fontSize="12px"
                fontWeight="bold"
                boxShadow="0px 20px 20px #00000029"
                mb="30px"
                _hover={{
                  backgroundColor: "#1d20ff",
                }}
                _focus={{
                  outline: "none",
                }}
                onClick={onConfirm}
                disabled={loading}
              >
                {t("createModal.yes")}
              </Button>
              <Button
                width="100%"
                bg="white 0% 0% no-repeat padding-box"
                color="#3E3E3E"
                borderRadius="12px"
                padding="22px 0px"
                fontSize="12px"
                fontWeight="bold"
                boxShadow="0px 20px 20px #00000029"
                _hover={{
                  backgroundColor: "white",
                }}
                _focus={{
                  outline: "none",
                }}
                onClick={onClose}
                disabled={loading}
              >
                {t("createModal.no")}
              </Button>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateConfirmModal;
