import { MINTING_ADDRESS, TRADING_EVENTS } from "../../../constants";

export function getTradingEvent(from, to, userAddress) {
  let event;

  if (from === MINTING_ADDRESS) {
    event = TRADING_EVENTS.MINT;
  } else if (to === MINTING_ADDRESS) {
    event = TRADING_EVENTS.BURN;
  } else if (from === userAddress) {
    event = TRADING_EVENTS.SALE;
  } else {
    event = TRADING_EVENTS.BUY;
  }

  return event;
}

export function getTradingPrice(event, value) {
  if (event === TRADING_EVENTS.MINT) {
    return null;
  } else {
    return value;
  }
}
