import { createSlice } from "@reduxjs/toolkit";

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    intro: null,
    newest: null,
  },
  reducers: {
    setIntro: (state, action) => {
      state.intro = action.payload;
    },
    setNewest: (state, action) => {
      state.newest = action.payload;
    },
  },
});

export const { setNewest, setIntro } = homeSlice.actions;

export default homeSlice.reducer;
