import { removePrefixFromChainId } from "./removePrefixFromChainId";

const DOMAIN_TYPE = [
  {
    type: "string",
    name: "name",
  },
  {
    type: "string",
    name: "version",
  },
  {
    type: "uint256",
    name: "chainId",
  },
  {
    type: "address",
    name: "verifyingContract",
  },
];

const Types = {
  Offer: [
    { name: "tokenId", type: "uint256" },
    { name: "tokenURI", type: "string" },
    { name: "buyerAddress", type: "address" },
    { name: "sellerAddress", type: "address" },
    { name: "offerPrice", type: "uint256" },
    { name: "totalPrice", type: "uint256" },
    { name: "expiration", type: "string" },
    { name: "tokenAddress", type: "address" },
  ],
};

export function createTypeData(domainData, primaryType, message, types) {
  return {
    types: Object.assign(
      {
        EIP712Domain: DOMAIN_TYPE,
      },
      types
    ),
    domain: domainData,
    primaryType: primaryType,
    message: message,
  };
}

export function signTypedData(web3, from, data) {
  return new Promise(async (resolve, reject) => {
    function cb(err, result) {
      if (err) {
        return reject(err);
      }
      if (result.error) {
        return reject(result.error);
      }

      const sig = result.result;
      const sig0 = sig.substring(2);
      const r = "0x" + sig0.substring(0, 64);
      const s = "0x" + sig0.substring(64, 128);
      const v = parseInt(sig0.substring(128, 130), 16);

      resolve({
        data,
        sig,
        v,
        r,
        s,
      });
    }
    if (web3.provider.isMetaMask) {
      web3.provider.sendAsync(
        {
          jsonrpc: "2.0",
          method: "eth_signTypedData_v3",
          params: [from, JSON.stringify(data)],
          id: new Date().getTime(),
        },
        cb
      );
    } else if (web3.provider.isPortis) {
      web3.provider.sendAsync(
        {
          jsonrpc: "2.0",
          method: "eth_signTypedData_v3",
          params: [from, JSON.stringify(data)],
          id: new Date().getTime(),
        },
        cb
      );
    } else {
      let send = web3.provider.sendAsync;
      if (!send) send = web3.provider.send;
      send.bind(web3.provider)(
        {
          jsonrpc: "2.0",
          method: "eth_signTypedData",
          params: [from, data],
          id: new Date().getTime(),
        },
        cb
      );
    }
  });
}

export async function signOffer(
  web3,
  _chainId,
  account,
  {
    tokenId,
    tokenURI,
    buyerAddress,
    sellerAddress,
    offerPrice,
    totalPrice,
    expiration,
    tokenAddress,
  },
  verifyingContract
) {
  const chainId = Number(removePrefixFromChainId(_chainId));
  const data = createTypeData(
    {
      name: "Offer",
      chainId,
      version: "1",
      verifyingContract,
    },
    "Offer",
    {
      tokenId,
      tokenURI,
      buyerAddress,
      sellerAddress,
      offerPrice,
      totalPrice,
      expiration,
      tokenAddress,
    },
    Types
  );
  return (await signTypedData(web3, account, data)).sig;
}
