const ROUTE_NAMES = {
  home: "/",
  arts: "/arts",
  tutorial: "/tutorial",
  updateProfile: "/update_profile",
  profile: "/profile",
  create: "/create",
  art: "/art",
  market: "/market",
  tradingHistory: "/trading_history",
  viewAll: "/view_all",
  spirit: "/spirit",
  terms: "/terms",
  privacy: "/privacy",
  item: "/item",
};

export default ROUTE_NAMES;
