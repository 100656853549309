import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/modal";
import React from "react";

import { Box, VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { AUTH_OPTIONS, MOBILE_AUTH_OPTIONS } from "../utils/authConstants";
import useAuthModal from "./AuthModal.hooks";
import {
  isMobile as isMobileDevice,
  isDesktop,
  deviceDetect,
  isFirefox,
} from "react-device-detect";
import useResponsive from "../../common/utils/useResponsive";

const ICON_WIDTH = "28px";

/**
 * Auth modal to authenticate
 * auth options
 * 1. metamask
 * 2. coinbase
 * 3. walletconnect
 * 4. fortmatic
 */
function AuthModal({ isOpen, onClose }) {
  const { operations } = useAuthModal();
  const { isMobile } = useResponsive();

  const { signIn } = operations;

  return (
    <Modal
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={true}
    >
      <ModalContent
        borderRadius={17}
        py="4"
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
        marginLeft={isMobile ? "20px" : "0px"}
        marginRight={isMobile ? "20px" : "0px"}
      >
        <ModalHeader>
          <ModalCloseButton
            top="10"
            right="10"
            _focus={{ outline: "none" }}
            _hover={{ backgroundColor: "transparent" }}
          />
        </ModalHeader>
        <ModalBody px="6" py="6" textAlign="center">
          <Box marginBottom={8} fontSize="17px" fontWeight="bold">
            Connect
          </Box>
          <VStack spacing={4}>
            {isDesktop
              ? Object.keys(AUTH_OPTIONS).map((option) => (
                  <Button
                    key={AUTH_OPTIONS[option].name}
                    justifyContent="flex-start"
                    px="8"
                    py="6"
                    width="100%"
                    backgroundColor="white"
                    fontSize="12px"
                    borderRadius="12px"
                    boxShadow="0px 20px 20px #00000029"
                    _hover={{ backgroundColor: "white" }}
                    _active={{ outline: "none" }}
                    _focus={{ outline: "none", backgroundColor: "white" }}
                    onClick={() => signIn(AUTH_OPTIONS[option].value)}
                  >
                    <Image
                      src={AUTH_OPTIONS[option].icon}
                      w={ICON_WIDTH}
                      h={ICON_WIDTH}
                      marginRight="8"
                    />
                    <div>{AUTH_OPTIONS[option].name}</div>
                  </Button>
                ))
              : Object.keys(MOBILE_AUTH_OPTIONS).map((option) => (
                  <Button
                    key={MOBILE_AUTH_OPTIONS[option].name}
                    justifyContent="flex-start"
                    px="8"
                    py="6"
                    width="100%"
                    backgroundColor="white"
                    fontSize="12px"
                    borderRadius="12px"
                    boxShadow="0px 20px 20px #00000029"
                    _hover={{ backgroundColor: "white" }}
                    _active={{ outline: "none" }}
                    _focus={{ outline: "none", backgroundColor: "white" }}
                    onClick={() => signIn(MOBILE_AUTH_OPTIONS[option].value)}
                  >
                    <Image
                      src={MOBILE_AUTH_OPTIONS[option].icon}
                      w={ICON_WIDTH}
                      h={ICON_WIDTH}
                      marginRight="8"
                    />
                    <div>{MOBILE_AUTH_OPTIONS[option].name}</div>
                  </Button>
                ))}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default AuthModal;
