import { Box, Container, Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
// Import Worker
import { SpecialZoomLevel, Worker } from "@react-pdf-viewer/core";
import { useLocation } from "react-router-dom";

// Import the main Viewer component
import { Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function TermsContainer() {
  const { Moralis } = useMoralis();

  const location = useLocation();

  const { pathname } = location;

  const TYPE_MAP = {
    "/terms": {
      type: "TERMS",
      title: "Terms",
    },
    "/privacy": {
      type: "PRIVACY",
      title: "Privacy",
    },
  };

  const [terms, setTerms] = useState();

  const fetchTerm = async () => {
    const query = new Moralis.Query("Policy");
    query.equalTo("type", TYPE_MAP[pathname].type);
    const result = await query.first();

    const file = result?.get("file")?._url;
    setTerms(file);
  };

  useEffect(() => {
    fetchTerm();

    return () => {
      setTerms(null);
    };
  }, []);

  return (
    <Container maxW="container.lg">
      <Box
        borderRadius="19px"
        margin="0px auto"
        bg="#F6F5F7"
        padding="3em"
        width="90%"
        h="80vh"
      >
        <Box fontSize="20px" fontWeight="bold" mb="15px">
          {TYPE_MAP[pathname]?.title}
        </Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          overflow="auto"
          paddingBottom="40px"
          height="100%"
          w="100%"
          position="relative"
          borderRadius="8px"
        >
          {terms && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
              <Viewer
                fileUrl={terms}
                defaultScale={SpecialZoomLevel.PageWidth}
              ></Viewer>
            </Worker>
          )}
        </Flex>
      </Box>
    </Container>
  );
}

export default TermsContainer;
