import { useMoralis } from "react-moralis";
import Portis from "@portis/web3";
import detectEthereumProvider from "@metamask/detect-provider";

import { AUTH_OPTIONS } from "../utils/authConstants";
import {
  DEFAULT_CHAIN,
  ENV,
  PORTIS_APP_ID,
  WEB3AUTH_CLIENT_ID,
} from "../../../constants";
import PortisWeb3Connector from "../providers/PortisWeb3Connector";
import Web3 from "web3";
import { getMoralisRpcs } from "../../common/utils/getMoralisRpcs";

function useAuthModal() {
  const {
    isWeb3Enabled,
    authenticate,
    isAuthenticated,
    isAuthenticating,
    logout,
    Moralis,
    user,
    enableWeb3,
  } = useMoralis();

  /**
   * Sign in with wallet
   * options
   * 1. metamask
   * 2. wallet connect
   * @param {string}} walletName
   */
  const signIn = async (walletName) => {
    // if user is already authenticated
    // sign out
    if (isAuthenticated) {
      await logout();
    }

    if (walletName === AUTH_OPTIONS.METAMASK.value) {
      const providerDetected = await detectEthereumProvider();

      if (providerDetected) {
        await authenticate({
          signingMessage: "MENNIN: Make it Visual",
        });
        window.localStorage.setItem(
          "provider",
          JSON.stringify({ provider: "injected" })
        );
      } else {
        window.open(
          "https://metamask.app.link/dapp/mennin-dev.web.app",
          "_blank"
        );
      }
    } else if (walletName === AUTH_OPTIONS.WALLET_CONNECT.value) {
      await authenticate({
        provider: "walletconnect",
        mobileLinks: ["metamask"],
      });
      window.localStorage.setItem(
        "provider",
        JSON.stringify({ provider: "walletconnect", mobileLinks: ["metamask"] })
      );
    } else {
      const portisConnector = PortisWeb3Connector;
      const user = await authenticate({
        connector: portisConnector,
        signingMessage: "MENNIN: Make it Visual",
      });

      window.localStorage.setItem(
        "provider",
        JSON.stringify({ provider: "portis" })
      );

      console.log(user, isAuthenticated, isAuthenticating);
    }
  };

  return {
    operations: {
      signIn,
    },
  };
}

export default useAuthModal;
