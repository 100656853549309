import { Box, Flex } from "@chakra-ui/layout";
import { Textarea } from "@chakra-ui/textarea";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../common/utils/useResponsive";

function CreateDescription({ description, onItemChange, editDisabled }) {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useResponsive();

  return (
    <Box mt={isDesktop ? "0px" : "30px"}>
      <Box
        paddingLeft="20px"
        fontSize={!isTablet ? "20px" : "17px"}
        fontWeight="bold"
        mb="11px"
      >
        {t("create.description")}
      </Box>
      <Flex
        bg="#F6F5F7 0% 0% no-repeat padding-box"
        borderRadius="19px"
        w="100%"
        h={isDesktop ? "180px" : "159px"}
        alignItems="center"
        justifyContent="center"
      >
        <Textarea
          disabled={editDisabled}
          w="80%"
          margin="0px auto"
          resize="none"
          outline="none"
          border="none"
          padding="0px"
          h={isDesktop ? "113px" : "87px"}
          fontSize={isDesktop ? "20px" : "15px"}
          _focus={{ border: "none" }}
          value={description}
          _disabled={{
            color: "black",
          }}
          placeholder="for creative minds"
          onChange={(e) => onItemChange(e, "description")}
        />
      </Flex>
    </Box>
  );
}

export default CreateDescription;
