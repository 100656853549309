import { Box, Container, Flex, Textarea } from "@chakra-ui/react";
import React, { useState } from "react";
import HomeFooter from "./components/HomeFooter";
import HomeIntro from "./components/HomeIntro";
import HomeNewest from "./components/HomeNewest";

import useResponsive from "../common/utils/useResponsive";
import Loading from "../common/components/Loading";

function HomeContainer() {
  const { isMobile, isDesktop, isTablet } = useResponsive();

  const [introLoading, setIntroLoading] = useState(false);
  const [newestLoading, setNewestLoading] = useState(false);

  const loading = introLoading || newestLoading;

  return (
    <Container
      maxW={!isMobile ? "unset" : "container.xl"}
      paddingLeft={isDesktop ? "160px" : isTablet ? "60px" : "20px"}
      paddingRight={isDesktop ? "160px" : isTablet ? "60px" : "20px"}
    >
      {loading && (
        <Flex w="100%" h="80vh" alignItems="center" justifyContent="center">
          <Loading />
        </Flex>
      )}
      {!loading && (
        <>
          <HomeIntro setIntroLoading={setIntroLoading} />
          <HomeNewest setNewestLoading={setNewestLoading} />
        </>
      )}
      <HomeFooter />
    </Container>
  );
}

export default HomeContainer;
