import { Box, Container, Flex, Image } from "@chakra-ui/react";
import React from "react";
import useResponsive from "../common/utils/useResponsive";

import spirit1 from "../../assets/spirit/spirit_1.png";
import spirit2 from "../../assets/spirit/spirit_2.png";
import spirit3 from "../../assets/spirit/spirit_3.png";
import spirit4 from "../../assets/spirit/spirit_4.png";
import spirit5 from "../../assets/spirit/spirit_5.png";
import spirit6 from "../../assets/spirit/spirit_6.png";
import TopLogo from "../auth/components/TopLogo";
import { MOBILE_NAVIGATION_HEIGHT } from "../../utilities/constants";

const IDENTITY_ROW = [
  {
    image: spirit2,
    title: "Refined",
    description:
      "“With impurities or unwanted elements having been removed by processing.” By setting this word as one of MENNIN’s brand identity, MENNIN wants to make it as clean and neat more than others.<br /><br />To contain classic modern orientalism look, we decided to create serif fonts for our “refined” line.",
  },
  {
    image: spirit3,
    title: "Untied",
    description:
      "“not fastened or knotted”. MENNIN untied is for people whos bored with classic, typical types of thing. MENNIN untied line claims about how people are tied up to social binds and suffered from it.<br /><br />​“A departure from reality” could be one of our mission. MENNIN untieds are not stricted to formal and clean environments. We aimed for wild, youthful and outgoing mood.",
  },
];

const LAST_ROW = [
  {
    image: spirit4,
    title: "Visualized",
    description:
      "Sometimes, words are not enough. Make it visual, express in arts. MENNIN’s visual defines uniqueness and proprietary elements brought to life.",
  },
  {
    image: spirit5,
    title: "With a Twist",
    description:
      "With the harmony of modernism and classicism offer us an unexpected charm. MENNIN is founded on respecting the diversity of tastes according to each individual’s change of flavor, its embracement.",
  },
  {
    image: spirit6,
    title: "Dedicated",
    description:
      "MENNIN is a new culture. To preserved and developed with it, we face the challenge, we make it through, but we never satisfy.",
  },
];

function SpiritContainer() {
  const { isMobile, isDesktop, isTablet } = useResponsive();

  return (
    <>
      {isMobile && <TopLogo />}
      <Container
        maxW="container.xl"
        paddingTop="40px"
        paddingBottom={!isMobile ? "300px" : `${MOBILE_NAVIGATION_HEIGHT}px`}
      >
        <Box
          fontSize={isDesktop ? "25px" : isTablet ? "15px" : "20px"}
          fontWeight="bold"
          mb={isDesktop ? "39px" : "20px"}
        >
          Make it Visual.
        </Box>
        <Box
          bg="#F6F5F7"
          borderRadius="19px"
          padding={
            isDesktop ? "37px 172px" : isTablet ? "42px 91px" : "50px 20px"
          }
          fontSize={isDesktop ? "20px" : isTablet ? "13px" : "14px"}
          mb={isDesktop ? "70px" : isTablet ? "50px" : "40px"}
          fontWeight="light"
          lineHeight={isDesktop ? "35px" : "26px"}
        >
          <Box fontWeight="normal" marginBottom="0px">
            MENNIN is an open space for creative minds: a playground for you
            without condition.
          </Box>
          To define uniquity and proprietary elements brought to life. MENNIN
          honors freedom, creativity, generosity, and tolerance.
          <br />
          We provide shelter for whoever needs composure. Our ultimate mission
          is to bring the expansion of taste itself.
          <br />
          We discuss the expansion of taste itself, not only limited to trends.
        </Box>
        <Box w="100%" mb={isDesktop ? "65px" : isTablet ? "50px" : "40px"}>
          <Image src={spirit1} w="100%" objectFit="contain" />
        </Box>
        <Box
          fontSize={isDesktop ? "25px" : isTablet ? "15px" : "20px"}
          fontWeight="bold"
          mb={isDesktop ? "39px" : "20px"}
        >
          Identity.
        </Box>
        <Box
          bg="#F6F5F7"
          borderRadius="19px"
          padding={!isMobile ? "57px 0px" : "53px 0px"}
          fontSize={isDesktop ? "20px" : "13px"}
          mb={isDesktop ? "70px" : isTablet ? "50px" : "40px"}
        >
          <Box
            w={!isMobile ? "80%" : "90%"}
            margin="0px auto"
            lineHeight={isDesktop ? "35px" : "26px"}
          >
            MENNIN believes that “tastes” are not limited to just one or so.
            <br />
            There can be some reasons to alternate their tastes. We split MENNIN
            into two big identities.
          </Box>
        </Box>
        <Flex
          direction={isMobile ? "column" : "row"}
          alignItems="stretch"
          justifyContent="space-between"
          mb={isDesktop ? "70px" : isTablet ? "50px" : "40px"}
        >
          {IDENTITY_ROW.map((item, index) => (
            <Box
              key={item.title}
              flex="1"
              marginRight={
                index !== IDENTITY_ROW.length - 1
                  ? isDesktop
                    ? "75px"
                    : isTablet
                    ? "28px"
                    : "0px"
                  : "0px"
              }
              marginBottom={
                isMobile && index !== IDENTITY_ROW.length - 1 ? "40px" : "0px"
              }
              bg="#F6F5F7"
              borderRadius="19px"
              padding={!isMobile ? "50px" : "20px"}
            >
              <Image
                src={item.image}
                w="100%"
                objectFit="contain"
                mb={isDesktop ? "65px" : "30px"}
              />
              <Box
                fontSize={isDesktop ? "25px" : isTablet ? "13px" : "15px"}
                fontWeight="bold"
                mb={isDesktop ? "15px" : isTablet ? "10px" : "15px"}
              >
                {item.title}
              </Box>
              <Box
                dangerouslySetInnerHTML={{ __html: item.description }}
                fontSize={isDesktop ? "20px" : "10px"}
                fontWeight="light"
              ></Box>
            </Box>
          ))}
        </Flex>
        <Flex
          direction={isMobile ? "column" : "row"}
          alignItems="stretch"
          justifyContent="space-between"
        >
          {LAST_ROW.map((item, index) => (
            <Box
              key={item.title}
              flex="1"
              marginRight={
                index !== LAST_ROW.length - 1
                  ? isDesktop
                    ? "75px"
                    : isTablet
                    ? "28px"
                    : "0px"
                  : "0px"
              }
              mb={index !== LAST_ROW.length - 1 ? "40px" : "0px"}
            >
              <Image
                src={item.image}
                w="100%"
                objectFit="contain"
                mb={isDesktop ? "50px" : "30px"}
              />
              <Box
                paddingLeft="20px"
                fontSize={isDesktop ? "25px" : "12px"}
                fontWeight="bold"
                mb={isDesktop ? "19px" : "10px"}
              >
                {item.title}
              </Box>
              {isMobile && (
                <Flex
                  key={item.description}
                  borderRadius="19px"
                  w="100%"
                  padding="32px 40px"
                  bg="#F6F5F7"
                  fontSize="11px"
                  fontWeight="light"
                  alignItems="center"
                  justifyContent="center"
                >
                  {item.description}
                </Flex>
              )}
            </Box>
          ))}
        </Flex>
        {!isMobile && (
          <Flex alignItems="stretch" justifyContent="space-between">
            {LAST_ROW.map((item, index) => (
              <Flex
                key={item.description}
                borderRadius="19px"
                w="100%"
                padding={isDesktop ? "58px" : "34px"}
                bg="#F6F5F7"
                fontSize={isDesktop ? "17px" : "10px"}
                fontWeight="light"
                flex="1"
                marginRight={
                  index !== LAST_ROW.length - 1
                    ? isDesktop
                      ? "70px"
                      : "15px"
                    : "0px"
                }
                alignItems="center"
                justifyContent="center"
              >
                {item.description}
              </Flex>
            ))}
          </Flex>
        )}
      </Container>
    </>
  );
}

export default SpiritContainer;
