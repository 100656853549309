import { Box, Container, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useChain, useMoralis, useMoralisWeb3Api } from "react-moralis";
import { useParams } from "react-router-dom";
import ArtDetailDescription from "./components/ArtDetailDescription";
import ArtDetailDetail from "./components/ArtDetailDetail";
import ArtDetailFile from "./components/ArtDetailFile";
import ArtDetailPrice from "./components/ArtDetailPrice";
import ArtDetailTitle from "./components/ArtDetailTitle";
import ArtDetailButtons from "./components/ArtDetailButtons";
import ArtDetailBuyNowModal from "./components/ArtDetailBuyNowModal";
import ArtDetailOfferModal from "./components/ArtDetailOfferModal";

import ArtDetailTradingHistory from "./components/ArtDetailTradingHistory";
import useResponsive from "../common/utils/useResponsive";
import TopLogo from "../auth/components/TopLogo";
import AuthModal from "../auth/components/AuthModal";
import Loading from "../common/components/Loading";
import { useSelector } from "react-redux";
import { getMoralisRpcs } from "../common/utils/getMoralisRpcs";
import { fromHexToDecimal } from "../auth/utils/convert";
import { CONTRACT_ADDRESS_MAP } from "../../constants";

const QUERY_MAP = {
  "0x3": "getItemTradingHistory",
  "0x1": "getItemTradingHistory",
  "0x13881": "getItemTradingHistoryPolygon",
  "0x89": "getItemTradingHistoryPolygon",
};

function ArtDetailContainer() {
  const { tokenId, contractAddress } = useParams();

  const { Moralis, web3, user, isAuthenticated, chainId } = useMoralis();
  const { token } = useMoralisWeb3Api();

  const { switchNetwork } = useChain();

  const { isMobile, isDesktop, isTablet } = useResponsive();

  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState(null);

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showBuyNowModal, setShowBuyNowModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);
  const [tradingHistory, setTradingHistory] = useState(null);

  const itemChain = useSelector((state) => state.arts.itemChain);

  const fetchItem = async () => {
    setLoading(true);
    if (!contractAddress) {
      try {
        const result = await Moralis.Cloud.run("getEthMarketItem", {
          tokenId,
          currentUserAccount: user?.get("accounts")[0],
        });
        const fetchResult = await Moralis.Cloud.run(
          QUERY_MAP[result?.chainId],
          {
            tokenId,
            tokenAddress: CONTRACT_ADDRESS_MAP[result?.chainId],
          }
        );

        setTradingHistory(fetchResult);
        setItem(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    } else {
      try {
        const result = await token.getTokenIdMetadata({
          token_id: tokenId,
          address: contractAddress,
          chain: itemChain !== "ALL" ? itemChain : "0x1",
        });

        const parsedMetadata = JSON.parse(result?.metadata);

        const title = parsedMetadata?.name;
        const description = parsedMetadata.description;
        const imageSrc = parsedMetadata?.image;
        const isVideo = false;
        const owner = result?.owner_of;
        const chainId = itemChain !== "ALL" ? itemChain : "0x1";
        const tokenStandard = result?.contract_type || "ERC-721";

        const fetchResult = await token.getWalletTokenIdTransfers({
          chain: item?.chainId,
          address: contractAddress,
          token_id: tokenId,
        });

        const creator =
          fetchResult?.result[fetchResult?.result?.length - 1]?.to_address;

        setTradingHistory(fetchResult?.result);

        setItem({
          title,
          description,
          imageSrc,
          isVideo,
          creator,
          owner,
          chainId,
          tokenStandard,
          contractAddress,
          tokenId,
          chainType: "on-chain",
        });

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (tokenId) {
      fetchItem();
    }
  }, [tokenId, contractAddress]);

  useEffect(() => {
    if (isAuthenticated) {
      setShowAuthModal(false);
    }
  }, [isAuthenticated]);

  if (loading) {
    return (
      <Box w="100vw" h="80vh">
        <Loading />
      </Box>
    );
  }

  if (!loading && !item) {
    return <div />;
  }

  const toggleAuthModal = (value) => {
    setShowAuthModal(value);
  };

  const onBuyNowClick = async () => {
    if (user) {
      if (chainId !== item?.chainId) {
        setLoading(true);
        try {
          const newChain = web3.provider.isPortis
            ? {
                chainId: fromHexToDecimal(item?.chainId),
                nodeUrl: getMoralisRpcs()[fromHexToDecimal(item?.chainId)],
              }
            : item?.chainId;

          await switchNetwork(newChain);
          setShowBuyNowModal(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setShowBuyNowModal(false);
        }
      } else {
        setShowBuyNowModal(true);
      }
    } else {
      toggleAuthModal(true);
    }
  };

  const onOfferClick = async () => {
    if (user) {
      if (chainId !== item?.chainId) {
        setLoading(true);
        try {
          const newChain = web3.provider.isPortis
            ? {
                chainId: fromHexToDecimal(item?.chainId),
                nodeUrl: getMoralisRpcs()[fromHexToDecimal(item?.chainId)],
              }
            : item?.chainId;

          await switchNetwork(newChain);
          setShowOfferModal(true);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          setShowOfferModal(false);
        }
      } else {
        setShowOfferModal(true);
      }
    } else {
      toggleAuthModal(true);
    }
  };

  const renderDesktop = () => {
    return (
      <Flex alignItems="flex-start">
        {!!item && (
          <ArtDetailFile
            item={item}
            showAuthModal={() => setShowAuthModal(true)}
          />
        )}
        <Box w="35px" />
        <Box flex={1.5} overflow="hidden">
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            mb="35px"
            direction="column"
          >
            <ArtDetailTitle item={item} />
            <ArtDetailPrice item={item} />
          </Flex>
          <Box mb="35px">
            <ArtDetailDescription item={item} />
          </Box>
          <Box mb="35px">
            <ArtDetailDetail item={item} />
          </Box>
          <Box mb="35px">
            <ArtDetailButtons
              item={item}
              openBuyNowModal={onBuyNowClick}
              openOfferModal={onOfferClick}
            />
          </Box>

          <Box mb="35px" w="100%" overflow="hidden">
            <ArtDetailTradingHistory
              item={item}
              _tradingHistory={tradingHistory}
            />
          </Box>
        </Box>
      </Flex>
    );
  };

  const renderTablet = () => {
    return (
      <>
        <Flex
          alignItems="flex-start"
          position="relative"
          height="fit-content"
          mb="52px"
        >
          {!!item && (
            <ArtDetailFile
              item={item}
              showAuthModal={() => setShowAuthModal(true)}
            />
          )}
          <Box w="35px" />
          <Box flex={2}>
            <Flex
              w="100%"
              alignItems="center"
              justifyContent="space-between"
              mb="35px"
              direction="column"
            >
              <ArtDetailTitle item={item} />
              <ArtDetailPrice item={item} />
            </Flex>
          </Box>
        </Flex>
        <Box mb="35px">
          <ArtDetailDescription item={item} />
        </Box>
        <Box mb="35px">
          <ArtDetailDetail item={item} />
        </Box>
        <Box mb="94px">
          <ArtDetailButtons
            item={item}
            openBuyNowModal={onBuyNowClick}
            openOfferModal={onOfferClick}
          />
        </Box>

        <Box paddingBottom="100px">
          <ArtDetailTradingHistory
            item={item}
            _tradingHistory={tradingHistory}
          />
        </Box>
      </>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <TopLogo />
        <Box mt="36.93px" paddingBottom="60px">
          {!!item && (
            <ArtDetailFile
              item={item}
              showAuthModal={() => setShowAuthModal(true)}
            />
          )}
          <Box mb="20px" mt="30.89px">
            <ArtDetailTitle item={item} />
          </Box>
          <Box mb="20px">
            <ArtDetailPrice item={item} />
          </Box>
          <Box mb="20px">
            <ArtDetailDescription item={item} />
          </Box>
          <Box mb="20px">
            <ArtDetailDetail item={item} />
          </Box>
          <Box mb="66px">
            <ArtDetailButtons
              item={item}
              openBuyNowModal={onBuyNowClick}
              openOfferModal={onOfferClick}
            />
          </Box>
          <Box>
            <ArtDetailTradingHistory
              item={item}
              _tradingHistory={tradingHistory}
            />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Container maxW="container.xl" height="100%">
        {isDesktop
          ? renderDesktop()
          : isTablet
          ? renderTablet()
          : renderMobile()}
      </Container>
      {showBuyNowModal && (
        <ArtDetailBuyNowModal
          item={item}
          isOpen={showBuyNowModal}
          onClose={() => setShowBuyNowModal(false)}
        />
      )}
      {showOfferModal && (
        <ArtDetailOfferModal
          item={item}
          isOpen={showOfferModal}
          onClose={() => setShowOfferModal(false)}
        />
      )}
      {showAuthModal && (
        <AuthModal
          isOpen={showAuthModal}
          onClose={() => toggleAuthModal(false)}
        />
      )}
    </>
  );
}

export default ArtDetailContainer;
