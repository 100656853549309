import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useCoinPriceContext } from "../../common/providers/CoinPriceProvider";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import useCoinPrice from "../../common/hooks/useCoinPrice";
import useResponsive from "../../common/utils/useResponsive";
import { ENV, NETWORKS } from "../../../constants";
import { useParams } from "react-router-dom";

function ArtDetailPrice({ item }) {
  const { Moralis } = useMoralis();
  const { isTablet, isDesktop, isMobile } = useResponsive();
  const { contractAddress } = useParams();

  const { price, chainId } = item;

  const { i18n, t } = useTranslation();

  const { language } = i18n;

  const isKorean = language === "ko";

  const ethPrice = !contractAddress
    ? Moralis.Units.FromWei(price?.toString())
    : "";

  const coinPrice = useCoinPrice(ethPrice, chainId);

  const network = NETWORKS[ENV];

  return (
    <Flex
      flex={1}
      bg="#F6F5F7 0% 0% no-repeat padding-box"
      borderRadius="19px"
      padding={isDesktop ? "48px 39px" : "35px 39px"}
      w="100%"
      h={isDesktop ? "200px" : "162px"}
      flexDir="column"
    >
      <Box
        fontSize={isDesktop ? "15px" : "10px"}
        mb={isDesktop ? "6px" : "8px"}
        textAlign="left"
        w="100%"
      >
        {t("item.price")}
      </Box>
      <Flex
        alignItems="flex-end"
        mb={isDesktop ? "0px" : "10px"}
        w="100%"
        justify="flex-end"
      >
        <Box
          outline="none"
          border="none"
          fontSize={isDesktop ? "43px" : "30px"}
          w="fit-content"
          fontWeight="bold"
          mr="10px"
        >
          {price ? numberWithCommas(ethPrice) : "Not for sale"}
        </Box>
        {!contractAddress && (
          <Box fontSize={isDesktop ? "15px" : "10px"} mb="8px">
            {network[chainId]?.unit}
          </Box>
        )}
      </Flex>
      {!contractAddress && (
        <Box
          alignSelf="flex-end"
          bg="#FFFFFF 0% 0% no-repeat padding-box"
          borderRadius="12px"
          w="100%"
          padding={isDesktop ? "7px 37px 10px 38px" : "8px 30px"}
          fontSize={isDesktop ? "15px" : "10px"}
          fontWeight="bold"
          textAlign="right"
        >
          {coinPrice}
        </Box>
      )}
    </Flex>
  );
}

export default ArtDetailPrice;
