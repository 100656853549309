import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import useResponsive from "../utils/useResponsive";

function Loading({
  maxW,
  width = "90%",
  height = "100%",
  alignItems = "center",
  justifyContent = "center",
  marginTop = "0px",
}) {
  const { isMobile } = useResponsive();
  return (
    <Flex
      w={width}
      height={height}
      alignItems={alignItems}
      justifyContent={justifyContent}
      margin="0px auto"
      mb="40px"
      mt={marginTop}
    >
      <Box
        bg="#F6F5F7"
        border="1px solid #FFFFFF"
        boxShadow="0px 20px 30px #00000029"
        borderRadius="19px"
        height="fit-content"
        padding={isMobile ? "30px 50px" : "43px 50px"}
        w="100%"
      >
        <Box
          position="relative"
          bg="#949AB2"
          borderRadius="15px"
          height="15px"
          width="100%"
        >
          <Box
            className="loading_bar"
            bg="#1D20FF"
            borderRadius="15px"
            height="15px"
            position="absolute"
            top="0px"
            left="0px"
            // width="100%"
          />
        </Box>
      </Box>
    </Flex>
  );
}

export default Loading;
