import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import useResponsive from "../../common/utils/useResponsive";

function TutorialFAQ({ faq }) {
  const { isDesktop, isMobile, isTablet } = useResponsive();

  return (
    <Box mb="45px">
      <Flex alignItems="flex-end" mb={isDesktop ? "24px" : "15px"}>
        <Box
          fontSize={isDesktop ? "50px" : "25px"}
          color="#1D20FF"
          mr={isDesktop ? "25px" : isTablet ? "15px" : "10px"}
          fontWeight="extrabold"
        >
          Q.
        </Box>
        <Box
          fontSize={isDesktop ? "30px" : isTablet ? "15px" : "17px"}
          color="#3E3E3E"
          fontWeight="extrabold"
        >
          {faq?.title}
        </Box>
      </Flex>
      <Box
        bg="#F6F5F7"
        w="100%"
        borderRadius="9px"
        padding={isDesktop ? "39px 66px" : isTablet ? "33px 35px" : "30px 36px"}
        paddingBottom={isDesktop ? "29px" : isTablet ? "33px" : "30px"}
        fontSize={isDesktop ? "15px" : isTablet ? "9px" : "13px"}
        fontWeight="extrabold"
        lineHeight={isDesktop ? "21px" : isTablet ? "13px" : "20px"}
      >
        <Box dangerouslySetInnerHTML={{ __html: faq?.value }} />
      </Box>
    </Box>
  );
}

export default TutorialFAQ;
