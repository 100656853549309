import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

import InfiniteScroll from "react-infinite-scroller";

import ROUTE_NAMES from "../../../routes/utils/routeNames";
import { CATEGORIES } from "../../common/utils/categories";
import useResponsive from "../../common/utils/useResponsive";
import SearchContainer from "../../search/SearchContainer";
import ArtsListThumbnail from "./ArtsListThumbnail";

import searchIcon from "../../../assets/icons/magnifying-glass.svg";
import dollarIcon from "../../../assets/icons/money.svg";
import categoryIcon from "../../../assets/icons/art_category.svg";
import Loading from "../../common/components/Loading";

import ArtListPriceRange from "./ArtListPriceRange";

import {
  CONTRACT_ADDRESS_MAP,
  ENV,
  NETWORKS,
  PRICE_UNIT,
} from "../../../constants";
import useLanguageDetect from "../../common/hooks/useLanguageDetect";
import ArtsMobileCategory from "./ArtsMobileCategory";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  resetArtsPriceSearch,
  setArtsFetchedItemsCategory,
  setArtsIsSearching,
  setArtsItems,
  setArtsItemsCount,
  setArtsPriceError,
  setArtsSearchedItems,
  setArtsShowPriceSearch,
  setCurrentChain,
} from "../../../lib/redux/reducers/arts/artsReducer";

import { useCoinPriceContext } from "../../common/providers/CoinPriceProvider";
import ArtsChainSelector from "./ArtsChainSelector";
import { useTranslation } from "react-i18next";

const PAGE_SIZE = 12;

function ArtsList({ currentPage, scrollWrapperRef }) {
  const { Moralis, isInitialized, chainId, web3 } = useMoralis();
  const { token } = useMoralisWeb3Api();
  const { isMobile, isDesktop, isTablet } = useResponsive();

  const { t } = useTranslation();
  const { isKorean } = useLanguageDetect();

  const store = useStore();

  const isAll = currentPage === "ALL";
  const query = isAll ? "getItemsForArts" : "getItemsForArtsByCategory";

  const itemChain = useSelector((state) => state.arts.itemChain);
  const items = JSON.parse(useSelector((state) => state.arts.items));
  const itemsCount = useSelector((state) => state.arts.itemsCount);
  const fetchedItemsCategory = useSelector(
    (state) => state.arts.fetchedItemsCategory
  );
  const fetchedChain = useSelector((state) => state.arts.currentChain);

  const searchText = useSelector((state) => state.arts.searchText);
  const isSearching = useSelector((state) => state.arts.isSearching);

  const showPriceSearch = useSelector((state) => state.arts.showPriceSearch);
  const priceFrom = useSelector((state) => state.arts.priceFrom);
  const priceTo = useSelector((state) => state.arts.priceTo);
  const selectedUnit = useSelector((state) => state.arts.selectedUnit);

  const hasMore = items?.length < itemsCount;

  const dispatch = useDispatch();

  const { krw, usd } = useCoinPriceContext();

  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const [showMobileCategory, setShowMobileCategory] = useState(false);

  const fetchItems = async () => {
    setLoading(true);
    const params = isAll
      ? {
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        }
      : {
          category: CATEGORIES[currentPage].value,
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        };

    const result = await Moralis.Cloud.run(query, params);

    const { results, count } = result;

    dispatch(setArtsItems(JSON.stringify(results)));
    dispatch(setCurrentChain(itemChain));
    dispatch(setArtsItemsCount(count));
    dispatch(
      setArtsFetchedItemsCategory(isAll ? "ALL" : CATEGORIES[currentPage].value)
    );

    setLoading(false);
  };

  const createSubscription = useCallback(async (object) => {
    const params = isAll
      ? {
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        }
      : {
          category: CATEGORIES[currentPage].value,
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        };

    const result = await Moralis.Cloud.run(query, params);

    const { results, count } = result;

    store.dispatch(setArtsItems(JSON.stringify(results)));
    store.dispatch(setCurrentChain(itemChain));
    store.dispatch(setArtsItemsCount(count));
    store.dispatch(
      setArtsFetchedItemsCategory(isAll ? "ALL" : CATEGORIES[currentPage].value)
    );
  }, []);

  const updateSubscription = (object) => {
    const currentState = store.getState();
    const previousItems = JSON.parse(currentState.arts.items);

    const prevItemIndex = [...previousItems].findIndex(
      (item) => item?.objectId === object?.id
    );
    const newItems = [...previousItems];
    if (prevItemIndex > -1) {
      newItems[prevItemIndex] = object;
    }

    store.dispatch(setArtsItems(JSON.stringify(newItems)));
  };

  const deleteSubscription = (object) => {
    const currentState = store.getState();
    const previousItems = JSON.parse(currentState.arts.items);
    const previousItemsCount = currentState.arts.itemsCount;

    const newItems = [...previousItems]?.filter(
      (item) => item.objectId !== object?.id
    );

    store.dispatch(setArtsItems(JSON.stringify(newItems)));
    store.dispatch(setArtsItemsCount(previousItemsCount - 1));
  };

  const updateUserSubscription = async () => {
    const params = isAll
      ? {
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        }
      : {
          category: CATEGORIES[currentPage].value,
          pageSize: PAGE_SIZE,
          chainId: itemChain,
          contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
        };

    const result = await Moralis.Cloud.run(query, params);

    const { results, count } = result;

    dispatch(setArtsItems(JSON.stringify(results)));
    dispatch(setArtsItemsCount(count));
  };

  useEffect(() => {
    if (
      isInitialized &&
      (!items ||
        fetchedItemsCategory !== currentPage ||
        fetchedChain !== itemChain)
    ) {
      fetchItems();
    }
    let subscription;
    let userSubscription;
    if (isInitialized) {
      (async () => {
        const query = new Moralis.Query("Item");
        subscription = await query.subscribe();
        subscription.on("create", createSubscription);
        subscription.on("update", updateSubscription);
        subscription.on("delete", deleteSubscription);
        const usersQuery = new Moralis.Query(Moralis.User);
        userSubscription = await usersQuery.subscribe();
        userSubscription.on(
          "update",
          async () => await updateUserSubscription()
        );
      })();
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
      if (userSubscription) {
        userSubscription.unsubscribe();
      }
    };
  }, [isInitialized, currentPage, items, fetchedItemsCategory, itemChain]);

  const onPriceIconClick = () => {
    dispatch(setArtsShowPriceSearch(!showPriceSearch));
    if (showPriceSearch) {
      dispatch(resetArtsPriceSearch());
    }
  };

  const onSearchIconClick = () => {
    setShowSearch(!showSearch);
    if (showPriceSearch) {
      dispatch(resetArtsPriceSearch());
    }
  };

  const closeSearch = () => {
    setShowSearch(false);
  };

  const search = async () => {
    setShowSearch(false);
    setLoading(true);
    const searchQuery = await Moralis.Cloud.run("searchItemsForArts", {
      pageSize: PAGE_SIZE,
      q: searchText,
      chainId: itemChain,
      contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
    });

    const { results, count } = searchQuery;

    setLoading(false);

    dispatch(setArtsIsSearching(true));

    dispatch(setArtsItems(JSON.stringify(results)));
    dispatch(setArtsItemsCount(count));
    dispatch(setArtsSearchedItems());
  };

  const searchPriceRange = async () => {
    if (!priceTo && !priceFrom) return;

    if (Number(priceTo) <= Number(priceFrom)) {
      dispatch(setArtsPriceError(true));
      return;
    }

    const from =
      selectedUnit === PRICE_UNIT.ETH
        ? Moralis.Units.ETH(priceFrom)
        : isKorean
        ? priceFrom / krw
        : priceFrom / usd;
    const to =
      selectedUnit === PRICE_UNIT.ETH
        ? Moralis.Units.ETH(priceTo)
        : isKorean
        ? priceTo / krw
        : priceTo / usd;

    const searchQuery = await Moralis.Cloud.run("searchPriceItemsForArts", {
      pageSize: PAGE_SIZE,
      category: CATEGORIES[currentPage]?.value || null,
      from: Number(from),
      to: Number(to),
      chainId: itemChain,
      contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
    });

    const { results, count } = searchQuery;

    dispatch(setArtsItems(JSON.stringify(results)));
    dispatch(setArtsItemsCount(count));
    dispatch(
      setArtsFetchedItemsCategory(isAll ? "ALL" : CATEGORIES[currentPage].value)
    );
    if (isMobile) {
      dispatch(setArtsShowPriceSearch(!showPriceSearch));
    }
  };

  const toggleMobileCategory = (value) => {
    setShowMobileCategory(value);
  };

  const fetchMore = async () => {
    if (!hasMore || fetchingMore || !(items?.length > 0)) return;

    if (showPriceSearch) {
      if (!priceTo && !priceFrom) return;

      if (Number(priceTo) <= Number(priceFrom)) {
        dispatch(setArtsPriceError(true));
        return;
      }
      setFetchingMore(true);

      const from =
        selectedUnit === PRICE_UNIT.ETH
          ? Moralis.Units.ETH(priceFrom)
          : isKorean
          ? priceFrom / krw
          : priceFrom / usd;
      const to =
        selectedUnit === PRICE_UNIT.ETH
          ? Moralis.Units.ETH(priceTo)
          : isKorean
          ? priceTo / krw
          : priceTo / usd;

      const searchQuery = await Moralis.Cloud.run("searchPriceItemsForArts", {
        skip: items?.length,
        pageSize: PAGE_SIZE,
        category: CATEGORIES[currentPage]?.value || null,
        from: Number(from),
        to: Number(to),
        chainId: itemChain,
        contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
      });

      const { results, count } = searchQuery;

      dispatch(setArtsItems(JSON.stringify([...items, ...results])));
      dispatch(setArtsItemsCount(count));
      setFetchingMore(false);
    } else if (!isSearching) {
      setFetchingMore(true);

      // if (itemChain === "ALL" || itemChain === "0x1") {
      if (hasMore) {
        const params = isAll
          ? {
              skip: items?.length,
              pageSize: PAGE_SIZE,
              chainId: itemChain,
              contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
            }
          : {
              category: CATEGORIES[currentPage].value,
              skip: items?.length,
              pageSize: PAGE_SIZE,
              chainId: itemChain,
              contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
            };

        const result = await Moralis.Cloud.run(query, params);

        const { results, count } = result;

        dispatch(setArtsItems(JSON.stringify([...items, ...results])));
        setFetchingMore(false);
      }
    } else {
      setFetchingMore(true);
      const searchQuery = await Moralis.Cloud.run("searchItemsForArts", {
        q: searchText,
        chainId: itemChain,
        skip: items?.length,
        pageSize: PAGE_SIZE,
        contractAddress: CONTRACT_ADDRESS_MAP[itemChain],
      });

      const { results, count } = searchQuery;

      dispatch(setArtsIsSearching(true));

      dispatch(setArtsItems(JSON.stringify([...items, ...results])));
      setFetchingMore(false);
    }
  };

  const uniqueItems = [
    ...new Map(
      items?.map((item) => [item?.marketItem?.objectId, item])
    ).values(),
  ];

  return (
    <Box position="relative" w="100%">
      {!isMobile ? (
        <Box mb={isDesktop ? "40px" : "30px"}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginRight={isDesktop ? "0px" : "2%"}
          >
            <Box
              fontSize={isDesktop ? "50px" : "27px"}
              fontWeight="extrabold"
              color={isAll ? "#3E3E3E" : "#1D20FF"}
            >
              {isAll
                ? t("arts.arts")
                : t(`arts.${CATEGORIES[currentPage].name}`)}
            </Box>
            <Flex alignItems="center">
              {itemChain !== "ALL" && (
                <Box
                  cursor="pointer"
                  onClick={onPriceIconClick}
                  mr={isDesktop ? "32px" : "15px"}
                >
                  <Image
                    src={dollarIcon}
                    w={isDesktop ? "32px" : "23px"}
                    h={isDesktop ? "32px" : "23px"}
                  />
                </Box>
              )}
              <Box cursor="pointer" onClick={onSearchIconClick}>
                <Image
                  src={searchIcon}
                  w={isDesktop ? "32px" : "23px"}
                  h={isDesktop ? "32px" : "23px"}
                />
              </Box>
            </Flex>
          </Flex>
          {showPriceSearch && (
            <ArtListPriceRange
              itemChain={itemChain}
              searchPriceRange={searchPriceRange}
            />
          )}
        </Box>
      ) : (
        <Flex direction="column" alignItems="flex-end" mb="15px">
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mb="8px"
            w="100%"
          >
            <Box
              fontSize="35px"
              fontWeight="extrabold"
              color={isAll ? "#3E3E3E" : "#1D20FF"}
            >
              {isAll
                ? t("arts.arts")
                : t(`arts.${CATEGORIES[currentPage].name}`)}
            </Box>
            <Flex alignItems="center">
              <Box
                cursor="pointer"
                onClick={() => toggleMobileCategory(true)}
                mr="20px"
              >
                <Image src={categoryIcon} w="22px" h="22px" />
              </Box>
              {itemChain !== "ALL" && (
                <Box cursor="pointer" onClick={onPriceIconClick} mr="20px">
                  <Image src={dollarIcon} w="22px" h="22px" />
                </Box>
              )}
              <Box cursor="pointer" onClick={onSearchIconClick}>
                <Image src={searchIcon} w="22px" h="22px" />
              </Box>
            </Flex>
          </Flex>
          <ArtsChainSelector itemChain={itemChain} />
          <ArtListPriceRange
            itemChain={itemChain}
            searchPriceRange={searchPriceRange}
          />
          {showMobileCategory && (
            <ArtsMobileCategory
              isOpen={showMobileCategory}
              onClose={() => toggleMobileCategory(false)}
            />
          )}
        </Flex>
      )}
      {!loading ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          useWindow={false}
          getScrollParent={() => scrollWrapperRef?.current}
          hasMore={hasMore}
        >
          <Flex
            alignItems="center"
            flexWrap="wrap"
            justifyContent={isMobile ? "space-between" : "flex-start"}
            marginLeft={!isMobile ? "-2%" : "-1%"}
            marginRight={!isMobile ? "-2%" : "-1%"}
          >
            {uniqueItems &&
              [...uniqueItems]?.map(({ marketItem, owner }) => (
                <ArtsListThumbnail
                  key={marketItem?.objectId}
                  tokenId={marketItem?.tokenId}
                  username={owner?.username || marketItem?.owner?.username}
                  price={marketItem?.price?.toString()}
                  title={marketItem?.title}
                  imageSrc={marketItem?.nftFilePath}
                  routes={ROUTE_NAMES.market}
                  chainId={itemChain}
                  isVideo={marketItem?.isVideo}
                />
              ))}
          </Flex>
        </InfiniteScroll>
      ) : (
        <Loading alignItems="flex-start" marginTop="200px" />
      )}
      {showSearch && (
        <SearchContainer
          showSearch={showSearch}
          closeSearch={closeSearch}
          search={search}
        />
      )}
    </Box>
  );
}

export default ArtsList;
