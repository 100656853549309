import { Button } from "@chakra-ui/button";
import { Flex } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useResponsive from "../../common/utils/useResponsive";

function CreateButtons({
  onCreateClick,
  createDisabled,
  editDisabled,
  onCancelClick,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const { isDesktop, isTablet, isMobile } = useResponsive();

  return (
    <Flex mt={isDesktop ? "0px" : "50px"}>
      <Button
        flex={1}
        bg="#1D20FF 0% 0% no-repeat padding-box"
        color="white"
        borderRadius={isMobile ? "10px" : "19px"}
        height={isDesktop ? "83px" : isTablet ? "63px" : "40px"}
        margin={isDesktop ? "0px 15px" : isTablet ? "0px 13px" : "0px 7.5px"}
        marginLeft="0px"
        fontSize={isDesktop ? "25px" : isTablet ? "17px" : "15px"}
        fontWeight="bold"
        boxShadow={
          isMobile
            ? "0px 20px 20px -15px rgba(29, 32, 255, 0.4)"
            : "0px 35px 40px -20px rgba(29, 32, 255, 0.4)"
        }
        _hover={{
          backgroundColor: "#1d20ff",
        }}
        _active={{
          outline: "none",
          backgroundColor: "#1d20ff",
        }}
        _focus={{
          outline: "none",
          backgroundColor: "#1d20ff",
        }}
        onClick={onCreateClick}
        disabled={createDisabled}
      >
        {editDisabled ? t("global.edit") : t("global.create")}
      </Button>
      <Button
        flex={1}
        bg="white 0% 0% no-repeat padding-box"
        color="#1D20FF"
        borderRadius={isMobile ? "10px" : "19px"}
        height={isDesktop ? "83px" : isTablet ? "63px" : "40px"}
        marginRight="0px"
        fontSize={isDesktop ? "25px" : isTablet ? "17px" : "15px"}
        fontWeight="bold"
        boxShadow={
          isMobile
            ? "0px 20px 20px -15px rgba(0,0,0,0.3)"
            : "0px 35px 40px -20px rgba(0,0,0,0.3)"
        }
        _hover={{
          backgroundColor: "white",
        }}
        _active={{
          outline: "none",
          backgroundColor: "white",
        }}
        _focus={{
          outline: "none",
          backgroundColor: "white",
        }}
        onClick={onCancelClick}
      >
        {!editDisabled ? t("global.cancel") : t("global.delete")}
      </Button>
    </Flex>
  );
}

export default CreateButtons;
