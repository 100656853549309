import { Box, Button, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useMoralis } from "react-moralis";
import { useSelector, useDispatch } from "react-redux";

import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";
import HomeTextImage from "./HomeTextImage";
import { setNewest } from "../../../lib/redux/reducers/home/homeReducer";
import { Trans, useTranslation } from "react-i18next";

const IMAGE_HEIGHT = "470px";
const TABLET_IMAGE_HEIGHT = "210px";
const MOBILE_IMAGE_HEIGHT = "180px";

function HomeNewest({ setNewestLoading }) {
  const { t } = useTranslation();
  const { isDesktop, isMobile, isTablet } = useResponsive();

  const history = useHistory();

  const { Moralis } = useMoralis();

  const newestItems = useSelector((state) => state.home.newest);
  const dispatch = useDispatch();

  const fetchNewestItems = async () => {
    setNewestLoading(true);
    const itemsQuery = await Moralis.Cloud.run("getHomeNewest");

    let first = [];
    let second = [];
    let third = [];

    itemsQuery.map((item, index) => {
      if (index % 3 === 0) {
        first.push(item);
      } else if (index % 3 === 1) {
        third.push(item);
      } else {
        second.push(item);
      }
    });

    dispatch(setNewest(JSON.stringify({ first, second, third })));

    setNewestLoading(false);
  };

  useEffect(() => {
    if (!newestItems) {
      fetchNewestItems();
    }
  }, [newestItems]);

  const onImageClick = (image) => {
    history.push(ROUTE_NAMES.market + `/${image?.tokenId}`);
  };

  const items = JSON.parse(newestItems);

  return (
    <>
      <Box
        w="100%"
        borderRadius="20px"
        bg={!isMobile ? "#F6F5F7" : "white"}
        padding={
          isDesktop ? "200px 100px" : isTablet ? "70px 24px" : "15px 0px"
        }
        position="relative"
        mb={isDesktop ? "100px" : isTablet ? "60px" : "80px"}
      >
        {
          <Flex
            alignItems="center"
            justifyContent="space-between"
            marginTop="-40px"
            marginBottom="-40px"
          >
            <Box flex={1}>
              {items?.first?.map((item) => (
                <Box
                  key={item?.objectId}
                  height={
                    isDesktop
                      ? IMAGE_HEIGHT
                      : isTablet
                      ? TABLET_IMAGE_HEIGHT
                      : MOBILE_IMAGE_HEIGHT
                  }
                  marginTop={isDesktop ? "80px" : "30px"}
                  marginBottom={isDesktop ? "80px" : "30px"}
                  cursor="pointer"
                  onClick={() => onImageClick(item)}
                >
                  {!item?.isVideo ? (
                    <img
                      className="lazyload"
                      src={item?.nftFilePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 27,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <video
                      src={item?.nftFilePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "27px",
                      }}
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  )}
                </Box>
              ))}
            </Box>
            <Box
              flex={1}
              marginLeft={isDesktop ? "80px" : "30px"}
              marginRight={isDesktop ? "80px" : isTablet ? "30px" : "0px"}
              mt="-20px"
            >
              <Box
                marginTop={isDesktop ? "5px" : isTablet ? "19px" : "0px"}
                textAlign={!isMobile ? "center" : "left"}
                mb={isDesktop ? "131px" : isTablet ? "34px" : "20px"}
              >
                <Box
                  fontSize={isDesktop ? "40px" : "20px"}
                  color="#3E3E3E"
                  fontWeight="extrabold"
                  mb={isDesktop ? "23px" : "10px"}
                >
                  {t("mainPage.newest")}
                </Box>
                <Box
                  fontSize={isDesktop ? "14px" : isTablet ? "10px" : "10px"}
                  color="#3E3E3E"
                  fontWeight="light"
                  lineHeight={isDesktop ? "18px" : "12px"}
                >
                  <Trans i18nKey="mainPage.newestDescription" />
                </Box>
              </Box>
              {items?.second?.map((item) => (
                <Box
                  key={item?.objectId}
                  height={
                    isDesktop
                      ? IMAGE_HEIGHT
                      : isTablet
                      ? TABLET_IMAGE_HEIGHT
                      : MOBILE_IMAGE_HEIGHT
                  }
                  marginTop={isDesktop ? "80px" : "30px"}
                  marginBottom={isDesktop ? "80px" : "30px"}
                  cursor="pointer"
                  onClick={() => onImageClick(item)}
                >
                  {!item?.isVideo ? (
                    <Image
                      src={item?.nftFilePath}
                      width="100%"
                      height="100%"
                      borderRadius="27px"
                      objectFit="cover"
                    />
                  ) : (
                    <video
                      src={item?.nftFilePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "27px",
                      }}
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  )}
                </Box>
              ))}
              <Box
                position="relative"
                mt={isDesktop ? "30px" : isTablet ? "14px" : "20px"}
              >
                <Box
                  position="absolute"
                  left="50%"
                  transform="translateX(-50%)"
                  bg="#1D20FF 0% 0% no-repeat padding-box"
                  width="80%"
                  top="30%"
                  bottom="0"
                  height="70%"
                  filter="blur(30px)"
                  zIndex={0}
                />
                <Link to={ROUTE_NAMES.arts}>
                  <Button
                    width="100%"
                    height={isDesktop ? "68px" : isTablet ? "34px" : "30px"}
                    bg="#1D20FF"
                    color="white"
                    padding="0px"
                    borderRadius={!isTablet ? "19px" : "10px"}
                    fontSize={isDesktop ? "21px" : isTablet ? "10px" : "15px"}
                    fontWeight="bold"
                    _hover={{
                      bg: "#1D20FF",
                    }}
                    _active={{
                      bg: "#1D20FF",
                      outline: "none",
                    }}
                    _focus={{
                      bg: "#1D20FF",
                      outline: "none",
                    }}
                  >
                    {t("mainPage.viewAll")}
                  </Button>
                </Link>
              </Box>
            </Box>
            {!isMobile && (
              <Box flex={1}>
                {items?.third?.map((item) => (
                  <Box
                    key={item?.objectId}
                    height={
                      isDesktop
                        ? IMAGE_HEIGHT
                        : isTablet
                        ? TABLET_IMAGE_HEIGHT
                        : MOBILE_IMAGE_HEIGHT
                    }
                    marginTop={isDesktop ? "80px" : "30px"}
                    marginBottom={isDesktop ? "80px" : "30px"}
                    cursor="pointer"
                    onClick={() => onImageClick(item)}
                  >
                    {!item?.isVideo ? (
                      <Image
                        src={item?.nftFilePath}
                        width="100%"
                        height="100%"
                        borderRadius="27px"
                        objectFit="cover"
                      />
                    ) : (
                      <video
                        src={item?.nftFilePath}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: "27px",
                        }}
                        autoPlay
                        loop
                        muted
                        playsInline
                      />
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Flex>
        }
      </Box>
      <HomeTextImage isDown={false} />
    </>
  );
}

export default HomeNewest;
