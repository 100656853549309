import { Flex } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/icons/logo.svg";
import logoWhite from "../../../assets/icons/logo_white.svg";
import ROUTE_NAMES from "../../../routes/utils/routeNames";

function TopLogo({ isCenter = true, marginTop = "0px", isWhite = false }) {
  return (
    <Flex
      marginTop={marginTop}
      paddingLeft="20px"
      paddingRight="20px"
      justifyContent={isCenter ? "center" : "flex-start"}
      backgroundColor="transparent"
    >
      <Link to={ROUTE_NAMES.home}>
        <img
          src={isWhite ? logoWhite : logo}
          style={{ width: 101, height: 15 }}
        />
      </Link>
    </Flex>
  );
}

export default TopLogo;
