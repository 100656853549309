import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setArtsCurrentPage,
  setArtsIsSearching,
  setArtsItems,
  setArtsNavClick,
  setArtsSearchText,
} from "../../../lib/redux/reducers/arts/artsReducer";
import { DESKTOP_NAVIGATION_HEIGHT } from "../../../utilities/constants";
import { CATEGORIES } from "../../common/utils/categories";
import useResponsive from "../../common/utils/useResponsive";
import ArtsChainSelector from "./ArtsChainSelector";

const ICON_SIZE = "34px";
const ICON_SIZE_TABLET = "27px";

function ArtsNavigationBar({ currentPage }) {
  const { isTablet, isMobile, isDesktop } = useResponsive();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const onNavClick = (page) => {
    dispatch(setArtsNavClick(page));
  };

  return (
    <Box
      w={isTablet ? "260px" : "360px"}
      paddingTop={DESKTOP_NAVIGATION_HEIGHT + 80}
      paddingBottom={DESKTOP_NAVIGATION_HEIGHT}
      bg="#F6F5F7"
      height="100vh"
      zIndex={2}
      overflow="auto"
    >
      <Box paddingLeft={isTablet ? "60px" : "82px"}>
        <Box
          fontSize={isTablet ? "16px" : "20px"}
          fontWeight="extrabold"
          color="#949AB2"
          mb="45px"
          cursor="pointer"
          onClick={() => onNavClick("ALL")}
        >
          {t("arts.arts")}
        </Box>
        {Object.keys(CATEGORIES).map((key) => (
          <Flex
            alignItems="center"
            key={CATEGORIES[key].name}
            mb={isTablet ? "22px" : "35px"}
            cursor="pointer"
            onClick={() => onNavClick(key)}
          >
            <Box mr="20px">
              <Image
                src={CATEGORIES[key].icon}
                width={isTablet ? ICON_SIZE_TABLET : ICON_SIZE}
                height={isTablet ? ICON_SIZE_TABLET : ICON_SIZE}
              />
            </Box>
            <Box
              fontSize={isTablet ? "14px" : "18px"}
              fontWeight="extrabold"
              color={key === currentPage ? "#1D20FF" : "#949AB2"}
            >
              {t(`arts.${CATEGORIES[key].name}`)}
            </Box>
          </Flex>
        ))}
      </Box>
      <ArtsChainSelector />
    </Box>
  );
}

export default ArtsNavigationBar;
