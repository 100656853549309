import { Avatar } from "@chakra-ui/avatar";
import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Box, Flex } from "@chakra-ui/layout";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../../common/utils/useResponsive";

import profileIcon from "../../../../assets/icons/profile_menu.svg";

const IMAGE_WIDTH = 140;
const IMAGE_WIDTH_TABLET = 90;

function UpdateProfileAvatar({ profile, tempAvatar, onFileChange }) {
  const { t } = useTranslation();
  const { isMobile, isDesktop } = useResponsive();

  const fileInputRef = useRef();

  const onChangeAvatarClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <Flex
      flex={1}
      flexDir="column"
      alignItems="center"
      justifyContent="center"
      bg="#f6f5f7"
      marginLeft={isMobile ? "20%" : "0px"}
      marginRight={isMobile ? "20%" : "0px"}
      paddingTop={isMobile ? "45px" : "10"}
      paddingBottom={isMobile ? "45px" : "10"}
      paddingLeft={isMobile ? "39px" : "0px"}
      paddingRight={isMobile ? "39px" : "0px"}
      borderRadius="19px"
      marginBottom={isMobile ? "41px" : "0px"}
    >
      {isDesktop && (
        <Box
          w="100%"
          fontSize="25px"
          fontWeight="bold"
          marginBottom="31px"
          textAlign="center"
        >
          {t("updateProfile.title")}
        </Box>
      )}
      <Box marginBottom={isMobile ? "33px" : "52px"}>
        <Image
          src={tempAvatar || profile.avatar || profileIcon}
          w={isMobile ? IMAGE_WIDTH_TABLET : IMAGE_WIDTH}
          h={isMobile ? IMAGE_WIDTH_TABLET : IMAGE_WIDTH}
          borderRadius={isMobile ? IMAGE_WIDTH_TABLET / 2 : IMAGE_WIDTH / 2}
          border="none"
          objectFit="cover"
        />
      </Box>
      <Box position="relative">
        <Box
          position="absolute"
          top="30%"
          left="2%"
          right="2%"
          height="90%"
          bg="rgba(29, 32, 255,0.3)"
          filter="blur(30px)"
        ></Box>
        <Button
          bg="#1d20ff"
          color="white"
          fontSize={isMobile ? "10px" : "13px"}
          fontWeight="bold"
          borderRadius="12px"
          _hover={{
            backgroundColor: "#1d20ff",
          }}
          _focus={{ outline: "none", backgroundColor: "#1d20ff" }}
          _active={{ outline: "none", backgroundColor: "#1d20ff" }}
          onClick={onChangeAvatarClick}
          padding={isDesktop ? "15px 50px" : "15px 35px"}
        >
          {t("updateProfile.changeAvatarButton")}
        </Button>
      </Box>
      <input
        ref={fileInputRef}
        type="file"
        onChange={onFileChange}
        accept="image/gif, image/jpeg, image/png"
      />
    </Flex>
  );
}

export default UpdateProfileAvatar;
