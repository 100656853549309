import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { isFirefox } from "react-device-detect";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import { useHistory, useParams } from "react-router-dom";
import { ERC721_CONTRACT_ABI } from "../../../abis/tokenAbi";
import { CONTRACT_ADDRESS_MAP } from "../../../constants";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";
import { useDispatch } from "react-redux";
import { setNewest } from "../../../lib/redux/reducers/home/homeReducer";
import { Trans, useTranslation } from "react-i18next";

function CreateDeleteModal({ isOpen, onClose }) {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const { tokenId } = useParams();
  const history = useHistory();
  const toast = useToast();
  const { t } = useTranslation();

  const { Moralis, user, chainId } = useMoralis();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onConfirmClick = async () => {
    setLoading(true);
    const itemFromServer = await Moralis.Cloud.run("getItem", {
      tokenId: Number(tokenId),
    });

    const chainType = itemFromServer?.get("chainType");
    if (chainType === "off-chain") {
      itemFromServer.set("isDeleted", true);
      await itemFromServer.save();
      toast({
        title: "Successfully Deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      history.push(ROUTE_NAMES.profile);
    } else {
      const ops = {
        contractAddress: CONTRACT_ADDRESS_MAP[chainId],
        abi: ERC721_CONTRACT_ABI,
        functionName: "burn",
        params: {
          tokenId,
        },
      };

      const transaction = await Moralis.executeFunction(ops);

      let result;
      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      const offersForThisItem = await Moralis.Cloud.run(
        "getOffersForThisItem",
        {
          tokenId: tokenId,
          contractAddress: CONTRACT_ADDRESS_MAP[chainId],
        }
      );

      if (offersForThisItem?.length > 0) {
        for await (let offer of offersForThisItem) {
          offer.set("isRejected", true);
          await offer.save();
        }
      }

      itemFromServer.set("isDeleted", true);
      await itemFromServer.save();
      toast({
        title: "Successfully Deleted",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      history.push(ROUTE_NAMES.profile);
    }
    dispatch(setNewest(null));

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={!isMobile}
      size={isMobile ? "full" : "xl"}
      closeOnEsc
      closeOnOverlayClick
    >
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
        overflow="auto"
        maxHeight="100vh"
      >
        <ModalCloseButton
          top="57px"
          right="51px"
          _hover={{ backgroundColor: "transparent" }}
          _focus={{ outline: "none" }}
        />
        <ModalBody padding="100px 90px">
          <Box textAlign="center" fontSize="24px" fontWeight="bold" mb="20px">
            {t("deleteModal.delete")}
          </Box>
          <Box textAlign="center" fontSize="13px" fontWeight="bold" mb="89px">
            <Trans i18nKey="deleteModal.description" />
          </Box>
          <Box textAlign="left" w="100%">
            <Box fontSize="13px" fontWeight="bold" mb="15px">
              {t("deleteModal.areYouSure")}
            </Box>
            <Button
              width="100%"
              bg="#1D20FF 0% 0% no-repeat padding-box"
              color="white"
              borderRadius="12px"
              padding="22px 0px"
              fontSize="12px"
              fontWeight="bold"
              boxShadow="0px 20px 20px #00000029"
              mb="30px"
              _hover={{
                backgroundColor: "#1d20ff",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={onConfirmClick}
              disabled={loading}
            >
              {t("deleteModal.yes")}
            </Button>
            <Button
              width="100%"
              bg="white 0% 0% no-repeat padding-box"
              color="#3E3E3E"
              borderRadius="12px"
              padding="22px 0px"
              fontSize="12px"
              fontWeight="bold"
              boxShadow="0px 20px 20px #00000029"
              _hover={{
                backgroundColor: "white",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={onClose}
              disabled={loading}
            >
              {t("deleteModal.no")}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default CreateDeleteModal;
