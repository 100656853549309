import Portis from "@portis/web3";
import Web3 from "web3";
import { PORTIS_APP_ID } from "../../../constants";
import { fromDecimalToHex, fromHexToDecimal } from "../utils/convert";
import verifyChainId from "../utils/verifyChainId";
import AbstractWeb3Connector from "./AbstractWeb3Connector";
import Moralis from "moralis";
import { getMoralisRpcs } from "../../common/utils/getMoralisRpcs";
import { ConnectorEvents } from "./events";
import { ENV } from "../../../constants";

const defaultChain = ENV === "dev" ? 3 : 1;

/**
 * Abstract connector to connect EIP-1193 providers to Moralis
 *
 * It should implement at least:
 * - activate()
 * - Emit ConnectorEvent.CHAIN_CHANGED when the chain has changed (if possible)
 * - Emit ConnectorEvent.ACCOUNT_CHANGED when the account has changed (if possible)
 * - type: a name to identify
 * - network: the network type that is used (eg. 'evm')
 */
class PortisWeb3Connector extends AbstractWeb3Connector {
  portis;

  activate = async () => {
    let portis;
    let provider;
    let web3;

    if (this.portis) {
      await this.deactivate();
    }

    try {
      portis = new Portis(PORTIS_APP_ID, {
        chainId: defaultChain,
        nodeUrl: getMoralisRpcs()[defaultChain],
      });
      provider = portis.provider;
      web3 = new Web3(provider);
      provider.isTrust = true;
    } catch (error) {
      console.log("error", error);
    }

    try {
      this.chainId = verifyChainId(await web3.eth.getChainId());

      const accounts = await web3.eth.getAccounts();
      this.account = accounts[0];

      this.portis = portis;
      this.provider = provider;

      Moralis.switchNetwork = portis.changeNetwork;

      this.subscribeToEvents(this.provider);

      return {
        chainId: this.chainId,
        account: this.account,
        provider: this.provider,
      };
    } catch (error) {
      console.log("error", error);
    }
  };

  handleChainChanged = async (chainId) => {
    /**
     * Updates chainId and emit event, on EIP-1193 accountsChanged events
     */
    const newChainId = verifyChainId(chainId);
    this.chainId = newChainId;

    console.log("chainId", chainId);

    this.emit(ConnectorEvents.CHAIN_CHANGED, newChainId);
  };

  deactivate = async () => {
    if (this.portis) {
      this.portis.logout();
    }

    this.unsubscribeToEvents(this.provider);

    await Moralis.cleanup();
    this.account = null;
    this.chainId = null;
    this.provider = null;
    this.portis = null;
  };
}

export default PortisWeb3Connector;
