import { createSlice } from "@reduxjs/toolkit";

export const tutorialSlice = createSlice({
  name: "tutorial",
  initialState: {
    tutorials: null,
    currentSection: null,
    faqs: null,
    faqPage: 1,
    faqCount: 0,
    faqSearchTerm: "",
    faqLanguage: null,
  },
  reducers: {
    setTutorials: (state, action) => {
      state.tutorials = action.payload;
    },
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setFaqs: (state, action) => {
      state.faqs = action.payload;
    },
    setFaqLanguage: (state, action) => {
      state.faqLanguage = action.payload;
    },
    addFaqPage: (state, action) => {
      state.faqPage += 1;
    },
    setFaqCount: (state, action) => {
      state.faqCount = action.payload;
    },
    setFaqSearchTerm: (state, action) => {
      state.faqSearchTerm = action.payload;
    },
  },
});

export const {
  setFaqs,
  setTutorials,
  setCurrentSection,
  addFaqPage,
  setFaqLanguage,
  setFaqCount,
  setFaqSearchTerm,
} = tutorialSlice.actions;

export default tutorialSlice.reducer;
