export const ITEM_STATUS = {
  SELL: {
    value: "SELL",
    name: "sell",
  },
  EXHIBIT_ONLY: {
    value: "EXHIBIT_ONLY",
    name: "exhibitOnly",
  },
};
