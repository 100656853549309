import { Box, Flex, Image } from "@chakra-ui/react";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import useResponsive from "../../common/utils/useResponsive";
import { ENV, MINTING_ADDRESS, NETWORKS } from "../../../constants";

const IMAGE_SIZE = 60;
const IMAGE_SIZE_MOBILE = 75;

const NATIVE_TRANSACTION_CLASSES = {
  "0x1": "EthTransactions",
  "0x3": "EthTransactions",
  "0x13881": "PolygonTransactions",
  "0x89": "PolygonTransactions",
};

const TOKEN_TRANSFER_CLASSES = {
  "0x1": "EthTokenTransfers",
  "0x3": "EthTokenTransfers",
  "0x13881": "PolygonTokenTransfers",
  "0x89": "PolygonTokenTransfers",
};

function ArtDetailTradingHistoryOtherMarket({ transfer, item }) {
  const { t } = useTranslation();
  const { Moralis } = useMoralis();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  return !isMobile ? (
    <Flex
      alignItems="center"
      padding="20px 25px"
      bg="#F6F5F7"
      borderRadius="19px"
      margin="12px 0px"
      width="100%"
    >
      <>
        <Box>
          {!item?.isVideo ? (
            <Image
              width={`${IMAGE_SIZE}px`}
              height={`${IMAGE_SIZE}px`}
              objectFit="cover"
              borderRadius="6px"
              src={item?.imageSrc}
            />
          ) : (
            <video
              src={item?.imageSrc}
              style={{
                width: IMAGE_SIZE,
                height: IMAGE_SIZE,
                objectFit: "cover",
                borderRadius: 6,
              }}
              playsInline
              autoPlay
              loop
              muted
            />
          )}
        </Box>
        <Box
          flex={2}
          borderRight="1px solid #C6C6C6"
          fontSize="13px"
          padding="0px 17px"
          overflow="hidden"
        >
          <Box
            color="#000000"
            fontWeight="bold"
            mb="8px"
            w="100%"
            noOfLines={1}
          >
            {t("profile.price")}
          </Box>
          <Box w="100%" noOfLines={1}>
            {transfer?.value
              ? numberWithCommas(Moralis.Units.FromWei(transfer?.value)) +
                `[${NETWORKS[ENV]?.[item?.chainId]?.unit}]`
              : "-"}{" "}
          </Box>
        </Box>
        <Box
          flex={1}
          borderRight="1px solid #C6C6C6"
          fontSize="13px"
          padding="0px 17px"
          overflow="hidden"
        >
          <Box
            color="#000000"
            fontWeight="bold"
            mb="8px"
            w="100%"
            noOfLines={1}
          >
            {t("profile.event")}
          </Box>
          <Box w="100%" noOfLines={1}>
            {transfer?.from_address === MINTING_ADDRESS ? "MINT" : "TRANSFER"}
          </Box>
        </Box>

        <Box
          flex={1}
          borderRight="1px solid #C6C6C6"
          fontSize="13px"
          padding="0px 17px"
          overflow="hidden"
        >
          <Box
            color="#000000"
            fontWeight="bold"
            mb="8px"
            w="100%"
            noOfLines={1}
          >
            From
          </Box>
          <Box w="100%" noOfLines={1}>
            {transfer?.from_address === MINTING_ADDRESS
              ? "-"
              : transfer?.from_address}
          </Box>
        </Box>
        <Box
          flex={1}
          borderRight="1px solid #C6C6C6"
          fontSize="13px"
          padding="0px 17px"
          overflow="hidden"
        >
          <Box
            color="#000000"
            fontWeight="bold"
            mb="8px"
            w="100%"
            noOfLines={1}
          >
            To
          </Box>
          <Box w="100%" noOfLines={1}>
            {transfer?.to_address === MINTING_ADDRESS
              ? "-"
              : transfer?.to_address}
          </Box>
        </Box>
        <Box flex={1} fontSize="13px" padding="0px 17px" overflow="hidden">
          <Box
            color="#000000"
            fontWeight="bold"
            mb="8px"
            w="100%"
            noOfLines={1}
          >
            {t("profile.date")}
          </Box>
          <Box w="100%" noOfLines={1}>
            {moment(transfer?.block_timestamp).format("YYYY.MM.DD")}
          </Box>
        </Box>
      </>
    </Flex>
  ) : (
    <Box padding="22px 20px" bg="#F6F5F7" margin="10px 0px" borderRadius="19px">
      <>
        <Flex alignItems="center" mb="27px">
          <Box>
            {!item?.isVideo ? (
              <Image
                width={`${IMAGE_SIZE_MOBILE}px`}
                height={`${IMAGE_SIZE_MOBILE}px`}
                objectFit="cover"
                borderRadius="6px"
                src={item?.imageSrc}
              />
            ) : (
              <video
                src={item?.imageSrc}
                style={{
                  width: IMAGE_SIZE_MOBILE,
                  height: IMAGE_SIZE_MOBILE,
                  objectFit: "cover",
                  borderRadius: 6,
                }}
                playsInline
                autoPlay
                loop
                muted
              />
            )}
          </Box>
          <Flex
            flex={1}
            alignItems="center"
            bg="white"
            borderTopRightRadius="5px"
            borderBottomRightRadius="5px"
            paddingTop="11px"
            paddingBottom="11px"
            paddingLeft="51px"
            overflow="hidden"
          >
            <Box flex={1} fontSize="13px">
              <Box
                color="#000000"
                fontWeight="bold"
                mb="8px"
                w="100%"
                noOfLines={1}
              >
                {t("profile.price")}
              </Box>
              <Box w="100%" noOfLines={1}>
                {transfer?.value
                  ? numberWithCommas(transfer?.value) +
                    `[${NETWORKS[ENV]?.[item?.chainId]?.unit}]`
                  : "-"}{" "}
              </Box>
            </Box>
          </Flex>
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          bg="white"
          borderRadius="5px"
          padding="11px 24px"
          mb="27px"
        >
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("profile.event")}
            </Box>
            <Box w="100%" noOfLines={1}>
              TRANSFER
            </Box>
          </Box>
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              From
            </Box>
            <Box w="100%" noOfLines={1}>
              {transfer?.from_address === MINTING_ADDRESS
                ? "-"
                : transfer?.from_address}
            </Box>
          </Box>
        </Flex>
        <Flex
          flex={1}
          alignItems="center"
          bg="white"
          borderRadius="5px"
          padding="11px 24px"
        >
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              To
            </Box>
            <Box w="100%" noOfLines={1}>
              {transfer?.to_address === MINTING_ADDRESS
                ? "-"
                : transfer?.to_address}
            </Box>
          </Box>
          <Box flex={1} fontSize="13px" overflow="hidden">
            <Box
              color="#000000"
              fontWeight="bold"
              mb="8px"
              w="100%"
              noOfLines={1}
            >
              {t("profile.date")}
            </Box>
            <Box w="100%" noOfLines={1}>
              {moment(transfer?.block_timestamp).format("YYYY.MM.DD")}
            </Box>
          </Box>
        </Flex>
      </>
    </Box>
  );
}

export default ArtDetailTradingHistoryOtherMarket;
