export function getEmailBody(
  title,
  isVideo,
  imageSrc,
  color,
  phrase,
  url,
  viewText
) {
  console.log({
    title,
    color,
    isVideo,
    imageSrc,
    phrase,
    url,
    viewText,
  });
  if (isVideo) {
    return `
    <html>
    <head>
      <title>${title}</title>
    </head>
    <body style="font-family: tahoma, geneva, sans-serif">
      <div style="width: 100%; padding-bottom: 40px">
        <div style="max-width: 500px; width: 100%; margin: 0px auto">
          <div
            style="height: 10px; width: 100%; background-color: ${color}"
          ></div>
          <div style="padding: 18px 0px; text-align: center; font-size: 21px; color:${color};">
            ${title}
          </div>
          <div
            style="height: 10px; width: 100%; background-color: ${color}"
          ></div>
          <div style="padding: 18px 0px; font-size: 14px;color:black;">
            ${phrase}
          </div>
          <div style="width: 50%; margin: 0px auto; margin-bottom: 30px">
            <video src="${imageSrc}" style="width: 100%; object-fit: contain" />
          </div>
          <div style="width: fit-content; margin: 0px auto; margin-bottom: 30px">
            <a
              href="${url}"
              style="
                padding: 12px 50px;
                border-radius: 6px;
                border: 1px solid #333333;
                text-align: center;
                font-size: 14px;
                background-color: #024ead;
                color: white;
                text-decoration: none;
              "
              >${viewText}</a
            >
          </div>
          <div
            style="
              height: 10px;
              width: 100%;
              background-color: ${color};
              margin-bottom: 30px;
            "
          ></div>
          <table align="center" style="-webkit-margin-start:auto;-webkit-margin-end:auto;">
          <tbody><tr align="center"><td style="padding: 0px 5px;" class="social-icon-column">
    <a role="social-icon-link" href="https://twitter.com/menninofficial" target="_blank" alt="Twitter" title="Twitter" style="display:inline-block; background-color:#024ead; height:21px; width:21px;">
      <img role="social-icon" alt="Twitter" title="Twitter" src="https://mc.sendgrid.com/assets/social/white/twitter.png" style="height:21px; width:21px;" height="21" width="21">
    </a>
  </td><td style="padding: 0px 5px;" class="social-icon-column">
    <a role="social-icon-link" href="https://instagram.com/menninofficial" target="_blank" alt="Instagram" title="Instagram" style="display:inline-block; background-color:#024ead; height:21px; width:21px;">
      <img role="social-icon" alt="Instagram" title="Instagram" src="https://mc.sendgrid.com/assets/social/white/instagram.png" style="height:21px; width:21px;" height="21" width="21">
    </a>
  </td></tr></tbody>
        </table>
        </div>
      </div>
    </body>
  </html>
  
        `;
  } else {
    return `
    <html>
    <head>
      <title>${title}</title>
    </head>
    <body style="font-family: tahoma, geneva, sans-serif">
      <div style="width: 100%; padding-bottom: 40px">
        <div style="max-width: 500px; width: 100%; margin: 0px auto">
          <div
            style="height: 10px; width: 100%; background-color: ${color}"
          ></div>
          <div style="padding: 18px 0px; text-align: center; font-size: 21px; color:${color};">
            ${title}
          </div>
          <div
            style="height: 10px; width: 100%; background-color: ${color}"
          ></div>
          <div style="padding: 18px 0px; font-size: 14px;color:black;">
            ${phrase}
          </div>
          <div style="width: 50%; margin: 0px auto; margin-bottom: 30px">
            <img src="${imageSrc}" style="width: 100%; object-fit: contain; pointer-events:none;" />
          </div>
          <div style="width: fit-content; margin: 0px auto; margin-bottom: 30px">
            <a
              href="${url}"
              style="
                padding: 12px 50px;
                border-radius: 6px;
                border: 1px solid #333333;
                text-align: center;
                font-size: 14px;
                background-color: #024ead;
                color: white;
                text-decoration: none;
              "
              >${viewText}</a
            >
          </div>
          <div
            style="
              height: 10px;
              width: 100%;
              background-color: ${color};
              margin-bottom: 30px;
            "
          ></div>
          <table align="center" style="-webkit-margin-start:auto;-webkit-margin-end:auto;">
          <tbody><tr align="center"><td style="padding: 0px 5px;" class="social-icon-column">
    <a role="social-icon-link" href="https://twitter.com/menninofficial" target="_blank" alt="Twitter" title="Twitter" style="display:inline-block; background-color:#024ead; height:21px; width:21px;">
      <img role="social-icon" alt="Twitter" title="Twitter" src="https://mc.sendgrid.com/assets/social/white/twitter.png" style="height:21px; width:21px;" height="21" width="21">
    </a>
  </td><td style="padding: 0px 5px;" class="social-icon-column">
    <a role="social-icon-link" href="https://instagram.com/menninofficial" target="_blank" alt="Instagram" title="Instagram" style="display:inline-block; background-color:#024ead; height:21px; width:21px;">
      <img role="social-icon" alt="Instagram" title="Instagram" src="https://mc.sendgrid.com/assets/social/white/instagram.png" style="height:21px; width:21px;" height="21" width="21">
    </a>
  </td></tr></tbody>
        </table>
        </div>
      </div>
    </body>
  </html>
  
        `;
  }
}
