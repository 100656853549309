import { Box, Flex, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import polygonIcon from "../../../assets/icons/polygon.svg";
import ethIcon from "../../../assets/icons/eth.svg";
import { useChain, useMoralis } from "react-moralis";
import { ENV, NETWORKS } from "../../../constants";
import Loading from "../../common/components/Loading";
import arrowDown from "../../../assets/icons/arrow_down_gray.svg";
import arrowUp from "../../../assets/icons/arrow_up_gray.svg";
import useResponsive from "../../common/utils/useResponsive";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setItemChain } from "../../../lib/redux/reducers/arts/artsReducer";

const ICON_SIZE = "26px";
const ICON_SIZE_TABLET = "19px";
const ICON_SIZE_MOBILE = "23px";

function ArtsChainSelector({}) {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { chainId, isInitializing, user } = useMoralis();

  const { t } = useTranslation();

  const itemChain = useSelector((state) => state.arts.itemChain);

  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);

  const networks = NETWORKS[ENV];

  const changeNetwork = async (chain) => {
    setShowDropdown(false);
    dispatch(setItemChain(chain));
  };

  if (isInitializing) {
    return <div />;
  }

  const itemChains = {
    ALL: {
      name: "all",
      chainId: "all",
    },
    ...networks,
  };

  return (
    <Box
      position="relative"
      cursor="pointer"
      w={isMobile ? "47%" : "80%"}
      margin={isMobile ? 0 : "0px auto"}
      fontSize={isMobile ? "11px" : isTablet ? "10px" : "15px"}
      color="#949AB2"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={isMobile ? "12px 22px" : "10px 35px"}
        paddingRight={isMobile ? "11px" : "15px"}
        bg={isMobile ? "#F6F5F7" : "white"}
        borderRadius="9px"
        borderBottomRightRadius={showDropdown ? "0px" : "9px"}
        borderBottomLeftRadius={showDropdown ? "0px" : "9px"}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <Flex alignItems="center">
          {itemChains[itemChain]?.icon && (
            <Image
              src={
                isMobile
                  ? networks[itemChain]?.mobileIcon
                  : networks[itemChain]?.icon
              }
              w={
                isMobile
                  ? ICON_SIZE_MOBILE
                  : isTablet
                  ? ICON_SIZE_TABLET
                  : ICON_SIZE
              }
              h={
                isMobile
                  ? ICON_SIZE_MOBILE
                  : isTablet
                  ? ICON_SIZE_TABLET
                  : ICON_SIZE
              }
              mr={isMobile ? "10px" : isTablet ? "12px" : "16px"}
            />
          )}
          <Box>{t(`arts.${itemChains[itemChain]?.name}`)}</Box>
        </Flex>
        <Image
          src={showDropdown ? arrowUp : arrowDown}
          width="16px"
          height="12px"
        />
      </Flex>
      {showDropdown && (
        <Box
          position="absolute"
          top="100%"
          left="0px"
          right="0px"
          borderRadius="9px"
          borderTopRightRadius={showDropdown ? "0px" : "9px"}
          borderTopLeftRadius={showDropdown ? "0px" : "9px"}
          bg={isMobile ? "#F6F5F7" : "white"}
          zIndex={2}
        >
          {Object.keys(itemChains)
            ?.filter((key) => key !== itemChain)
            ?.map((chain) => (
              <Flex
                key={chain}
                alignItems="center"
                justifyContent="space-between"
                padding={isMobile ? "12px 22px" : "10px 35px"}
                paddingRight={isMobile ? "11px" : "15px"}
                onClick={() => changeNetwork(chain)}
                color="#949AB2"
              >
                <Flex alignItems="center">
                  {itemChains[chain]?.icon ? (
                    <Image
                      src={
                        isMobile
                          ? itemChains[chain]?.mobileIcon
                          : itemChains[chain]?.icon
                      }
                      w={
                        isMobile
                          ? ICON_SIZE_MOBILE
                          : isTablet
                          ? ICON_SIZE_TABLET
                          : ICON_SIZE
                      }
                      h={
                        isMobile
                          ? ICON_SIZE_MOBILE
                          : isTablet
                          ? ICON_SIZE_TABLET
                          : ICON_SIZE
                      }
                      mr={isMobile ? "10px" : isTablet ? "12px" : "16px"}
                    />
                  ) : (
                    <Box
                      w={
                        isMobile
                          ? ICON_SIZE_MOBILE
                          : isTablet
                          ? ICON_SIZE_TABLET
                          : ICON_SIZE
                      }
                      h={
                        isMobile
                          ? ICON_SIZE_MOBILE
                          : isTablet
                          ? ICON_SIZE_TABLET
                          : ICON_SIZE
                      }
                      mr={isMobile ? "10px" : isTablet ? "12px" : "16px"}
                    />
                  )}
                  <Box>{t(`arts.${itemChains[chain]?.name}`)}</Box>
                </Flex>
              </Flex>
            ))}
        </Box>
      )}
    </Box>
  );
}

export default ArtsChainSelector;
