import {
  Box,
  Flex,
  Image as Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMoralis } from "react-moralis";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import { CATEGORIES } from "../../common/utils/categories";

import starEmpty from "../../../assets/icons/star_empty.svg";
import starFull from "../../../assets/icons/star_full.svg";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import useResponsive from "../../common/utils/useResponsive";
import { getFavoritesCount } from "../utils/getFavoritesCount";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const STAR_SIZE = "35px";
const STAR_SIZE_TABLET = "19px";
const STAR_SIZE_MOBILE = "24.45px";

const IMAGE_HEIGHT = "660px";
const IMAGE_HEIGHT_TABLET = "348px";
const IMAGE_HEIGHT_MOBILE = "461px";

function ArtDetailFile({ item, showAuthModal }) {
  const history = useHistory();
  const { t } = useTranslation();
  const { contractAddress } = useParams();

  const { isMobile, isDesktop, isTablet } = useResponsive();

  const { Moralis, user } = useMoralis();
  const [togglingFavorite, setTogglingFavorite] = useState(false);
  const [isFavorite, setIsFavorite] = useState(item?.isFavorite);
  const [favoritesCount, setFavoritesCount] = useState(item?.favoritesCount);
  const [isMyItem, setIsMyItem] = useState(false);

  const [showImageModal, setShowImageModal] = useState(false);

  const imgSrc = item?.imageSrc;
  const category = item?.category;

  useEffect(() => {
    if (user && !contractAddress) {
      const checkIsMine = item?.owner?.get("ethAddress")
        ? item?.owner?.get("ethAddress") === user?.get("ethAddress")
        : item?.creator?.get("ethAddress") === user?.get("ethAddress");

      setIsMyItem(checkIsMine);
    }
  }, [user, item]);

  const setFavorites = async () => {
    if (!user) {
      showAuthModal();

      return;
    }

    if (togglingFavorite) return;

    setTogglingFavorite(true);
    // query item
    const itemQuery = new Moralis.Query("Item");
    itemQuery.equalTo("objectId", item?.itemId);
    const itemObject = await itemQuery.first();

    // user item favorite relation
    const userItemFavoriteRelation = user.relation("favorites");
    // item user favorite relation
    const itemUserFavorteRelation = itemObject.relation("favorites");

    // if user checked favorite already
    if (isFavorite) {
      setIsFavorite(false);
      setFavoritesCount(favoritesCount - 1);
      // remove user-item favorites relation
      userItemFavoriteRelation.remove(itemObject);
      await user.save();
      // remove item-user favorites relation

      itemUserFavorteRelation.remove(user);
      await itemObject.save();
    } else {
      setIsFavorite(true);
      setFavoritesCount(favoritesCount + 1);
      userItemFavoriteRelation.add(itemObject);
      await user.save();
      itemUserFavorteRelation.add(user);
      await itemObject.save();
    }
    setTogglingFavorite(false);
  };

  return (
    <Flex
      flex={1}
      direction="column"
      justifyContent={isDesktop ? "flex-start" : "space-between"}
    >
      <Box
        position="relative"
        width="100%"
        marginBottom={isDesktop ? "61px" : isTablet ? "32px" : "40.66px"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="19px"
        cursor="pointer"
        onClick={() => setShowImageModal(true)}
      >
        {!item?.isVideo ? (
          <img
            className="lazyload"
            src={imgSrc}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 19,
              objectFit: "contain",
            }}
            alt={"MENNIN, NFT Digital Art, " + item?.title}
          />
        ) : (
          <video
            className="lazyload"
            src={imgSrc}
            style={{
              height: "100%",
              objectFit: "cover",
              borderRadius: 19,
            }}
            autoPlay
            loop
            muted
            playsInline
            alt={"MENNIN, NFT Digital Art, " + item?.title}
          />
        )}
      </Box>

      {!contractAddress && (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          justifySelf="flex-end"
          paddingLeft={isDesktop ? "0px" : "12px"}
          paddingBottom={isDesktop ? "40px" : "0px"}
        >
          <Flex
            onClick={setFavorites}
            alignItems="center"
            cursor="pointer"
            pointerEvents={togglingFavorite ? "none" : "auto"}
          >
            <Box mr={isDesktop ? "20px" : isTablet ? "10.47px" : "14.58px"}>
              <Img
                src={isFavorite ? starFull : starEmpty}
                width={
                  isDesktop
                    ? STAR_SIZE
                    : isTablet
                    ? STAR_SIZE_TABLET
                    : STAR_SIZE_MOBILE
                }
                height={
                  isDesktop
                    ? STAR_SIZE
                    : isTablet
                    ? STAR_SIZE_TABLET
                    : STAR_SIZE_MOBILE
                }
              />
            </Box>
            <Box
              mt="4px"
              fontSize={isDesktop ? "17px" : isTablet ? "9px" : "10px"}
              color="#000000"
              fontWeight="light"
              verticalAlign="center"
            >
              {getFavoritesCount(favoritesCount)} {t("item.favoritesUsers")}
            </Box>
          </Flex>
          <Flex alignItems="center">
            {isMyItem && (
              <Box
                bg="#1D20FF"
                color="white"
                borderRadius={isDesktop ? "7px" : "5px"}
                width={isDesktop ? "109.75px" : isTablet ? "64px" : "80px"}
                textAlign="center"
                padding={
                  isDesktop ? "8px 22px" : isTablet ? "4px 14px" : "6px 20px"
                }
                fontSize={isDesktop ? "17px" : isTablet ? "8px" : "10px"}
                fontWeight="extrabold"
                cursor="pointer"
                onClick={() => {
                  history.push(ROUTE_NAMES.art + `/${item?.tokenId}`);
                }}
                mr="10px"
              >
                {t("item.edit")}
              </Box>
            )}
            <Box
              bg="#F6F5F7"
              borderRadius={isDesktop ? "7px" : "5px"}
              padding={
                isDesktop ? "8px 22px" : isTablet ? "4px 14px" : "6px 20px"
              }
              // width={isDesktop ? "109.75px" : isTablet ? "64px" : "80px"}
              fontSize={isDesktop ? "17px" : isTablet ? "8px" : "10px"}
              fontWeight="extrabold"
              cursor="pointer"
              onClick={() => {
                history.push(ROUTE_NAMES.arts, {
                  category,
                });
              }}
            >
              {t(`arts.${CATEGORIES[category].name}`)}
            </Box>
          </Flex>
        </Flex>
      )}

      {showImageModal && (
        <Modal
          isOpen={showImageModal}
          onClose={() => setShowImageModal(false)}
          size="full"
        >
          <ModalOverlay
            bg="#FFFFFF 0% 0% no-repeat padding-box"
            backdropFilter="blur(30px)"
          />
          <ModalContent width="100vw" height="100vh">
            <ModalCloseButton
              size="lg"
              zIndex={9999}
              _focus={{ outline: "none" }}
            />
            <ModalBody
              boxShadow="none"
              position="relative"
              width="100vw"
              height="100vh"
              padding="0px"
            >
              <Flex height="100%" alignItems="center" justifyContent="center">
                {!item?.isVideo ? (
                  <img
                    className="lazyload"
                    src={imgSrc}
                    style={{
                      height: "100%",
                      borderRadius: 19,
                      objectFit: "contain",
                    }}
                    alt={"MENNIN, NFT Digital Art, " + item?.title}
                  />
                ) : (
                  <video
                    className="lazyload"
                    style={{
                      height: "100%",
                      objectFit: "contain",
                      borderRadius: 19,
                      pointerEvents: "auto",
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                    loop
                    playsInline
                    controls={true}
                    controlsList="nodownload"
                  >
                    <source src={imgSrc} />
                  </video>
                )}
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
}

export default ArtDetailFile;
