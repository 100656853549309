import metamaskIcon from "../../../assets/icons/metamask.svg";
import coinbaseIcon from "../../../assets/icons/coinbase.svg";
import walletConnectIcon from "../../../assets/icons/wallet_connect.svg";
import fortmaticIcon from "../../../assets/icons/fortmatic.svg";
import portisIcon from "../../../assets/icons/portis.png";

export const AUTH_OPTIONS = {
  METAMASK: {
    value: "metamask",
    name: "MetaMask",
    icon: metamaskIcon,
  },
  WALLET_CONNECT: {
    value: "walletconnect",
    name: "WalletConnect",
    icon: walletConnectIcon,
  },
  PORTIS: {
    value: "portis",
    name: "Portis",
    icon: portisIcon,
  },
};

export const MOBILE_AUTH_OPTIONS = {
  METAMASK: {
    value: "metamask",
    name: "MetaMask",
    icon: metamaskIcon,
  },
  // WALLET_CONNECT: {
  //   value: "walletconnect",
  //   name: "Mobile Wallets",
  //   icon: walletConnectIcon,
  // },
};
