import {
  Box,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef, useEffect, useState } from "react";
import { isFirefox } from "react-device-detect";
import { useMoralis } from "react-moralis";
import { useDispatch, useSelector } from "react-redux";

import closeIcon from "../../assets/icons/close.svg";
import searchIcon from "../../assets/icons/magnifying-glass.svg";
import { setArtsSearchText } from "../../lib/redux/reducers/arts/artsReducer";
import useResponsive from "../common/utils/useResponsive";
import Loading from "../common/components/Loading";
import { setPopularSearch } from "../../lib/redux/reducers/search/searchReducer";
import { useTranslation } from "react-i18next";

const SEARCH_WIDTH = "971px";
const SEARCH_WIDTH_TABLET = "475px";
const SEARCH_WIDTH_MOBILE = "100%";

const CLOSE_ICON_SIZE = "32px";
const CLOSE_ICON_SIZE_TABLET = "16px";
const CLOSE_ICON_SIZE_MOBILE = "22px";

const SEARCH_ICON_WIDTH = "49.15px";
const SEARCH_ICON_WIDTH_TABLET = "23px";
const SEARCH_ICON_WIDTH_MOBILE = "22px";
const SEARCH_ICON_HEIGHT = "48.48px";
const SEARCH_ICON_HEIGHT_TABLET = "23px";
const SEARCH_ICON_HEIGHT_MOBILE = "22px";

const POPULARS = ["Music", "Art", "Design", "Character"];

function SearchContainer({ showSearch, closeSearch, search }) {
  const searchRef = useRef();

  const popularTitleDivRef = useRef();

  const { t } = useTranslation();

  const { isMobile, isDesktop, isTablet } = useResponsive();

  const { Moralis } = useMoralis();

  const searchText = useSelector((state) => state.arts.searchText);
  const populars = JSON.parse(useSelector((state) => state.search.popular));

  const [loading, setLoading] = useState(false);
  const [popularHeight, setPopularHeight] = useState("0px");

  const dispatch = useDispatch();

  useOutsideClick({
    ref: searchRef,
    handler: closeSearch,
  });

  const fetchPopularSearches = async () => {
    setLoading(true);
    const popularClass = new Moralis.Query("PopularSearch");
    const result = await popularClass.find();

    dispatch(setPopularSearch(JSON.stringify(result)));
    setLoading(false);
  };

  useEffect(() => {
    if (!populars) {
      fetchPopularSearches();
    }
  }, [populars]);

  useEffect(() => {
    if (popularTitleDivRef?.current) {
      const { bottom } = popularTitleDivRef?.current?.getBoundingClientRect();

      setPopularHeight(`calc(100vh - ${bottom + 40}px)`);
    }
  }, [popularTitleDivRef]);

  const onSearchTextChange = (e) => {
    const { value } = e.target;

    dispatch(setArtsSearchText(value));
  };

  const onPopurlarSearchClick = (value) => {
    dispatch(setArtsSearchText(value));
  };

  return !isMobile ? (
    <Box
      ref={searchRef}
      position="fixed"
      top="0"
      right="0"
      w={
        isDesktop
          ? SEARCH_WIDTH
          : isTablet
          ? SEARCH_WIDTH_TABLET
          : SEARCH_WIDTH_MOBILE
      }
      bottom="0"
      padding={isDesktop ? "134.5px 122.5px" : "64.83px 59.33px"}
      bg={!isFirefox ? "transparent" : "white"}
      backdropFilter="blur(160px)"
      zIndex={999}
    >
      <Flex direction="column" w="100%">
        <Box
          cursor="pointer"
          onClick={closeSearch}
          mb={isDesktop ? "141.5px" : isTablet ? "80.17px" : "85.69px"}
          alignSelf={!isMobile ? "flex-start" : "flex-end"}
        >
          <Image
            src={closeIcon}
            w={
              isDesktop
                ? CLOSE_ICON_SIZE
                : isTablet
                ? CLOSE_ICON_SIZE_TABLET
                : CLOSE_ICON_SIZE_MOBILE
            }
            h={
              isDesktop
                ? CLOSE_ICON_SIZE
                : isTablet
                ? CLOSE_ICON_SIZE_TABLET
                : CLOSE_ICON_SIZE_MOBILE
            }
          />
        </Box>
        <Flex
          alignItems="flex-end"
          justifyContent="space-between"
          borderBottom="5px solid black"
          paddingBottom={
            isDesktop ? "38.02px" : isTablet ? "14.57px" : "10.3px"
          }
          mb={isDesktop ? "27.5px" : isTablet ? "20.43px" : "18.7px"}
        >
          <Input
            value={searchText}
            onChange={onSearchTextChange}
            autoFocus
            placeholder={t("arts.search")}
            fontSize={isDesktop ? "25px" : isTablet ? "13px" : "17px"}
            fontWeight="bold"
            border="none"
            padding="0px"
            _focus={{
              border: "none",
            }}
            _placeholder={{
              color: "#949AB2",
            }}
            onKeyPress={async (e) => {
              const { key } = e;

              if (key === "Enter") {
                await search();
              }
            }}
          />
          <Box cursor="pointer" onClick={search}>
            <Image
              src={searchIcon}
              w={
                isDesktop
                  ? SEARCH_ICON_WIDTH
                  : isTablet
                  ? SEARCH_ICON_WIDTH_TABLET
                  : SEARCH_ICON_WIDTH_MOBILE
              }
              h={
                isDesktop
                  ? SEARCH_ICON_HEIGHT
                  : isTablet
                  ? SEARCH_ICON_HEIGHT_TABLET
                  : SEARCH_ICON_HEIGHT_MOBILE
              }
            />
          </Box>
        </Flex>
        <Box>
          <Box
            ref={popularTitleDivRef}
            fontSize={isDesktop ? "30px" : isTablet ? "15px" : "20px"}
            fontWeight="bold"
            color="#3E3E3E"
            mb={isDesktop ? "19px" : isTablet ? "15px" : "20px"}
          >
            {t("arts.popular")}
          </Box>
          {loading ? (
            <Loading />
          ) : (
            <Box height={popularHeight} overflowY="auto">
              {populars?.map((item) => (
                <Box
                  key={item?.objectId}
                  fontSize={isDesktop ? "20px" : isTablet ? "11px" : "15px"}
                  fontWeight="bold"
                  color="#3E3E3E"
                  mb={isDesktop ? "11px" : isTablet ? "15px" : "10px"}
                  cursor="pointer"
                  onClick={() => onPopurlarSearchClick(item?.term)}
                >
                  {item?.term}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  ) : (
    <Modal
      isOpen={showSearch}
      onClose={closeSearch}
      size="full"
      closeOnOverlayClick={false}
      scrollBehavior="inside"
      autoFocus
    >
      <ModalOverlay bg="transparent" />
      <ModalContent
        ref={searchRef}
        bg={!isFirefox ? "transparent" : "white"}
        backdropFilter="blur(160px)"
      >
        <ModalCloseButton _focus={{ outline: "none" }} />

        <ModalBody
          padding={isDesktop ? "134.5px 122.5px" : "64.83px 59.33px"}
          w="100%"
          h="100%"
        >
          <Box w="100%">
            <Flex
              alignItems="flex-end"
              justifyContent="space-between"
              borderBottom="5px solid black"
              paddingBottom={
                isDesktop ? "38.02px" : isTablet ? "14.57px" : "10.3px"
              }
              mb={isDesktop ? "27.5px" : isTablet ? "20.43px" : "18.7px"}
            >
              <Input
                value={searchText}
                onChange={onSearchTextChange}
                placeholder={t("arts.search")}
                fontSize={isDesktop ? "25px" : isTablet ? "13px" : "17px"}
                fontWeight="bold"
                border="none"
                padding="0px"
                _focus={{
                  border: "none",
                }}
                _placeholder={{
                  color: "#949AB2",
                }}
                onKeyPress={async (e) => {
                  const { key } = e;

                  if (key === "Enter") {
                    await search();
                  }
                }}
              />
              <Box cursor="pointer" onClick={search}>
                <Image
                  src={searchIcon}
                  w={
                    isDesktop
                      ? SEARCH_ICON_WIDTH
                      : isTablet
                      ? SEARCH_ICON_WIDTH_TABLET
                      : SEARCH_ICON_WIDTH_MOBILE
                  }
                  h={
                    isDesktop
                      ? SEARCH_ICON_HEIGHT
                      : isTablet
                      ? SEARCH_ICON_HEIGHT_TABLET
                      : SEARCH_ICON_HEIGHT_MOBILE
                  }
                />
              </Box>
            </Flex>
            <Box>
              <Box
                fontSize={isDesktop ? "30px" : isTablet ? "15px" : "20px"}
                fontWeight="bold"
                color="#3E3E3E"
                mb={isDesktop ? "19px" : isTablet ? "15px" : "20px"}
              >
                {t("arts.popular")}
              </Box>
              {loading ? (
                <Loading />
              ) : (
                <Box>
                  {populars?.map((item) => (
                    <Box
                      key={item?.objectId}
                      fontSize={isDesktop ? "20px" : isTablet ? "11px" : "15px"}
                      fontWeight="bold"
                      color="#3E3E3E"
                      mb={isDesktop ? "11px" : isTablet ? "15px" : "10px"}
                      cursor="pointer"
                      onClick={() => onPopurlarSearchClick(item?.term)}
                    >
                      {item?.term}
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default SearchContainer;
