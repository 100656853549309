import { Box } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setArtsCurrentPage } from "../../../lib/redux/reducers/arts/artsReducer";
import {
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
} from "../../../utilities/constants";
import TopLogo from "../../auth/components/TopLogo";
import useResponsive from "../../common/utils/useResponsive";
import ArtsList from "./ArtsList";

function ArtsListPage({ currentPage }) {
  const isAll = currentPage === "ALL";
  const scrollWrapperRef = useRef();

  const { t } = useTranslation();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const dispatch = useDispatch();

  const renderMobile = () => {
    return (
      <Box
        flex={1}
        w="100%"
        height="100vh"
        ref={scrollWrapperRef}
        paddingBottom={`${MOBILE_NAVIGATION_HEIGHT + 80}px`}
        overflowY="auto"
      >
        <TopLogo />
        <Box paddingTop="50px" w="100%" paddingLeft="20px" paddingRight="20px">
          {!isAll && (
            <Box
              fontSize="15px"
              fontWeight="extrabold"
              color="#3E3E3E"
              mb="5px"
              cursor="pointer"
              onClick={() => {
                dispatch(setArtsCurrentPage("ALL"));
              }}
            >
              {t("arts.arts")}
            </Box>
          )}
          <Box>
            <ArtsList
              currentPage={currentPage}
              scrollWrapperRef={scrollWrapperRef}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {!isMobile ? (
        <Box
          paddingTop={DESKTOP_NAVIGATION_HEIGHT}
          paddingLeft={isDesktop ? "3%" : "40px"}
          paddingRight={isDesktop ? "3%" : "40px"}
          paddingBottom={DESKTOP_NAVIGATION_HEIGHT}
          flex={1}
          height="100vh"
          overflowY="auto"
          position="relative"
          ref={scrollWrapperRef}
        >
          {!isAll && (
            <Box
              fontSize={isDesktop ? "20px" : "11px"}
              fontWeight="extrabold"
              color="#3E3E3E"
              mb="14px"
              cursor="pointer"
              onClick={() => {
                dispatch(setArtsCurrentPage("ALL"));
              }}
            >
              {t("arts.arts")}
            </Box>
          )}

          <ArtsList
            currentPage={currentPage}
            scrollWrapperRef={scrollWrapperRef}
          />
        </Box>
      ) : (
        renderMobile()
      )}
    </>
  );
}

export default ArtsListPage;
