import React from "react";

import { useMoralis } from "react-moralis";
import { Route, Redirect } from "react-router-dom";
import ROUTE_NAMES from "../utils/routeNames";

// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useMoralis();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: ROUTE_NAMES.home,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
