import { createSlice } from "@reduxjs/toolkit";
import { PRICE_UNIT } from "../../../../constants";

export const artsReducer = createSlice({
  name: "arts",
  initialState: {
    items: null,
    itemChain: "ALL",
    currentChain: null,
    currentPage: "ALL",
    itemsCount: 0,
    fetchedItemsCategory: "ALL",
    isSearching: false,
    searchText: "",
    priceFrom: "",
    priceTo: "",
    selectedUnit: PRICE_UNIT.ETH,
    showPriceSearch: false,
    priceError: false,
  },
  reducers: {
    setArtsItems: (state, action) => {
      state.items = action.payload;
    },

    setItemChain: (state, action) => {
      state.itemChain = action.payload;
      state.showPriceSearch = false;
    },
    setCurrentChain: (state, action) => {
      state.currentChain = action.payload;
    },
    setArtsCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setArtsItemsCount: (state, action) => {
      state.itemsCount = action.payload;
    },

    setArtsFetchedItemsCategory: (state, action) => {
      state.fetchedItemsCategory = action.payload;
    },
    setArtsSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setArtsIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    setArtsSearchedItems: (state, action) => {
      state.currentPage = "ALL";
      state.fetchedItemsCategory = "ALL";
    },
    setArtsShowPriceSearch: (state, action) => {
      state.showPriceSearch = action.payload;
    },
    setArtsPriceFrom: (state, action) => {
      state.priceFrom = action.payload;
    },
    setArtsPriceTo: (state, action) => {
      state.priceTo = action.payload;
    },
    setArtsPriceSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload;
    },
    setArtsPriceError: (state, action) => {
      state.priceError = action.payload;
    },
    resetArtsPriceSearch: (state, action) => {
      state.priceFrom = "";
      state.priceTo = "";
      state.selectedUnit = PRICE_UNIT.ETH;
      state.showPriceSearch = false;
      state.priceError = false;
      state.items = null;
    },
    setArtsNavClick: (state, action) => {
      state.currentPage = action.payload;
      state.items = null;
      state.isSearching = false;
      state.searchText = "";
      state.priceFrom = "";
      state.priceTo = "";
      state.selectedUnit = PRICE_UNIT.ETH;
      state.showPriceSearch = false;
      state.priceError = false;
    },
  },
});

export const {
  setArtsItems,
  setOurItemEndCursor,
  setItemChain,
  setCurrentChain,
  setArtsCurrentPage,
  setArtsItemsCount,
  setArtsFetchedItemsCategory,
  setArtsSearchText,
  setArtsIsSearching,
  setArtsShowPriceSearch,
  setArtsPriceFrom,
  setArtsPriceTo,
  setArtsPriceSelectedUnit,
  setArtsPriceError,
  resetArtsPriceSearch,
  setArtsNavClick,
  setArtsSearchedItems,
} = artsReducer.actions;

export default artsReducer.reducer;
