import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";
import { ENV, NETWORKS } from "../../../../constants";
import useAccountNFTTransfers from "../../../common/hooks/useAccountNFTTransfers";
import useCoinPrice from "../../../common/hooks/useCoinPrice";
import useResponsive from "../../../common/utils/useResponsive";

function ProfileHeaderSaleInfo() {
  const { user, Moralis, web3, chainId } = useMoralis();
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();

  const { userAccount } = useParams();

  const account = userAccount || user?.get("accounts")[0];

  const {
    nftTransfers: soldTransfers,
    nftTransferLoading: nftTransferLoadingSold,
  } = useAccountNFTTransfers(account, 100, "from");

  const {
    nftTransfers: purchasedTransfers,
    nftTransferLoading: nftTransferLoadingPurchased,
  } = useAccountNFTTransfers(account, 100, "to");

  const getValueSumReducer = (accumulator, current) => {
    if (isNaN(current?.value)) {
      return accumulator;
    } else {
      return accumulator + Number(current.value);
    }
  };

  let soldAmountWei =
    soldTransfers?.result?.reduce(getValueSumReducer, 0)?.toString() || "0";
  let purchasedAmountWei =
    purchasedTransfers?.result?.reduce(getValueSumReducer, 0)?.toString() ||
    "0";

  const soldAmount = Moralis.Units.FromWei(soldAmountWei) || 0;
  const purchasedAmount = Moralis.Units.FromWei(purchasedAmountWei) || 0;

  const soldCoinPrice = useCoinPrice(soldAmount, chainId) || 0;
  const purchasedCoinPrice = useCoinPrice(purchasedAmount, chainId) || 0;

  return !isMobile ? (
    <>
      <Flex alignItems="center" mb="10.69px">
        <Box
          flex={1}
          padding="15px 26px"
          borderRadius="13px"
          bg="#FFFFFF"
          fontSize="13px"
          fontWeight="bold"
          textAlign="center"
          mr="25px"
        >
          {t("profile.purchased")}
        </Box>
        <Box flex={4}>
          <Box fontSize="20px" color="white" fontWeight="bold">
            {purchasedAmount} {NETWORKS[ENV]?.[chainId]?.unit}
          </Box>
          <Box fontSize="15px" color="white" fontWeight="thin">
            {purchasedCoinPrice}
          </Box>
        </Box>
      </Flex>
      <Flex alignItems="center">
        <Box
          flex={1}
          padding="15px 26px"
          borderRadius="13px"
          bg="#FFFFFF"
          fontSize="13px"
          fontWeight="bold"
          textAlign="center"
          mr="25px"
        >
          {t("profile.sold")}
        </Box>
        <Box flex={4}>
          <Box fontSize="20px" color="white" fontWeight="bold">
            {soldAmount} {NETWORKS[ENV]?.[chainId]?.unit}
          </Box>
          <Box fontSize="15px" color="white" fontWeight="thin">
            {soldCoinPrice}
          </Box>
        </Box>
      </Flex>
    </>
  ) : (
    <Flex
      direction="column"
      padding="20px"
      borderRadius="18px"
      bg="#F6F5F7"
      alignItems="center"
    >
      <Box
        width="100%"
        height="45px"
        textAlign="center"
        borderRadius="13px"
        bg="#FFFFFF"
        fontSize="13px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="10.25px"
      >
        {t("profile.purchased")}
      </Box>
      <Box color="black" textAlign="center">
        <Box fontSize="17px" fontWeight="bold">
          {purchasedAmount} {NETWORKS[ENV]?.[chainId]?.unit}
        </Box>
        <Box fontSize="13px" fontWeight="thin">
          {purchasedCoinPrice}
        </Box>
      </Box>
      <Box
        width="100%"
        height="45px"
        textAlign="center"
        borderRadius="13px"
        bg="#FFFFFF"
        fontSize="13px"
        fontWeight="bold"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb="10.25px"
      >
        {t("profile.sold")}
      </Box>
      <Box color="black" textAlign="center" mb="25.5px">
        <Box fontSize="17px" fontWeight="bold">
          {soldAmount} {NETWORKS[ENV]?.[chainId]?.unit}
        </Box>
        <Box fontSize="13px" fontWeight="thin">
          {soldCoinPrice}
        </Box>
      </Box>
    </Flex>
  );
}

export default ProfileHeaderSaleInfo;
