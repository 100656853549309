import { Box, Flex, Image } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { isFirefox } from "react-device-detect";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import { CONTRACT_ADDRESS_MAP } from "../../../constants";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import useResponsive from "../../common/utils/useResponsive";

const THUMBNAIL_WIDTH = "270px";
const THUMBNAIL_HEIGHT = "360px";

const THUMBNAIL_WIDTH_TABLET = "137px";
const THUMBNAIL_HEIGHT_TABLET = "188px";

const THUMBNAIL_WIDTH_MOBILE = "43%";
const THUMBNAIL_HEIGHT_MOBILE = "218px";

function ArtsListThumbnail({
  tokenId,
  imageSrc: _imageSrc,
  tokenUri,
  username,
  price,
  title: _title,
  routes,
  itemChain,
  viewCountDisabled = false,
  isVideo = false,
  tokenAddress = null,
}) {
  const history = useHistory();

  const { isDesktop, isTablet, isMobile } = useResponsive();

  const { Moralis } = useMoralis();
  const [imageSrc, setImageSrc] = useState(_imageSrc);
  const [title, setTitle] = useState(_title);

  useEffect(() => {
    if (!imageSrc && tokenUri) {
      if (tokenUri) {
        axios.get(tokenUri).then((res) => {
          const { data } = res;

          setImageSrc(data.image);
        });
      }
    }
  }, [_imageSrc, tokenUri]);

  const onThumbnailClick = async () => {
    if (tokenAddress) {
      history.push(routes + "/" + tokenAddress + "/" + tokenId);
    } else {
      history.push(routes + "/" + tokenId);
    }

    if (!viewCountDisabled) {
      const query = new Moralis.Query("Item");
      query.equalTo("tokenId", Number(tokenId));
      query.equalTo("contractAddress", CONTRACT_ADDRESS_MAP[itemChain]);
      const result = await query.first();
      const views = result?.get("views");
      if (views) {
        result.set("views", views + 1);
      } else {
        result.set("views", 1);
      }
      await result.save();
    }
  };

  return (
    <Box
      flexBasis={isDesktop ? "21%" : isTablet ? "29%" : "46%"}
      height={
        isDesktop
          ? THUMBNAIL_HEIGHT
          : isTablet
          ? THUMBNAIL_HEIGHT_TABLET
          : THUMBNAIL_HEIGHT_MOBILE
      }
      position="relative"
      bg="white"
      borderRadius="19px"
      cursor="pointer"
      onClick={onThumbnailClick}
      marginBottom={isDesktop ? "60px" : isTablet ? "30px" : "20px"}
      marginLeft={isDesktop ? "2%" : isTablet ? "2%" : "1%"}
      marginRight={isDesktop ? "2%" : isTablet ? "2%" : "1%"}
      pointerEvents={imageSrc ? "auto" : "none"}
    >
      {!isVideo ? (
        <img
          className="lazyload"
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 19,
            objectFit: "cover",
          }}
        />
      ) : (
        <video
          className="lazyload"
          src={imageSrc}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 19,
          }}
          autoPlay
          loop
          muted
          playsInline
        />
      )}
      <Box
        position="absolute"
        bottom={-1}
        left={0}
        right={-0.2}
        backdropFilter="blur(30px)"
        bg={isFirefox ? "#F6F5F7" : "rgba(193, 192, 208, 0.3)"}
        borderBottomLeftRadius={isDesktop ? "19px" : "16px"}
        borderBottomRightRadius={isDesktop ? "19px" : "16px"}
        padding={
          isDesktop
            ? "37px 25px 34px 31px"
            : isTablet
            ? "19px 12px 16px 17px"
            : "19px 18px 24px 19px"
        }
      >
        <Box
          fontSize={isDesktop ? "25px" : isTablet ? "12px" : "15px"}
          fontWeight="bold"
          mb={!isMobile ? "3px" : "5px"}
          noOfLines={1}
          overflow="hidden"
        >
          {title}
        </Box>
        <Box
          fontSize={isDesktop ? "18px" : isTablet ? "9px" : "10px"}
          fontWeight="light"
          noOfLines={1}
          overflow="hidden"
        >
          {username}
        </Box>
        {!isNaN(price) ? (
          <Box fontSize={isDesktop ? "18px" : "13px"} fontWeight="bold">
            {numberWithCommas(Moralis.Units.FromWei(price?.toString()))}
          </Box>
        ) : (
          <Box fontSize={isDesktop ? "18px" : "13px"} fontWeight="bold">
            {price}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ArtsListThumbnail;
