import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const Button = {
  _active: {
    backgroundColor: "transparent",
    outline: "none",
  },
  _hover: {
    backgroundColor: "transparent",
    outline: "none",
  },
  _focus: {
    _active: {
      backgroundColor: "transparent",
      outline: "none",
    },
  },
};

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const breakpoints = createBreakpoints({
  sm: "375px",
  md: "768px",
  lg: "1920px",
});

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "S-CoreDream",
        // backgroundColor: "white",
      },
    },
  },
  components: {
    Button,
  },
  config,
  breakpoints,
});

export default theme;
