import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import { DESKTOP_NAVIGATION_HEIGHT } from "../../../../utilities/constants";
import useResponsive from "../../../common/utils/useResponsive";

const NAV_DESKTOP_MIN_WIDTH = "300px";
const NAV_TABLET_MIN_WIDTH = "220px";

function ProfileViewAllContainer({
  options,
  page,
  currentOption,
  routeDisabled,
}) {
  const { t } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { userAccount } = useParams();

  return (
    <Flex
      alignItems="flex-start"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex={0}
    >
      <Box
        minWidth={isDesktop ? NAV_DESKTOP_MIN_WIDTH : NAV_TABLET_MIN_WIDTH}
        bg="#F6F5F7"
        height="100%"
        paddingTop={DESKTOP_NAVIGATION_HEIGHT + (isDesktop ? 150 : 110)}
        paddingLeft="60px"
      >
        {Object.keys(options).map((option) => {
          const isCurrent = option === currentOption;
          return (
            <Box
              key={option}
              padding={isDesktop ? "29px 40px" : "16px 22px"}
              bg={isCurrent ? "white" : "#F6F5F7"}
              borderRadius="15px"
              borderTopRightRadius="0px"
              borderBottomRightRadius="0px"
              fontSize={isDesktop ? "20px" : "11px"}
              fontWeight="extrabold"
              cursor="pointer"
              color={isCurrent ? "#1D20FF" : "#949AB2"}
            >
              {routeDisabled ? (
                t(`profile.${options[option]}`)
              ) : (
                <Link
                  to={
                    ROUTE_NAMES.viewAll +
                    `/${option}` +
                    (userAccount ? `/${userAccount}` : "")
                  }
                >
                  {t(`profile.${options[option]}`)}
                </Link>
              )}
            </Box>
          );
        })}
      </Box>
      <Box
        flex={1}
        height="100%"
        paddingTop={DESKTOP_NAVIGATION_HEIGHT + (isDesktop ? 0 : 40)}
        overflowY="auto"
        paddingLeft="40px"
        paddingRight="40px"
      >
        {page}
      </Box>
    </Flex>
  );
}

export default ProfileViewAllContainer;
