import { Box, Container, Flex } from "@chakra-ui/layout";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import { getBase64 } from "../../common/utils/getBase64";
import UpdateProfileAvatar from "./components/UpdateProfileAvatar";
import UpdateProfileBasicInfo from "./components/UpdateProfileBasicInfo";
import UpdateProfileButtons from "./components/UpdateProfileButtons";
import UpdateProfileSns from "./components/UpdateProfileSns";

import useResponsive from "../../common/utils/useResponsive";
import TopLogo from "../../auth/components/TopLogo";
import { isValidHttpUrl } from "../../common/utils/isValidHttpUrl";
import { isValidEmail } from "../../common/utils/isValidEmail";

const defaultSnsLinks = [
  {
    sns: "Instagram",
    url: "",
  },
  {
    sns: "Discord",
    url: "",
  },
  {
    sns: "Twitter",
    url: "",
  },
];

function UpdateProfileContainer() {
  const { t } = useTranslation();
  const history = useHistory();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { user, Moralis } = useMoralis();

  const [profile, setProfile] = useState({
    email: "",
    username: "",
    avatar: null,
    description: "",
    snsLinks: defaultSnsLinks,
    email: "",
    checkNotification: false,
    snsErrors: null,
    emailError: false,
    usernameError: false,
  });

  const [avatarFile, setAvatarFile] = useState(null);
  const [tempAvatar, setTempAvatar] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      getUserInfo();
    }
  }, [user]);

  const getUserInfo = () => {
    const username = user.get("username");
    const avatar = user.get("avatar");
    const description = user.get("description");
    const snsLinks = user.get("snsLinks");
    const email = user?.get("email");
    const notification = user?.get("checkNotification");

    setProfile((prev) => ({
      ...prev,
      username: username || "",
      avatar: avatar?.url() || null,
      description: description || "",
      snsLinks: snsLinks || defaultSnsLinks,
      email: email || "",
      checkNotification: notification,
    }));
  };

  const onFileChange = async (e) => {
    const { files } = e.target;

    setAvatarFile(files[0]);

    const base64 = await getBase64(files[0]);
    setTempAvatar(base64);
  };

  const onValueChange = (e, key) => {
    const { value } = e.target;

    setProfile((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateSnsLinks = () => {
    let erroredSns = [];

    profile.snsLinks?.map((link) => {
      if (link?.url?.length > 0) {
        const isValid = isValidHttpUrl(link?.url);
        if (!isValid) {
          erroredSns.push(link?.sns);
        }
      }
    });
    if (erroredSns?.length > 0) {
      setProfile((prev) => ({
        ...prev,
        snsErrors: erroredSns,
      }));
      return false;
    } else {
      return true;
    }
  };

  const validateEmail = () => {
    const isValid = isValidEmail(profile?.email);

    if (isValid || !profile?.email) {
      return true;
    } else {
      setProfile((prev) => ({
        ...prev,
        emailError: true,
      }));
    }
  };

  const confirm = async () => {
    setLoading(true);

    const snsValidated = validateSnsLinks();
    const emailValidated = validateEmail(profile?.email);

    if (!(snsValidated && emailValidated)) {
      setLoading(false);

      return;
    }

    user.set("username", profile.username);
    user.set("description", profile.description);
    user.set("snsLinks", profile.snsLinks);
    user.set("checkNotification", profile?.checkNotification);
    if (profile?.email?.length > 0) {
      user.setEmail(profile?.email);
    } else {
      user?.unset("email");
    }

    if (avatarFile) {
      const { name } = avatarFile;

      const splitedName = name?.split(".");
      const tempName = "avatar." + splitedName?.[splitedName?.length - 1];
      const avatar = new Moralis.File(tempName, avatarFile);
      user.set("avatar", avatar);
    }

    try {
      await user.save();
      getUserInfo();
      history.goBack();
    } catch (error) {
      if (error?.message?.indexOf("username") > -1) {
        setProfile((prev) => ({
          ...prev,
          usernameError: true,
        }));
      }
      if (error.message?.indexOf("email") > -1) {
        setProfile((prev) => ({
          ...prev,
          emailError: true,
        }));
      }
    }

    setLoading(false);
  };

  const cancel = () => {
    history.goBack();
  };

  return (
    <>
      {isMobile && <TopLogo isCenter={false} />}
      <Container
        maxW="container.xl"
        centerContent
        paddingTop={isMobile ? "40px" : isTablet ? "20px" : "70px"}
        paddingBottom={isMobile ? "90px" : "70px"}
      >
        {!isDesktop && (
          <Box
            w="100%"
            fontSize={isMobile ? "17px" : "25px"}
            fontWeight="bold"
            marginBottom={isMobile ? "15px" : "20px"}
            textAlign={isMobile ? "center" : "left"}
          >
            {t("updateProfile.title")}
          </Box>
        )}
        <Flex
          flexDir={isMobile ? "column" : "row"}
          alignItems="stretch"
          justifyContent="space-between"
          w="100%"
          marginBottom={isMobile ? "35px" : isDesktop ? "50px" : "25px"}
        >
          <UpdateProfileAvatar
            profile={profile}
            tempAvatar={tempAvatar}
            onFileChange={onFileChange}
          />

          <UpdateProfileBasicInfo
            profile={profile}
            onValueChange={onValueChange}
            setProfile={setProfile}
          />
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          flexDir={!isDesktop ? "column" : "row"}
        >
          <UpdateProfileSns profile={profile} setProfile={setProfile} />
          <UpdateProfileButtons
            loading={loading}
            profile={profile}
            confirm={confirm}
            cancel={cancel}
          />
        </Flex>
      </Container>
    </>
  );
}

export default UpdateProfileContainer;
