import { Box, Container, Flex } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  useMoralis,
  useMoralisWeb3Api,
  useWeb3ExecuteFunction,
} from "react-moralis";
import { useHistory } from "react-router-dom";

import { ERC721_CONTRACT_ABI } from "../../abis/tokenAbi";
import { b64EncodeUnicode } from "../common/utils/b64EncodeUnicode";
import { CATEGORIES } from "../common/utils/categories";
import { ITEM_STATUS } from "../common/utils/itemStatus";
import CreateButtons from "./components/CreateButtons";
import CreateCategoryStatus from "./components/CreateCategoryStatus";
import CreateConfirmModal from "./components/CreateConfirmModal";
import CreateDescription from "./components/CreateDescription";
import CreatePrice from "./components/CreatePrice";
import CreateRoyalties from "./components/CreateRoyalties";
import CreateTitle from "./components/CreateTitle";
import CreateUploader from "./components/CreateUploader";
import ThankyouContainer from "./components/ThankyouContainer";

import { APPROVAL_KEYS, CONTRACT_ADDRESS_MAP } from "../../constants";
import { useParams } from "react-router-dom";
import useResponsive from "../common/utils/useResponsive";
import TopLogo from "../auth/components/TopLogo";
import {
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
} from "../../utilities/constants";

import Loading from "../common/components/Loading";

import { sign } from "../common/utils/getSignature";
import CreateDeleteModal from "./components/CreateDeleteModal";
import CreateFileInfo from "./components/CreateFileInfo";
import CreateChainSelector from "./components/CreateChainSelector";
import { removeLeadingZero } from "../common/utils/removeLeadingZero";

function CreateContainer() {
  const history = useHistory();
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { tokenId } = useParams();

  const toast = useToast();

  const editDisabled = !!tokenId;

  const { Moralis, user, isWeb3Enabled, chainId, web3 } = useMoralis();

  const [item, setItem] = useState({
    id: "",
    title: "",
    price: "",
    description: "",
    royalty: "",
    category: CATEGORIES.GRAPHIC.value,
    status: ITEM_STATUS.SELL.value,
    isVideo: false,
  });
  const [itemFile, setItemFile] = useState(null);
  const [tempFile, setTempFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const fetchToken = async () => {
    const itemFromServer = await Moralis.Cloud.run("getItem", {
      tokenId: Number(tokenId),
    });

    setItem({
      id: itemFromServer?.get("tokenId"),
      title: itemFromServer?.get("title"),
      price: Moralis.Units.FromWei(itemFromServer?.get("price")?.toString()),
      description: itemFromServer?.get("description"),
      category: itemFromServer?.get("category"),
      status: itemFromServer?.get("status"),
      royalty: JSON.parse(itemFromServer?.get("royalty")).amount / 100,
      isVideo: itemFromServer?.get("isVideo") || false,
    });

    setTempFile(itemFromServer?.get("nftFilePath"));
  };

  useEffect(() => {
    if (tokenId) {
      fetchToken();
    }
  }, [tokenId]);

  const onItemChange = (e, key) => {
    const { value } = e.target;

    setItem((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const onPriceChange = (e) => {
    const { value } = e.target;

    if (value >= 1000) return;

    setItem((prev) => ({
      ...prev,
      price:
        value?.indexOf(".") === 0
          ? removeLeadingZero("0" + value)
          : removeLeadingZero(value),
    }));
  };

  const onRoyaltyChange = (e) => {
    const { value } = e.target;

    const royaltyValue = value < 0 ? 0 : value > 30 ? "30" : value;

    setItem((prev) => ({
      ...prev,
      royalty: removeLeadingZero(royaltyValue),
    }));
  };

  const onFileChange = async (e) => {
    const { files } = e.target;

    if (files[0]) {
      if (files[0].size > 50 * 1048576) {
        toast({
          title: "Maximum file size is 50MB",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      setItemFile(files[0]);

      const base64 = URL.createObjectURL(files[0]);

      setTempFile(base64);
      const { type } = files[0];

      if (type.indexOf("video") > -1) {
        setItem((prev) => ({
          ...prev,
          isVideo: true,
        }));
      } else {
        setItem((prev) => ({
          ...prev,
          isVideo: false,
        }));
      }
    }
  };

  const onCategoryChange = (category) => {
    setItem((prev) => ({
      ...prev,
      category,
    }));
  };

  const onStatusChange = (status) => {
    setItem((prev) => ({
      ...prev,
      status,
    }));
  };

  const checkIsApprovedForAll = async () => {
    let isApproved;

    const approvedContracts = user?.get(APPROVAL_KEYS[chainId]);

    console.log(
      CONTRACT_ADDRESS_MAP[chainId],
      ERC721_CONTRACT_ABI,
      user?.get("ethAddress"),
      chainId
    );

    const isApprovedForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "isApprovedForAll",
      params: {
        owner: user?.get("ethAddress"),
        operator: CONTRACT_ADDRESS_MAP[chainId],
      },
    };

    const transaction = await Moralis.executeFunction(isApprovedForAllOps);

    let result;
    try {
      result = await transaction.wait();
    } catch (error) {
      if (error.code === "TRANSACTION_REPLACED") {
        if (!error.cancelled) {
          result = error.replacement;
        }
      }
    }

    user?.set(APPROVAL_KEYS[chainId], CONTRACT_ADDRESS_MAP[chainId]);
    await user.save();

    return transaction;
  };

  const setApprovalForAll = async () => {
    const approvalForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "setApprovalForAll",
      params: {
        operator: CONTRACT_ADDRESS_MAP[chainId],
        approved: true,
      },
    };

    const transaction = await Moralis.executeFunction(approvalForAllOps);

    let result;
    try {
      result = await transaction.wait();
    } catch (error) {
      if (error.code === "TRANSACTION_REPLACED") {
        if (!error.cancelled) {
          result = error.replacement;
        }
      }
    }

    user?.set(APPROVAL_KEYS[chainId], CONTRACT_ADDRESS_MAP[chainId]);
    await user.save();

    return result;
  };

  const ensureMarketIsApproved = async () => {
    let isApproved = await checkIsApprovedForAll();

    if (!isApproved) {
      try {
        const approvalResult = await setApprovalForAll();
        return true;
      } catch (error) {
        return false;
      }
    }

    return isApproved;
  };

  const createItem = async () => {
    if (itemFile) {
      setLoading(true);
      const isApproved = await ensureMarketIsApproved();

      if (!isApproved) {
        toast({
          title: "Must approve first to use MENNIN",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });

        setLoading(false);

        setShowConfirmModal(false);
        return;
      }

      try {
        const { name } = itemFile;

        const splitedName = name?.split(".");
        const tempName = "temp." + splitedName?.[splitedName?.length - 1];
        const nftFile = new Moralis.File(tempName, itemFile);

        await nftFile.saveIPFS();

        const nftFileIpfs = nftFile.ipfs();

        const metadata = {
          title: item.title,
          description: item.description,
          image: nftFileIpfs,
        };

        const nftMetadataFile = new Moralis.File("metadata.json", {
          base64: b64EncodeUnicode(JSON.stringify(metadata)),
        });

        await nftMetadataFile.saveIPFS();

        const nftMetadataFilePath = nftMetadataFile.ipfs();
        const nftMetadataFileHash = nftMetadataFile.hash();

        const newTokenId = await Moralis.Cloud.run("getTokenId");

        const voucher = {
          tokenId: newTokenId,
          tokenURI: nftMetadataFilePath,
          creatorAddress: user?.get("ethAddress"),
          royalty: {
            account: user?.get("ethAddress"),
            amount: item?.royalty ? item.royalty * 100 : 0,
          },
          tokenAddress: CONTRACT_ADDRESS_MAP[chainId],
        };

        const signature = await sign(
          web3,
          chainId,
          user?.get("ethAddress"),
          voucher,
          CONTRACT_ADDRESS_MAP[chainId]
        );

        const Item = Moralis.Object.extend("Item");
        const nftItem = new Item();

        nftItem.set("tokenId", newTokenId);
        nftItem.set("title", item.title);
        nftItem.set("description", item.description);
        nftItem.set(
          "royalty",
          JSON.stringify({
            account: user?.get("ethAddress"),
            amount: item?.royalty ? item.royalty * 100 : 0,
          })
        );
        nftItem.set("price", item?.price * ("1e" + 18));
        nftItem.set("lowestPrice", item?.price * ("1e" + 18));
        nftItem.set("nftFilePath", nftFileIpfs);
        nftItem.set("nftMetadataFilePath", nftMetadataFilePath);
        nftItem.set("nftMetadataFileHash", nftMetadataFileHash);
        nftItem.set("nftContractAddress", CONTRACT_ADDRESS_MAP[chainId]);
        nftItem.set("creator", user);
        nftItem.set("creatorAccount", user?.get("accounts")[0]);
        nftItem.set("owner", user);
        nftItem.set("ownerAccount", user?.get("accounts")[0]);
        nftItem.set("symbol", "MNT");
        nftItem.set("tokenType", "ERC-721");
        nftItem.set("status", item.status);
        nftItem.set("category", item.category);
        nftItem.set("chainType", "off-chain");
        nftItem.set("chainId", chainId);
        nftItem.set("views", 0);
        nftItem.set("signature", signature);
        nftItem.set("isDeleted", false);
        nftItem.set("isVideo", item?.isVideo);

        await nftItem.save();

        setLoading(false);

        setShowConfirmModal(false);
        setIsDone(true);

        switch (item.status) {
          case ITEM_STATUS.EXHIBIT_ONLY.value: {
            toast({
              title: "Successfully Minted",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });

            return;
          }
          case ITEM_STATUS.SELL.value: {
            toast({
              title: "Successfully Listed",
              status: "success",
              duration: 2000,
              isClosable: true,
              position: "top",
            });

            break;
          }

          default: {
            break;
          }
        }
      } catch (error) {
        toast({
          title: "Error occured. Please try again",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });
        setLoading(false);
        setShowConfirmModal(false);
      }
    }
  };

  const updateItem = async () => {
    try {
      const itemFromServer = await Moralis.Cloud.run("getItem", {
        tokenId: Number(tokenId),
      });

      itemFromServer.set("status", item.status);
      itemFromServer.set("category", item.category);
      itemFromServer.set("price", item.price * ("1e" + 18));

      await itemFromServer.save();

      setLoading(false);

      setShowConfirmModal(false);
      setIsDone(true);

      switch (item.status) {
        case ITEM_STATUS.EXHIBIT_ONLY.value: {
          toast({
            title: "Successfully Minted",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          return;
        }
        case ITEM_STATUS.SELL.value: {
          toast({
            title: "Successfully Listed",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
          });

          break;
        }

        default: {
          break;
        }
      }
    } catch (error) {
      setLoading(false);

      setShowConfirmModal(false);
    }
  };

  const create = async () => {
    setLoading(true);

    if (tokenId) {
      await updateItem();
    } else {
      await createItem();
    }
  };

  const onCreateClick = () => {
    setShowConfirmModal(true);
  };

  const onCancelClick = async () => {
    if (tokenId) {
      setShowDeleteModal(true);
    } else {
      history.goBack();
    }
  };

  const { title, description, price, category, status, royalty } = item;

  const createDisabled = tokenId
    ? false
    : !(title && description && price && itemFile);

  if (!isWeb3Enabled) {
    return <Loading />;
  }

  const renderDesktop = () => {
    return (
      <Flex
        flexDirection="column"
        alignItems="flex-end"
        justifyContent="space-between"
        paddingBottom="71px"
      >
        <Flex
          alignItems="flex-start"
          justifyContent="space-between"
          w="100%"
          flex={1}
        >
          <CreateUploader
            tempFile={tempFile}
            onFileChange={onFileChange}
            editDisabled={editDisabled}
            isVideo={item?.isVideo}
          />
          <Box w="100px" />
          <Flex flex={2} flexDir="column" justifyContent="space-between">
            <CreateTitle
              title={title}
              onItemChange={onItemChange}
              editDisabled={editDisabled}
            />
            <CreatePrice price={price} onItemChange={onPriceChange} />

            <Box mb="35px">
              <CreateDescription
                description={description}
                onItemChange={onItemChange}
                editDisabled={editDisabled}
              />
            </Box>
            <Box mb="35px">
              <CreateCategoryStatus
                category={category}
                status={status}
                onCategoryChange={onCategoryChange}
                onStatusChange={onStatusChange}
              />
            </Box>
            <Box>
              <CreateRoyalties
                royalty={royalty}
                onRoyaltyChange={onRoyaltyChange}
                editDisabled={editDisabled}
              />
            </Box>
            <Box width="100%" mt="60px">
              <CreateButtons
                onCreateClick={onCreateClick}
                createDisabled={createDisabled}
                editDisabled={editDisabled}
                onCancelClick={onCancelClick}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderTablet = () => {
    return (
      <>
        {isMobile && <TopLogo />}
        <Box
          paddingBottom={
            isTablet ? "56px" : `${MOBILE_NAVIGATION_HEIGHT + 56}px`
          }
          paddingLeft={isTablet ? "44px" : "0px"}
          paddingRight={isTablet ? "44px" : "0px"}
          paddingTop={isTablet ? DESKTOP_NAVIGATION_HEIGHT : "34px"}
        >
          {isTablet ? (
            <>
              <Flex alignItems="stretch" justifyContent="space-between">
                <Box flex={1} mr="40px">
                  <CreateUploader
                    tempFile={tempFile}
                    onFileChange={onFileChange}
                    editDisabled={editDisabled}
                    isVideo={item?.isVideo}
                  />
                </Box>
                <Box flex={1.5}>
                  <CreateTitle
                    title={title}
                    onItemChange={onItemChange}
                    editDisabled={editDisabled}
                  />
                  <CreatePrice price={price} onItemChange={onPriceChange} />
                </Box>
              </Flex>
              <CreateFileInfo />
            </>
          ) : (
            <>
              <Box mb="36px">
                <CreateUploader
                  tempFile={tempFile}
                  onFileChange={onFileChange}
                  editDisabled={editDisabled}
                  isVideo={item?.isVideo}
                />
              </Box>
              <CreateFileInfo />

              <Box mt="20px">
                <CreateTitle
                  title={title}
                  onItemChange={onItemChange}
                  editDisabled={editDisabled}
                />
                <CreateChainSelector />
                <CreatePrice price={price} onItemChange={onPriceChange} />
              </Box>
            </>
          )}
          <CreateDescription
            description={description}
            onItemChange={onItemChange}
            editDisabled={editDisabled}
          />
          <CreateCategoryStatus
            category={category}
            status={status}
            onCategoryChange={onCategoryChange}
            onStatusChange={onStatusChange}
          />
          <CreateRoyalties
            royalty={royalty}
            onRoyaltyChange={onRoyaltyChange}
            editDisabled={editDisabled}
          />
          <CreateButtons
            onCreateClick={onCreateClick}
            createDisabled={createDisabled}
            editDisabled={editDisabled}
            onCancelClick={onCancelClick}
          />
        </Box>
      </>
    );
  };

  return (
    <>
      <Container maxW="container.xl" height="100%">
        {!isDone ? (
          isDesktop ? (
            renderDesktop()
          ) : (
            renderTablet()
          )
        ) : (
          <ThankyouContainer />
        )}
      </Container>
      <CreateConfirmModal
        price={item.price}
        isOpen={showConfirmModal}
        loading={loading}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={create}
        editDisabled={editDisabled}
      />
      <CreateDeleteModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      />
    </>
  );
}

export default CreateContainer;
