import { useEffect, useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";

function useAccountNFTTransfers(address, limit, direction, offset) {
  const { web3, chainId } = useMoralis();
  const { account } = useMoralisWeb3Api();

  const [loading, setLoading] = useState(true);
  const [nftTransfers, setNftTransfers] = useState(null);

  const fetchAccountNFTTransfers = async () => {
    const result = await account.getNFTTransfers({
      chain: chainId,
      address,
      limit,
      direction,
      offset,
    });

    setNftTransfers(result);
    setLoading(false);
  };

  useEffect(() => {
    if (address) {
      fetchAccountNFTTransfers();
    }
  }, [address]);

  return { nftTransfers, nftTransferLoading: loading };
}

export default useAccountNFTTransfers;
