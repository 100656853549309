import { Image } from "@chakra-ui/image";
import { Box, Flex } from "@chakra-ui/layout";
import { useOutsideClick } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import arrowIcon from "../../../assets/icons/down_arrow.svg";
import useResponsive from "../utils/useResponsive";

const ICON_WIDTH = 29;

function Selector({ icon, label, value, options, setOption }) {
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { t } = useTranslation();
  const wrapperRef = useRef();

  const [isOpen, setIsOpen] = useState(false);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => setIsOpen(false),
  });

  const editedOptions = Object.keys(options).filter((key) => key !== value);

  const onSelectOption = (option) => {
    setOption(option);
    setIsOpen(false);
  };

  return (
    <Flex
      ref={wrapperRef}
      flex={1}
      position="relative"
      marginLeft={isDesktop ? "10px" : "20px"}
      marginRight={isDesktop ? "10px" : "20px"}
      w="100%"
      cursor="pointer"
    >
      <Flex
        flex={1}
        bg="white"
        padding={isDesktop ? "10px 15px" : "12px 15px"}
        borderRadius="9px"
        borderBottomRadius={isOpen ? 0 : "9px"}
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Flex alignItems="center">
          <Image src={icon} w={ICON_WIDTH} h={ICON_WIDTH} mr="20px" />
          <Box color="#949ab2" fontSize="15px">
            {label}
          </Box>
        </Flex>
        <Box>
          <img
            src={arrowIcon}
            style={{
              transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
              width: 16,
              height: 12,
            }}
          />
        </Box>
      </Flex>
      {isOpen && (
        <Box
          position="absolute"
          bottom="0"
          left="-5px"
          transform="translateY(100%)"
          border="solid #f6f5f7"
          borderTopWidth={0}
          borderBottomWidth={isOpen ? 5 : 0}
          borderLeftWidth={isOpen ? 5 : 0}
          borderRightWidth={isOpen ? 5 : 0}
          borderBottomLeftRadius="9px"
          borderBottomRightRadius="9px"
          w="calc(100% + 10px)"
          bg="white"
          zIndex={200}
        >
          {editedOptions.map((option) => {
            const { value, name, icon: optionIcon } = options[option];
            console.log(name);
            console.log(t("create." + name));
            return (
              <Flex
                alignItems="center"
                key={option}
                color="#949ab2"
                fontSize="15px"
                padding="10px 15px"
                onClick={() => onSelectOption(value)}
              >
                <Flex alignItems="center">
                  {optionIcon && (
                    <Image
                      src={optionIcon}
                      w={ICON_WIDTH}
                      h={ICON_WIDTH}
                      mr="20px"
                    />
                  )}
                  <Box
                    color="#949ab2"
                    fontSize="15px"
                    paddingLeft={optionIcon ? "0px" : "12"}
                  >
                    {t("create." + name)}
                  </Box>
                </Flex>
              </Flex>
            );
          })}
        </Box>
      )}
    </Flex>
  );
}

export default Selector;
