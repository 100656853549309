import React, { useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { useMoralis } from "react-moralis";
import NavigationBar from "../../modules/navigation/components/NavigationBar";
import UpdateProfileContainer from "../../modules/profile/updateProfile/UpdateProfileContainer";
import ROUTE_NAMES from "../utils/routeNames";
import PrivateRoute from "./PrivateRoute";
import CreateContainer from "../../modules/create/CreateContainer";
import {
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
} from "../../utilities/constants";
import ProfileContainer from "../../modules/profile/profile/ProfileContainer";
import ArtsContainer from "../../modules/arts/ArtsContainer";
import ArtDetailContainer from "../../modules/artDetail/ArtDetailContainer";
import TradingHistoryContainer from "../../modules/profile/tradingHistory/TradingHistoryContainer";
import ViewAllContainer from "../../modules/profile/viewAll/ViewAllContainer";
import useResponsive from "../../modules/common/utils/useResponsive";
import HomeContainer from "../../modules/home/HomeContainer";
import SpiritContainer from "../../modules/spirit/SpritContainer";
import TutorialContainer from "../../modules/tutorial/TutorialContainer";
import Loading from "../../modules/common/components/Loading";
import TermsContainer from "../../modules/terms/TermsContainer";
import useRoutesContainer from "./RoutesContainer.hooks";

function RoutesContainer() {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const location = useLocation();
  const { pathname } = location;

  const { isAuthenticated, isInitialized } = useMoralis();

  useRoutesContainer();

  if (!isInitialized) {
    return <Loading />;
  }

  const isTransparentNavbar =
    pathname === ROUTE_NAMES.arts ||
    pathname.includes(ROUTE_NAMES.viewAll) ||
    pathname === ROUTE_NAMES.tutorial;

  return !isMobile ? (
    <>
      <NavigationBar />
      <Switch>
        <>
          <div
            id="routes_wrapper"
            style={{
              width: "100vw",
              height: !isMobile ? "100vh" : "fit-content",
              overflowY: isTransparentNavbar ? "hidden" : "auto",
              paddingTop: !isMobile
                ? !isTransparentNavbar
                  ? DESKTOP_NAVIGATION_HEIGHT
                  : 0
                : 40,
              paddingBottom: isMobile ? MOBILE_NAVIGATION_HEIGHT : 0,
            }}
          >
            <Route exact path={ROUTE_NAMES.home}>
              <HomeContainer />
            </Route>
            {isAuthenticated && (
              <>
                <Route path={ROUTE_NAMES.updateProfile}>
                  <UpdateProfileContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.profile}>
                  <ProfileContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.create}>
                  <CreateContainer />
                </Route>

                <Route exact path={ROUTE_NAMES.tradingHistory}>
                  <TradingHistoryContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.viewAll + "/:option"}>
                  <ViewAllContainer />
                </Route>

                <Route exact path={ROUTE_NAMES.art + "/:tokenId"}>
                  <CreateContainer />
                </Route>
              </>
            )}
            <Route exact path={ROUTE_NAMES.arts}>
              <ArtsContainer />
            </Route>

            <Route exact path={ROUTE_NAMES.market + "/:tokenId"}>
              <ArtDetailContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.profile + "/:userAccount"}>
              <ProfileContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.spirit}>
              <SpiritContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.tutorial}>
              <TutorialContainer />
            </Route>
            <Route
              exact
              path={ROUTE_NAMES.viewAll + "/:option" + "/:userAccount"}
            >
              <ViewAllContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.tradingHistory + "/:userAccount"}>
              <TradingHistoryContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.terms}>
              <TermsContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.privacy}>
              <TermsContainer />
            </Route>
            <Route
              exact
              path={ROUTE_NAMES.item + "/:contractAddress" + "/:tokenId"}
            >
              <ArtDetailContainer />
            </Route>
          </div>
        </>
      </Switch>
    </>
  ) : (
    <>
      <NavigationBar />

      <Switch>
        <>
          <div
            id="routes_wrapper"
            style={{
              width: "100vw",
              height: "fit-content",
              overflowY: pathname === ROUTE_NAMES.arts ? "hidden" : "auto",
              paddingTop: 40,
              paddingBottom: MOBILE_NAVIGATION_HEIGHT,
            }}
          >
            <Route exact path={ROUTE_NAMES.home}>
              <HomeContainer />
            </Route>
            {isAuthenticated && (
              <>
                <Route path={ROUTE_NAMES.updateProfile}>
                  <UpdateProfileContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.profile}>
                  <ProfileContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.create}>
                  <CreateContainer />
                </Route>

                <Route exact path={ROUTE_NAMES.tradingHistory}>
                  <TradingHistoryContainer />
                </Route>
                <Route exact path={ROUTE_NAMES.viewAll + "/:option"}>
                  <ViewAllContainer />
                </Route>

                <Route exact path={ROUTE_NAMES.art + "/:tokenId"}>
                  <CreateContainer />
                </Route>
              </>
            )}
            <Route exact path={ROUTE_NAMES.arts}>
              <ArtsContainer />
            </Route>

            <Route exact path={ROUTE_NAMES.market + "/:tokenId"}>
              <ArtDetailContainer />
            </Route>
            <Route
              exact
              path={ROUTE_NAMES.item + "/:contractAddress" + "/:tokenId"}
            >
              <ArtDetailContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.profile + "/:userAccount"}>
              <ProfileContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.spirit}>
              <SpiritContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.tutorial}>
              <TutorialContainer />
            </Route>
            <Route
              exact
              path={ROUTE_NAMES.viewAll + "/:option" + "/:userAccount"}
            >
              <ViewAllContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.tradingHistory + "/:userAccount"}>
              <TradingHistoryContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.terms}>
              <TermsContainer />
            </Route>
            <Route exact path={ROUTE_NAMES.privacy}>
              <TermsContainer />
            </Route>
          </div>
        </>
      </Switch>
    </>
  );
}

export default RoutesContainer;
