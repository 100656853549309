import axios from "axios";
import { ONESIGNAL_APP_ID, ONESIGNAL_AUTHORIZATION } from "../../../constants";

/**
 *  Email params data
 *  {
 *    imageSrc,
 *    price,
 *    url
 *  }
 */
export function sendEmail({ email_subject, receiver, emailBody }) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Basic ${ONESIGNAL_AUTHORIZATION}`, //Add Rest API Key from OneSignal
  };

  const body = {
    app_id: ONESIGNAL_APP_ID, //From OneSignal
    name: "Email",
    email_subject: email_subject,
    include_email_tokens: [receiver],
    email_body: emailBody,
  };

  axios({
    method: "POST",
    url: "https://onesignal.com/api/v1/notifications",
    data: body,
    headers,
  }).then((res) => {
    console.log({ res });
  });
}
