import { Box, Container } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/react";
import React from "react";
import { MOBILE_NAVIGATION_HEIGHT } from "../../../utilities/constants";
import useResponsive from "../../common/utils/useResponsive";
import ProfileHeader from "./components/ProfileHeader";
import ProfileThumbnails from "./components/ProfileThumbnails";
import ProfileTradingHistory from "./components/ProfileTradingHistory";

/**
 * TODO: Add profile container
 */
function ProfileContainer() {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  return (
    <Container
      maxW="1720px"
      paddingBottom={
        isMobile || isTablet ? `${MOBILE_NAVIGATION_HEIGHT}px` : "0px"
      }
      marginTop={isMobile ? "-40px" : 0}
      overflow="hidden"
    >
      <ProfileHeader />
      {isDesktop ? (
        <Flex alignItems="stretch">
          <ProfileTradingHistory />
          <ProfileThumbnails />
        </Flex>
      ) : (
        <>
          <ProfileTradingHistory />
          <ProfileThumbnails />
        </>
      )}
    </Container>
  );
}

export default ProfileContainer;
