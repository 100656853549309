import artsReducer from "./arts/artsReducer";
import homeReducer from "./home/homeReducer";
import profileTradingHistoryReducer from "./profile/profileTradingHistory/profileTradingHistoryReducer";
import profileViewAllReducer from "./profile/viewAll/profileViewAllReducer";
import searchReducer from "./search/searchReducer";
import tutorialReducer from "./tutorial/tutorialReducer";

export default {
  home: homeReducer,
  profileTradingHistory: profileTradingHistoryReducer,
  tutorial: tutorialReducer,
  arts: artsReducer,
  profileViewAll: profileViewAllReducer,
  search: searchReducer,
};
