import { Box, Flex, Textarea } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../common/utils/useResponsive";

function ArtDetailDescription({ item }) {
  const { t } = useTranslation();

  const { isMobile, isDesktop, isTablet } = useResponsive();

  return (
    <Box>
      <Box
        paddingLeft="20px"
        fontSize={isDesktop ? "20px" : isTablet ? "17px" : "20px"}
        fontWeight="bold"
        mb="11px"
      >
        {t("item.description")}
      </Box>
      <Flex
        bg="#F6F5F7 0% 0% no-repeat padding-box"
        borderRadius="19px"
        w="100%"
        h={isDesktop ? "180px" : isTablet ? "159px" : "230px"}
        alignItems="center"
        justifyContent="center"
      >
        <Textarea
          disabled
          w="80%"
          margin="0px auto"
          resize="none"
          outline="none"
          border="none"
          padding="0px"
          h={isDesktop ? "113px" : isTablet ? "87px" : "143px"}
          fontSize={isDesktop ? "20px" : isTablet ? "15px" : "13px"}
          value={item?.description}
          _disabled={{
            color: "black",
          }}
        />
      </Flex>
    </Box>
  );
}

export default ArtDetailDescription;
