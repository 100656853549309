import { useCallback, useEffect } from "react";
import { useSelector, useDispatch, useStore } from "react-redux";
import { useMoralis } from "react-moralis";
import {
  setProfileOfferMadeItems,
  setProfileOfferReceivedItems,
} from "../../lib/redux/reducers/profile/viewAll/profileViewAllReducer";
import PortisWeb3Connector from "../../modules/auth/providers/PortisWeb3Connector";
import { sendEmail } from "../../modules/common/utils/sendEmail";

import { getEmailBody } from "../../modules/common/utils/getEmailBody";

function useRoutesContainer() {
  const {
    Moralis,
    user,
    isInitialized,
    authenticate,
    isAuthenticated,
    enableWeb3,
    isWeb3Enabled,
    isWeb3EnableLoading,
    logout,
  } = useMoralis();

  const store = useStore();

  const dispatch = useDispatch();

  const offerCreateSubscriptionHandler = useCallback(async (object) => {
    const offerAccount = store.getState().profileViewAll.offerAccount;

    const sellerAccount = object?.get("sellerAccount");
    const buyerAccount = object?.get("buyerAccount");

    if (sellerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferReceived = JSON.parse(
        currentState.profileViewAll.offerReceived
      );
      const previousOfferReceivedCount =
        currentState.profileViewAll.offerReceivedCount;

      const newState =
        previousOfferReceived?.length > 0
          ? [object, ...previousOfferReceived]
          : [object];
      const newCount =
        previousOfferReceivedCount > 0 ? previousOfferReceivedCount + 1 : 1;
      store.dispatch(
        setProfileOfferReceivedItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    } else if (buyerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferMade = JSON.parse(
        currentState.profileViewAll.offerMade
      );
      const previousOfferMadeCount = currentState.profileViewAll.offerMadeCount;

      const newState =
        previousOfferMade?.length > 0
          ? [object, ...previousOfferMade]
          : [object];
      const newCount =
        previousOfferMadeCount > 0 ? previousOfferMadeCount + 1 : 1;

      store.dispatch(
        setProfileOfferMadeItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    }
  }, []);

  const offerUpdateSubscriptionHandler = useCallback(async (object) => {
    const offerAccount = store.getState().profileViewAll.offerAccount;
    const sellerAccount = object?.get("sellerAccount");
    const buyerAccount = object?.get("buyerAccount");

    if (sellerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferReceived = JSON.parse(
        currentState.profileViewAll.offerReceived
      );
      const previousOfferReceivedCount =
        currentState.profileViewAll.offerReceivedCount;

      const updatedIndex = [...previousOfferReceived]?.findIndex(
        (item) => item?.objectId === object?.id
      );
      const newState = [...previousOfferReceived];
      newState[updatedIndex] = object;

      const newCount = previousOfferReceivedCount;
      store.dispatch(
        setProfileOfferReceivedItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    } else if (buyerAccount === offerAccount) {
      const currentState = store.getState();
      const previousOfferMade = JSON.parse(
        currentState.profileViewAll.offerMade
      );
      const previousOfferMadeCount = currentState.profileViewAll.offerMadeCount;

      const updatedIndex = [...previousOfferMade]?.findIndex(
        (item) => item?.objectId === object?.id
      );
      const newState = [...previousOfferMade];
      newState[updatedIndex] = object;

      const newCount = previousOfferMadeCount;

      store.dispatch(
        setProfileOfferMadeItems({
          items: JSON.stringify(newState),
          count: newCount,
          account: offerAccount,
        })
      );
    }
  }, []);

  useEffect(() => {
    let offerSubscription;

    (async () => {
      if (isInitialized) {
        const offerQuery = new Moralis.Query("Offers");
        offerSubscription = await offerQuery.subscribe();
        offerSubscription.on("create", offerCreateSubscriptionHandler);
        offerSubscription.on("update", offerUpdateSubscriptionHandler);
      }
    })();

    return () => {
      offerSubscription?.unsubscribe();
    };
  }, [isInitialized, user]);

  useEffect(() => {
    const subscription = Moralis.onAccountChanged(async (chain) => {
      await logout();
      const savedProvider = JSON.parse(window.localStorage.getItem("provider"));
      if (savedProvider?.provider === "portis") {
        const portisConnector = PortisWeb3Connector;
        const user = await authenticate({
          connector: portisConnector,
        });
      } else {
        await authenticate({ ...savedProvider });
      }
    });

    return () => subscription();
  }, [user]);

  useEffect(() => {
    if (isAuthenticated && !isWeb3Enabled && !isWeb3EnableLoading) {
      (async () => {
        const savedProvider = JSON.parse(
          window.localStorage.getItem("provider")
        );
        if (savedProvider?.provider === "portis") {
          const portisConnector = PortisWeb3Connector;
          await enableWeb3({
            connector: portisConnector,
          });
        } else {
          await enableWeb3({ ...savedProvider });
        }
      })();
    }
  }, [isAuthenticated, isWeb3Enabled]);
}

export default useRoutesContainer;
