import { Box } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../common/utils/useResponsive";

function CreateFileInfo() {
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { t, i18n } = useTranslation();
  return (
    <>
      <Box
        bg="#F6F5F7"
        borderRadius={isMobile ? "15px" : "19px"}
        padding={isDesktop ? "28px 37px" : isTablet ? "30px 28px" : "17px 15px"}
        textAlign={!isTablet ? "center" : "left"}
        mb={isDesktop ? "14px" : "10px"}
      >
        {i18n.language !== "ko" && (
          <Box
            fontSize={isDesktop ? "20px" : isTablet ? "17px" : "15px"}
            fontWeight="bold"
            mb={isMobile ? "7px" : "20px"}
          >
            {t("create.fileInfoTitle")}
          </Box>
        )}
        <Box fontSize={isMobile ? "11px" : "15px"} fontWeight="light">
          {t("create.fileInfoDescription")}
        </Box>
      </Box>
      <Box
        fontSize={isMobile ? "11px" : "15px"}
        fontWeight="bold"
        color="#1D20FF"
        w="100%"
        textAlign={!isTablet ? "center" : "right"}
      >
        <Box>{t("create.feeInfo")}</Box>
      </Box>
    </>
  );
}

export default CreateFileInfo;
