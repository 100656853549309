import { useTranslation } from "react-i18next";

function useLanguageDetect() {
  const { i18n } = useTranslation();

  const { language } = i18n;

  const isKorean = language === "ko";

  return { isKorean };
}

export default useLanguageDetect;
