import { Box, Container, Flex } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { useParams } from "react-router-dom";

import {
  DESKTOP_NAVIGATION_HEIGHT,
  MOBILE_NAVIGATION_HEIGHT,
} from "../../../utilities/constants";
import ProfileTrading from "../profile/components/ProfileTrading";
import {
  ERC721_CONTRACT_ADDRESS,
  ERC721_POLYGON_CONTRACT_ADDRESS,
} from "../../../constants";
import useResponsive from "../../common/utils/useResponsive";
import TopLogo from "../../auth/components/TopLogo";
import { useDispatch, useSelector } from "react-redux";
import {
  setProfileTradingHistory,
  setProfileTradingHistoryCount,
  setProfileTradingUserAccount,
} from "../../../lib/redux/reducers/profile/profileTradingHistory/profileTradingHistoryReducer";
import Loading from "../../common/components/Loading";
import InfiniteScroll from "react-infinite-scroller";

const PAGE_SIZE = 12;

function TradingHistoryContainer() {
  const { isTablet, isMobile, isDesktop } = useResponsive();

  const { userAccount } = useParams();

  const { user, Moralis } = useMoralis();

  const scrollWrapperRef = useRef();

  const currentAccount = useSelector(
    (state) => state.profileTradingHistory.profileTradingHistoryUserAccount
  );
  const transfers = useSelector(
    (state) => state.profileTradingHistory.profileTradingHistory
  );

  const itemsCount = useSelector(
    (state) => state.profileTradingHistory.itemsCount
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const fetchTradingHistory = async (account) => {
    setLoading(true);

    const result = await Moralis.Cloud.run("getTradingHistory", {
      ethAddress: ERC721_CONTRACT_ADDRESS,
      polygonAddress: ERC721_POLYGON_CONTRACT_ADDRESS,
      account: account,
      skip: 0,
      pageSize: PAGE_SIZE,
    });

    const { finalResults, count } = result;

    dispatch(setProfileTradingUserAccount(account));

    dispatch(setProfileTradingHistory(JSON.stringify(finalResults)));

    dispatch(setProfileTradingHistoryCount(count));

    setLoading(false);
  };

  useEffect(() => {
    if (userAccount) {
      if (userAccount !== currentAccount) {
        fetchTradingHistory(userAccount);
      }
    } else {
      if (user?.get("ethAddress") !== currentAccount) {
        fetchTradingHistory(user?.get("ethAddress"));
      }
    }
  }, [currentAccount, userAccount, user]);

  const fetchMore = async () => {
    const parsedTransfers = JSON.parse(transfers);
    const hasMore = parsedTransfers?.length < itemsCount;
    if (hasMore && !fetchingMore) setFetchingMore(true);

    const result = await Moralis.Cloud.run("getTradingHistory", {
      ethAddress: ERC721_CONTRACT_ADDRESS,
      polygonAddress: ERC721_POLYGON_CONTRACT_ADDRESS,
      account: currentAccount,
      skip: parsedTransfers?.length,
      pageSize: PAGE_SIZE,
    });

    const { finalResults, count } = result;

    dispatch(
      setProfileTradingHistory(
        JSON.stringify([...parsedTransfers, ...finalResults])
      )
    );

    setFetchingMore(false);
  };

  const renderDesktop = () => {
    return (
      <Container maxW="1600px" ref={scrollWrapperRef}>
        <Box mb="25px" fontSize="20px" fontWeight="extrabold">
          Trading history
        </Box>
        {loading && <Loading />}
        {!loading && (
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchMore}
            useWindow={false}
            getScrollParent={() => scrollWrapperRef?.current}
            hasMore={JSON.parse(transfers)?.length < itemsCount}
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              marginLeft="-20px"
              marginRight="-20px"
            >
              {JSON.parse(transfers)?.map((transfer, index) => (
                <Flex
                  flexBasis={isDesktop ? "calc((100% - 80px)/2)" : "100%"}
                  key={index}
                  marginLeft="20px"
                  marginRight="20px"
                >
                  <ProfileTrading transfer={transfer} />
                </Flex>
              ))}
            </Flex>
          </InfiniteScroll>
        )}
      </Container>
    );
  };

  return !isMobile ? (
    renderDesktop()
  ) : (
    <Container
      paddingBottom={`${MOBILE_NAVIGATION_HEIGHT}px`}
      ref={scrollWrapperRef}
    >
      <TopLogo />
      <Flex
        w="100%"
        bg="#1D20FF"
        h="60px"
        borderRadius="12px"
        mt="49.93px"
        alignItems="center"
        justifyContent="center"
        fontSize="15px"
        fontWeight="bold"
        color="#FFFFFF"
        mb="30px"
      >
        Trading History
      </Flex>
      {loading && <Loading />}
      {!loading && (
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          useWindow={false}
          getScrollParent={() => scrollWrapperRef?.current}
          hasMore={JSON.parse(transfers)?.length < itemsCount}
          threshold={100}
        >
          {JSON.parse(transfers)?.map((transfer, index) => (
            <ProfileTrading key={index} transfer={transfer} />
          ))}
        </InfiniteScroll>
      )}
    </Container>
  );
}

export default TradingHistoryContainer;
