import React, { createContext, useContext, useEffect, useState } from "react";
import { getCoinPrice } from "../utils/getCoinPrice";

const defaultPrice = { usd: "", krw: "", usdMatic: "", krwMatic: "" };

const CoinPriceContext = createContext(defaultPrice);

function CoinPriceProvider({ children }) {
  const [coinPrice, setCoinPrice] = useState(defaultPrice);

  useEffect(() => {
    (async () => {
      const price = await getCoinPrice();
      setCoinPrice(price);
    })();
  }, []);

  return (
    <CoinPriceContext.Provider value={coinPrice}>
      {children}
    </CoinPriceContext.Provider>
  );
}

export default CoinPriceProvider;

export function useCoinPriceContext() {
  return useContext(CoinPriceContext);
}
