import { useColorMode } from "@chakra-ui/color-mode";
import { useEffect } from "react";
import "./App.css";
import CoinPriceProvider from "./modules/common/providers/CoinPriceProvider";
import RoutesContainer from "./routes/components/RoutesContainer";

function App() {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    // to remove theme mode of os
    setColorMode("light");
  }, []);

  return (
    <CoinPriceProvider>
      <RoutesContainer />
    </CoinPriceProvider>
  );
}

export default App;
