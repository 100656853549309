import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import arrowDown from "../../../assets/icons/arrow_down_blue.svg";
import arrowUp from "../../../assets/icons/arrow_up_blue.svg";
import useResponsive from "../../common/utils/useResponsive";
import makeItVisual from "../../../assets/icons/make_it_visual.svg";

function HomeTextImage({ isDown = true }) {
  const { isDesktop, isTablet, isMobile } = useResponsive();

  return (
    <Flex direction="column" alignItems="center" justifyContent="center">
      {!isDown && (
        <Box mb="10px">
          <Image src={arrowUp} w="15px" h="13px" />
        </Box>
      )}
      <Image
        src={makeItVisual}
        w={isDesktop ? "239px" : isTablet ? "160px" : "136px"}
        objectFit="contain"
      />

      {isDown && (
        <Box mt="10px">
          <Image src={arrowDown} w="15px" h="13px" />
        </Box>
      )}
    </Flex>
  );
}

export default HomeTextImage;
