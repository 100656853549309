import polygonIcon from "./assets/icons/polygon.svg";
import ethIcon from "./assets/icons/eth.svg";
import polygonIconMobile from "./assets/icons/polygon_mobile.svg";
import ethIconMobile from "./assets/icons/eth_mobile.svg";
import ethSwapIcon from "./assets/icons/eth_icon.svg";

export const TRADING_EVENTS = {
  MINT: "MINT",
  SALE: "SALE",
  BUY: "BUY",
  BURN: "BURN",
};

export const PRICE_UNIT = {
  ETH: "ETH",
  USD: "USD",
  KRW: "KRW",
};

export const ENV = process.env.REACT_APP_ENV;

export const ERC721_CONTRACT_ADDRESS = process.env.REACT_APP_ERC_721_ADDRESS;

export const ERC721_POLYGON_CONTRACT_ADDRESS =
  process.env.REACT_APP_ERC721_POLYGON_ADDRESS;

export const WETH_CONTRACT_ADDRESS = process.env.REACT_APP_WETH_ADDRESS;

export const WMATIC_CONTRACT_ADDRESS = process.env.REACT_APP_WMATIC_ADDRESS;

export const PLATFORM_OWNER_ADDRESS = process.env.REACT_APP_OWNER;

export const CREATOR_ADDRESS = process.env.REACT_APP_CREATOR;

export const OTHER_MARKETPLACE_CONTRACT_ADDRESSES = {
  superRare: "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0",
  rarible: "0xF6793dA657495ffeFF9Ee6350824910Abc21356C",
};

export const OWNER_CUT = 125;

export const ETHERSCAN_ADDRESS_URLS = {
  "0x3": "https://ropsten.etherscan.io",
  "0x13881": "https://mumbai.polygonscan.com",
  "0x1": "https://etherscan.io",
  "0x89": "https://polygonscan.com",
};

export const DEFAULT_CHAIN = "ALL";

export const NETWORKS = {
  dev: {
    "0x3": {
      chainId: "0x3",
      name: "Ethereum",
      shortName: "ETH",
      icon: ethIcon,
      mobileIcon: ethIconMobile,
      swapIcon: ethSwapIcon,
      unit: "ETH",
      gaekoId: "ethereum",
    },
    "0x13881": {
      chainId: "0x13881",
      name: "Polygon",
      shortName: "Polygon",
      icon: polygonIcon,
      mobileIcon: polygonIconMobile,
      swapIcon: polygonIcon,
      unit: "MATIC",
      gaekoId: "matic-network",
    },
  },
  release: {
    "0x1": {
      chainId: "0x1",
      name: "Ethereum",
      shortName: "ETH",
      icon: ethIcon,
      mobileIcon: ethIconMobile,
      swapIcon: ethSwapIcon,
      unit: "ETH",
      gaekoId: "ethereum",
    },
    "0x89": {
      chainId: "0x89",
      name: "Polygon",
      shortName: "Polygon",
      icon: polygonIcon,
      mobileIcon: polygonIconMobile,
      swapIcon: polygonIcon,
      unit: "MATIC",
      gaekoId: "matic-network",
    },
  },
};

export const CONTRACT_ADDRESS_MAP = {
  "0x3": ERC721_CONTRACT_ADDRESS,
  "0x13881": ERC721_POLYGON_CONTRACT_ADDRESS,
  "0x1": ERC721_CONTRACT_ADDRESS,
  "0x89": ERC721_POLYGON_CONTRACT_ADDRESS,
};

export const WETH_ADDRESS_MAP = {
  "0x3": WETH_CONTRACT_ADDRESS,
  "0x13881": WMATIC_CONTRACT_ADDRESS,
  "0x1": WETH_CONTRACT_ADDRESS,
  "0x89": WMATIC_CONTRACT_ADDRESS,
};

export const WRAPPED_ETH_NAMES = {
  "0x3": "WETH",
  "0x13881": "WMATIC",
  "0x1": "WETH",
  "0x89": "WMATIC",
};

export const WRAPPED_ETH_QUERY_MAP = {
  "0x3": "EthTokenBalance",
  "0x13881": "PolygonTokenBalance",
  "0x1": "EthTokenBalance",
  "0x89": "PolygonTokenBalance",
};

export const PORTIS_APP_ID = process.env.REACT_APP_PORTIS_APP_ID;

export const APPROVAL_KEYS = {
  "0x1": "ethApprovedAddress",
  "0x3": "ethApprovedAddress",
  "0x13881": "polygonApprovedAddress",
  "0x89": "polygonApprovedAddress",
};

export const MINTING_ADDRESS = "0x0000000000000000000000000000000000000000";

export const PORTIS_CHAIN_NAMES = {
  "0x1": "mainnet",
  "0x3": "ropsten",
  "0x13881": "maticMumbai",
  "0x89": "matic",
};

export const SENDGRID_API_KEY = process.env.REACT_APP_SENDGRID_API_KEY;
export const ONESIGNAL_APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
export const ONESIGNAL_AUTHORIZATION =
  process.env.REACT_APP_ONESIGNAL_AUTHORIZATION;

export const WEB3AUTH_CLIENT_ID = process.env.REACT_APP_WEB3AUTH_CLIENT_ID;
