import { TabPanel } from "@chakra-ui/react";
import ProfileTabPanel from "../components/ProfileTabPanel";

export const PROFILE_TAB_TYPES = {
  CREATED: "CREATED",
  COLLECTED: "COLLECTED",
  OFFERED: "OFFERED",
  FAVORITE: "FAVORITE",
};

export const PROFILE_TAB_OPTIONS = [
  {
    type: PROFILE_TAB_TYPES.CREATED,
    name: "Created",
  },
  {
    type: PROFILE_TAB_TYPES.COLLECTED,
    name: "Collected",
  },
  {
    type: PROFILE_TAB_TYPES.OFFERED,
    name: "Offered",
  },
  {
    type: PROFILE_TAB_TYPES.FAVORITE,
    name: "Favorited",
  },
];

export const PROFILE_TAB_ROUTE_NAMES = {
  CREATED: "created",
  COLLECTED: "collected",
  OFFERED: "offered",
  FAVORITE: "favorite",
};

export const VIEW_ALL_ROUTE_NAMES = {
  created: "Created",
  collected: "Collected",
  offered: "Offered",
  favorite: "Favorite",
};

export const OFFER_VIEW_STATE = {
  OFFER_RECEIVED: {
    value: "OFFER_RECEIVED",
    name: "Offer Received",
  },
  OFFER_MADE: {
    value: "OFFER_MADE",
    name: "Offer Made",
  },
};
