import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../../common/utils/useResponsive";
import {
  PROFILE_TAB_OPTIONS,
  PROFILE_TAB_TYPES,
} from "../utils/profileTabOptions";
import ProfileTabPanel from "./ProfileTabPanel";

function ProfileThumbnails() {
  const [tabIndex, setTabIndex] = useState(0);
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();

  const onTabChange = (index) => {
    setTabIndex(index);
  };

  return !isMobile ? (
    <Box flex={1}>
      <Tabs variant="unstyled" index={tabIndex} onChange={onTabChange}>
        <TabList paddingLeft="23px">
          {PROFILE_TAB_OPTIONS.map((option, index) => {
            const isActive = index === tabIndex;
            return (
              <Tab
                key={option.name}
                fontSize="15px"
                fontWeight="bold"
                padding="12px 31px"
                paddingTop="16px"
                bg={isActive ? "#F6F5F7" : "transparent"}
                borderRadius="12px"
                borderBottomLeftRadius="0px"
                borderBottomRightRadius="0px"
                _focus={{
                  outline: "none",
                }}
              >
                {t(`profile.${option.name}`)}
              </Tab>
            );
          })}
        </TabList>
        <TabPanels>
          <ProfileTabPanel type={PROFILE_TAB_OPTIONS[tabIndex].type} />
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    <>
      <Flex
        alignItems="center"
        marginLeft="-7.5px"
        marginRight="-7.5px"
        justifyContent="space-between"
        flexWrap="wrap"
        marginBottom="calc(40px - 7.5px)"
      >
        {PROFILE_TAB_OPTIONS.map((option, index) => {
          const isActive = index === tabIndex;
          return (
            <Flex
              key={option.name}
              fontSize="15px"
              fontWeight="bold"
              width="45%"
              height="60px"
              alignItems="center"
              margin="7.5px"
              justifyContent="center"
              bg={isActive ? "#1D20FF" : "#F6F5F7"}
              color={isActive ? "white" : "black"}
              borderRadius="12px"
              _focus={{
                outline: "none",
              }}
              onClick={() => onTabChange(index)}
            >
              {t(`profile.${option.name}`)}
            </Flex>
          );
        })}
      </Flex>
      <ProfileTabPanel type={PROFILE_TAB_OPTIONS[tabIndex].type} />
    </>
  );
}

export default ProfileThumbnails;
