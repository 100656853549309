import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useMoralis, useWeb3ExecuteFunction } from "react-moralis";
import useCoinPrice from "../../common/hooks/useCoinPrice";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import {
  APPROVAL_KEYS,
  CONTRACT_ADDRESS_MAP,
  CREATOR_ADDRESS,
  ENV,
  NETWORKS,
  OWNER_CUT,
} from "../../../constants";
import { ERC721_CONTRACT_ABI } from "../../../abis/tokenAbi";
import { useHistory } from "react-router-dom";
import useResponsive from "../../common/utils/useResponsive";
import { isFirefox } from "react-device-detect";
import Loading from "../../common/components/Loading";
import ThankyouContainer from "../../create/components/ThankyouContainer";
import { ITEM_STATUS } from "../../common/utils/itemStatus";
import { useTranslation } from "react-i18next";
import { sendEmail } from "../../common/utils/sendEmail";
import { getEmailBody } from "../../common/utils/getEmailBody";
import ROUTE_NAMES from "../../../routes/utils/routeNames";

const IMAGE_WIDTH = 100;
const IMAGE_WIDTH_TABLET = 75;

function ArtDetailBuyNowModal({ item, isOpen, onClose }) {
  const { isTablet, isMobile, isDesktop } = useResponsive();

  const { t, i18n } = useTranslation();
  const toast = useToast();

  const { Moralis, user, isWeb3Enabled, chainId: _chainId } = useMoralis();

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const { imageSrc, price, owner, creator, creatorAccount, chainId } = item;

  const ethPrice = Moralis.Units.FromWei(price?.toString());

  const coinPrice = useCoinPrice(ethPrice, chainId);

  const totalPrice = Number(price) + (Number(price) * OWNER_CUT) / 10000;
  const totalPriceEth = Moralis.Units.FromWei(totalPrice?.toString());
  const totalCoinPrice = useCoinPrice(totalPriceEth, chainId);

  const serviceFeeAmount = (Number(price) * OWNER_CUT) / 10000;

  const serviceFeeEthPrice = Moralis.Units.FromWei(
    parseInt((totalPrice - price).toString()).toString()
  );
  const serviceFeeCoinPrice = useCoinPrice(serviceFeeEthPrice, chainId);

  const checkIsApprovedForAll = async () => {
    const isApprovedForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "isApprovedForAll",
      params: {
        owner: user?.get("ethAddress"),
        operator: CONTRACT_ADDRESS_MAP[chainId],
      },
    };

    let result;

    const transaction = await Moralis.executeFunction(isApprovedForAllOps);

    try {
      result = await transaction.wait();
    } catch (error) {
      if (error.code === "TRANSACTION_REPLACED") {
        if (!error.cancelled) {
          result = error.replacement;
        }
      }
    }

    return transaction;
  };

  const setApprovalForAll = async () => {
    const approvalForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "setApprovalForAll",
      params: {
        operator: CONTRACT_ADDRESS_MAP[chainId],
        approved: true,
      },
    };

    try {
      const transaction = await Moralis.executeFunction(approvalForAllOps);

      let result;

      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      return result;
    } catch (error) {
      return false;
    }
  };

  const ensureMarketIsApproved = async () => {
    let isApproved = await checkIsApprovedForAll();

    if (!isApproved) {
      try {
        const approvalResult = await setApprovalForAll();
        return approvalResult;
      } catch (error) {
        return false;
      }
    }

    return isApproved;
  };

  const onConfirm = async () => {
    try {
      setLoading(true);

      const isApproved = await ensureMarketIsApproved();

      if (!isApproved) {
        toast({
          title: "Must approve first to use MENNIN",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });

        setLoading(false);

        return;
      }

      const { chainType } = item;

      const parsedRoyalty = JSON.parse(item.royalty);

      let ops;

      switch (chainType) {
        case "off-chain": {
          ops = {
            contractAddress: CONTRACT_ADDRESS_MAP[chainId],
            functionName: "buyOffchain",
            abi: ERC721_CONTRACT_ABI,
            params: {
              price: price?.toString(),
              data: {
                tokenId: Number(item.tokenId),
                tokenURI: item.tokenURI,
                creatorAddress: creatorAccount,
                royalty: parsedRoyalty.amount?.toString(),
              },
              ownerCut: (Number(totalPrice) - Number(price)).toString(),
              creator: CREATOR_ADDRESS,
            },
            msgValue: totalPrice?.toString(),
          };

          break;
        }

        case "on-chain": {
          ops = {
            contractAddress: CONTRACT_ADDRESS_MAP[chainId],
            functionName: "buyOnChain",
            abi: ERC721_CONTRACT_ABI,
            params: {
              tokenId: Number(item?.tokenId),
              price: price?.toString(),
              seller: owner?.get("ethAddress"),
              artist: creator?.get("ethAddress"),
              royalty: parsedRoyalty.amount?.toString(),
              ownerCut: (Number(totalPrice) - Number(price)).toString(),
              creator: CREATOR_ADDRESS,
            },
            msgValue: totalPrice?.toString(),
          };
        }

        default: {
          break;
        }
      }

      let result;
      const transaction = await Moralis.executeFunction(ops);

      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      const getSeller = await Moralis.Cloud.run("getUser", {
        account: item?.ownerAccount,
      });

      if (getSeller?.get("email") && getSeller?.get("checkNotification")) {
        sendEmail({
          email_subject: t("email.itemSold"),
          receiver: getSeller?.get("email"),
          emailBody: getEmailBody(
            t("email.itemSold"),
            item?.isVideo,
            imageSrc,
            "rgb(2, 78, 173)",
            t("email.itemSoldBody1") +
              ` <strong>${ethPrice} ${NETWORKS[ENV]?.[chainId]?.unit}</strong> ` +
              t("email.itemSoldBody2"),
            "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
            t("email.viewItem")
          ),
        });
      }

      setTimeout(() => {
        sendEmail({
          email_subject: "NFT sold",
          receiver: "mennintrade@gmail.com",
          emailBody: getEmailBody(
            t("email.itemSold"),
            item?.isVideo,
            imageSrc,
            "rgb(2, 78, 173)",
            t("email.itemSoldBody1") +
              ` <strong>${ethPrice} ${NETWORKS[ENV]?.[chainId]?.unit}</strong> ` +
              t("email.itemSoldBody2"),
            "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
            t("email.viewItem")
          ),
        });
      }, 1000);

      const getCreator = await Moralis.Cloud.run("getUser", {
        account: creatorAccount,
      });

      if (getCreator?.get("email") && getCreator?.get("checkNotification")) {
        setTimeout(() => {
          sendEmail({
            email_subject: t("email.royaltyReceived"),
            receiver: getCreator?.get("email"),
            emailBody: getEmailBody(
              t("email.royaltyReceived"),
              item?.isVideo,
              imageSrc,
              "rgb(2, 78, 173)",
              t("email.royaltyReceivedBody1") +
                ` <strong>${ethPrice} ${NETWORKS[ENV]?.[chainId]?.unit}</strong> ` +
                t("email.royaltyReceivedBody2"),
              "https://mennin.net" + ROUTE_NAMES.market + "/" + item?.tokenId,
              t("email.viewItem")
            ),
          });
        }, 1000);
      }

      const confirmedItem = await Moralis.Cloud.run("getItem", {
        tokenId: Number(item?.tokenId),
      });

      const currentLowestPrice = confirmedItem?.get("lowestPrice");

      if (chainType !== "on-chain") {
        confirmedItem.set("chainType", "on-chain");
      }
      confirmedItem.set("price", price);
      if (currentLowestPrice > price) {
        confirmedItem.set("lowestPrice", price);
      }
      confirmedItem.set("status", ITEM_STATUS.EXHIBIT_ONLY.value);

      confirmedItem.set("owner", user);
      confirmedItem.set("ownerAccount", user?.get("ethAddress"));

      const savedItem = await confirmedItem.save();

      const nftTransfers = Moralis.Object.extend("NFTTransfers");
      const transfer = new nftTransfers();
      transfer.set("tokenId", item?.tokenId);
      transfer.set("value", price);
      transfer.set("from_address", item?.ownerAccount);
      transfer.set("from", owner);
      transfer.set("to_address", user?.get("ethAddress"));
      transfer.set("to", user);
      transfer.set("token_address", CONTRACT_ADDRESS_MAP[chainId]);
      transfer.set("chainId", chainId);
      transfer.set("item", savedItem);
      transfer.set("transaction_hash", result?.hash || result?.transactionHash);

      await transfer.save();

      const offersForThisItem = await Moralis.Cloud.run(
        "getOffersForThisItem",
        {
          tokenId: item?.tokenId,
          contractAddress: CONTRACT_ADDRESS_MAP[chainId],
        }
      );

      if (offersForThisItem?.length > 0) {
        for await (let offer of offersForThisItem) {
          offer.set("isRejected", true);
          await offer.save();
        }
      }

      toast({
        title: "Successfully transferred",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });

      setLoading(false);
      setIsDone(true);

      setTimeout(() => {
        onClose();
        history.goBack();
      }, 2000);
    } catch (error) {
      let errorMessage = "Error! Please try later";

      if (
        error.message?.indexOf("insufficient funds") > -1 ||
        error?.data?.message?.indexOf("insufficient funds")
      ) {
        errorMessage = "insufficient funds";
      }

      setLoading(false);
      onClose();
      toast({
        title: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const royalty = JSON.parse(item?.royalty);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={!isMobile}
      size={isMobile ? "full" : "xl"}
      closeOnEsc={!loading}
      closeOnOverlayClick={!loading}
      scrollBehavior="inside"
    >
      <ModalContent
        position="relative"
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
      >
        <ModalCloseButton
          top={isDesktop ? "57px" : "40px"}
          right={isDesktop ? "51px" : "40px"}
          size="23px"
          _hover={{ backgroundColor: "transparent" }}
          _focus={{ outline: "none" }}
        />
        <ModalBody
          paddingLeft={isDesktop ? "93px" : isTablet ? "69px" : "20px"}
          paddingRight={isDesktop ? "93px" : isTablet ? "69px" : "20px"}
          paddingBottom={isDesktop ? "50px" : "50px"}
          paddingTop={isDesktop ? "50px" : "50px"}
          overflowY="auto"
        >
          <Flex
            direction="column"
            w="100%"
            alignItems="center"
            justifyContent="center"
            spacing="0px"
          >
            <Box
              fontSize={!isTablet ? "25px" : "19px"}
              fontWeight="bold"
              color="#3E3E3E"
              marginBottom={isDesktop ? "25px" : isTablet ? "18px" : "40px"}
            >
              {t("buynow.buyNow")}
            </Box>
            <Box
              textAlign="center"
              fontSize={!isTablet ? "13px" : "9px"}
              fontWeight="light"
              color="#3E3E3E"
              marginBottom={
                isDesktop
                  ? "40px !important"
                  : isTablet
                  ? "35.8px !important"
                  : "25px !important"
              }
            >
              {t("buynow.reviewInformation")}
            </Box>
            <Box
              width="100%"
              height="3px"
              bg="#000000"
              mb={
                isDesktop
                  ? "50.93px !important"
                  : isTablet
                  ? "38.2px !important"
                  : "23.93px !important"
              }
            />
            <Flex
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={isDesktop ? "55.07px !important" : "42.38px !important"}
              paddingLeft={!isMobile ? "0px" : "20px"}
              paddingRight={!isMobile ? "0px" : "20px"}
            >
              <Flex alignItems="center">
                {!item?.isVideo ? (
                  <Image
                    src={imageSrc}
                    width={!isTablet ? IMAGE_WIDTH : IMAGE_WIDTH_TABLET}
                    borderRadius="19px"
                    objectFit="contain"
                    mr={isDesktop ? "25px" : isTablet ? "19px" : "43px"}
                  />
                ) : (
                  <video
                    src={imageSrc}
                    style={{
                      width: !isTablet ? IMAGE_WIDTH : IMAGE_WIDTH_TABLET,
                      borderRadius: 19,
                      marginRight: isDesktop
                        ? "25px"
                        : isTablet
                        ? "19px"
                        : "43px",
                      objectFit: "contain",
                    }}
                    playsInline
                    muted
                    autoPlay
                    loop
                  />
                )}
                <Box>
                  <Box
                    color="#1C20FE"
                    fontSize={!isTablet ? "20px" : "14px"}
                    fontWeight="bold"
                    mb={!isTablet ? "5px" : "4px"}
                  >
                    {item?.title}
                  </Box>
                  <Box
                    fontSize={!isTablet ? "15px" : "11px"}
                    color="#3E3E3E"
                    noOfLines={1}
                    w="100%"
                    wordBreak="break-all"
                  >
                    <span style={{ fontWeight: "bold" }}>Owner </span>
                    {item?.owner?.get("username")}
                  </Box>
                  <Box
                    fontSize={!isTablet ? "13px" : "10px"}
                    fontWeight="light"
                  >
                    {t("buynow.royalty")} {royalty?.amount / 100}%
                  </Box>
                </Box>
              </Flex>
              {!isMobile && (
                <Box textAlign="right">
                  <Box
                    fontSize={isDesktop ? "20px" : "14px"}
                    fontWeight="bold"
                    color="#3E3E3E"
                    width="fit-content"
                    textAlign="right"
                  >
                    {numberWithCommas(ethPrice)}{" "}
                    {NETWORKS[ENV]?.[item?.chainId]?.unit}
                  </Box>
                  <Box
                    fontSize={isDesktop ? "13px" : "9px"}
                    fontWeight="bold"
                    color="#949AB2"
                  >
                    {coinPrice}
                  </Box>
                </Box>
              )}
            </Flex>
            {isMobile && (
              <Box
                textAlign="right"
                mt="13px"
                w="100%"
                paddingLeft="20px"
                paddingRight="20px"
                mb="31.07px !important"
              >
                <Box
                  fontSize={!isTablet ? "20px" : "14px"}
                  fontWeight="bold"
                  color="#3E3E3E"
                  width="100%"
                  textAlign="right"
                >
                  {numberWithCommas(ethPrice)}{" "}
                  {NETWORKS[ENV]?.[item?.chainId]?.unit}
                </Box>
                <Box
                  fontSize={!isTablet ? "13px" : "9px"}
                  fontWeight="bold"
                  color="#949AB2"
                >
                  {coinPrice}
                </Box>
              </Box>
            )}
            <Box width="100%" height="3px" bg="#000000" />
            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              fontSize={isDesktop ? "15px" : isTablet ? "11px" : "15px"}
              w="100%"
              padding={!isTablet ? "16px 0px" : "20px 0px"}
            >
              <Flex
                alignItems={isMobile ? "flex-start" : "center"}
                direction={isMobile ? "column" : "row"}
              >
                <Box color="#3E3E3E" fontWeight="bold" mr="10px">
                  {t("buynow.serviceFee")}
                </Box>
                <Box color="#3E3E3E" fontWeight="light">
                  1.25%
                </Box>
              </Flex>
              <Box textAlign="right">
                <Box>
                  {numberWithCommas(serviceFeeEthPrice)}{" "}
                  {NETWORKS[ENV]?.[item?.chainId]?.unit}
                </Box>
                <Box fontWeight="light">{serviceFeeCoinPrice}</Box>
              </Box>
            </Flex>
            <Box
              width="100%"
              height="3px"
              bg="#000000"
              mb={isDesktop ? "31px" : isTablet ? "20px" : "45px"}
            />

            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              width="100%"
              mb={!isTablet ? "20px !important" : "30px !important"}
            >
              <Box
                fontSize={!isTablet ? "20px" : "14px"}
                fontWeight="bold"
                color="#1C20FE"
              >
                {t("buynow.total")}
              </Box>
              <Box textAlign="right">
                <Box
                  fontSize={!isTablet ? "25px" : "19px"}
                  fontWeight="bold"
                  color="#3E3E3E"
                >
                  {numberWithCommas(totalPriceEth)}{" "}
                  {NETWORKS[ENV]?.[item?.chainId]?.unit}
                </Box>
                <Box
                  fontSize={!isTablet ? "15px" : "11px"}
                  fontWeight="bold"
                  color="#949AB2"
                >
                  {totalCoinPrice}
                </Box>
              </Box>
            </Flex>

            <Button
              width="100%"
              bg="#1D20FF 0% 0% no-repeat padding-box"
              color="white"
              borderRadius="12px"
              padding={
                isDesktop ? "22px 0px" : isTablet ? "16px 0px" : "21px 0px"
              }
              fontSize={isDesktop ? "12px" : isTablet ? "11px" : "15px"}
              fontWeight="bold"
              boxShadow="0px 35px 40px -20px rgba(0,0,0,0.5)"
              mb="30px"
              _hover={{
                backgroundColor: "#1d20ff",
              }}
              _focus={{
                outline: "none",
              }}
              onClick={onConfirm}
              disabled={loading}
            >
              {t("buynow.confirm")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
      {(loading || isDone) && (
        <Flex
          position="fixed"
          direction="column"
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          zIndex={1500}
          bg="white"
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <Box w="100%" textAlign="center">
              <Box
                fontSize={isDesktop ? "25px" : isTablet ? "16px" : "20px"}
                fontWeight="extrabold"
                mb={isDesktop ? "10px" : isTablet ? "8px" : "3px"}
              >
                {t("global.purchaseLoading")}
              </Box>
              <Loading width={isDesktop ? "60%" : isTablet ? "80%" : "90%"} />
            </Box>
          )}
          {isDone && (
            <Box w="90%">
              <ThankyouContainer isGoingBack={true} />
            </Box>
          )}
        </Flex>
      )}
    </Modal>
  );
}

export default ArtDetailBuyNowModal;
