import { createSlice } from "@reduxjs/toolkit";

export const profileTradingHistorySlice = createSlice({
  name: "profileTradingHistory",
  initialState: {
    profileTradingHistoryUserAccount: null,
    profileTradingHistory: null,
    itemsCount: 0,
  },
  reducers: {
    setProfileTradingUserAccount: (state, action) => {
      state.profileTradingHistoryUserAccount = action.payload;
    },
    setProfileTradingHistory: (state, action) => {
      state.profileTradingHistory = action.payload;
    },
    setProfileTradingHistoryCount: (state, action) => {
      state.itemsCount = action.payload;
    },
  },
});

export const {
  setProfileTradingHistory,
  setProfileTradingUserAccount,
  setProfileTradingHistoryCount,
} = profileTradingHistorySlice.actions;

export default profileTradingHistorySlice.reducer;
