import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";

function HomeImageModal({ isOpen, onClose, image }) {
  const { isTablet, isMobile, isDesktop } = useResponsive();
  const history = useHistory();

  const viewMoreOnClick = () => {
    history.push(ROUTE_NAMES.market + `/${image?.tokenId}`);
  };

  const renderDesktop = () => {
    return (
      <>
        <Box
          fontSize="20px"
          fontWeight="extrabold"
          color="#949AB2"
          mb="25px"
          textAlign="center"
          cursor="pointer"
          onClick={viewMoreOnClick}
          zIndex={2}
          mt="-100px"
        >
          View More
        </Box>
        <Box position="relative">
          <Box cursor="pointer" onClick={viewMoreOnClick}>
            {!image?.isVideo ? (
              <img
                className="lazyload"
                src={image?.nftFilePath}
                style={{
                  width: 400,
                  height: 550,
                  borderRadius: 14,
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                className="lazyload"
                src={image?.nftFilePath}
                style={{
                  width: 400,
                  height: 550,
                  objectFit: "cover",
                  borderRadius: "14px",
                }}
                autoPlay
                loop
                muted
                playsInline
              />
            )}
          </Box>
          <Box
            position="absolute"
            top="19px"
            left="8%"
            right="8%"
            height="100%"
            bg="#000000 0% 0% no-repeat padding-box"
            filter="blur(30px)"
            zIndex={-1}
          />
        </Box>
        <Box
          position="absolute"
          left="0px"
          right="0px"
          top="50%"
          transform="translateY(-65%)"
          bg="transparent"
          height="30%"
          backdropFilter="blur(28px)"
          zIndex="-1"
        />
      </>
    );
  };

  const renderTablet = () => {
    return (
      <Flex direction="column" alignItems="center">
        <Box
          position="absolute"
          top="0px"
          bottom="0px"
          left="50%"
          transform="translateX(-50%)"
          bg="transparent"
          width="20%"
          backdropFilter="blur(28px)"
          zIndex="-1"
        />
        <Box position="relative" marginTop="-30px">
          <Box cursor="pointer" onClick={viewMoreOnClick}>
            {!image?.isVideo ? (
              <img
                className="lazyload"
                src={image?.nftFilePath}
                style={{
                  width: 346,
                  height: 469,
                  borderRadius: 14,
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                className="lazyload"
                src={image?.nftFilePath}
                style={{
                  width: 346,
                  height: 469,
                  objectFit: "cover",
                  borderRadius: "14px",
                }}
                autoPlay
                loop
                muted
                playsInline
              />
            )}
          </Box>
          <Box
            position="absolute"
            top="19px"
            left="8%"
            right="8%"
            height="100%"
            bg="#000000 0% 0% no-repeat padding-box"
            filter="blur(30px)"
            zIndex={-1}
          />
          <Box
            position="absolute"
            left="346px"
            top="50%"
            marginLeft="25px"
            fontSize="17px"
            fontWeight="extrabold"
            color="#949AB2"
            textAlign="center"
            cursor="pointer"
            onClick={viewMoreOnClick}
            zIndex={2}
            width="fit-content"
          >
            View More
          </Box>
        </Box>
      </Flex>
    );
  };

  const renderMobile = () => {
    return (
      <>
        <Box
          position="relative"
          mb="55px"
          cursor="pointer"
          onClick={viewMoreOnClick}
        >
          {!image?.isVideo ? (
            <img
              className="lazyload"
              src={image?.nftFilePath}
              style={{
                width: 290,
                borderRadius: 14,
                objectFit: "cover",
              }}
            />
          ) : (
            <video
              className="lazyload"
              src={image?.nftFilePath}
              style={{
                width: 290,
                objectFit: "cover",
                borderRadius: "14px",
              }}
              autoPlay
              loop
              muted
              playsInline
            />
          )}
        </Box>
        <Box
          fontSize="15px"
          fontWeight="extrabold"
          color="#949AB2"
          textAlign="center"
          cursor="pointer"
          onClick={viewMoreOnClick}
          zIndex={2}
        >
          View More
        </Box>
      </>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      isCentered
      closeOnOverlayClick
      size="full"
    >
      <ModalOverlay bg="transparent" />
      <ModalContent bg="transparent" boxShadow="none">
        <ModalBody
          bg="transparent"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          position="relative"
          backdropFilter={isMobile ? "blur(28px)" : "none"}
        >
          <Box
            position="absolute"
            left="0"
            right="0"
            top="0"
            bottom="0"
            bg="transparent"
            zIndex={0}
            onClick={onClose}
          />
          {isDesktop && renderDesktop()}
          {isTablet && renderTablet()}
          {isMobile && renderMobile()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default HomeImageModal;
