import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import React, { useRef } from "react";

import createIcon from "../../../assets/icons/create_sample.svg";
import useResponsive from "../../common/utils/useResponsive";

import plusIcon from "../../../assets/icons/plus.svg";
import { Image } from "@chakra-ui/react";
import CreateFileInfo from "./CreateFileInfo";

const UPLOADER_HEIGHT = 600;
const UPLOADER_HEIGHT_TABLET = 380;
const UPLOADER_HEIGHT_MOBILE = 460;

function CreateUploader({ tempFile, onFileChange, editDisabled, isVideo }) {
  const { isTablet, isDesktop, isMobile } = useResponsive();

  const fileInputRef = useRef();

  const onAddFileClick = () => {
    fileInputRef?.current?.click();
  };

  return (
    <Box
      flex={1.2}
      position="relative"
      flexDir="column"
      justifyContent="space-between"
      boxSizing="border-box"
    >
      <Flex
        w="100%"
        height={
          isDesktop
            ? UPLOADER_HEIGHT + "px"
            : isTablet
            ? UPLOADER_HEIGHT_TABLET + "px"
            : UPLOADER_HEIGHT_MOBILE + "px"
        }
        bg="#949AB2 0% 0% no-repeat padding-box"
        borderRadius="16px"
        justifyContent="center"
        alignItems="center"
        position="relative"
        boxSizing="border-box"
        mb="53px"
      >
        {!tempFile && (
          <img
            src={createIcon}
            style={{ width: "30%", objectFit: "contain" }}
          />
        )}
        {tempFile &&
          (!isVideo ? (
            <img
              src={tempFile}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: "19px",
              }}
            />
          ) : (
            <video
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 19,
              }}
              autoPlay
              loop
              muted
              playsInline
              src={tempFile}
            ></video>
          ))}
        {!editDisabled && (
          <Button
            w="90%"
            bg="white"
            position="absolute"
            bottom="5%"
            left="50%"
            borderRadius={isMobile ? "19px" : isTablet ? "10px" : "17px"}
            height={isMobile ? "46px" : isTablet ? "34px" : "63px"}
            transform="translateX(-50%)"
            boxShadow="0px 20px 20px #0000002E"
            _hover={{ backgroundColor: "white" }}
            _focus={{
              outline: "none",
            }}
            _active={{
              outline: "none",
              backgroundColor: "white",
              boxShadow: "none",
            }}
            onClick={onAddFileClick}
          >
            <Image
              src={plusIcon}
              width={isMobile ? "21px" : isTablet ? "15px" : "28px"}
              height={isMobile ? "21px" : isTablet ? "15px" : "28px"}
            />
          </Button>
        )}
        <input
          ref={fileInputRef}
          type="file"
          onChange={onFileChange}
          accept=".jpg,.jpeg,.png,.gif,.webp,.mp4,.mp3,.wav,.svg,.GLB"
        />
        <Box
          position="absolute"
          zIndex={-1}
          top="5%"
          left="50%"
          transform="translateX(-50%)"
          bg="#949AB2 0% 0% no-repeat padding-box"
          filter="blur(33px)"
          w="80%"
          h="100%"
        ></Box>
      </Flex>
      {isDesktop && <CreateFileInfo />}
    </Box>
  );
}

export default CreateUploader;
