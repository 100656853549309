import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Input,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";

import {
  addFaqPage,
  setCurrentSection,
  setFaqCount,
  setFaqLanguage,
  setFaqs,
  setFaqSearchTerm,
  setTutorials,
} from "../../lib/redux/reducers/tutorial/tutorialReducer";
import { DESKTOP_NAVIGATION_HEIGHT } from "../../utilities/constants";
import Loading from "../common/components/Loading";
import useResponsive from "../common/utils/useResponsive";
import TutorialFAQ from "./components/TutorialFAQ";
import TopLogo from "../auth/components/TopLogo";
import arrowDown from "../../assets/icons/arrow_down_blue.svg";
import arrowUp from "../../assets/icons/arrow_up_blue.svg";
import { useTranslation } from "react-i18next";
import searchIcon from "../../assets/icons/magnifying-glass_white.png";

const deviceOptions = {
  Mobile: "Mobile",
  Tablet: "Tablet",
  Desktop: "Desktop",
};

const NAV_DESKTOP_MIN_WIDTH = "300px";
const NAV_TABLET_MIN_WIDTH = "220px";

const PAGE_SIZE = 6;

function TutorialContainer() {
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { Moralis, isInitialized } = useMoralis();
  const { i18n } = useTranslation();

  const wrapperRef = useRef();
  const dropdownRef = useRef();

  const [loading, setLoading] = useState(false);
  const [faqLoading, setFaqLoading] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const [showMobileDropdown, setShowMobileDropdown] = useState(false);

  useOutsideClick({
    ref: dropdownRef,
    handler: () => setShowMobileDropdown(false),
  });

  const sections = JSON.parse(useSelector((state) => state.tutorial.tutorials));
  const currentSection = useSelector((state) => state.tutorial.currentSection);
  const faqs = JSON.parse(useSelector((state) => state.tutorial.faqs));
  const faqPage = useSelector((state) => state.tutorial.faqPage);
  const faqCount = useSelector((state) => state.tutorial.faqCount);
  const faqLanguage = useSelector((state) => state.tutorial.faqLanguage);
  const faqSearchTerm = useSelector((state) => state.tutorial.faqSearchTerm);

  const dispatch = useDispatch();

  const getDeviceOptions = () => {
    if (isMobile) return deviceOptions.Mobile;
    if (isTablet) return deviceOptions.Tablet;
    if (isDesktop) return deviceOptions.Desktop;
  };

  const fetchTutorial = async () => {
    setLoading(true);

    // add fetched language to refetch when language is changed
    const tutorialQuery = await Moralis.Cloud.run("getTutorial", {
      device: getDeviceOptions(),
      language: i18n.language?.slice(0, 2),
    });

    console.log(tutorialQuery);

    const fetchedSections = tutorialQuery.reduce((prev, current) => {
      const section = current?.get("section");

      if (prev?.[section]) {
        prev[section].push(current);
      } else {
        prev[section] = [current];
      }
      return prev;
    }, {});

    console.log(fetchedSections);

    dispatch(setTutorials(JSON.stringify(fetchedSections)));
    dispatch(setCurrentSection(Object.keys(fetchedSections)?.[0]));

    setLoading(false);
  };

  const fetchFAQ = async () => {
    setFaqLoading(true);
    const faqQuery = await Moralis.Cloud.run("getFAQs", {
      page: faqPage,
      pageSize: PAGE_SIZE,
      language: i18n.language?.slice(0, 2),
    });

    dispatch(setFaqs(JSON.stringify(faqQuery?.results)));
    dispatch(setFaqCount(faqQuery?.count));
    dispatch(setFaqLanguage(i18n.language?.slice(0, 2)));

    setFaqLoading(false);
  };

  const fetchMoreFaq = async () => {
    if (!fetchingMore && faqCount > faqs?.length) {
      setFetchingMore(true);
      const faqQuery = await Moralis.Cloud.run("getFAQs", {
        page: faqPage + 1,
        pageSize: PAGE_SIZE,
        language: i18n.language?.slice(0, 2),
      });

      const editedFaqs = [...faqs, ...faqQuery?.results];
      dispatch(addFaqPage(faqPage + 1));
      dispatch(setFaqs(JSON.stringify(editedFaqs)));

      setFetchingMore(false);
    }
  };

  useEffect(() => {
    if (isInitialized && !sections) {
      fetchTutorial();
    }
  }, [isMobile, isDesktop, isTablet, isInitialized, sections, i18n?.language]);

  useEffect(() => {
    if (faqs && faqLanguage !== i18n.language?.slice(0, 2)) {
      fetchFAQ();
    }
  }, [faqs, i18n.language, faqLanguage]);

  const onSidebarClick = async (section) => {
    if (section !== "FAQ") {
      dispatch(setCurrentSection(section));

      setTimeout(() => {
        const selectedDiv = document.getElementById(section);
        selectedDiv?.scrollIntoView({ behavior: "smooth" });
      }, 2);
    } else {
      dispatch(setCurrentSection(section));
      wrapperRef?.current?.scrollTo(0, 0);

      if (!faqs) {
        await fetchFAQ();
      }
    }

    if (isMobile) {
      setShowMobileDropdown(false);
    }
  };

  const searchFAQ = async () => {
    setLoading(true);
    const searchQuery = await Moralis.Cloud.run("searchFAQs", {
      q: faqSearchTerm,
      language: i18n.language?.slice(0, 2),
    });

    const { results, count } = searchQuery;

    dispatch(setFaqCount(count));
    dispatch(setFaqs(JSON.stringify(results)));
    setLoading(false);
  };

  if (loading)
    return (
      <Box w="100vw" h="80vh">
        <Loading />
      </Box>
    );

  return !isMobile ? (
    <Flex
      alignItems="flex-start"
      position="absolute"
      top="0"
      left="0"
      right="0"
      bottom="0"
      zIndex={0}
    >
      <Box
        minWidth={isDesktop ? NAV_DESKTOP_MIN_WIDTH : NAV_TABLET_MIN_WIDTH}
        bg="#F6F5F7"
        height="100%"
        paddingTop={DESKTOP_NAVIGATION_HEIGHT + (isDesktop ? 150 : 110)}
        paddingLeft="60px"
        boxSizing="border-box"
      >
        {sections && (
          <>
            {Object.keys(sections)?.map((section) => {
              const isCurrent = section === currentSection;

              return (
                <Box
                  key={section}
                  padding={isDesktop ? "29px 40px" : "16px 22px"}
                  bg={isCurrent ? "white" : "#F6F5F7"}
                  borderRadius="15px"
                  borderTopRightRadius="0px"
                  borderBottomRightRadius="0px"
                  fontSize={isDesktop ? "20px" : "11px"}
                  fontWeight="extrabold"
                  cursor="pointer"
                  color={isCurrent ? "#1D20FF" : "#949AB2"}
                  onClick={() => onSidebarClick(section)}
                >
                  {section}
                </Box>
              );
            })}
            <Box
              padding={isDesktop ? "29px 40px" : "16px 22px"}
              bg={currentSection === "FAQ" ? "white" : "#F6F5F7"}
              borderRadius="15px"
              borderTopRightRadius="0px"
              borderBottomRightRadius="0px"
              fontSize={isDesktop ? "20px" : "11px"}
              fontWeight="extrabold"
              cursor="pointer"
              color={currentSection === "FAQ" ? "#1D20FF" : "#949AB2"}
              onClick={() => onSidebarClick("FAQ")}
            >
              FAQ
            </Box>
          </>
        )}
      </Box>
      <Box
        flex={1}
        ref={wrapperRef}
        paddingTop={DESKTOP_NAVIGATION_HEIGHT + (isDesktop ? 0 : 40)}
        overflowY="auto"
        paddingLeft={isDesktop ? "181px" : "30px"}
        paddingRight={isDesktop ? "181px" : "30px"}
        height="100%"
      >
        <Flex
          fontSize={isDesktop ? "50px" : "23px"}
          fontWeight="extrabold"
          marginBottom={isDesktop ? "79px" : "40px"}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>{currentSection !== "FAQ" ? "Tutorial" : "FAQ"}</Box>
          {currentSection === "FAQ" && !isMobile && (
            <Flex alignItems="center">
              <Input
                borderRadius="9px"
                padding="16px 30px"
                bg="#F6F5F7"
                placeholder="Search"
                fontSize={isDesktop ? "15px" : "9px"}
                fontWeight="extrabold"
                w={isDesktop ? "350px" : "190px"}
                h={isDesktop ? "50px" : "27px"}
                mr={isDesktop ? "20px" : "15px"}
                _placeholder={{
                  color: "#949AB2",
                }}
                border="none"
                _focus={{ outline: "none" }}
                value={faqSearchTerm}
                onChange={(e) => {
                  const { value } = e.target;
                  dispatch(setFaqSearchTerm(value));
                }}
                onKeyPress={async (e) => {
                  const { key } = e;

                  if (key === "Enter") {
                    await searchFAQ();
                  }
                }}
              />
              <Button
                bg="#1D20FF"
                w={isDesktop ? "69px" : "34px"}
                h={isDesktop ? "50px" : "27px"}
                padding="0px"
                borderRadius="9px"
                _hover={{
                  backgroundColor: "#1D20FF",
                }}
                _active={{
                  background: "#1D20FF",
                  outline: "none",
                }}
                _focus={{
                  background: "#1D20FF",
                  outline: "none",
                }}
                onClick={searchFAQ}
              >
                <Image
                  src={searchIcon}
                  boxSize={isDesktop ? "30px" : "60%"}
                  objectFit="contain"
                />
              </Button>
            </Flex>
          )}
        </Flex>
        {currentSection !== "FAQ" &&
          sections &&
          Object.keys(sections)?.map((section) => (
            <Box
              key={sections[section]?.[0]?.objectId}
              id={section}
              maxWidth="1220px"
              margin="0px auto"
            >
              {sections[section]?.map((item) => {
                return (
                  <Image
                    src={item?.image?.url}
                    key={item?.order}
                    width="100%"
                    objectFit="contain"
                  />
                );
              })}
            </Box>
          ))}
        {currentSection === "FAQ" &&
          (faqLoading ? (
            <Loading />
          ) : (
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchMoreFaq}
              hasMore={faqCount > faqs?.length}
              useWindow={false}
              getScrollParent={() => wrapperRef?.current}
            >
              {faqs &&
                faqs?.map((faq) => (
                  <TutorialFAQ key={faq?.objectId} faq={faq} />
                ))}
            </InfiniteScroll>
          ))}
      </Box>
    </Flex>
  ) : (
    <Container>
      <TopLogo />
      <Box
        fontSize="35px"
        fontWeight="extrabold"
        color="#3E3E3E"
        mb="15px"
        mt="50px"
      >
        {currentSection !== "FAQ" ? "Tutorial" : "FAQ"}
      </Box>
      <Box position="relative" ref={dropdownRef} mb="30px">
        <Flex
          bg="#F6F5F7"
          borderRadius="9px"
          boxShadow={showMobileDropdown ? "0px 5px 6px #00000029" : "none"}
          padding="16px 15px"
          fontSize="15px"
          fontWeight="extrabold"
          alignItems="center"
          justifyContent="space-between"
          zIndex={100}
          color="#1D20FF"
          borderBottomRightRadius={showMobileDropdown ? "0px" : "9px"}
          borderBottomLeftRadius={showMobileDropdown ? "0px" : "9px"}
          onClick={() => setShowMobileDropdown(!showMobileDropdown)}
        >
          <Box>{currentSection}</Box>
          <Image src={showMobileDropdown ? arrowUp : arrowDown} />
        </Flex>
        {showMobileDropdown && (
          <Box
            position="absolute"
            top="100%"
            left="0"
            right="0"
            padding="20px 35px"
            bg="#F6F5F7"
            boxShadow="0px 5px 6px #00000029"
            borderBottomLeftRadius="9px"
            borderBottomRightRadius="9px"
            zIndex={100}
          >
            {Object.keys(sections)
              ?.filter((section) => section !== currentSection)
              ?.map((section) => {
                return (
                  <Box
                    key={section}
                    padding="7.5px 0px"
                    fontSize="15px"
                    fontWeight="extrabold"
                    color="#949AB2"
                    onClick={() => onSidebarClick(section)}
                  >
                    {section}
                  </Box>
                );
              })}
            <Box
              padding="7.5px 0px"
              fontSize="15px"
              fontWeight="extrabold"
              color="#949AB2"
              onClick={() => onSidebarClick("FAQ")}
            >
              FAQ
            </Box>
          </Box>
        )}
      </Box>
      {currentSection !== "FAQ" &&
        sections &&
        Object.keys(sections)?.map((section) => (
          <Box key={sections[section]?.[0]?.objectId} id={section}>
            {sections[section]?.map((item) => {
              return <Image src={item?.image?.url} key={item?.order} />;
            })}
          </Box>
        ))}
      {currentSection === "FAQ" &&
        (faqLoading ? (
          <Loading />
        ) : (
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchMoreFaq}
            hasMore={faqCount > faqs?.length}
            useWindow={false}
          >
            {faqs &&
              faqs?.map((faq) => <TutorialFAQ key={faq?.objectId} faq={faq} />)}
          </InfiniteScroll>
        ))}
    </Container>
  );
}

export default TutorialContainer;
