import { Box, Flex, Image, useOutsideClick } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import arrowIcon from "../../../assets/icons/down_arrow.svg";
import detailIcon from "../../../assets/icons/art_detail.svg";
import useResponsive from "../../common/utils/useResponsive";
import { useMoralis } from "react-moralis";
import { ENV, ETHERSCAN_ADDRESS_URLS, NETWORKS } from "../../../constants";
import { useTranslation } from "react-i18next";

const DETAIL_ICON_SIZE = "29px";
const DETAIL_ICON_SIZE_TABLET = "27px";

const ARROW_WIDTH = "16px";
const ARROW_WIDTH_TABLET = "14px";
const ARROW_HEIGHT = "12px";
const ARROW_HEIGHT_TABLET = "10px";

function ArtDetailDetail({ item }) {
  const { isDesktop, isTablet, isMobile } = useResponsive();
  const { t } = useTranslation();

  const wrapperRef = useRef();

  const { chainId } = item;
  const [showDetail, setShowDetail] = useState(false);

  useOutsideClick({
    ref: wrapperRef,
    handler: () => setShowDetail(false),
  });

  const onTokenAddressClick = () => {
    const url =
      chainId === "0x1"
        ? ETHERSCAN_ADDRESS_URLS[chainId] +
          "/nft" +
          `/${item?.contractAddress}` +
          `/${item?.tokenId}`
        : ETHERSCAN_ADDRESS_URLS[chainId] +
          "/token" +
          `/${item?.contractAddress}` +
          `?a=${item?.tokenId}`;

    window.open(url);
  };

  return (
    <Box
      padding={isDesktop ? "25px 45px" : isTablet ? "21px 40px" : "20px 18px"}
      bg="#F6F5F7"
      borderRadius="19px"
      position="relative"
    >
      <Box position="relative" ref={wrapperRef}>
        <Flex
          bg="white"
          borderRadius="9px"
          borderBottomLeftRadius={showDetail ? "0px" : "9px"}
          borderBottomRightRadius={showDetail ? "0px" : "9px"}
          alignItems="center"
          justifyContent="space-between"
          padding="10px 15px"
          onClick={() => setShowDetail(!showDetail)}
          position="relative"
          cursor="pointer"
        >
          <Flex alignItems="center">
            <Image
              src={detailIcon}
              width={!isTablet ? DETAIL_ICON_SIZE : DETAIL_ICON_SIZE_TABLET}
              height={!isTablet ? DETAIL_ICON_SIZE : DETAIL_ICON_SIZE_TABLET}
              marginRight="20px"
            />
            <Box
              fontSize={!isTablet ? "15px" : "11px"}
              fontWeight="extrabold"
              color="#949AB2"
            >
              {t("item.detail")}
            </Box>
          </Flex>
          <Image
            src={arrowIcon}
            width={!isTablet ? ARROW_WIDTH : ARROW_WIDTH_TABLET}
            height={!isTablet ? ARROW_HEIGHT : ARROW_HEIGHT_TABLET}
          />
        </Flex>
        {showDetail && (
          <Box
            position="absolute"
            bg="white"
            top="100%"
            left="0px"
            right="0px"
            padding={
              isDesktop ? "22px 64px" : isTablet ? "10px 62px" : "26px 15px"
            }
            boxSizing="border-box"
            fontSize={!isTablet ? "15px" : "11px"}
            color="#949AB2"
            zIndex={10}
            borderBottomLeftRadius="9px"
            borderBottomRightRadius="9px"
          >
            <Flex
              alignItems="center"
              justifyContent="space-between"
              margin={
                isDesktop ? "6px 0px" : isTablet ? "10px 0px" : "7.5px 0px"
              }
            >
              <Box flex={1}>Token Address</Box>
              <Box
                color={item?.chainType === "on-chain" ? "#1D20FF" : "inherit"}
                flex={1}
                noOfLines={1}
                textAlign="right"
                cursor="pointer"
                onClick={onTokenAddressClick}
                pointerEvents={item?.chainType === "on-chain" ? "auto" : "none"}
              >
                {item?.contractAddress}
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              margin={
                isDesktop ? "6px 0px" : isTablet ? "10px 0px" : "7.5px 0px"
              }
            >
              <Box flex={1}>Token ID</Box>
              <Box flex={1} noOfLines={1} textAlign="right">
                {item?.tokenId}
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              margin={
                isDesktop ? "6px 0px" : isTablet ? "10px 0px" : "7.5px 0px"
              }
            >
              <Box>Token Standard</Box>
              <Box>{item?.tokenStandard}</Box>
            </Flex>
            <Flex
              alignItems="center"
              justifyContent="space-between"
              margin={
                isDesktop ? "6px 0px" : isTablet ? "10px 0px" : "7.5px 0px"
              }
            >
              <Box>BlockChain</Box>
              <Box>{NETWORKS[ENV][chainId].name}</Box>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ArtDetailDetail;
