import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Box, Flex } from "@chakra-ui/layout";
import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import XRegExp from "xregexp";

import instagramIcon from "../../../../assets/icons/instagram.svg";
import twitterIcon from "../../../../assets/icons/twitter.svg";
import discordIcon from "../../../../assets/icons/discord.svg";
import burgerIcon from "../../../../assets/icons/burger.svg";
import { Image } from "@chakra-ui/image";
import { Input } from "@chakra-ui/input";
import { Button } from "@chakra-ui/react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import useResponsive from "../../../common/utils/useResponsive";
import checkboxIcon from "../../../../assets/icons/checkbox.svg";
import checkIcon from "../../../../assets/icons/check.svg";

const SNS_ICONS = {
  Instagram: instagramIcon,
  Discord: discordIcon,
  Twitter: twitterIcon,
};

function UpdateProfileSns({ profile, setProfile }) {
  const { t } = useTranslation();
  const { isMobile } = useResponsive();

  const { snsLinks, email, snsErrors, emailError, checkNotification } = profile;

  // a little function to help us with reordering the result
  const reorder = (startIndex, endIndex) => {
    const result = Array.from(snsLinks);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(result.source.index, result.destination.index);

    setProfile((prev) => ({
      ...prev,
      snsLinks: items,
    }));
  };

  const onUrlChange = (e, index) => {
    const { value } = e.target;

    const editedSnsLinks = [...snsLinks];

    editedSnsLinks[index].url = value;

    setProfile((prev) => ({
      ...prev,
      snsLinks: editedSnsLinks,
    }));
  };

  const onSnsFocus = () => {
    if (snsErrors?.length > 0) {
      setProfile((prev) => ({
        ...prev,
        snsErrors: null,
      }));
    }
  };

  const onEmailChange = (e) => {
    const { value } = e.target;

    setProfile((prev) => ({
      ...prev,
      email: value,
    }));
  };

  const onEmailFocus = () => {
    if (emailError) {
      setProfile((prev) => ({
        ...prev,
        emailError: false,
      }));
    }
  };

  const onCheckClick = () => {
    setProfile((prev) => ({
      ...prev,
      checkNotification: !profile?.checkNotification,
    }));
  };

  function renderDesktop() {
    return snsLinks.map((link, index) => (
      <Draggable key={link.sns} draggableId={link.sns} index={index}>
        {(provided, snapshot) => (
          <Flex
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
            }}
            alignItems="center"
            marginTop="8px"
            marginBottom="8px"
          >
            <Box marginRight="20px">
              <Image src={SNS_ICONS[link.sns]} />
            </Box>
            <Flex
              alignItems="center"
              flex={1}
              bg="white"
              borderRadius="8px"
              padding="4px 24px"
              fontSize="17px"
              fontWeight="bold"
              marginRight="20px"
              border={
                snsErrors?.length > 0 && snsErrors?.indexOf(link?.sns) > -1
                  ? "1px solid red"
                  : "none"
              }
            >
              <Box>{t(`updateProfile.${link.sns}`) + " : "}</Box>
              <Input
                flex={1}
                paddingLeft="8px"
                paddingRight="8px"
                fontWeight="bold"
                onFocus={onSnsFocus}
                border="none"
                _focus={{ outline: "none" }}
                value={link.url}
                onChange={(e) => onUrlChange(e, index)}
              />
            </Flex>

            <Box
              width="30px"
              height="30px"
              backgroundColor="transparent"
              padding="0px"
            >
              <Image src={burgerIcon} width="100%" height="100%" />
            </Box>
          </Flex>
        )}
      </Draggable>
    ));
  }

  function renderMobile() {
    return snsLinks.map((link, index) => (
      <Draggable key={link.sns} draggableId={link.sns} index={index}>
        {(provided, snapshot) => (
          <Flex
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
            }}
            alignItems="center"
            marginTop="35px"
            marginBottom="35px"
          >
            <Flex
              flexDir="column"
              alignItems="center"
              justifyContent="space-between"
              marginRight="20px"
            >
              <Box
                w="50px"
                h="50px"
                justifyContent="center"
                alignItems="center"
                marginBottom="20px"
              >
                <Image src={SNS_ICONS[link.sns]} w="50px" h="50px" />
              </Box>
              <Box
                width="30px"
                height="30px"
                backgroundColor="transparent"
                padding="0px"
                justifyContent="center"
                alignItems="center"
              >
                <Image src={burgerIcon} width="100%" height="100%" />
              </Box>
            </Flex>
            <Flex
              flex={1}
              flexDir="column"
              alignItems="center"
              justifyContent="space-between"
            >
              <Flex
                alignItems="center"
                width="100%"
                bg="white"
                borderRadius="8px"
                padding="15px 23px"
                fontSize="17px"
                fontWeight="bold"
                marginBottom="20px"
                border={
                  snsErrors?.length > 0 && snsErrors?.indexOf(link?.sns) > -1
                    ? "1px solid red"
                    : "none"
                }
              >
                <Box>{t(`updateProfile.${link.sns}`) + " : "}</Box>
              </Flex>
              <Box
                flex={1}
                w="100%"
                border={
                  snsErrors?.length > 0 && snsErrors?.indexOf(link?.sns) > -1
                    ? "1px solid red"
                    : "none"
                }
                borderRadius="8px"
              >
                <Input
                  flex={1}
                  height="50.55px"
                  w="100%"
                  bg="white"
                  padding="15px 23px"
                  fontWeight="bold"
                  border="none"
                  onFocus={onSnsFocus}
                  _focus={{ outline: "none" }}
                  value={link.url}
                  onChange={(e) => onUrlChange(e, index)}
                />
              </Box>
            </Flex>
          </Flex>
        )}
      </Draggable>
    ));
  }

  return (
    <Box flex={4} w="100%">
      <Flex
        flexDir="column"
        bg="#f6f5f7"
        borderRadius="19px"
        paddingTop="6"
        paddingBottom="6"
        alignItems="center"
        w="100%"
      >
        <FormControl w={isMobile ? "90%" : "80%"}>
          <FormLabel fontSize="20px" fontWeight="bold">
            {t("updateProfile.snsLink")}
          </FormLabel>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="snsLinks">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={{
                    opacity: snapshot.isDraggingOver ? 0.6 : 1,
                  }}
                >
                  {isMobile ? renderMobile() : renderDesktop()}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {!isMobile && (
            <Box>
              <Flex alignItems="center" mt="25px" mb="15px">
                <Box fontSize="20px" mr="15px" fontWeight="bold">
                  {t("updateProfile.email")}
                </Box>
                <Box fontSize="15px" fontWeight="light" mr="10px">
                  {t("updateProfile.signUpAgreement")}
                </Box>
                <Box
                  position="relative"
                  cursor="pointer"
                  onClick={onCheckClick}
                >
                  <Image src={checkboxIcon} />
                  {profile?.checkNotification && (
                    <Box position="absolute" top="-20%" left="0%">
                      <Image src={checkIcon} />
                    </Box>
                  )}
                </Box>
              </Flex>
              <Flex alignItems="center" marginTop="8px" marginBottom="8px">
                <Flex
                  alignItems="center"
                  flex={1}
                  bg="white"
                  borderRadius="8px"
                  padding="4px 24px"
                  fontSize="17px"
                  fontWeight="bold"
                  marginRight="20px"
                  border={emailError ? "1px solid red" : "none"}
                >
                  <Input
                    flex={1}
                    paddingLeft="8px"
                    paddingRight="8px"
                    fontWeight="bold"
                    border="none"
                    _focus={{ outline: "none" }}
                    value={email}
                    onFocus={onEmailFocus}
                    onChange={(e) => onEmailChange(e)}
                  />
                </Flex>

                <Box w="30px" h="30px" padding="0px" />
              </Flex>
            </Box>
          )}
        </FormControl>
      </Flex>
      {isMobile && (
        <Box bg="#F6F5F7" borderRadius="19px" padding="20px" mt="30px">
          <Box>
            <Box fontSize="20px" mb="10px" fontWeight="bold">
              {t("updateProfile.email")}
            </Box>
            <Flex
              alignItems="flex-end"
              mb="15px"
              justifyContent="space-between"
            >
              <Box fontSize="15px" fontWeight="light" mr="10px">
                <Trans i18nKey="updateProfile.signUpAgreementMobile" />
              </Box>
              <Box
                position="relative"
                cursor="pointer"
                onClick={onCheckClick}
                mb="4px"
              >
                <Image src={checkboxIcon} />
                {profile?.checkNotification && (
                  <Box position="absolute" top="-20%" left="0%">
                    <Image src={checkIcon} />
                  </Box>
                )}
              </Box>
            </Flex>
          </Box>
          <Flex alignItems="center">
            <Flex
              alignItems="center"
              width="100%"
              bg="white"
              borderRadius="8px"
              padding="4px 24px"
              fontSize="17px"
              fontWeight="bold"
              border={emailError ? "1px solid red" : "none"}
            >
              <Input
                flex={1}
                paddingLeft="8px"
                paddingRight="8px"
                fontWeight="bold"
                border="none"
                _focus={{ outline: "none" }}
                value={email}
                onFocus={onEmailFocus}
                onChange={(e) => onEmailChange(e)}
              />
            </Flex>
          </Flex>
        </Box>
      )}
    </Box>
  );
}

export default UpdateProfileSns;
