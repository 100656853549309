import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { isFirefox } from "react-device-detect";
import useResponsive from "../../../common/utils/useResponsive";

function ProfileCopiedModal({ isOpen, onClose }) {
  const { t } = useTranslation();
  const { isDesktop, isMobile, isTablet } = useResponsive();
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xl">
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
        margin={isMobile ? "0px 20px" : "0px"}
      >
        {!isMobile && (
          <ModalCloseButton
            top="20px"
            right="20px"
            size="lg"
            _focus={{ outline: "none" }}
          />
        )}
        <ModalBody
          padding={isDesktop ? "100px 86px" : isTablet ? "60px 70px" : "22px"}
        >
          <Flex
            alignItems="center"
            justifyContent="center"
            w="100%"
            height={isDesktop ? "57px" : isTablet ? "45px" : "43px"}
            borderRadius="12px"
            boxShadow="0px 20px 20px #00000029"
            textAlign="center"
            bg="white"
            fontSize={isDesktop ? "17px" : isTablet ? "16px" : "15px"}
            fontWeight="bold"
          >
            {t("profile.copied")}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ProfileCopiedModal;
