import { Box, Container, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { DEFAULT_CHAIN } from "../../constants";
import { setArtsCurrentPage } from "../../lib/redux/reducers/arts/artsReducer";
import useResponsive from "../common/utils/useResponsive";
import ArtsListPage from "./components/ArtsListPage";
import ArtsNavigationBar from "./components/ArtsNavigationBar";

function ArtsContainer() {
  const { Moralis, chainId, user } = useMoralis();
  const location = useLocation();
  const { isMobile } = useResponsive();

  const currentPage = useSelector((state) => state.arts.currentPage);

  const dispatch = useDispatch();

  useEffect(() => {
    if (location?.state?.category) {
      dispatch(setArtsCurrentPage(location?.state?.category));
    }
  }, [location?.state]);

  return (
    <Flex
      alignItems={!isMobile ? "flex-start" : "center"}
      height="100vh"
      direction={!isMobile ? "row" : "column"}
    >
      {!isMobile && <ArtsNavigationBar currentPage={currentPage} />}
      <ArtsListPage currentPage={currentPage} />
    </Flex>
  );
}

export default ArtsContainer;
