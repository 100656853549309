import { Avatar, Box, Flex, Image, Textarea } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMoralis } from "react-moralis";
import twitterIcon from "../../../../assets/icons/twitter.svg";
import discordIcon from "../../../../assets/icons/discord.svg";
import instagramIcon from "../../../../assets/icons/instagram.svg";
import profileMenuIcon from "../../../../assets/icons/profile_menu.svg";

import ProfileHeaderSaleInfo from "./ProfileHeaderSaleInfo";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import useResponsive from "../../../common/utils/useResponsive";
import TopLogo from "../../../auth/components/TopLogo";
import { useParams } from "react-router-dom";
import ProfileMenuModal from "./ProfileMenuModal";
import ProfileCopiedModal from "./ProfileCopiedModal";
import shareIcon from "../../../../assets/icons/share.svg";
import { useTranslation } from "react-i18next";

const AVATAR_SIZE = 170;

const SNS_ICON_SET = {
  Instagram: instagramIcon,
  Discord: discordIcon,
  Twitter: twitterIcon,
};

const SNS_BG_SIZE = 30;

function ProfileHeader() {
  const { user, Moralis } = useMoralis();
  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { userAccount } = useParams();

  const [userInfo, setUserInfo] = useState(null);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showCopiedModal, setShowCopiedModal] = useState(false);

  const fetchUser = async () => {
    const userResult = await Moralis.Cloud.run("getUser", {
      account: userAccount,
    });

    if (userResult) {
      setUserInfo({
        avatar: userResult?.get("avatar"),
        username: userResult?.get("username"),
        snsLinks: userResult?.get("snsLinks"),
        description: userResult?.get("description"),
      });
    }
  };

  useEffect(() => {
    if (!userAccount) {
      const avatar = user.get("avatar");
      const username = user.get("username");
      const snsLinks = user.get("snsLinks");
      const description = user.get("description");

      setUserInfo({
        avatar,
        username,
        snsLinks,
        description,
      });
    } else {
      fetchUser();
    }
  }, [userAccount]);

  const toggleProfileModal = (value) => {
    setShowProfileModal(value);
  };

  const copy = () => {
    console.log(window.location.href);
    const copyText = userAccount
      ? window.location.href
      : window.location.href + `/${user?.get("ethAddress")}`;
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(copyText).then(() => {
        setShowCopiedModal(true);
      });
    } else {
      var textarea = document.createElement("textarea");
      textarea.textContent = copyText;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy"); // Security exception may be thrown by some browsers.
        setShowCopiedModal(true);
      } catch (ex) {
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  return !isMobile ? (
    <Flex
      bg="#949AB2"
      borderRadius="16px"
      padding="43px"
      position="relative"
      mb="84px"
      direction={isTablet ? "column" : "row"}
    >
      <Flex
        flex={1}
        alignItems="center"
        paddingBottom={isTablet ? "45.5px" : "0px"}
        borderBottom={isTablet ? "1px solid white" : "none"}
        overflow="hidden"
      >
        <Flex flex={1} alignItems="flex-end">
          <Box
            mr="38px"
            cursor="pointer"
            onClick={() => {
              history.push(ROUTE_NAMES.updateProfile);
            }}
            pointerEvents={userAccount ? "none" : "auto"}
          >
            <Image
              src={userInfo?.avatar?._url || profileMenuIcon}
              w={AVATAR_SIZE}
              h={AVATAR_SIZE}
              borderRadius="50%"
            />
          </Box>
          <Box flex={1} overflow="hidden" position="relative">
            <Flex alignItems="center" mb="15.15px" w="100%" overflow="hidden">
              <Box
                fontSize="35px"
                color="#F6F5F7"
                fontWeight="extrabold"
                mr="20px"
                noOfLines={1}
                maxW="50%"
                overflow="hidden"
              >
                {userInfo?.username}
              </Box>
              {userInfo?.snsLinks?.map(
                (sns) =>
                  sns?.url?.length > 0 && (
                    <Box
                      key={sns.sns}
                      w={SNS_BG_SIZE}
                      h={SNS_BG_SIZE}
                      mg="10px"
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          sns.url?.indexOf("http") > -1
                            ? sns.url
                            : "https://" + sns.url,
                          "_blank"
                        )
                      }
                    >
                      <img src={SNS_ICON_SET[sns.sns]} />
                    </Box>
                  )
              )}
            </Flex>
            <ProfileHeaderSaleInfo />
          </Box>
        </Flex>
      </Flex>
      <Flex
        flex={1}
        alignItems="center"
        justifyContent="center"
        borderLeft={isTablet ? "none" : "1px solid white"}
        fontSize="20px"
        fontWeight="light"
        color="#F6F5F7"
        paddingTop={isTablet ? "45px" : "0px"}
        paddingBottom={isTablet ? "45px" : "0px"}
      >
        <Textarea
          disabled={true}
          textAlign="left"
          w="80%"
          maxHeight={isTablet ? "78px" : "fit-content"}
          _disabled={{
            backgroundColor: "transparent",
            color: "white",
          }}
          resize="none"
          border="none"
          overflowY="auto"
          defaultValue={userInfo?.description}
        />
      </Flex>
      {/* SHADOW */}
      <Box
        bg="#949AB2"
        filter="blur(33px)"
        position="absolute"
        left="3%"
        right="3%"
        top="10%"
        height="100%"
        zIndex={-1}
      />
      <Box
        position="absolute"
        top="20px"
        right="20px"
        w="46px"
        h="46px"
        cursor="pointer"
        onClick={copy}
      >
        <Image src={shareIcon} />
      </Box>
      <ProfileCopiedModal
        isOpen={showCopiedModal}
        onClose={() => setShowCopiedModal(false)}
      />
    </Flex>
  ) : (
    <>
      <Box
        // marginTop="-50px"
        paddingTop="50px"
        marginLeft="-16px"
        marginRight="-16px"
        bg="#949AB2"
        borderBottomRightRadius="16px"
        borderBottomLeftRadius="16px"
        position="relative"
        mb="23px"
      >
        <TopLogo isCenter={false} isWhite />
        <Flex direction="column" alignItems="center">
          <Box
            cursor="pointer"
            mt="44.93px"
            mb="15.81px"
            position="relative"
            onClick={() => toggleProfileModal(!showProfileModal)}
          >
            <Image
              src={userInfo?.avatar?._url || profileMenuIcon}
              w={AVATAR_SIZE}
              h={AVATAR_SIZE}
              borderRadius="50%"
            />
          </Box>
          <Box
            fontSize="35px"
            color="#F6F5F7"
            fontWeight="extrabold"
            mb="5.19px"
            w="80%"
            noOfLines={1}
            overflow="hidden"
            textAlign="center"
          >
            {userInfo?.username}
          </Box>
          <Flex mb="47px" alignItems="center">
            {userInfo?.snsLinks?.map(
              (sns) =>
                sns?.url?.length > 0 && (
                  <Box
                    key={sns.sns}
                    w={SNS_BG_SIZE}
                    h={SNS_BG_SIZE}
                    mr="10px"
                    ml="10px"
                    cursor="pointer"
                    onClick={() =>
                      window.open(
                        sns.url?.indexOf("http") > -1
                          ? sns.url
                          : "https://" + sns.url,
                        "_blank"
                      )
                    }
                  >
                    <img src={SNS_ICON_SET[sns.sns]} />
                  </Box>
                )
            )}
          </Flex>
        </Flex>
        <Box
          position="absolute"
          top="10%"
          left="5%"
          right="5%"
          height="100%"
          bg="#949AB2"
          filter="blur(30px)"
          zIndex={-1}
        />
        <Box
          position="absolute"
          top="50px"
          right="20px"
          w="46px"
          h="46px"
          cursor="pointer"
          onClick={copy}
        >
          <Image src={shareIcon} />
        </Box>
        <ProfileCopiedModal
          isOpen={showCopiedModal}
          onClose={() => setShowCopiedModal(false)}
        />
      </Box>
      <ProfileHeaderSaleInfo />

      <Box mt="35px" fontSize="17px" fontWeight="bold" mb="10px">
        {t("profile.description")}
      </Box>
      <Box
        padding="20px"
        bg="#F6F5F7"
        borderRadius="18px"
        mb={isTablet ? "100px" : "51px"}
      >
        <Textarea
          disabled={true}
          textAlign="left"
          bg="white"
          padding="30px 38px"
          fontSize="13px"
          _disabled={{
            backgroundColor: "white",
            color: "black",
          }}
          resize="none"
          border="none"
          height="200px"
          overflowY="auto"
          borderRadius="18px"
          defaultValue={userInfo?.description}
        />
      </Box>
      {showProfileModal && (
        <ProfileMenuModal
          isOpen={showProfileModal}
          onClose={() => toggleProfileModal(false)}
        />
      )}
    </>
  );
}

export default ProfileHeader;
