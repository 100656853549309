import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useToast,
  VStack,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isFirefox } from "react-device-detect";
import {
  useMoralis,
  useMoralisWeb3Api,
  useWeb3ExecuteFunction,
} from "react-moralis";
import useCoinPrice from "../../common/hooks/useCoinPrice";
import checkBox from "../../../assets/icons/checkbox.svg";
import WrapEthModal from "../../common/components/WrapEthModal";
import {
  APPROVAL_KEYS,
  CONTRACT_ADDRESS_MAP,
  ENV,
  NETWORKS,
  OWNER_CUT,
  WETH_ADDRESS_MAP,
  WRAPPED_ETH_NAMES,
  WRAPPED_ETH_QUERY_MAP,
} from "../../../constants";
import checkIcon from "../../../assets/icons/check.svg";
import { ERC721_CONTRACT_ABI } from "../../../abis/tokenAbi";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import { signOffer } from "../../common/utils/getOfferSignature";
import { preventArrowKeyFromInput } from "../../common/utils/preventArrowKeyFromInput";
import { removeLeadingZero } from "../../common/utils/removeLeadingZero";
import useResponsive from "../../common/utils/useResponsive";
import thumb from "../../../assets/icons/slider_thumb.svg";
import { WETH_CONTRACT_ABI } from "../../../abis/wethAbi";
import Loading from "../../common/components/Loading";
import ThankyouContainer from "../../create/components/ThankyouContainer";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { sendEmail } from "../../common/utils/sendEmail";
import { getEmailBody } from "../../common/utils/getEmailBody";
import ROUTE_NAMES from "../../../routes/utils/routeNames";

const VALUES = {
  12: 12,
  24: 24,
  36: 48,
  48: 24 * 7,
  60: 24 * 14,
};

function ArtDetailOfferModal({ isOpen, onClose, item }) {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { isMobile, isTablet, isDesktop } = useResponsive();

  const { account } = useMoralisWeb3Api();

  const history = useHistory();

  const tokenProcess = useWeb3ExecuteFunction();
  const { Moralis, chainId, user, isWeb3Enabled, enableWeb3, web3 } =
    useMoralis();

  const [tokenBalance, setTokenBalance] = useState(0);
  const [offerHour, setOfferHour] = useState(12);
  const [offerPrice, setOfferPrice] = useState("");
  const [showWrapModal, setShowWrapModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const serviceFee = (offerPrice * OWNER_CUT) / 10000;
  const total = Number(offerPrice) + Number(serviceFee);

  const coinPrice = useCoinPrice(offerPrice, chainId);

  const serviceFeeCoinPrice = useCoinPrice(serviceFee, chainId);
  const totalCoinPrice = useCoinPrice(total, chainId);

  const getValueText = () => {
    const value = VALUES[offerHour];

    switch (value) {
      case 24: {
        return value / 24 + "Day";
      }

      case 48: {
        return value / 24 + "Days";
      }

      case 24 * 7: {
        return value / 24 / 7 + "Week";
      }

      case 24 * 14: {
        return value / 24 / 7 + "Weeks";
      }

      default: {
        return value + "Hours";
      }
    }
  };

  const fetchTokenBalance = async () => {
    const tokenResult = await account.getTokenBalances({
      chain: chainId,
      address: user?.get("ethAddress"),
      token_addresses: [WETH_ADDRESS_MAP[chainId]],
    });

    const weth = tokenResult?.filter(
      (item) => item?.token_address === WETH_ADDRESS_MAP[chainId]?.toLowerCase()
    )[0];

    setTokenBalance(weth?.balance || 0);
  };

  useEffect(() => {
    if (user) {
      setTimeout(fetchTokenBalance, 500);
    }
  }, [user, chainId, showWrapModal]);

  const checkIsApprovedForAll = async () => {
    const isApprovedForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "isApprovedForAll",
      params: {
        owner: user?.get("ethAddress"),
        operator: CONTRACT_ADDRESS_MAP[chainId],
      },
    };

    try {
      const transaction = await Moralis.executeFunction(isApprovedForAllOps);

      let result;

      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      user?.set(APPROVAL_KEYS[chainId], CONTRACT_ADDRESS_MAP[chainId]);
      await user.save();

      return transaction;
    } catch (error) {
      return false;
    }
  };

  const setApprovalForAll = async () => {
    const approvalForAllOps = {
      contractAddress: CONTRACT_ADDRESS_MAP[chainId],
      abi: ERC721_CONTRACT_ABI,
      functionName: "setApprovalForAll",
      params: {
        operator: CONTRACT_ADDRESS_MAP[chainId],
        approved: true,
      },
    };
    try {
      const transaction = await Moralis.executeFunction(approvalForAllOps);

      let result;

      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      return result;
    } catch (error) {
      return false;
    }
  };

  const ensureMarketIsApproved = async () => {
    let isApproved = await checkIsApprovedForAll();

    if (!isApproved) {
      try {
        const approvalResult = await setApprovalForAll();
        return approvalResult;
      } catch (error) {
        return false;
      }
    }

    return isApproved;
  };

  const checkApproval = async () => {
    const approvalQuery = new Moralis.Query("WethApprove");
    approvalQuery.equalTo("address", user?.get("ethAddress"));
    approvalQuery.equalTo("contractAddress", CONTRACT_ADDRESS_MAP[chainId]);
    const queryResult = await approvalQuery.first();

    return queryResult;
  };

  const approve = async () => {
    const approvalAmount = Moralis.Units.ETH(1000000000000);

    const ops = {
      contractAddress: WETH_ADDRESS_MAP[chainId],
      abi: WETH_CONTRACT_ABI,
      functionName: "approve",
      params: {
        guy: CONTRACT_ADDRESS_MAP[chainId],
        wad: approvalAmount,
      },
    };
    try {
      let result;
      const transaction = await Moralis.executeFunction(ops);
      try {
        result = await transaction.wait();
      } catch (error) {
        if (error.code === "TRANSACTION_REPLACED") {
          if (!error.cancelled) {
            result = error.replacement;
          }
        }
      }

      const approvalClass = Moralis.Object.extend("WethApprove");
      const approval = new approvalClass();
      approval.set("address", user?.get("ethAddress"));
      approval.set("contractAddress", CONTRACT_ADDRESS_MAP[chainId]);
      approval.set("amount", approvalAmount);
      await approval.save();
    } catch (error) {}
  };

  const placeOffer = async () => {
    if (!isWeb3Enabled) {
      await enableWeb3();
    }
    try {
      setLoading(true);

      const isApproved = await ensureMarketIsApproved();

      if (!isApproved) {
        toast({
          title: "Must approve first to use MENNIN",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "top",
        });

        setLoading(false);

        return;
      }

      const approval = await checkApproval();

      if (!approval) {
        await approve();
      }

      const tokenId = item?.tokenId;
      const expiration = moment().add(VALUES[offerHour], "hours").toDate();
      const buyer = user;
      const seller = await Moralis.Cloud.run("getUser", {
        account:
          item?.owner?.get("ethAddress") || item?.creatorAccount?.toLowerCase(),
      });

      const price = Moralis.Units.ETH(
        offerPrice ? offerPrice?.toString() : "0"
      );
      const totalAmount = Number(price) + (Number(price) * OWNER_CUT) / 10000;

      const signature = await signOffer(
        web3,
        chainId,
        buyer?.get("ethAddress"),
        {
          tokenId,
          tokenURI: item?.tokenURI,
          buyerAddress: buyer?.get("ethAddress"),
          sellerAddress: seller?.get("ethAddress"),
          offerPrice: parseInt(price?.toString()).toString(),
          totalPrice: parseInt(totalAmount?.toString()).toString(),
          expiration: moment(expiration).format("YYYY-MM-DD HH:mm:ss"),
          tokenAddress: CONTRACT_ADDRESS_MAP[chainId],
        },
        CONTRACT_ADDRESS_MAP[chainId]
      );

      const Offer = Moralis.Object.extend("Offers");
      const newOffer = new Offer();

      newOffer.set("tokenId", tokenId);
      newOffer.set("item", item);
      newOffer.set("buyer", buyer);
      newOffer.set("buyerAccount", buyer?.get("ethAddress"));
      newOffer.set("seller", seller);
      newOffer.set("sellerAccount", seller?.get("ethAddress"));
      newOffer.set("offerPrice", price);
      newOffer.set("totalAmount", totalAmount);
      newOffer.set("currentLowestPrice", item?.lowestPrice);
      newOffer.set("expiration", expiration);
      newOffer.set("contractAddress", CONTRACT_ADDRESS_MAP[chainId]);
      newOffer.set("isAccepted", false);
      newOffer.set("isRejected", false);
      newOffer.set("isCanceled", false);
      newOffer.set("isExpired", false);
      newOffer.set("signature", signature);

      await newOffer.save();

      if (seller?.get("email") && seller?.get("checkNotification")) {
        sendEmail({
          email_subject: t("email.offerReceived"),
          receiver: seller?.get("email"),
          emailBody: getEmailBody(
            t("email.offerReceived"),
            item?.isVideo,
            item?.imageSrc,
            "rgb(2, 78, 173)",
            t("email.offerReceivedBody1") +
              ` <strong>${offerPrice} ${NETWORKS[ENV]?.[chainId]?.unit}</strong> ` +
              t("email.offerReceivedBody2"),
            "https://mennin.net" + ROUTE_NAMES.market + "/" + tokenId,
            t("email.viewItem")
          ),
        });
      }

      toast({
        title: "Successfully sent an offer",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      setIsDone(true);
      setTimeout(() => {
        onClose();
        history.goBack();
      }, 2000);
    } catch (error) {
      let errorMessage = "Error! Please try later";

      if (
        error.message?.indexOf("insufficient funds") > -1 ||
        error?.data?.message?.indexOf("insufficient funds")
      ) {
        errorMessage = "insufficient funds";
      }

      toast({
        title: errorMessage,
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      setLoading(false);
      onClose();
    }
  };

  const tokenBal = Moralis.Units.FromWei(tokenBalance);

  const totalAmountEth =
    Number(offerPrice) + (Number(offerPrice) * OWNER_CUT) / 10000;

  const isOfferDisabled =
    loading || !checked || offerPrice < 0 || tokenBal < totalAmountEth;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      blockScrollOnMount
      isCentered={!isMobile}
      size={isMobile ? "full" : "xl"}
      scrollBehavior="inside"
    >
      <ModalContent
        bg={isFirefox ? "white" : "transparent"}
        backdropFilter="blur(160px)"
        maxH="100vh"
      >
        <ModalCloseButton
          top="20px"
          right="20px"
          _hover={{ backgroundColor: "transparent" }}
          _focus={{ outline: "none" }}
        />
        <ModalBody
          paddingLeft={isMobile ? "20px" : "80px"}
          paddingRight={isMobile ? "20px" : "80px"}
          paddingBottom="30px"
          paddingTop="30px"
        >
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="0px"
          >
            <Box
              fontSize="25px"
              fontWeight="bold"
              color="#3E3E3E"
              marginBottom="25px"
            >
              {t("offer.offer")}
            </Box>
            <Box
              textAlign="center"
              fontSize="13px"
              fontWeight="light"
              color="#3E3E3E"
              marginBottom="40px"
            >
              <Trans i18nkey="offer.offerDescription" />
            </Box>
            <Box width="100%" height="3px" bg="#000000" mb="25.93px" />

            {/* OFFER EXPIRATION */}

            <Flex alignItems="flex-start" direction="column" width="100%">
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Box
                  flex={1}
                  fontSize="13px"
                  fontWeight="bold"
                  color="#3E3E3E"
                  mb="10px"
                >
                  {t("offer.offerExpiration")}
                </Box>
                <Box
                  fontSize="15px"
                  fontWeight="bold"
                  textAlign="right"
                  color="#1C20FE"
                  mb="12px"
                >
                  {getValueText()}
                </Box>
              </Flex>
              <Flex
                width="100%"
                bg="white"
                boxShadow="0px 20px 20px #00000029"
                borderRadius="12px"
                padding="0px 41px"
                h="61px"
                mb="14px"
                alignItems="center"
              >
                <Slider
                  defaultValue={12}
                  min={12}
                  max={60}
                  value={offerHour}
                  onChange={(value) => setOfferHour(value)}
                  width="100%"
                  step={12}
                >
                  <SliderTrack bg="#949AB2" height="5px" borderRadius="12px">
                    <SliderFilledTrack height="10px" bg="#1B1FF7" />
                  </SliderTrack>
                  <SliderThumb
                    boxSize="24px"
                    _active={{ outline: "none", bg: "transparent" }}
                    _focus={{ outline: "none", bg: "transparent" }}
                  >
                    <Image src={thumb} />
                  </SliderThumb>
                </Slider>
              </Flex>
            </Flex>

            {/* Price */}
            <Flex
              alignItems="flex-start"
              direction="column"
              width="100%"
              mb="25px"
            >
              <Flex
                w="100%"
                fontSize="13px"
                fontWeight="bold"
                color="#3E3E3E"
                mb="10px"
                alignItems="flex-end"
                justifyContent="space-between"
              >
                <Box>{t("offer.price")}</Box>
                <Button
                  bg="#1B1FF7"
                  borderRadius="7px"
                  padding="6px 21px"
                  width="fit-content"
                  height="fit-content"
                  boxShadow="0px 5px 10px #00000029"
                  color="white"
                  fontSize="13px"
                  fontWeight="bold"
                  _hover={{
                    bg: "#1B1FF7",
                  }}
                  _active={{
                    bg: "#1B1FF7",
                    outline: "none",
                  }}
                  _focus={{
                    bg: "#1B1FF7",
                    outline: "none",
                  }}
                  onClick={() => setShowWrapModal(true)}
                >
                  {t(`offer.${WRAPPED_ETH_NAMES[item?.chainId]}`)}{" "}
                  {t("offer.swap")}
                </Button>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                padding="10.5px 25px"
                borderRadius="12px"
                bg="white"
                boxShadow="0px 20px 20px #00000029"
                width="100%"
                fontSize="20px"
                fontWeight="bold"
                zIndex={2}
              >
                <Flex alignItems="center">
                  <Input
                    type="number"
                    outline="none"
                    border="none"
                    padding="0px"
                    fontSize="20px"
                    fontWeight="bold"
                    width="70px"
                    _focus={{ border: "none" }}
                    placeholder="0.00"
                    _placeholder={{
                      fontSize: 20,
                      fontWeight: "bold",
                      color: "black",
                    }}
                    value={offerPrice}
                    onKeyDown={preventArrowKeyFromInput}
                    onChange={(e) => {
                      setOfferPrice(
                        Number(e.target.value) >= 0
                          ? removeLeadingZero(e.target.value)
                          : offerPrice
                      );
                    }}
                    onWheel={(e) => e.target.blur()}
                  />
                  <Box color="#949AB2">
                    {" "}
                    {t(`offer.${WRAPPED_ETH_NAMES[item?.chainId]}`)}
                  </Box>
                </Flex>
                <Box color="#3E3E3E" fontWeight="bold">
                  {coinPrice}
                </Box>
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="flex-end"
                w="100%"
                mt="10px"
                fontSize="13px"
                color="#949AB2"
                fontWeight="bold"
              >
                <Box mr="18px">
                  {t(`offer.${WRAPPED_ETH_NAMES[item?.chainId]}`)}{" "}
                  {t("offer.balance")}
                </Box>
                <Box>{tokenBal}</Box>
              </Flex>
            </Flex>
            <Flex
              direction="column"
              alignItems="flex-start"
              width="100%"
              paddingLeft="10%"
              fontSize="15px"
              fontWeight="bold"
              mb="25px"
            >
              <Flex alignItems="center">
                <Box color="#1C20FE">{t("offer.creator")} : </Box>
                <Box ml="4px">{item?.creator?.get("username")}</Box>
              </Flex>
              <Flex alignItems="center">
                <Box color="#1C20FE">{t("offer.owner")} : </Box>
                <Box ml="4px">{item?.owner?.get("username")}</Box>
              </Flex>
              <Flex alignItems="center">
                <Box color="#1C20FE">{t("offer.royalty")} : </Box>
                <Box ml="4px">
                  {(
                    (Number(JSON.parse(item?.royalty).amount) / 10000) *
                    100
                  ).toFixed(2)}{" "}
                  %
                </Box>
              </Flex>
            </Flex>
            <Box w="100%" h="3px" bg="black" mb="16px" />
            <Flex
              w="100%"
              alignItems="flex-start"
              justifyContent="space-between"
              fontSize="15px"
              mb="16px"
            >
              <Box color="#3E3E3E" fontWeight="bold">
                {t("offer.serviceFee")}{" "}
                <span style={{ marginLeft: "10px", fontWeight: "lighter" }}>
                  {OWNER_CUT / 100} %
                </span>
              </Box>
              <Box textAlign="right" fontWeight="light">
                <Box color="#3E3E3E">
                  {(offerPrice * OWNER_CUT) / 10000}{" "}
                  {WRAPPED_ETH_NAMES[item?.chainId]}
                </Box>
                <Box color="#949AB2">{serviceFeeCoinPrice}</Box>
              </Box>
            </Flex>
            <Box w="100%" h="3px" bg="black" mb="32px" />

            <Flex
              alignItems="flex-start"
              justifyContent="space-between"
              w="100%"
              mb="40px"
            >
              <Box fontSize="20px" color="#1C20FE" fontWeight="bold">
                {t("offer.total")}
              </Box>
              <Box fontWeight="bold" textAlign="right">
                <Box fontSize="25px">
                  {numberWithCommas(total)} {WRAPPED_ETH_NAMES[item?.chainId]}
                </Box>
                <Box fontSize="15px" color="#949AB2">
                  {totalCoinPrice}
                </Box>
              </Box>
            </Flex>

            <Flex alignItems="center" mb="31px">
              <Box
                mr="8px"
                position="relative"
                cursor="pointer"
                onClick={() => setChecked(!checked)}
              >
                <Image src={checkBox} />
                {checked && (
                  <Box
                    position="absolute"
                    top="0px"
                    left="0px"
                    transform="translate(10%,-25%)"
                  >
                    <Image src={checkIcon} />
                  </Box>
                )}
              </Box>
              <Box fontSize="12px" fontWeight="light" color="#3E3E3E">
                {t("offer.agreement")}
              </Box>
            </Flex>
            <Button
              width="100%"
              bg="#1D20FF 0% 0% no-repeat padding-box"
              color="white"
              height="61px"
              borderRadius="12px"
              fontSize="15px"
              fontWeight="bold"
              boxShadow="0px 20px 20px #00000029"
              _hover={{
                backgroundColor: "#1d20ff",
              }}
              _focus={{
                outline: "none",
                backgroundColor: "#1d20ff",
              }}
              _active={{
                outline: "none",
                backgroundColor: "#1d20ff",
              }}
              disabled={isOfferDisabled}
              onClick={placeOffer}
            >
              {t("offer.makeOffer")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
      {showWrapModal && (
        <WrapEthModal
          isOpen={showWrapModal}
          onClose={() => setShowWrapModal(false)}
        />
      )}
      {(loading || isDone) && (
        <Flex
          position="fixed"
          direction="column"
          top="0px"
          left="0px"
          right="0px"
          bottom="0px"
          zIndex={1500}
          bg="white"
          alignItems="center"
          justifyContent="center"
        >
          {loading && (
            <Box w="100%" textAlign="center">
              <Box
                fontSize={isDesktop ? "25px" : isTablet ? "16px" : "20px"}
                fontWeight="extrabold"
                mb={isDesktop ? "10px" : isTablet ? "8px" : "3px"}
              >
                {t("global.offerLoading")}
              </Box>
              <Loading width={isDesktop ? "60%" : isTablet ? "80%" : "90%"} />
            </Box>
          )}
          {isDone && (
            <Box w="90%">
              <ThankyouContainer isGoingBack={true} />
            </Box>
          )}
        </Flex>
      )}
    </Modal>
  );
}

export default ArtDetailOfferModal;
