import {
  Box,
  Button,
  Image,
  useModal,
  useOutsideClick,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import closeIcon from "../../../assets/icons/close.svg";
import ROUTE_NAMES from "../../../routes/utils/routeNames";
import useResponsive from "../../common/utils/useResponsive";

function ProfileDropdownMenu({ closeMenu }) {
  const { isDesktop, isMobile, isTablet } = useResponsive();
  const { logout } = useMoralis();

  const { t } = useTranslation();

  const boxRef = useRef();

  const history = useHistory();

  useOutsideClick({
    ref: boxRef,
    handler: closeMenu,
  });

  const onViewProfileClick = () => {
    history.push(ROUTE_NAMES.profile);
    closeMenu();
  };

  const onEditProfileClick = () => {
    history.push(ROUTE_NAMES.updateProfile);
    closeMenu();
  };

  const onLogoutClick = async () => {
    closeMenu();
    await logout();
    history.push(ROUTE_NAMES.home);
    document.location.reload();
  };

  return (
    <Box
      ref={boxRef}
      position="absolute"
      top="140%"
      right="50%"
      background="#F6F5F7 0% 0% no-repeat padding-box"
      padding={isDesktop ? "20px" : "16px"}
      borderRadius={isDesktop ? "15px" : "10px"}
      borderTopRightRadius="0px"
      fontSize={isDesktop ? "15px" : "12px"}
      fontWeight="bold"
      w={isDesktop ? "300px" : "241px"}
    >
      <Button
        w="100%"
        bg="white"
        borderRadius={isDesktop ? "12px" : "5px"}
        mb="10px"
        _hover={{ bg: "white", outline: "none" }}
        _active={{ bg: "white", outline: "none" }}
        _focus={{ bg: "white", outline: "none" }}
        onClick={onViewProfileClick}
      >
        {t("profileMenu.viewProfile")}
      </Button>
      <Button
        w="100%"
        bg="white"
        borderRadius={isDesktop ? "12px" : "5px"}
        mb="10px"
        _hover={{ bg: "white", outline: "none" }}
        _active={{ bg: "white", outline: "none" }}
        _focus={{ bg: "white", outline: "none" }}
        onClick={onEditProfileClick}
      >
        {t("profileMenu.editProfile")}
      </Button>
      <Button
        w="100%"
        bg="white"
        borderRadius={isDesktop ? "12px" : "5px"}
        _hover={{ bg: "white", outline: "none" }}
        _active={{ bg: "white", outline: "none" }}
        _focus={{ bg: "white", outline: "none" }}
        onClick={onLogoutClick}
      >
        {t("profileMenu.logout")}
      </Button>
    </Box>
  );
}

export default ProfileDropdownMenu;
