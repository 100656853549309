import { Input } from "@chakra-ui/input";
import { Box, Flex } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { ENV, NETWORKS } from "../../../constants";
import useCoinPrice from "../../common/hooks/useCoinPrice";
import { numberWithCommas } from "../../common/utils/numberWithCommas";
import { preventArrowKeyFromInput } from "../../common/utils/preventArrowKeyFromInput";
import useResponsive from "../../common/utils/useResponsive";
import CreateChainSelector from "./CreateChainSelector";

function CreatePrice({ price, onItemChange }) {
  const { chainId } = useMoralis();
  const coinPrice = useCoinPrice(price, chainId);
  const { isTablet, isDesktop, isMobile } = useResponsive();
  const { t } = useTranslation();

  return (
    <>
      <Flex
        paddingLeft="20px"
        fontSize={!isTablet ? "20px" : "17px"}
        fontWeight="bold"
        mb="11px"
        textAlign="left"
        alignItems="center"
        w="100%"
        justifyContent="space-between"
      >
        <Box>{t("create.price")}</Box>
        {!isMobile && <CreateChainSelector />}
      </Flex>
      <Flex
        bg="#F6F5F7 0% 0% no-repeat padding-box"
        borderRadius="19px"
        w="100%"
        h={isDesktop ? "200px" : "162px"}
        flexDir="column"
        alignItems="center"
        mb="35px"
        justifyContent="center"
        padding="0px 45px"
      >
        <Flex
          alignItems="flex-end"
          mb={isDesktop ? "20px" : "21px"}
          justifyContent="space-between"
          w="100%"
        >
          <Input
            type="number"
            outline="none"
            border="none"
            padding="0px"
            fontSize={isDesktop ? "43px" : "30px"}
            height="100%"
            w="90%"
            fontWeight="bold"
            _focus={{ border: "none" }}
            placeholder="0"
            _placeholder={{
              fontSize: isDesktop ? 43 : 30,
              fontWeight: "bold",
              color: "black",
            }}
            value={price}
            textAlign="right"
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => {
              preventArrowKeyFromInput(e);
            }}
            onChange={(e) => {
              onItemChange(e);
            }}
          />
          <Box
            fontSize={isDesktop ? "15px" : "10px"}
            mr="12px"
            ml={isMobile ? "10px" : "0px"}
          >
            {NETWORKS[ENV]?.[chainId]?.unit}
          </Box>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          bg="#FFFFFF 0% 0% no-repeat padding-box"
          borderRadius="12px"
          w="100%"
          height={isMobile ? "50px" : "fit-content"}
          padding={isDesktop ? "10px 12px" : isTablet ? "8px 4px" : "0px 12px"}
          textAlign="right"
          fontSize={isDesktop ? "15px" : "10px"}
          fontWeight="bold"
          alignSelf="flex-end"
        >
          {coinPrice}
        </Flex>
      </Flex>
    </>
  );
}

export default CreatePrice;
