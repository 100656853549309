import { createSlice } from "@reduxjs/toolkit";

export const profileViewAllSlice = createSlice({
  name: "profileViewAll",
  initialState: {
    created: null,
    createdCount: 0,
    createdAccount: null,
    collected: null,
    collectedCount: 0,
    collectedAccount: null,
    offer: null,
    offeredAccount: null,
    offerReceived: null,
    offerReceivedCount: 0,
    offerMade: null,
    offerMadeCount: 0,
    offerAccount: null,
    favorites: null,
    favoritesCount: 0,
    favoritesAccount: null,
  },
  reducers: {
    setProfileCreatedItems: (state, action) => {
      state.created = action.payload.items;
      state.createdCount = action.payload.count;
      state.createdAccount = action.payload.account;
    },
    setProfileCollectedItems: (state, action) => {
      state.collected = action.payload.items;
      state.collectedCount = action.payload.count;
      state.collectedAccount = action.payload.account;
    },
    setProfileOfferItems: (state, action) => {
      state.offer = action.payload.items;
      state.offeredAccount = action.payload.account;
    },
    setProfileOfferReceivedItems: (state, action) => {
      state.offerReceived = action.payload.items;
      state.offerReceivedCount = action.payload.count;
      state.offerAccount = action.payload.account;
    },
    setProfileOfferMadeItems: (state, action) => {
      state.offerMade = action.payload.items;
      state.offerMadeCount = action.payload.count;
      state.offerAccount = action.payload.account;
    },
    setProfileFavoritesItems: (state, action) => {
      state.favorites = action.payload.items;
      state.favoritesCount = action.payload.count;
      state.favoritesAccount = action.payload.account;
    },
  },
});

export const {
  setProfileCreatedItems,
  setProfileCollectedItems,
  setProfileOfferItems,
  setProfileOfferReceivedItems,
  setProfileOfferMadeItems,
  setProfileFavoritesItems,
} = profileViewAllSlice.actions;

export default profileViewAllSlice.reducer;
