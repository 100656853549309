import { useEffect, useState } from "react";
import { useMoralis } from "react-moralis";

function useGetUser(account) {
  const { Moralis } = useMoralis();

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUser = async () => {
    setLoading(true);
    const result = await Moralis.Cloud.run("getUser", {
      account,
    });

    setUser(result);
    setLoading(false);
  };

  useEffect(() => {
    if (account) {
      fetchUser();
    }
  }, [account]);

  return { user, loading };
}

export default useGetUser;
