import { Box, Flex, Image, useOutsideClick } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useChain, useMoralis } from "react-moralis";
import { ENV, NETWORKS } from "../../../constants";
import useResponsive from "../../common/utils/useResponsive";

import arrowDown from "../../../assets/icons/arrow_down_gray.svg";
import arrowUp from "../../../assets/icons/arrow_up_gray.svg";
import { useTranslation } from "react-i18next";
import { getMoralisRpcs } from "../../common/utils/getMoralisRpcs";
import { fromHexToDecimal } from "../../auth/utils/convert";

const ICON_SIZE = "26px";
const ICON_SIZE_TABLET = "19px";
const ICON_SIZE_MOBILE = "23px";

function CreateChainSelector() {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { chainId, isInitializing, user, web3 } = useMoralis();
  const { switchNetwork, chain } = useChain();

  const [showDropdown, setShowDropdown] = useState(false);

  const networks = NETWORKS[ENV];

  const { t } = useTranslation();

  const wrapperRef = useRef();

  useOutsideClick({
    ref: wrapperRef,
    handler: () => setShowDropdown(false),
  });

  const changeNetwork = async (chain) => {
    if (user) {
      const newChain = web3.provider.isPortis
        ? {
            chainId: fromHexToDecimal(chain),
            nodeUrl: getMoralisRpcs()[fromHexToDecimal(chain)],
          }
        : chain;

      console.log(newChain, chain);

      await switchNetwork(newChain);
    }
    setShowDropdown(false);
  };

  if (isInitializing) {
    return <div />;
  }

  return (
    <Box
      position="relative"
      cursor="pointer"
      w={isMobile ? "100%" : isTablet ? "160px" : "236px"}
      fontSize={isMobile ? "11px" : isTablet ? "10px" : "15px"}
      color="#949AB2"
      mb={"15px"}
      fontWeight="extrabold"
      ref={wrapperRef}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        padding={isMobile ? "12px 22px" : isTablet ? "7px 15px" : "11px 20px"}
        bg="#F6F5F7"
        borderRadius="9px"
        borderBottomRightRadius={showDropdown ? "0px" : "9px"}
        borderBottomLeftRadius={showDropdown ? "0px" : "9px"}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <Flex alignItems="center">
          <Image
            src={networks[chainId]?.mobileIcon}
            w={
              isMobile
                ? ICON_SIZE_MOBILE
                : isTablet
                ? ICON_SIZE_TABLET
                : ICON_SIZE
            }
            h={
              isMobile
                ? ICON_SIZE_MOBILE
                : isTablet
                ? ICON_SIZE_TABLET
                : ICON_SIZE
            }
            mr={isMobile ? "10px" : isTablet ? "12px" : "16px"}
          />
          <Box>{t(`create.${networks[chainId]?.name}`)}</Box>
        </Flex>
        <Image
          src={showDropdown ? arrowUp : arrowDown}
          width={isTablet ? "13px" : "16px"}
          height={isTablet ? "9px" : "12px"}
        />
      </Flex>
      {showDropdown && (
        <Box
          position="absolute"
          top="100%"
          left="0px"
          right="0px"
          borderRadius="9px"
          borderTopRightRadius={showDropdown ? "0px" : "9px"}
          borderTopLeftRadius={showDropdown ? "0px" : "9px"}
          bg="#F6F5F7"
          zIndex={2}
        >
          {Object.keys(networks)
            ?.filter((key) => key !== chainId)
            ?.map((chain) => (
              <Flex
                key={chain}
                alignItems="center"
                justifyContent="space-between"
                padding={
                  isMobile ? "12px 22px" : isTablet ? "7px 15px" : "11px 20px"
                }
                onClick={() => changeNetwork(chain)}
                color="#949AB2"
              >
                <Flex alignItems="center">
                  <Image
                    src={networks[chain]?.mobileIcon}
                    w={
                      isMobile
                        ? ICON_SIZE_MOBILE
                        : isTablet
                        ? ICON_SIZE_TABLET
                        : ICON_SIZE
                    }
                    h={
                      isMobile
                        ? ICON_SIZE_MOBILE
                        : isTablet
                        ? ICON_SIZE_TABLET
                        : ICON_SIZE
                    }
                    mr={isMobile ? "10px" : isTablet ? "12px" : "16px"}
                  />
                  <Box>{t("create." + networks[chain]?.name)}</Box>
                </Flex>
              </Flex>
            ))}
        </Box>
      )}
    </Box>
  );
}

export default CreateChainSelector;
