import { Box, Flex } from "@chakra-ui/layout";
import { Input } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import useResponsive from "../../common/utils/useResponsive";

function CreateTitle({ title, onItemChange, editDisabled }) {
  const { t } = useTranslation();
  const { isTablet, isDesktop, isMobile } = useResponsive();

  return (
    <Flex
      direction="column"
      justifyContent="center"
      bg="#F6F5F7 0% 0% no-repeat padding-box"
      borderRadius="19px"
      height={isDesktop ? "200px" : "162px"}
      mb={isDesktop ? "35px" : "24px"}
      padding="0px 45px"
      width="100%"
    >
      <Box fontSize={isDesktop ? "42px" : "30px"} mb="15px" fontWeight="bold">
        {t("create.title")}
      </Box>
      <Input
        disabled={editDisabled}
        outline="none"
        border="none"
        bg="white"
        padding={!isMobile ? "20px" : "10px 20px"}
        borderRadius="12px"
        fontSize={isDesktop ? "20px" : isTablet ? "30px" : "20px"}
        _focus={{ border: "none" }}
        placeholder={t("create.title")}
        _placeholder={{
          fontSize: isDesktop ? 20 : isTablet ? 30 : 20,
          fontWeight: "bold",
        }}
        _disabled={{
          color: "black",
        }}
        value={title}
        onChange={(e) => onItemChange(e, "title")}
        autoFocus={!isMobile}
        height="fit-content"
      />
    </Flex>
  );
}

export default CreateTitle;
