import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { useMoralis } from "react-moralis";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import ProfileTrading from "./ProfileTrading";
import {
  ERC721_CONTRACT_ADDRESS,
  ERC721_POLYGON_CONTRACT_ADDRESS,
} from "../../../../constants";
import useResponsive from "../../../common/utils/useResponsive";
import Loading from "../../../common/components/Loading";
import {
  setProfileTradingHistory,
  setProfileTradingHistoryCount,
  setProfileTradingUserAccount,
} from "../../../../lib/redux/reducers/profile/profileTradingHistory/profileTradingHistoryReducer";
import { useTranslation } from "react-i18next";

function ProfileTradingHistory() {
  const { user, Moralis } = useMoralis();
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const { userAccount } = useParams();

  const { t } = useTranslation();

  const currentAccount = useSelector(
    (state) => state.profileTradingHistory.profileTradingHistoryUserAccount
  );
  const transfers = useSelector(
    (state) => state.profileTradingHistory.profileTradingHistory
  );
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const fetchTradingHistory = async (account) => {
    setLoading(true);
    const result = await Moralis.Cloud.run("getTradingHistory", {
      ethAddress: ERC721_CONTRACT_ADDRESS,
      polygonAddress: ERC721_POLYGON_CONTRACT_ADDRESS,
      account: account,
      pageSize: 12,
    });

    const { finalResults, count } = result;

    dispatch(setProfileTradingUserAccount(account));
    dispatch(setProfileTradingHistory(JSON.stringify(finalResults)));

    dispatch(setProfileTradingHistoryCount(count));

    setLoading(false);
  };

  useEffect(() => {
    if (userAccount) {
      if (userAccount !== currentAccount) {
        fetchTradingHistory(userAccount);
      }
    } else {
      if (user?.get("ethAddress") !== currentAccount) {
        fetchTradingHistory(user?.get("ethAddress"));
      }
    }
  }, [currentAccount, userAccount, user]);

  return (
    <Box
      flex={1}
      paddingRight={!isDesktop ? "0px" : "78px"}
      mb={!isDesktop ? "65px" : "0px"}
      overflow="hidden"
    >
      <Flex alignItems="center" justifyContent="space-between">
        <Box fontSize="20px" fontWeight="bold" color="#000000" ml="29px">
          {t("profile.tradingHistory")}
        </Box>

        <Button
          borderRadius="9px"
          color="white"
          bg="#1D20FF"
          fontSize="10px"
          fontWeight="bold"
          textAlign="center"
          width="125px"
          height="25px"
          _hover={{
            backgroundColor: "#1D20FF",
          }}
          _focus={{
            backgroundColor: "#1D20FF",
            outline: "none",
          }}
          _active={{
            backgroundColor: "#1D20FF",
            outline: "none",
          }}
        >
          <Link
            to={
              userAccount
                ? ROUTE_NAMES.tradingHistory + `/${userAccount}`
                : ROUTE_NAMES.tradingHistory
            }
          >
            {t("profile.viewAll")}
          </Link>
        </Button>
      </Flex>
      {loading ? (
        <Loading />
      ) : (
        JSON.parse(transfers)
          ?.slice(0, 4)
          .map((transfer, index) => {
            return <ProfileTrading key={index} transfer={transfer} />;
          })
      )}
    </Box>
  );
}

export default ProfileTradingHistory;
