import { Box, Flex, HStack, Image, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { DESKTOP_NAVIGATION_HEIGHT } from "../../../utilities/constants";

import useResponsive from "../../common/utils/useResponsive";
import HomeTextImage from "./HomeTextImage";
import HomeImageModal from "./HomeImageModal";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import TopLogo from "../../auth/components/TopLogo";
import { useTranslation } from "react-i18next";
import { useMoralis } from "react-moralis";
import { useSelector, useDispatch } from "react-redux";
import { setIntro } from "../../../lib/redux/reducers/home/homeReducer";

function HomeIntro({ setIntroLoading }) {
  const { isTablet, isMobile, isDesktop } = useResponsive();
  const { i18n, t } = useTranslation();

  const { Moralis } = useMoralis();

  const introItems = useSelector((state) => state.home.intro);
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState(null);

  const [showLanguageSelector, setShowLanguageSelector] = useState(false);

  const fetchHomeIntroItems = async () => {
    setIntroLoading(true);
    const homeIntroQuery = await Moralis.Cloud.run("getHomeIntroItems");

    dispatch(setIntro(JSON.stringify(homeIntroQuery)));
    setIntroLoading(false);
  };

  useEffect(() => {
    if (!introItems) {
      fetchHomeIntroItems();
    }
  }, [introItems]);

  const showImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  const onLangChange = (lang) => {
    i18n.changeLanguage(lang);
    setShowLanguageSelector(false);
    document.location.reload();
  };

  const items = JSON.parse(introItems);

  const renderDesktop = () => {
    return (
      <HStack
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        width="100%"
        mb="75px"
      >
        {items?.map((item) => (
          <Box
            key={item?.objectId}
            width="8%"
            height="390px"
            cursor="pointer"
            onClick={() => showImageModal(item)}
          >
            {!item?.isVideo ? (
              <img
                className="lazyload"
                src={item?.nftFilePath}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 14,
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                className="lazyload"
                src={item?.nftFilePath}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "14px",
                }}
                autoPlay
                loop
                muted
                playsInline
              />
            )}
          </Box>
        ))}
      </HStack>
    );
  };

  const renderTablet = () => {
    return (
      <VStack spacing={9} mb="60px" w="100%">
        {items?.slice(0, 6).map((item) => (
          <Box
            key={item?.objectId}
            width="286px"
            height="85px"
            cursor="pointer"
            onClick={() => showImageModal(item)}
          >
            {!item?.isVideo ? (
              <img
                className="lazyload"
                src={item?.nftFilePath}
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: 14,
                  objectFit: "cover",
                }}
              />
            ) : (
              <video
                className="lazyload"
                src={item?.nftFilePath}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "14px",
                }}
                autoPlay
                loop
                muted
                playsInline
              />
            )}
          </Box>
        ))}
      </VStack>
    );
  };

  const renderMobile = () => {
    return (
      <Box width="100%" boxSizing="border-box" mb="100px" paddingTop="0px">
        <TopLogo isCenter={false} />
        <Box mb="80px" mt="56.93px" w="100%">
          {
            <Carousel
              centerMode
              centerSlidePercentage={50}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              infiniteLoop
              autoPlay={!selectedImage}
              interval={3000}
              showArrows={false}
            >
              {items?.map((item) => (
                <Box
                  key={item?.objectId}
                  height="40vh"
                  marginLeft="25px"
                  marginRight="25px"
                  cursor="pointer"
                  onClick={() => showImageModal(item)}
                >
                  {!item?.isVideo ? (
                    <img
                      className="lazyload"
                      src={item?.nftFilePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: 14,
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <video
                      className="lazyload"
                      src={item?.nftFilePath}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "14px",
                      }}
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  )}
                </Box>
              ))}
            </Carousel>
          }
        </Box>
        <Box>
          <HomeTextImage />
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        h={
          !isMobile
            ? `calc(100vh - ${DESKTOP_NAVIGATION_HEIGHT}px)`
            : "fit-content"
        }
        position="relative"
        display="flex"
        direction="column"
        alignItems="center"
        marginLeft={isMobile ? "-20px" : "0px"}
        marginRight={isMobile ? "-20px" : "0px"}
      >
        {!isMobile && (
          <Flex
            w="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
            boxSizing="border-box"
          >
            {isDesktop && renderDesktop()}
            {isTablet && renderTablet()}

            <Box>
              <HomeTextImage />
            </Box>
          </Flex>
        )}
        {isMobile && renderMobile()}
        {!isMobile && (
          <Box
            bg="#F6F5F7"
            position="absolute"
            bottom="0px"
            left="50%"
            transform="translateX(-50%)"
            borderTopLeftRadius="15px"
            borderTopRightRadius="15px"
            textAlign="center"
            padding="9px 82px"
            fontSize={isDesktop ? "10px" : "6px"}
            fontWeight="black"
          >
            {!showLanguageSelector && (
              <Box
                cursor="pointer"
                onClick={() => setShowLanguageSelector(true)}
              >
                {i18n.language?.slice(0, 2) === "en" ? "ENGLISH" : "한국어"}
              </Box>
            )}
            {showLanguageSelector && (
              <>
                <Box
                  mb="10px"
                  cursor="pointer"
                  onClick={() => onLangChange("en")}
                >
                  ENGLISH
                </Box>
                <Box cursor="pointer" onClick={() => onLangChange("ko")}>
                  한국어
                </Box>
              </>
            )}
          </Box>
        )}
      </Box>
      {selectedImage && (
        <HomeImageModal
          isOpen={selectedImage}
          onClose={closeImageModal}
          image={selectedImage}
        />
      )}
    </>
  );
}

export default HomeIntro;
