import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useMoralis } from "react-moralis";
import { useHistory } from "react-router-dom";
import ROUTE_NAMES from "../../../../routes/utils/routeNames";
import {
  PROFILE_TAB_ROUTE_NAMES,
  PROFILE_TAB_TYPES,
} from "../utils/profileTabOptions";
import ProfileThumbnail from "./ProfileThumbnail";
import useResponsive from "../../../common/utils/useResponsive";
import { useParams } from "react-router-dom";
import Loading from "../../../common/components/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  setProfileCollectedItems,
  setProfileCreatedItems,
  setProfileFavoritesItems,
  setProfileOfferItems,
} from "../../../../lib/redux/reducers/profile/viewAll/profileViewAllReducer";
import { useTranslation } from "react-i18next";

const CONTENT_WIDTH = "72%";

function ProfileTabPanel({ type }) {
  const history = useHistory();
  const { userAccount } = useParams();

  const { isMobile } = useResponsive();

  const { t } = useTranslation();

  const { user, Moralis, web3, chainId } = useMoralis();

  const created = useSelector((state) => state.profileViewAll.created);
  const createdAccount = useSelector(
    (state) => state.profileViewAll.createdAccount
  );

  const collected = useSelector((state) => state.profileViewAll.collected);
  const collectedAccount = useSelector(
    (state) => state.profileViewAll.collectedAccount
  );

  const offer = useSelector((state) => state.profileViewAll.offer);
  const offeredAccount = useSelector(
    (state) => state.profileViewAll.offeredAccount
  );

  const favorites = useSelector((state) => state.profileViewAll.favorites);
  const favoritesAccount = useSelector(
    (state) => state.profileViewAll.favoritesAccount
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const limit = isMobile ? 12 : 9;

  const getProfileTabResults = async (account) => {
    if (loading) return;

    setLoading(true);

    switch (type) {
      case PROFILE_TAB_TYPES.CREATED: {
        const createdFetchRestuls = [];

        const createdQuery = await Moralis.Cloud.run(
          "getUserCreatedThumbnails",
          {
            account: account,
            limit: limit,
          }
        );

        if (createdQuery?.results) {
          for (let item of createdQuery?.results) {
            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),

              username: item?.get("owner")?.get("username"),
              price: item?.get("price"),
              isDeleted: item?.get("isDeleted"),
              isVideo: item?.get("isVideo"),
            };

            createdFetchRestuls.push(obj);
          }
        }

        dispatch(
          setProfileCreatedItems({
            items: createdFetchRestuls,
            count: createdQuery?.count,
            account,
          })
        );

        break;
      }

      case PROFILE_TAB_TYPES.COLLECTED: {
        const collectedFetchResults = [];

        const collectedQuery = await Moralis.Cloud.run(
          "getUserCollectedThumbnails",
          {
            account: account,
            limit: limit,
          }
        );

        if (collectedQuery?.results) {
          for (let item of collectedQuery?.results) {
            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),

              username: item?.get("owner")?.get("username"),

              price: item?.get("price"),
              isVideo: item?.get("isVideo"),
              isDeleted: item?.get("isDeleted"),
            };

            collectedFetchResults.push(obj);
          }
        }

        dispatch(
          setProfileCollectedItems({
            items: collectedFetchResults,
            count: collectedQuery?.count,
            account,
          })
        );

        break;
      }

      case PROFILE_TAB_TYPES.OFFERED: {
        const offerFetchResults = [];

        const offerQuery = await Moralis.Cloud.run("getProfileTabOffers", {
          account: account,
        });

        if (offerQuery?.result) {
          for (let i in offerQuery?.result) {
            const { item } = offerQuery?.result[i];
            const obj = {
              id: item?.itemId + i,
              tokenId: item?.tokenId,
              imageSrc: item?.imageSrc,
              title: item?.title,
              username:
                item.owner?.get("username") || item?.creator?.get("username"),
              price: item?.price,
              isDeleted: item?.isDeleted,
            };

            offerFetchResults.push(obj);
          }
        }

        dispatch(setProfileOfferItems({ items: offerFetchResults, account }));

        break;
      }

      default: {
        const favoritesFetchResults = [];

        const currentUserQuery = await Moralis.Cloud.run("getUser", {
          account: account,
        });

        const userItemRelation = currentUserQuery.relation("favorites");
        const userItemRelationQuery = userItemRelation.query();

        userItemRelationQuery.notEqualTo("isDeleted", true);
        userItemRelationQuery.limit(limit);
        userItemRelationQuery.withCount();

        const favoritesQuery = await userItemRelationQuery.find();

        if (favoritesQuery?.results) {
          for await (let item of favoritesQuery?.results) {
            const userQuery = await Moralis.Cloud.run("getUser", {
              account: item?.get("ownerAccount"),
            });

            const username = userQuery?.get("username");

            const obj = {
              id: item?.id,
              tokenId: item?.get("tokenId"),
              imageSrc: item?.get("nftFilePath"),
              title: item?.get("title"),
              username,
              price: item?.get("price"),
              isVideo: item?.get("isVideo"),
              isDeleted: item?.get("isDeleted"),
            };

            favoritesFetchResults.push(obj);
          }
        }

        dispatch(
          setProfileFavoritesItems({
            items: favoritesFetchResults,
            count: favoritesQuery?.count,
            account,
          })
        );

        break;
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (type === PROFILE_TAB_TYPES.CREATED) {
      if (!createdAccount) {
        getProfileTabResults(userAccount || user?.get("ethAddress"));
      }
      if (createdAccount && userAccount && createdAccount !== userAccount) {
        getProfileTabResults(userAccount);
      }
      if (
        createdAccount &&
        user &&
        createdAccount !== user?.get("ethAddress")
      ) {
        getProfileTabResults(user?.get("ethAddress"));
      }
    }

    return () => {
      if (createdAccount) {
        dispatch(
          setProfileCreatedItems({ items: null, count: 0, account: null })
        );
      }
    };
  }, [user, userAccount, createdAccount, created, type]);

  useEffect(() => {
    if (type === PROFILE_TAB_TYPES.COLLECTED) {
      if (!collectedAccount) {
        getProfileTabResults(userAccount || user?.get("ethAddress"));
      }
      if (collectedAccount && userAccount && collectedAccount !== userAccount) {
        getProfileTabResults(userAccount);
      }
      if (
        collectedAccount &&
        user &&
        collectedAccount !== user?.get("ethAddress")
      ) {
        getProfileTabResults(user?.get("ethAddress"));
      }
    }

    return () => {
      if (collectedAccount) {
        dispatch(
          setProfileCollectedItems({ items: null, count: 0, account: null })
        );
      }
    };
  }, [user, userAccount, collectedAccount, created, type]);

  useEffect(() => {
    if (type === PROFILE_TAB_TYPES.OFFERED) {
      if (!offeredAccount) {
        getProfileTabResults(userAccount || user?.get("ethAddress"));
      }
      if (offeredAccount && userAccount && offeredAccount !== userAccount) {
        getProfileTabResults(userAccount);
      }
      if (
        offeredAccount &&
        user &&
        offeredAccount !== user?.get("ethAddress")
      ) {
        getProfileTabResults(user?.get("ethAddress"));
      }
    }

    return () => {
      if (offeredAccount) {
        dispatch(
          setProfileOfferItems({ items: null, count: 0, account: null })
        );
      }
    };
  }, [user, userAccount, offeredAccount, created, type]);

  useEffect(() => {
    if (type === PROFILE_TAB_TYPES.FAVORITE) {
      if (!favoritesAccount) {
        getProfileTabResults(userAccount || user?.get("ethAddress"));
      }
      if (favoritesAccount && userAccount && favoritesAccount !== userAccount) {
        getProfileTabResults(userAccount);
      }
      if (
        favoritesAccount &&
        user &&
        favoritesAccount !== user?.get("ethAddress")
      ) {
        getProfileTabResults(user?.get("ethAddress"));
      }
    }

    return () => {
      if (favoritesAccount) {
        dispatch(
          setProfileFavoritesItems({ items: null, count: 0, account: null })
        );
      }
    };
  }, [user, userAccount, favoritesAccount, created, type]);

  const items = {
    [PROFILE_TAB_TYPES.CREATED]: created,
    [PROFILE_TAB_TYPES.COLLECTED]: collected,
    [PROFILE_TAB_TYPES.OFFERED]: offer,
    [PROFILE_TAB_TYPES.FAVORITE]: favorites,
  };

  return (
    <Flex
      bg="#F6F5F7"
      borderRadius="19px"
      padding="83px 0px"
      paddingBottom="27px"
      flexDirection="column"
      alignItems="center"
    >
      {loading ? (
        <Loading />
      ) : !isMobile ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          w={CONTENT_WIDTH}
        >
          <ProfileThumbnail
            stack={[items?.[type]?.[0], items?.[type]?.[3], items?.[type]?.[6]]}
          />
          <ProfileThumbnail
            stack={[items?.[type]?.[1], items?.[type]?.[4], items?.[type]?.[7]]}
          />
          <ProfileThumbnail
            stack={[items?.[type]?.[2], items?.[type]?.[5], items?.[type]?.[9]]}
          />
        </Flex>
      ) : (
        <Box padding="0px 28px" w="100%">
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <ProfileThumbnail
              stack={[
                items?.[type]?.[0],
                items?.[type]?.[4],
                items?.[type]?.[8],
              ]}
            />
            <ProfileThumbnail
              stack={[
                items?.[type]?.[1],
                items?.[type]?.[5],
                items?.[type]?.[9],
              ]}
            />
          </Flex>
          <Flex alignItems="center" justifyContent="space-between" w="100%">
            <ProfileThumbnail
              stack={[
                items?.[type]?.[2],
                items?.[type]?.[6],
                items?.[type]?.[10],
              ]}
            />
            <ProfileThumbnail
              stack={[
                items?.[type]?.[3],
                items?.[type]?.[7],
                items?.[type]?.[11],
              ]}
            />
          </Flex>
        </Box>
      )}
      <Button
        w={isMobile ? "90%" : CONTENT_WIDTH}
        bg="#1D20FF"
        borderRadius="13px"
        height={isMobile ? "45px" : "60px"}
        color="white"
        textAlign="center"
        fontSize="15px"
        fontWeight="bold"
        _hover={{
          backgroundColor: "#1D20FF",
        }}
        _focus={{
          backgroundColor: "#1D20FF",
          outline: "none",
        }}
        _active={{
          backgroundColor: "#1D20FF",
          outline: "none",
        }}
        onClick={() =>
          history.push(
            ROUTE_NAMES.viewAll +
              `/${PROFILE_TAB_ROUTE_NAMES[type]}` +
              (userAccount ? `/${userAccount}` : "")
          )
        }
      >
        {t("profile.viewAll")}
      </Button>
    </Flex>
  );
}

export default ProfileTabPanel;
