import { useMediaQuery } from "react-responsive";
import { BREAK_POINTS } from "../../../theme/breakpoints";

function useResponsive() {
  const isMobile = useMediaQuery({ maxWidth: BREAK_POINTS.tablet });

  const isTablet = useMediaQuery({
    minWidth: BREAK_POINTS.tablet,
    maxWidth: BREAK_POINTS.desktop,
  });

  const isDesktop = useMediaQuery({ minWidth: BREAK_POINTS.desktop });

  return { isMobile, isTablet, isDesktop };
}

export default useResponsive;
