import { Box, Flex } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
import Selector from "../../common/components/Selector";
import graphicIcon from "../../../assets/icons/graphic.svg";
import clockIcon from "../../../assets/icons/clock.svg";
import { CATEGORIES } from "../../common/utils/categories";
import { ITEM_STATUS } from "../../common/utils/itemStatus";
import useResponsive from "../../common/utils/useResponsive";

function CreateCategoryStatus({
  category,
  status,
  onCategoryChange,
  onStatusChange,
}) {
  const { t } = useTranslation();
  const { isDesktop, isTablet, isMobile } = useResponsive();

  return (
    <Box mt={isDesktop ? "0px" : "30px"}>
      <Box
        paddingLeft="20px"
        fontSize={!isTablet ? "20px" : "17px"}
        fontWeight="bold"
        mb="11px"
      >
        {t("create.category&status")}
      </Box>
      <Flex
        bg="#F6F5F7 0% 0% no-repeat padding-box"
        borderRadius="19px"
        w="100%"
        alignItems="center"
        justifyContent="center"
        direction={isMobile ? "column" : "row"}
        padding={isDesktop ? "25px 45px" : "21px 41px"}
      >
        <Selector
          icon={CATEGORIES[category].icon}
          label={t(`create.${CATEGORIES[category].name}`)}
          options={CATEGORIES}
          value={category}
          setOption={(category) => onCategoryChange(category)}
        />
        {isMobile && <Box height="20px" />}
        <Selector
          icon={clockIcon}
          label={t(`create.${ITEM_STATUS[status].name}`)}
          options={ITEM_STATUS}
          value={status}
          setOption={(status) => onStatusChange(status)}
        />
      </Flex>
    </Box>
  );
}

export default CreateCategoryStatus;
